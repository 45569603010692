import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  FormGroup,
  ModalHeader,
  ModalFooter,
  CustomInput,
} from "reactstrap";
import { addCardAndMakeDefault, makeCardDefault } from "../../http-calls";
import { fetchCardsAsync } from "../../redux/actions/paymentData";
import { AppConfig } from "../../config/app-config";
import ApplePayment from "../../payment/ApplePayment";
import {
  checkUndefinedAndNull,
  errorHandler,
  formatCurrencyValue,
} from "../../helper-methods";
import { CURRENCY_SYMBOL } from "../../config";
import InjectedStripeElement from "../StripeElement";
import Skeleton from "react-loading-skeleton";

class PaymentModal extends Component {
  state = {
    preferredType: "exisiting", // 'new'
    isLoaderActive: false,
    defaultCard: null,
    selectedCard: null,
  };

  _onTypeChange = (e, card = null) => {
    if (card) {
      this.setState({
        preferredType: e.target.value,
        selectedCard: card,
      });
    } else {
      this.setState({ preferredType: e.target.value });
    }
  };

  _getDefaultCard = () => {
    return new Promise(async (resolve, reject) => {
      this._showLoader();
      const res = await this.props.fetchCardsAsync();
      const cards = res?.payload;
      if (cards?.length) {
        await this._setDefaultCard(cards);
        this._hideLoader();
      } else {
        // No cards available
        this.setState({ preferredType: "new", defaultCard: null }, () => {
          this._hideLoader();
          resolve();
        });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { isVisible } = this.props;

    if (isVisible && isVisible !== prevProps.isVisible) {
      this._getDefaultCard();
    }
  }

  _setDefaultCard = (cards) => {
    return new Promise((resolve, reject) => {
      let defaultCard = null;
      cards.forEach((card) => {
        if (card.isDefault) {
          defaultCard = card;
          return;
        }
      });
      this.setState({ defaultCard }, () => {
        resolve();
      });
    });
  };

  _onDismiss = () => {
    // Reset state
    this.setState({
      preferredType: "exisiting",
      isLoaderActive: false,
    });
    this.props.onDismiss();
  };

  _showLoader = () => {
    this.setState({ isLoaderActive: true });
  };

  _hideLoader = () => {
    this.setState({ isLoaderActive: false });
  };

  _processPayment = async () => {
    const { preferredType, selectedCard } = this.state;
    if (preferredType === "new") {
      // Try to add card
      this._addCard();
    } else {
      // preferredType === 'existing'
      if (selectedCard) {
        await this._makeCardAsDefault();
      }
      this.props.onPaymentConfirmation();
    }
  };

  _makeCardAsDefault = () => {
    const { selectedCard } = this.state;
    return new Promise((resolve, reject) => {
      makeCardDefault(selectedCard.id)
        .then((res) => {
          if (!res.error) {
            resolve();
          }
        })
        .catch((err) => {
          console.log("error >> ", err);
          reject();
        });
    });
  };

  _onStripeError = (e) => {
    this._hideLoader();
  };

  _onCardAdded = async (payload) => {
    // Add card
    try {
      await addCardAndMakeDefault(payload.token.id);
      // Card added
      // Payment confirmation
      this.props.onPaymentConfirmation();
    } catch (error) {
      this.props.onDismiss();
      errorHandler(error);
    }
  };

  render() {
    const { isVisible, amount, isFree, paymentData } = this.props;
    const { preferredType, isLoaderActive, defaultCard } = this.state;

    return (
      <Modal
        isOpen={isVisible}
        toggle={this._onDismiss}
        className="modal-dialog-centered modal-dialog-scrollable"
      >
        <ModalHeader toggle={this._onDismiss}>
          {isFree ? (
            "Enter Card Details"
          ) : (
            <>
              Select preferred payment method to pay{" "}
              {checkUndefinedAndNull(amount) && (
                <span className="themeColor">
                  {amount < AppConfig.mintransactionAmount
                    ? formatCurrencyValue(
                        Number(amount) + AppConfig.extraChargeAmount
                      )
                    : formatCurrencyValue(amount)}
                </span>
              )}
            </>
          )}
        </ModalHeader>

        {/* {amount ? ( */}
        <ModalBody>
          {paymentData?.cards?.length && defaultCard ? (
            paymentData?.cards?.map((card) => (
              <FormGroup
                check
                className="radio"
                key={card.id}
                style={{ marginBottom: 12 }}
              >
                <CustomInput
                  className="form-check-input"
                  type="radio"
                  name="cardType"
                  value="exisiting"
                  defaultChecked={card?.isDefault}
                  onChange={(e) => this._onTypeChange(e, card)}
                  id={`radio_payment_modal_${card?.id}`}
                  label={
                    card.isDefault
                      ? `Primary Card ending with ${card?.last4} (${
                          card?.exp_month
                        }/${card?.exp_year.toString().substr(2)})`
                      : `Card ending with ${card?.last4} (${
                          card?.exp_month
                        }/${card?.exp_year.toString().substr(2)})`
                  }
                />
              </FormGroup>
            ))
          ) : isLoaderActive ? (
            <Skeleton
              type={"box"}
              count={3}
              height={15}
              width={250}
              className="mb-2"
            />
          ) : null}

          <div>
            {defaultCard ? (
              <FormGroup check className="radio" style={{ marginBottom: 12 }}>
                <CustomInput
                  className="form-check-input"
                  type="radio"
                  name="cardType"
                  value="new"
                  onChange={this._onTypeChange}
                  id={`radio_payment_modal_defaultCard`}
                  label={"Add New Card"}
                />
              </FormGroup>
            ) : null}

            {preferredType === "new" ? (
              <div className="stripeWrapper">
                <>
                  <InjectedStripeElement
                    onCardAdded={(payload) => this._onCardAdded(payload)}
                    onStripeError={this._onStripeError}
                    showLoader={this._showLoader}
                    hidePostalCode={false}
                    hideLoader={this._hideLoader}
                    addCard={(ref) => (this._addCard = ref)}
                    className="stripeCard"
                  />
                </>
              </div>
            ) : null}
          </div>
          {/* {amount < AppConfig.mintransactionAmount
                ? `Your transaction cost is less than ${CURRENCY_SYMBOL}${AppConfig.mintransactionAmount}, so you will be  charged extra ${AppConfig.extraChargeAmount * 100} cents. So total transaction cost will be ${CURRENCY_SYMBOL}${Number(
                    Number(amount) + AppConfig.extraChargeAmount
                  ).toFixed(2)}`
                : null} */}

          <p className="transactionCost">
            {amount < AppConfig.mintransactionAmount
              ? `Your transaction cost is less than ${CURRENCY_SYMBOL}${
                  AppConfig.mintransactionAmount
                }, so you will be charged an additional transaction fee of ${
                  AppConfig.extraChargeAmount * 100
                } cents. So total transaction cost will be ${CURRENCY_SYMBOL}${Number(
                  Number(amount) + AppConfig.extraChargeAmount
                ).toFixed(2)}`
              : null}
          </p>

          {/* Payment with apple/google/link Pay */}
          {this.props.content && (
            <ApplePayment
              content={this.props.content}
              onDismiss={() => this._onDismiss()}
              createPaymentIntent={() => this.props.createPaymentIntent()}
              onPaymentSuccess={() => this.props.onPaymentSuccess()}
            />
          )}
        </ModalBody>

        {/* ) : null} */}
        <ModalFooter className="customModalFooter">
          <div className="secureCheckoutTxtWrap">
            <a href="http://www.credit-card-logos.com/">
              <img
                alt="Credit Card Logo"
                title="Credit Card Logo"
                src="http://www.credit-card-logos.com/images/visa_credit-card-logos/visa_mastercard_logo_6.gif"
                width="120"
                loading="lazy"
              />
            </a>

            <p className="secureCheckoutTxt">
              Merchant country: United States <br />
              Secure checkout page Secure Sockets Layer (SSL) 128 bit encryption
            </p>
          </div>

          <div className="d-flex align-self-end">
            <Button
              className="modalBtnCancel mr-3"
              onClick={() => this._onDismiss()}
            >
              Cancel
            </Button>
            <Button
              className="modalBtnSave"
              onClick={() => this._processPayment()}
              disabled={isLoaderActive}
            >
              {isLoaderActive ? (
                <i className="fa fa-spinner fa-spin mr-1" />
              ) : null}
              {isFree ? "Save" : "Click To Accept"}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state?.userData,
    paymentData: state?.paymentData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCardsAsync: () => dispatch(fetchCardsAsync()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal);
