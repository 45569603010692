export const AppConfig = {
  mintransactionAmount: 3,
  extraChargeAmount: 0.3,
  maxTransactionAmount: 100000,
};

export const DescriptionConfig = {
  minLetter: 20,
  maxLetter: 500,
};

export const minAmountFor3DSecureEnable = 75;
