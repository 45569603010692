import React, { Component } from "react";
import { connect } from "react-redux";
import cryptoJS from "crypto-js";
import { CARD_ENCRYPTION_KEY } from "../config";
import {
  Col,
  Row,
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  Label,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { fetchCardsAsync } from "../redux/actions/paymentData";
import { makeCardDefaultForHumboldt } from "../http-calls";
import { AppConfig } from "../config/app-config";
import { formatCurrencyValue } from "../helper-methods";
import CustomSectionLoader from "../components/custom/CustomSectionLoader";

class HumboldtPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preferredType: "exisiting", // 'new' // 'exisiting'
      isLoaderActive: false,
      defaultCard: null,
      selectedCard: null,
      isCardValid: false,
      formFields: {
        cardNumber: {
          value: "",
          isValid: false,
          isDirty: false,
          error: "",
        },
        expiryMonth: {
          value: "",
          isValid: false,
          isDirty: false,
          error: "",
        },
        expiryYear: {
          value: "",
          isValid: false,
          isDirty: false,
          error: "",
        },
        cvv: {
          value: "",
          isValid: false,
          isDirty: false,
          error: "",
        },
      },
    };
  }

  _getDefaultCard = () => {
    return new Promise(async (resolve, reject) => {
      this._showLoader();
      const res = await this.props.fetchCardsAsync();
      const cards = res?.payload;
      if (cards?.length) {
        await this._setDefaultCard(cards);
        this._hideLoader();
      } else {
        // No cards available
        this.setState({ preferredType: "new", defaultCard: null }, () => {
          this._hideLoader();
          resolve();
        });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { isVisible } = this.props;

    if (isVisible && isVisible !== prevProps.isVisible) {
      this._getDefaultCard();
    }
  }

  _setDefaultCard = (cards) => {
    return new Promise((resolve, reject) => {
      let defaultCard = null;
      cards.forEach((card) => {
        if (card.isDefault) {
          defaultCard = card;
          return;
        }
      });
      this.setState({ defaultCard }, () => {
        resolve();
      });
    });
  };

  _onTypeChange = (e, card = null) => {
    if (card) {
      this.setState({
        preferredType: e.target.value,
        selectedCard: card,
      });
    } else {
      this.setState({ preferredType: e.target.value });
    }
  };

  _onDismiss = () => {
    // Reset state
    this.setState({
      preferredType: "exisiting",
      isLoaderActive: false,
    });
    this.props.onDismiss();
  };

  _showLoader = () => {
    this.setState({ isLoaderActive: true });
  };

  _hideLoader = () => {
    this.setState({ isLoaderActive: false });
  };

  _markAsDirty = (fieldName) => {
    const { formFields } = this.state;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields });
    this._validateCard();
  };

  _updateFieldValue = (fieldName, value) => {
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    this.setState({ formFields });
    if (formFields[fieldName].isDirty) {
      // Validate
      this._validateCard();
    }
  };

  _validateCard = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      let isCardValid = true;
      Object.keys(formFields).forEach((fieldName, index) => {
        switch (fieldName) {
          case "cardNumber": {
            if (formFields.cardNumber.value) {
              if (formFields.cardNumber.value.length === 16) {
                formFields.cardNumber.isValid = true;
              } else {
                formFields.cardNumber.isValid = false;
                formFields.cardNumber.error =
                  "* Card Number should be 16 digit";
                isCardValid = false;
              }
            } else {
              formFields.cardNumber.isValid = false;
              formFields.cardNumber.error = "* Required field";
              isCardValid = false;
            }
            break;
          }
          case "expiryMonth": {
            if (formFields.expiryMonth.value) {
              if (formFields.expiryMonth.value.length === 2) {
                formFields.expiryMonth.isValid = true;
              } else {
                formFields.expiryMonth.isValid = false;
                formFields.expiryMonth.error =
                  "* Expiry Month should be 2 digit";
                isCardValid = false;
              }
            } else {
              formFields.expiryMonth.isValid = false;
              formFields.expiryMonth.error = "* Required field";
              isCardValid = false;
            }
            break;
          }
          case "expiryYear": {
            if (formFields.expiryYear.value) {
              if (formFields.expiryYear.value.length === 4) {
                formFields.expiryYear.isValid = true;
              } else {
                formFields.expiryYear.isValid = false;
                formFields.expiryYear.error = "* Expiry Year should be 4 digit";
                isCardValid = false;
              }
            } else {
              formFields.expiryYear.isValid = false;
              formFields.expiryYear.error = "* Required field";
              isCardValid = false;
            }
            break;
          }
          case "cvv": {
            if (formFields.cvv.value) {
              if (formFields.cvv.value.length === 3) {
                formFields.cvv.isValid = true;
              } else {
                formFields.cvv.isValid = false;
                formFields.cvv.error = "* Expiry Year should be 3 digit";
                isCardValid = false;
              }
            } else {
              formFields.cvv.isValid = false;
              formFields.cvv.error = "* Required field";
              isCardValid = false;
            }
            break;
          }

          default: {
          }
        }
      });
      this.setState({ formFields, isCardValid }, () => {
        resolve();
      });
    });
  };

  _makeCardAsDefault = () => {
    const { selectedCard } = this.state;
    return new Promise((resolve, reject) => {
      makeCardDefaultForHumboldt(selectedCard.id)
        .then((res) => {
          if (!res.error) {
            resolve();
          }
        })
        .catch((err) => {
          console.log("error >> ", err);
          reject();
        });
    });
  };

  _encryptCardDetails = () => {
    const { formFields } = this.state;
    const card = {
      cardNumber: formFields.cardNumber.value,
      expiryMonth: formFields.expiryMonth.value,
      expiryYear: formFields.expiryYear.value,
      cvv: formFields.cvv.value,
    };
    // Encrypt
    var cardDetails = cryptoJS.AES.encrypt(
      JSON.stringify(card),
      CARD_ENCRYPTION_KEY
    ).toString();
    // console.log("cardDeatils >>", cardDetails);
    return cardDetails;
  };

  _processPayment = async () => {
    const { preferredType, selectedCard } = this.state;
    if (preferredType === "new") {
      // Try to add card
      // this._addCard();
      // Accecpt card details and call for Encription
      await this._validateCard();
      if (this.state.isCardValid) {
        const encryptCard = this._encryptCardDetails();
        this.props.onPaymentConfirmation(encryptCard);
      }
    } else {
      // console.log("selectedCard", selectedCard);
      // preferredType === 'existing'
      if (selectedCard) {
        await this._makeCardAsDefault();
      }
      this.props.onPaymentConfirmation();
    }
  };

  render() {
    const { isVisible, amount, isFree } = this.props;
    const { formFields, isLoaderActive, preferredType, defaultCard } =
      this.state;

    return (
      <Modal
        isOpen={isVisible}
        toggle={this._onDismiss}
        className="modal-dialog-centered modal-dialog-scrollable"
      >
        <ModalHeader toggle={this._onDismiss}>
          {isFree ? (
            "Enter Card Details"
          ) : (
            <>
              Select preferred payment method to pay{" "}
              {amount && (
                <span className="themeColor">
                  {amount < AppConfig.mintransactionAmount
                    ? formatCurrencyValue(
                        Number(amount) + AppConfig.extraChargeAmount
                      )
                    : formatCurrencyValue(amount)}
                </span>
              )}
            </>
          )}
        </ModalHeader>
        <ModalBody>
          <CustomSectionLoader
            isActive={isLoaderActive}
            loaderSize={30}
            height={
              preferredType === "new"
                ? defaultCard
                  ? 245
                  : 110
                : defaultCard
                ? 175
                : 110
            }
          />

          {defaultCard
            ? this.props.paymentData.cards.map((card) => (
                <FormGroup check className="radio mb-3" key={card.id}>
                  <Input
                    className="form-check-input"
                    style={{ marginTop: 2 }}
                    type="radio"
                    name="cardType"
                    value="exisiting"
                    defaultChecked={card.isDefault}
                    onChange={(e) => this._onTypeChange(e, card)}
                    id="radio1"
                  />
                  <Label className="form-check-label" htmlFor="radio1">
                    {card.isDefault
                      ? `Primary Card ending with ${card.cardLastFourDigits} (${card.expiryMonth}/${card.expiryYear})`
                      : `Card ending with ${card.cardLastFourDigits} (${card.expiryMonth}/${card.expiryYear})`}
                  </Label>
                  <br />
                </FormGroup>
              ))
            : null}

          {defaultCard ? (
            <>
              <FormGroup check className="radio mb-3">
                <Input
                  className="form-check-input"
                  style={{ marginTop: 2 }}
                  type="radio"
                  name="cardType"
                  value="new"
                  onChange={this._onTypeChange}
                  id="radio2"
                />
                <Label className="form-check-label" htmlFor="radio2">
                  Add New Card
                </Label>
              </FormGroup>
            </>
          ) : null}

          {preferredType === "new" ? (
            <div className="stripeWrapper mb-2">
              <>
                <FormGroup>
                  <Input
                    type="number"
                    placeholder="CARD NUMBER"
                    value={formFields.cardNumber.value}
                    onChange={(e) =>
                      this._updateFieldValue("cardNumber", e.target.value)
                    }
                    onBlur={() => this._markAsDirty("cardNumber")}
                  />
                  <div className="form-error">
                    {formFields.cardNumber.isDirty &&
                    !formFields.cardNumber.isValid
                      ? formFields.cardNumber.error
                      : ""}
                  </div>
                </FormGroup>
                <Row>
                  <Col xs="4">
                    <FormGroup>
                      <Input
                        type="number"
                        placeholder="EXPIRY MONTH"
                        value={formFields.expiryMonth.value}
                        onChange={(e) =>
                          this._updateFieldValue("expiryMonth", e.target.value)
                        }
                        onBlur={() => this._markAsDirty("expiryMonth")}
                      />
                      <div className="form-error">
                        {formFields.expiryMonth.isDirty &&
                        !formFields.expiryMonth.isValid
                          ? formFields.expiryMonth.error
                          : ""}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xs="4">
                    <FormGroup>
                      <Input
                        type="number"
                        placeholder="EXPIRY YEAR"
                        value={formFields.expiryYear.value}
                        onChange={(e) =>
                          this._updateFieldValue("expiryYear", e.target.value)
                        }
                        onBlur={() => this._markAsDirty("expiryYear")}
                      />
                      <div className="form-error">
                        {formFields.expiryYear.isDirty &&
                        !formFields.expiryYear.isValid
                          ? formFields.expiryYear.error
                          : ""}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xs="4">
                    <FormGroup>
                      <Input
                        type="number"
                        placeholder="CARD CVC"
                        value={formFields.cvv.value}
                        onChange={(e) =>
                          this._updateFieldValue("cvv", e.target.value)
                        }
                        onBlur={() => this._markAsDirty("cvv")}
                      />
                      <div className="form-error">
                        {formFields.cvv.isDirty && !formFields.cvv.isValid
                          ? formFields.cvv.error
                          : ""}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </>
            </div>
          ) : null}
        </ModalBody>
        <ModalFooter className="customModalFooter">
          <div className="secureCheckoutTxtWrap">
            <a href="http://www.credit-card-logos.com/">
              <img
                alt="Credit Card Logos"
                title="Credit Card Logos"
                src="http://www.credit-card-logos.com/images/visa_credit-card-logos/visa_mastercard_logo_6.gif"
                width="120"
                loading="lazy"
              />
            </a>

            <p className="secureCheckoutTxt">
              Merchant country: United States <br />
              Secure checkout page Secure Sockets Layer (SSL) 128 bit encryption
            </p>
          </div>

          <div className="d-flex flex-shrink-0 align-self-end">
            <Button
              className="modalBtnCancel mr-3"
              onClick={() => this._onDismiss()}
            >
              Cancel
            </Button>
            <Button
              className="modalBtnSave"
              onClick={() => this._processPayment()}
            >
              {isFree ? "Save" : <>Click To Accept</>}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    paymentData: state.paymentData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCardsAsync: () => dispatch(fetchCardsAsync()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HumboldtPayment);
