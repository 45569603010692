import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Input,
  Card,
  Button,
  CardTitle,
} from "reactstrap";
import { CONTACT_SUPPORT_URL } from "../config";
import { useHistory } from "react-router-dom";
import { faqs } from "../config/faq-config";
import FaqAccordion from "../components/FaqAccordion";

const FaqPage = () => {
  const history = useHistory();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categorisedFaqs, setCategorisedFaqs] = useState([]);
  const [filteredFaqs, setFilteredFaqs] = useState([]);
  const [allFAQs, setAllFAQs] = useState([]);

  const _goBack = () => {
    if (filteredFaqs?.length || searchTerm?.length) {
      setSearchTerm("");
      setFilteredFaqs([]);
    } else {
      history.goBack();
    }
  };

  const _onChangeHandler = (searchValue) => {
    setSearchTerm(searchValue);

    let searchResult = [];

    if (!searchValue?.trim()?.length) {
      setFilteredFaqs([]);
      return;
    }

    searchResult = allFAQs.filter((faq) =>
      faq?.searchableContent?.toLowerCase().includes(searchValue)
    );

    if (searchResult?.length) {
      setFilteredFaqs(searchResult);
    } else {
      setFilteredFaqs([]);
    }
  };

  const _onFaqCategoryChange = (categoryName) => {
    setSelectedCategory(categoryName);

    const filteredFaqObj = faqs.find(
      (faqObj) => faqObj?.category === categoryName
    );
    setFilteredFaqs(filteredFaqObj?.faqs);
  };

  const _setAllFAQsTogether = (categorisedFaqs) => {
    let decategorizedFaqs = [];

    categorisedFaqs.forEach((faqObj) => {
      if (faqObj?.faqs?.length) {
        decategorizedFaqs = decategorizedFaqs.concat(faqObj?.faqs);
      }
    });
    setAllFAQs(decategorizedFaqs);
  };

  const _formatToHtml = (originalString) => {
    // Iterate through the string
    const links = [];

    for (let i = 0; i < originalString.length; i++) {
      if (originalString[i] === "[" && originalString[i + 1] === "[") {
        const linkStartsAt = i + 2;
        // Link occurance found
        // Iterate to link close ']]'
        for (let j = i + 2; j < originalString.length; j++) {
          if (originalString[j] === "]" && originalString[j + 1] === "]") {
            // Link end found
            const linkEndsAt = j - 1;
            const link = originalString.substring(linkStartsAt, linkEndsAt);
            // Update parent iterator
            i = j + 1;
            const linkParts = link.split("||");
            links.push({
              originalString: originalString.substring(
                linkStartsAt - 2,
                linkEndsAt + 3
              ),
              formattedString: `<a href=${linkParts[1]}>${linkParts[0]}</a>`,
            });
            break;
          }
        }
      }
    }
    // Replace links
    links.forEach((link) => {
      originalString = originalString.replace(
        link.originalString,
        link.formattedString
      );
    });
    // Replace newlines
    originalString = originalString.split("<|<>|>").join("<br />");
    return `<p>${originalString}</p>`;
  };

  const _categoriseFaqs = () => {
    const categorisedFaqs = [];

    faqs.forEach((faqObj) => {
      faqObj?.["faqs"].forEach((faq) => {
        faq["htmlContent"] = _formatToHtml(faq["content"]);
        faq["searchableContent"] = (faq["content"] + faq["title"])
          .split("<|<>|>")
          .join("")
          .split("[[")
          .join("")
          .split("||")
          .join("")
          .split("]]")
          .join("");
      });
      categorisedFaqs.push(faqObj);
    });

    setCategorisedFaqs(categorisedFaqs);
    _setAllFAQsTogether(categorisedFaqs);
  };

  useEffect(() => {
    _categoriseFaqs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className="customPgHeight animated fadeIn">
        <Container fluid className="noPadding">
          <Row className="customMarginFaq justify-content-center">
            <Col
              xs={12}
              sm={11}
              md={12}
              className="pgPadding customPaddingFaq bg-white"
            >
              <div className="customWrapperFaq">
                <div className="pgTitle justify-content-start">
                  <Button
                    className="customBackBtn customBackBtnFaq"
                    onClick={() => _goBack()}
                  >
                    <i className="fa fa-chevron-left" />
                  </Button>

                  <h2>FAQ</h2>
                </div>

                {filteredFaqs?.length || searchTerm?.length ? (
                  <>
                    <h4 className="faqCategoryTitle">
                      {searchTerm ? "" : selectedCategory}
                    </h4>
                    <p className="someTipsFaq">
                      Some tips to help you. If you don't find an answer to your
                      issue here,{" "}
                      <a
                        href={CONTACT_SUPPORT_URL}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        write to us
                      </a>
                    </p>
                  </>
                ) : (
                  <h4 className="faqCategoryTitle">How can we help you?</h4>
                )}

                <div className="searchBarFaqWrap">
                  <i className="fa fa-search searchIconFaq" />
                  <Input
                    type="text"
                    name=""
                    placeholder="Search"
                    onChange={(e) => _onChangeHandler(e.target.value)}
                    value={searchTerm}
                    autoFocus
                  />
                  {searchTerm && (
                    <i
                      className="fa fa-times clearSearch"
                      onClick={() => _onChangeHandler("")}
                    />
                  )}
                </div>
              </div>

              <Row className="customMarginFaq justify-content-center">
                <Col xs={12} md={10} lg={8} xl={7} className="px-0">
                  <Row className="noMargin">
                    {filteredFaqs?.length || searchTerm?.length ? (
                      // {/* create the list of faqs */}
                      <FaqAccordion categoryMode={false} faqs={filteredFaqs} />
                    ) : // create the card by categories name or icon
                    categorisedFaqs?.length ? (
                      categorisedFaqs.map((faqObj, index) => (
                        <Col sm="6" className="noPadding" key={index}>
                          <div key={index}>
                            <Card
                              body
                              className="faqCategoryWrap"
                              onClick={() =>
                                _onFaqCategoryChange(faqObj?.category)
                              }
                            >
                              <CardTitle>
                                {faqObj.icon ? (
                                  <i className={faqObj?.icon} />
                                ) : (
                                  <i className="fa fa-question" />
                                )}
                              </CardTitle>
                              <p>{faqObj?.category}</p>
                            </Card>
                          </div>
                        </Col>
                      ))
                    ) : null}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FaqPage;
