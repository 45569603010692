import React, { Fragment, Suspense, useCallback, useMemo } from "react";
import {
  Container,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  UncontrolledPopover,
  PopoverBody,
  CustomInput,
} from "reactstrap";
import {
  CARD_REQUIRED_ON_FREE,
  DEFAULT_PROFILE_PICTURE,
  DISCORD_CONFIGURATION,
} from "../config/index";
import {
  showToast,
  getSocialLink,
  extractQueryParams,
  reactHtmlParser,
  getFeatureStatus,
  errorHandler,
  openSocialLink,
  getLowResolutionLink,
  getWindowDimensions,
} from "../helper-methods";
import {
  getInfluencerDetails,
  givenPollOption,
  getAllFolders,
  getIndividualInfluencerLiveEvents,
  getIndividualInfluencerPPV,
  likeDislikeEvent,
  likeDislikePPV,
  freeSubscribeToInfluencer,
  increaseViewCount,
  buySubscriptionWithCoupon,
  updateFavoritePPV,
  getPostDetails,
} from "../http-calls/index";
import { showLoader, hideLoader } from "../redux/actions/loaderData";
import PaymentSubscriptionTier from "../components/PaymentSubscriptionTier";
import PaymentHoc from "../components/PaymentHoc";
import ProfileCarousel from "../components/ProfileCarousel";
import DiscordConfigurationModal from "../components/modals/DiscordConfigurationModal";
import { APP_NAME } from "../config/index";
import ConfigureString from "../components/ConfigureString";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import {
  postFilterOptionsConfig,
  vaultSortOptionsConfig,
} from "../config/helper-config";
import SkeletonLoading from "../components/SkeletonLoading";
import ErrorBoundary from "../components/ErrorBoundary";
import ReadMoreReadLess from "../components/custom/ReadMoreReadLess";
import CancelSubscriptionModal from "../components/modals/CancelSubscriptionModal";
import ProcessPayment from "../payment";
import { updateOnlyUserData } from "../redux/actions";
import { useSelector } from "react-redux";

// code splitting
const FeedViewer = React.lazy(() => import("../components/FeedViewer"));
const VaultCardComponent = React.lazy(() =>
  import("../components/VaultCardComponent")
);
const LiveEventWrapper = React.lazy(() =>
  import("../components/LiveEventWrapper")
);
const PayPerViewWrapper = React.lazy(() =>
  import("../components/PayPerViewWrapper")
);

const InfluencerProfileDetails = () => {
  const history = useHistory();

  const params = useParams();

  const dispatch = useDispatch();

  const containerDivRef = useRef({});
  const timerIntervalRef = useRef({});

  const postsObserver = useRef();
  const foldersObserver = useRef();
  const liveEventsObserver = useRef();
  const payPerViewsObserver = useRef();

  const userData = useSelector((state) => state?.userData);

  const [activeTab, setActiveTab] = useState("1");
  const [contentFilter, setContentFilter] = useState("post");
  const [loadingState, setLoadingState] = useState({
    posts: false,
    folders: false,
    liveEvents: false,
    payPerViews: false,
    filters: false,
  });
  const [fetchedDataState, setFetchedDataState] = useState({
    posts: false,
    folders: false,
    liveEvents: false,
    payPerViews: false,
  });
  const [postDataPayload, setPostDataPayload] = useState({
    skip: 0,
    limit: 20,
  });

  const [folders, setFolders] = useState([]);
  const [foldersCount, setFoldersCount] = useState(0);
  const [foldersDataPayload, setFoldersDataPayload] = useState({
    skip: 0,
    limit: 20,
    pageSize: 20,
    pageNumber: 1,
    filterType: "Influencer",
  });
  const [isOpenFolderPopover, setIsOpenFolderPopover] = useState(false);

  const [posts, setPosts] = useState([]);
  const [postsCount, setPostsCount] = useState(0);

  const [liveEvents, setLiveEvents] = useState([]);
  const [liveEventsCount, setLiveEventsCount] = useState(0);
  const [liveEventDataPayload, setLiveEventDataPayload] = useState({
    skip: 0,
    limit: 20,
  });

  const [payPerViews, setPayPerViews] = useState([]);
  const [payPerViewsCount, setPayPerViewsCount] = useState(0);
  const [ppvDataPayload, setPpvDataPayload] = useState({
    skip: 0,
    limit: 20,
  });
  const [couponToken, setCouponToken] = useState(null);
  const [influencer, setInfluencer] = useState(null);
  const [bundles, setBundles] = useState([]);
  const [offers, setOffers] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState({
    isSubscribed: false,
    isSubscriptionExpired: false,
  });
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);
  const [discordConfigurationModal, setDiscordConfigurationModal] = useState({
    isOpen: false,
    influencerId: null,
  });
  const [paymentHoc, setPaymentHoc] = useState({
    isOpen: false,
    isFree: null,
  });
  const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState({
    isOpen: false,
    influencer: null,
    isRenewal: false,
    isResume: false,
  });
  const [screenWidth, setScreenWidth] = useState(0);
  const [sharedPostOnTop, setSharedPostOnTop] = useState(null);

  const isEventFeatureActive = useMemo(() => getFeatureStatus("event"), []);
  const isVaultFeatureActive = useMemo(() => getFeatureStatus("vault"), []);
  const isPPVFeatureActive = useMemo(() => getFeatureStatus("ppv"), []);
  // const isShoutoutFeatureActive = useMemo(() => getFeatureStatus("shoutout"),[])

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _manageFetchedDataState = (key = "", value = false) => {
    setFetchedDataState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _getInfluencerDetails = async (payload) => {
    try {
      _manageLoadingState("posts", true);

      const res = await getInfluencerDetails(params?.id, payload);

      let postDetailRes = {};
      if (params?.postId && !sharedPostOnTop?._id) {
        postDetailRes = await getPostDetails(params?.postId);
        setSharedPostOnTop(postDetailRes?.post);
      }

      if (
        res?.hasOwnProperty("posts") &&
        Array.isArray(res?.posts) &&
        postDetailRes?.post?._id
      ) {
        const postIndex = res?.posts.findIndex(
          (each) => each?._id === postDetailRes?.post?._id
        );

        if (postIndex > -1) {
          res.posts.splice(postIndex, 1);
        }
        console.log("lagaaaaaaaaaaaaaaaa");
        res?.posts?.unshift(postDetailRes?.post);
      }

      let couponToken = null;
      if (localStorage.couponToken) {
        if (
          res.isSubscribed ||
          (res.influencer.isFreeSubscription &&
            res.influencer.isFreeSubscriptionsAllow)
        ) {
          delete localStorage.couponToken;
        } else {
          couponToken = localStorage.couponToken;
        }
      }

      setCouponToken(couponToken);
      setInfluencer(res.influencer);
      setBundles(res.bundles);
      setOffers(res.offers);
      setSubscriptionData({
        isSubscribed: res.isSubscribed,
        isSubscriptionExpired: res.isSubscriptionExpired,
      });
      setPosts((prev) => (payload?.skip ? prev.concat(res.posts) : res.posts));
      setPostsCount(res.total);

      setLoadingState({});

      _manageFetchedDataState("post", true);
    } catch (error) {
      setLoadingState({});

      history.replace("/feed");

      errorHandler({
        reason: error?.reason?.includes("Cast to ObjectId failed for value")
          ? "No influencer found with this name"
          : error?.reason,
      });
    }
  };

  const _getAllFolders = async (payload) => {
    try {
      _manageLoadingState("folders", true);

      const res = await getAllFolders({ id: influencer?.id, payload });

      setFolders((prev) =>
        payload?.skip ? prev.concat(res.folders) : res.folders
      );

      setFoldersCount(res.count);

      _manageLoadingState("folders", false);
      _manageFetchedDataState("folders", true);
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("folders", false);
    }
  };

  const _updateVaultKey = ({ folderId, key, value }) => {
    try {
      const newFolders = [...folders];

      const findFolder = newFolders?.find((each) => each._id === folderId);
      findFolder[key] = value;

      setFolders(newFolders);
    } catch (error) {
      _getAllFolders({
        ...foldersDataPayload,
        skip: 0,
        pageNumber: 1,
      });
    }
  };

  const _onChangeFolderSort = (value) => {
    const newDataPayload = { ...foldersDataPayload };
    newDataPayload.skip = 0;
    newDataPayload.pageNumber = 1;
    newDataPayload.filterType = value;
    setFoldersDataPayload(newDataPayload);
    _getAllFolders(newDataPayload);
  };

  const _getInfluencerLiveEvents = async (payload) => {
    try {
      _manageLoadingState("liveEvents", true);

      const res = await getIndividualInfluencerLiveEvents(params?.id, payload);

      setLiveEvents((prev) =>
        payload?.skip ? prev.concat(res.events) : res.events
      );

      setLiveEventsCount(res.count);

      _manageLoadingState("liveEvents", false);
      _manageFetchedDataState("liveEvents", true);
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("liveEvents", false);
    }
  };

  const _getInfluencerAllPPV = async (payload) => {
    try {
      _manageLoadingState("payPerViews", true);

      const res = await getIndividualInfluencerPPV(params?.id, payload);

      setPayPerViews((prev) =>
        payload?.skip ? prev.concat(res.ppvs) : res.ppvs
      );

      setPayPerViewsCount(res.count);

      _manageLoadingState("payPerViews", false);
      _manageFetchedDataState("payPerViews", true);
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("payPerViews", false);
    }
  };

  const _toggleActiveTab = (tabId = "1") => {
    setActiveTab(tabId);

    switch (tabId) {
      case "1": {
        if (!fetchedDataState.posts) {
          _getInfluencerDetails({
            ...postDataPayload,
            skip: 0,
          });
        }
        break;
      }
      case "2": {
        if (!fetchedDataState.folders) {
          _getAllFolders({
            ...foldersDataPayload,
            skip: 0,
            pageNumber: 1,
          });
        }
        break;
      }
      case "3": {
        if (!fetchedDataState.liveEvents) {
          _getInfluencerLiveEvents({
            ...liveEventDataPayload,
            skip: 0,
          });
        }
        break;
      }
      case "4": {
        if (!fetchedDataState.payPerViews) {
          _getInfluencerAllPPV({
            ...ppvDataPayload,
            skip: 0,
          });
        }
        break;
      }
      default:
    }
  };

  const _updateIndividualPost = (index, limit = 1) => {
    return new Promise((resolve, reject) => {
      const payload = {
        skip: index,
        limit,
      };

      if (contentFilter !== "post") {
        payload.contentFilter = contentFilter;
      }

      getInfluencerDetails(params.id, payload)
        .then((res) => {
          const newPosts = [...posts];
          newPosts[index] = res.posts[0];
          setPosts(newPosts);
          resolve();
        })
        .catch((err) => {
          reject();
        });
    });
  };

  const _selectPollOption = async (feed, option, index) => {
    try {
      const data = {
        postId: feed._id,
        optionId: option._id,
      };

      const newPosts = [...posts];
      newPosts[index]["_opinions"] = [{ option: option._id }];
      setPosts(newPosts);

      await givenPollOption(data);

      await _updateIndividualPost(index);
    } catch (error) {
      _updateIndividualPost(index);
      errorHandler(error);
    }
  };

  const _toggleFilterPopover = () => {
    setIsFilterPopoverOpen((prev) => !prev);
  };

  const _onChangeFilter = (newContentFilter = "post") => {
    _manageLoadingState("filters", true);

    _toggleFilterPopover();
    setContentFilter(newContentFilter);

    const newPostDataPayload = { ...postDataPayload };
    if (newContentFilter !== "post") {
      newPostDataPayload.contentFilter = newContentFilter;
    } else {
      delete newPostDataPayload.contentFilter;
    }
    newPostDataPayload.skip = 0;
    setPostDataPayload(newPostDataPayload);

    _getInfluencerDetails(newPostDataPayload);
  };

  const _addCommentEvent = (index, isAdded) => {
    try {
      const newLiveEvents = [...liveEvents];
      if (isAdded) {
        if (newLiveEvents?.[index]?.comments) {
          newLiveEvents[index].comments++;
        } else {
          newLiveEvents[index].comments = 1;
        }
      } else {
        if (newLiveEvents?.[index]?.comments) {
          newLiveEvents[index].comments--;
        } else {
          newLiveEvents[index].comments = 0;
        }
      }
      setLiveEvents(newLiveEvents);
    } catch (error) {
      console.log({ error });
    }
  };

  const _addCommentPpv = (index, isAdded) => {
    try {
      const newPayPerViews = [...payPerViews];
      if (isAdded) {
        if (newPayPerViews?.[index]?.comments) {
          newPayPerViews[index].comments++;
        } else {
          newPayPerViews[index].comments = 1;
        }
      } else {
        if (newPayPerViews?.[index]?.comments) {
          newPayPerViews[index].comments--;
        } else {
          newPayPerViews[index].comments = 0;
        }
      }
      setPayPerViews(newPayPerViews);
    } catch (error) {
      console.log({ error });
    }
  };

  // Local Like or Dislike
  const _toggleLikePpv = (index, isLiked) => {
    if (timerIntervalRef?.like) clearInterval(timerIntervalRef.like);

    const newPayPerViews = [...payPerViews];

    if (isLiked) {
      // Decrease likes count
      newPayPerViews[index]._isLiked = 0;
      newPayPerViews[index].likes -= 1;
    } else {
      // Increase likes count
      newPayPerViews[index]._isLiked = 1;
      newPayPerViews[index].likes += 1;
    }

    setPayPerViews(newPayPerViews);

    timerIntervalRef.like = setTimeout(async () => {
      try {
        await likeDislikePPV(payPerViews[index].id, isLiked);
      } catch (error) {
        console.log({ error });
      }
    }, 1000);
  };

  // isFavourite toggle in local
  const _toggleFavouritePpv = (index, isFavourite) => {
    if (timerIntervalRef?.favourite) clearInterval(timerIntervalRef.favourite);

    const newPayPerViews = [...payPerViews];

    if (isFavourite) {
      newPayPerViews[index].isFavourite = false;
    } else {
      newPayPerViews[index].isFavourite = true;
    }

    setPayPerViews(newPayPerViews);

    timerIntervalRef.favourite = setTimeout(async () => {
      try {
        await updateFavoritePPV(payPerViews[index].id, !isFavourite);
      } catch (error) {
        console.log({ error });
      }
    }, 1000);
  };

  // Local Like or Dislike
  const _toggleLikeEvent = async (index, isLiked) => {
    if (timerIntervalRef?.like) clearInterval(timerIntervalRef.like);

    const newLiveEvents = [...liveEvents];

    if (isLiked) {
      // Decrease likes count
      newLiveEvents[index]._isLiked = 0;
      newLiveEvents[index].likes -= 1;
    } else {
      // Increse likes count
      newLiveEvents[index]._isLiked = 1;
      newLiveEvents[index].likes += 1;
    }

    setLiveEvents(newLiveEvents);

    timerIntervalRef.like = setTimeout(async () => {
      try {
        await likeDislikeEvent(liveEvents[index].id, isLiked);
      } catch (error) {
        console.log({ error });
      }
    }, 1000);
  };

  const _increaseViewCount = (index) => {
    try {
      const newPayPerViews = [...payPerViews];

      payPerViews[index].viewCount += 1;

      setPayPerViews(newPayPerViews);

      increaseViewCount(payPerViews[index].id);
    } catch (error) {
      console.log({ error });
    }
  };

  const _toggleLikePost = (index, isLiked) => {
    try {
      const newPosts = [...posts];

      if (isLiked) {
        newPosts[index]["_isLiked"] = 1;
        newPosts[index]["likes"] = +newPosts[index]["likes"] + 1;
      } else {
        newPosts[index]["_isLiked"] = 0;
        newPosts[index]["likes"] = +newPosts[index]["likes"] - 1;
      }

      setPosts(newPosts);
    } catch (error) {
      console.log({ error });
    }
  };

  const _onCommentPost = (index, isAdded) => {
    try {
      const newPosts = [...posts];

      let commentsCount = 0;
      if (isAdded) {
        if (newPosts[index]?.hasOwnProperty("comments")) {
          commentsCount = newPosts[index]["comments"] + 1;
        } else {
          commentsCount = 1;
        }
      } else {
        if (newPosts[index]["comments"]) {
          commentsCount = newPosts[index]["comments"] - 1;
        }
      }
      newPosts[index]["comments"] = commentsCount;

      setPosts(newPosts);
    } catch (error) {
      console.log({ error });
    }
  };

  const _updateCommentsCount = ({ index, value }) => {
    try {
      const newPosts = [...posts];
      newPosts[index].comments = value;
      setPosts(newPosts);
    } catch (error) {
      console.log({ error });
    }
  };

  const _buySubscriptionWithCoupon = (couponToken, influencerId) => {
    return new Promise((resolve, reject) => {
      buySubscriptionWithCoupon(couponToken, influencerId)
        .then((res) => {
          delete localStorage.couponToken;
          setCouponToken(null);
          resolve(res);
        })
        .catch((error) => {
          delete localStorage.couponToken;
          setCouponToken(null);
          reject(error);
        });
    });
  };

  const _onPaymentConfirmation = async () => {
    try {
      _toggleFreeSubscription();

      dispatch(showLoader(`Subscribing to ${influencer?.username}`));

      if (couponToken) {
        await _buySubscriptionWithCoupon(couponToken, influencer.id);
      } else {
        await freeSubscribeToInfluencer(influencer.id);
      }

      // Take user to home page
      _getInfluencerDetails({
        ...postDataPayload,
        skip: 0,
      });

      showToast(
        `Successfully subscribed to ${influencer?.username}`,
        "success"
      );

      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      errorHandler(error);
    }
  };

  const _toggleFreeSubscription = (isOpen = false, isFree = false) => {
    setPaymentHoc({
      isOpen,
      isFree,
    });
  };

  const _toggleDiscordConfigurationModal = (
    isOpen = false,
    influencerId = null
  ) => {
    setDiscordConfigurationModal({
      isOpen,
      influencerId,
    });
  };

  const postsLastElementRef = useCallback(
    (node) => {
      if (loadingState?.posts) return;

      if (postsObserver.current) postsObserver.current.disconnect();

      postsObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && posts?.length < postsCount) {
          const newDataPayload = { ...postDataPayload };
          newDataPayload["skip"] = posts?.length || 0;
          setPostDataPayload(newDataPayload);
          _getInfluencerDetails(newDataPayload);
        }
      });

      if (node) postsObserver.current.observe(node);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingState?.posts, posts]
  );

  const foldersLastElementRef = useCallback(
    (node) => {
      if (loadingState?.folders) return;

      if (foldersObserver.current) foldersObserver.current.disconnect();

      foldersObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && folders?.length < foldersCount) {
          const newDataPayload = { ...foldersDataPayload };
          newDataPayload["skip"] = folders?.length || 0;
          if (folders?.length) {
            newDataPayload["pageNumber"]++;
          }
          setFoldersDataPayload(newDataPayload);
          _getAllFolders(newDataPayload);
        }
      });

      if (node) foldersObserver.current.observe(node);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingState?.folders, folders]
  );

  const liveEventsLastElementRef = useCallback(
    (node) => {
      if (loadingState?.liveEvents) return;

      if (liveEventsObserver.current) liveEventsObserver.current.disconnect();

      liveEventsObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && liveEvents?.length < liveEventsCount) {
          const newDataPayload = { ...liveEventDataPayload };
          newDataPayload["skip"] = liveEvents?.length || 0;
          setLiveEventDataPayload(newDataPayload);
          _getInfluencerLiveEvents(newDataPayload);
        }
      });

      if (node) liveEventsObserver.current.observe(node);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingState?.liveEvents, liveEvents]
  );

  const payPerViewsLastElementRef = useCallback(
    (node) => {
      if (loadingState?.payPerViews) return;

      if (payPerViewsObserver.current) payPerViewsObserver.current.disconnect();

      payPerViewsObserver.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          payPerViews?.length < payPerViewsCount
        ) {
          const newDataPayload = { ...ppvDataPayload };
          newDataPayload["skip"] = payPerViews?.length || 0;
          setPpvDataPayload(newDataPayload);
          _getInfluencerAllPPV(newDataPayload);
        }
      });

      if (node) payPerViewsObserver.current.observe(node);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingState?.payPerViews, payPerViews]
  );

  const _toggleCancelSubscriptionModal = ({
    isOpen = false,
    influencer = null,
    isRenewal = false,
    isResume = false,
  }) => {
    setCancelSubscriptionModal({
      isOpen,
      influencer,
      isRenewal,
      isResume,
    });
  };

  const _renewSubscription = async () => {
    try {
      dispatch(showLoader("Processing Request"));

      const paymentApiData = {
        url: `/fan/subscriptions/renew/${influencer?._id}`,
        url3DSecure: `/payment/intent/subscriptions/renew/${influencer?._id}`,
        influencer,
      };

      const { transaction } = await ProcessPayment.startPaymentProcess(
        paymentApiData,
        null,
        "Put"
      );

      _getInfluencerDetails(postDataPayload);

      _toggleCancelSubscriptionModal({});

      dispatch(hideLoader());

      if (
        DISCORD_CONFIGURATION === true &&
        transaction?.subscriptionTier === "premium"
      ) {
        _toggleDiscordConfigurationModal(true, transaction._to);
      }
    } catch (error) {
      if (error?.isRiskAssessment) {
        dispatch(
          updateOnlyUserData({
            ...userData.user,
            isRiskAssessment: error.isRiskAssessment,
          })
        );
      }
      dispatch(hideLoader());
      errorHandler(error);
    }
  };

  const _showErrorToast = (username) => {
    showToast(`@${username || "Creator"} is currently Inactive.`, "error");
  };

  const _initializePage = () => {
    const { tab } = extractQueryParams();

    if (tab >= 1 && tab <= 4) {
      _toggleActiveTab(tab);
    }

    if (params?.id) {
      _getInfluencerDetails(postDataPayload);
    } else {
      history.replace("/feed");
    }
  };

  const _scrollToTop = () => {
    try {
      containerDivRef.current.scrollTo(0, 0);
    } catch (error) {
      console.log({ error });
    }
  };

  const _changeWidth = (event) => {
    const { screenWidth } = getWindowDimensions();
    if (screenWidth <= 1000) {
      setScreenWidth(screenWidth);
    }
  };

  useEffect(() => {
    _initializePage();

    _changeWidth();

    window.addEventListener("resize", _changeWidth, true);

    return () => {
      window.removeEventListener("resize", _changeWidth, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const profileCarouselData = useMemo(() => {
    if (!influencer?.carousel?.length) return [];

    const carouselCrop = influencer?.carouselCrop?.length
      ? influencer?.carouselCrop
      : [];

    const newCarousel = [];

    influencer?.carousel?.forEach((each, index) => {
      const carouselObj = {
        ...each,
        id: index,
      };

      if (each.contentType === "image") {
        const findCropObj = carouselCrop?.find(
          (each) => each.carouselIndex === index
        );

        if (findCropObj) {
          carouselObj.crop = findCropObj;
        }
      }

      newCarousel.push(carouselObj);
    });

    return newCarousel;
  }, [influencer?.carousel, influencer?.carouselCrop]);

  const _makeTabContent = () => {
    return (
      <TabContent
        activeTab={activeTab}
        className="customTabContent no-feed customTabContentInfluencerDetails"
      >
        <TabPane tabId="1">
          {/* multiple posts/feeds */}
          {posts?.length ? (
            posts.map((feed, feedIndex) => (
              <Fragment
                key={`influencer_profile_feed_${feed._id}_${feedIndex}`}
              >
                <ErrorBoundary>
                  <Suspense
                    fallback={<SkeletonLoading type={"post"} count={1} />}
                  >
                    <FeedViewer
                      {...(feedIndex === posts.length - 1
                        ? { lastElementRef: postsLastElementRef }
                        : {})}
                      feed={feed}
                      feedIndex={feedIndex}
                      feedBundles={bundles}
                      subscriptionData={subscriptionData}
                      influencer={influencer}
                      requestUpdate={() => _updateIndividualPost(feedIndex)}
                      onTierUpgrade={() => _updateIndividualPost(feedIndex)}
                      selectPollOption={(feed, option, index) =>
                        _selectPollOption(feed, option, index)
                      }
                      toggleLikePost={(index, isLiked) =>
                        _toggleLikePost(index, isLiked)
                      }
                      onComment={(index, isAdded) =>
                        _onCommentPost(index, isAdded)
                      }
                      onPaymentComplete={(index) =>
                        _updateIndividualPost(index)
                      }
                      scrollToTop={_scrollToTop}
                      loadingFeeds={loadingState.posts}
                      updateCommentsCount={({ index, value }) =>
                        _updateCommentsCount({ index, value })
                      }
                      {...(sharedPostOnTop?._id && feedIndex === 0
                        ? { customClassname: "sharedPostHighLighted" }
                        : {})}
                      isProfilePage={true}
                    />
                  </Suspense>
                </ErrorBoundary>
              </Fragment>
            ))
          ) : loadingState.posts ? (
            <SkeletonLoading type={"post"} count={4} />
          ) : (
            <div className="noContentFound">No post found</div>
          )}
        </TabPane>

        <TabPane tabId="2">
          <div style={{ padding: "20px 10px" }}>
            {folders?.length ? (
              <Row className="noMargin">
                {folders.map((each, index) => (
                  <Fragment
                    key={`influencer_profile_vault_${each._id}_${index}`}
                  >
                    <ErrorBoundary>
                      <Suspense
                        fallback={
                          <SkeletonLoading
                            type={"vaultContentCard"}
                            count={1}
                          />
                        }
                      >
                        <VaultCardComponent
                          {...(index === folders.length - 1
                            ? { lastElementRef: foldersLastElementRef }
                            : {})}
                          folder={each}
                          influencer={influencer}
                          updateVaultKey={_updateVaultKey}
                        />
                      </Suspense>
                    </ErrorBoundary>

                    {index === folders.length - 1 && loadingState.folders && (
                      <SkeletonLoading type={"vaultContentCard"} count={6} />
                    )}
                  </Fragment>
                ))}
              </Row>
            ) : loadingState.folders ? (
              <Row className="vaultFolderRow">
                <SkeletonLoading type={"vaultContentCard"} count={6} />
              </Row>
            ) : (
              <div className="noContentFound">
                <ConfigureString keyString={"No vault found"} />
              </div>
            )}
          </div>
        </TabPane>

        {/* Live Events */}
        <TabPane tabId="3">
          {liveEvents?.length ? (
            <Row className="liveEventWrapper mt-2 mt-sm-0">
              {liveEvents.map((each, index) => (
                <Fragment key={`influencer_profile_event_${each._id}_${index}`}>
                  <ErrorBoundary>
                    <Suspense
                      fallback={<SkeletonLoading type={"event"} count={1} />}
                    >
                      <LiveEventWrapper
                        {...(index === liveEvents.length - 1
                          ? { lastElementRef: liveEventsLastElementRef }
                          : {})}
                        event={each}
                        eventIndex={index}
                        onComment={(key, isAdded) =>
                          _addCommentEvent(key, isAdded)
                        }
                        toggleLike={(index, isLiked) =>
                          _toggleLikeEvent(index, isLiked)
                        }
                        getAllEvents={() =>
                          _getInfluencerLiveEvents({
                            ...liveEventDataPayload,
                            skip: 0,
                            limit: liveEvents.length,
                          })
                        }
                      />
                    </Suspense>
                  </ErrorBoundary>

                  {index === liveEvents.length - 1 &&
                    loadingState.liveEvents && (
                      <SkeletonLoading type={"event"} count={2} />
                    )}
                </Fragment>
              ))}
            </Row>
          ) : loadingState.liveEvents ? (
            <Row className="liveEventWrapper mt-2 mt-sm-0">
              <SkeletonLoading type={"event"} count={4} />
            </Row>
          ) : (
            <div className="noContentFound mb-4">
              <ConfigureString keyString={"No upcoming event found"} />
            </div>
          )}
        </TabPane>

        {/* PPV */}
        <TabPane tabId="4">
          {payPerViews?.length ? (
            <Row className="liveEventWrapper mt-2 mt-sm-0">
              {payPerViews.map((each, index) => (
                <Fragment key={`influencer_profile_ppv_${each._id}_${index}`}>
                  <ErrorBoundary>
                    <Suspense
                      fallback={<SkeletonLoading type={"event"} count={1} />}
                    >
                      <PayPerViewWrapper
                        {...(index === payPerViews.length - 1
                          ? { lastElementRef: payPerViewsLastElementRef }
                          : {})}
                        ppv={each}
                        ppvIndex={index}
                        onComment={(key, isAdded) =>
                          _addCommentPpv(key, isAdded)
                        }
                        getAllPPV={() =>
                          _getInfluencerAllPPV({
                            ...ppvDataPayload,
                            skip: 0,
                            limit: payPerViews.length,
                          })
                        }
                        toggleLike={() => _toggleLikePpv(index, each._isLiked)}
                        toggleFavourite={() =>
                          _toggleFavouritePpv(index, each.isFavourite)
                        }
                        increaseViewCount={(id) => _increaseViewCount(id)}
                      />
                    </Suspense>
                  </ErrorBoundary>

                  {index === payPerViews.length - 1 &&
                    loadingState.payPerViews && (
                      <SkeletonLoading type={"event"} count={2} />
                    )}
                </Fragment>
              ))}
            </Row>
          ) : loadingState.payPerViews ? (
            <Row className="liveEventWrapper mt-2 mt-sm-0">
              <SkeletonLoading type={"event"} count={4} />
            </Row>
          ) : (
            <div className="noContentFound mb-4">
              <ConfigureString keyString={"No ppv Found"} />
            </div>
          )}
        </TabPane>
      </TabContent>
    );
  };

  return (
    <>
      <div className="customPgHeight animated fadeIn" ref={containerDivRef}>
        <Container className="noPadding">
          <Row className="justify-content-center mb-2 noMargin">
            <Col sm={12} md={11} xl={12} className="noPadding bg-white">
              <Button
                className="customBackBtn profileBackBtn"
                onClick={() => history.goBack()}
              >
                <i className="fa fa-chevron-left" />
              </Button>
              {/* user profile card  */}
              <Card className="cardFeed profileCard">
                <CardHeader>
                  <ErrorBoundary>
                    <ProfileCarousel
                      items={profileCarouselData}
                      screenWidth={screenWidth}
                    />
                  </ErrorBoundary>

                  <div className="influencerDetailsWrap">
                    <div className="d-flex flex-column flex-sm-row">
                      <img
                        src={
                          getLowResolutionLink(influencer?.profilePicture) ||
                          DEFAULT_PROFILE_PICTURE
                        }
                        onError={(e) =>
                          (e.target.src = DEFAULT_PROFILE_PICTURE)
                        }
                        alt="Profile Img"
                        className="img100by100"
                        loading="lazy"
                      />

                      <div className="ml-0 ml-sm-2 mt-2">
                        {/* <h4>
                              {influencer &&
                              influencer.name &&
                              influencer.name.full.length
                                ? influencer.name.full
                                : "N/A"}
                            </h4> */}

                        {/* this will only start showing from mini tab view (from: 576px) */}
                        {/* this will not show for mobile view */}
                        {influencer?.username?.length ? (
                          <Button
                            color="link"
                            className="profileID p-0 d-none d-sm-inline-flex"
                            style={{ width: "max-content" }}
                          >
                            @{influencer?.username}
                          </Button>
                        ) : loadingState?.posts ? (
                          <SkeletonLoading
                            type={"box"}
                            count={1}
                            width={Math.random() * 50 + 50}
                            height={15}
                            borderRadius={15}
                          />
                        ) : null}
                      </div>
                    </div>

                    <div className="profileInfoWrap">
                      <div>
                        {loadingState?.posts &&
                        !influencer?.address?.country ? (
                          <SkeletonLoading
                            type={"box"}
                            height={15}
                            width={30}
                            count={1}
                          />
                        ) : (
                          <h5>{influencer?.address?.country || "N/A"}</h5>
                        )}
                        <p>Location</p>
                      </div>

                      {loadingState?.posts && !influencer?._subCount ? (
                        <div>
                          <SkeletonLoading
                            type={"box"}
                            count={1}
                            width={30}
                            height={15}
                          />
                          <p>Subscribers</p>
                        </div>
                      ) : influencer?.settings?.showFanCountOnProfile &&
                        influencer?._subCount > 0 ? (
                        <div>
                          <h5>{influencer._subCount}</h5>
                          <p>Subscribers</p>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* for mobile view (less than -> 576px) */}
                  {influencer?.username?.length ? (
                    <Button
                      color="link"
                      className="profileID p-0 d-inline-block d-sm-none"
                      style={{
                        width: "max-content",
                        margin: "5px auto 0 10px",
                        fontSize: 16,
                      }}
                    >
                      @{influencer?.username}
                    </Button>
                  ) : loadingState?.posts ? (
                    <SkeletonLoading
                      type={"box"}
                      count={1}
                      width={Math.random() * 50 + 50}
                      height={15}
                      borderRadius={15}
                    />
                  ) : null}
                </CardHeader>

                {loadingState?.posts && !influencer ? (
                  <CardBody>
                    <SkeletonLoading type={"box"} count={1} height={15} />
                  </CardBody>
                ) : (
                  <CardBody>
                    {influencer?.social?.length ? (
                      <div className="d-flex mt-1 mb-3">
                        {getSocialLink(influencer?.social, "facebook") && (
                          <Button
                            size="sm"
                            className="btn-facebook btn-brand mr-2"
                            title={getSocialLink(
                              influencer?.social,
                              "facebook"
                            )}
                            onClick={() =>
                              openSocialLink(
                                getSocialLink(influencer?.social, "facebook")
                              )
                            }
                          >
                            <i className="fa fa-facebook" />
                          </Button>
                        )}
                        {getSocialLink(influencer?.social, "twitter") && (
                          <Button
                            size="sm"
                            className="btn-twitter btn-brand mr-2"
                            title={getSocialLink(influencer?.social, "twitter")}
                            onClick={() =>
                              openSocialLink(
                                getSocialLink(influencer?.social, "twitter")
                              )
                            }
                          >
                            <i className="fa fa-twitter" />
                          </Button>
                        )}
                        {getSocialLink(influencer?.social, "instagram") && (
                          <Button
                            size="sm"
                            className="btn-instagram btn-brand mr-2"
                            title={getSocialLink(
                              influencer?.social,
                              "instagram"
                            )}
                            onClick={() =>
                              openSocialLink(
                                getSocialLink(influencer?.social, "instagram")
                              )
                            }
                          >
                            <i className="fa fa-instagram" />
                          </Button>
                        )}
                        {getSocialLink(influencer?.social, "youtube") && (
                          <Button
                            size="sm"
                            className="btn-youtube btn-brand mr-2"
                            title={getSocialLink(influencer?.social, "youtube")}
                            onClick={() =>
                              openSocialLink(
                                getSocialLink(influencer?.social, "youtube")
                              )
                            }
                          >
                            <i className="fa fa-youtube" />
                          </Button>
                        )}
                        {getSocialLink(influencer?.social, "tiktok") && (
                          <Button
                            size="sm"
                            className="btn-tiktok btn-brand"
                            title={getSocialLink(
                              influencer &&
                                influencer.social &&
                                influencer.social,
                              "tiktok"
                            )}
                            onClick={() =>
                              openSocialLink(
                                getSocialLink(
                                  influencer &&
                                    influencer.social &&
                                    influencer.social,
                                  "tiktok"
                                )
                              )
                            }
                          >
                            <img
                              src={"/assets/img/tiktok.png"}
                              alt="TikTok"
                              className="tiktok"
                              loading="lazy"
                            />
                          </Button>
                        )}
                      </div>
                    ) : null}

                    <div className="profileDesc">
                      {subscriptionData?.isSubscribed &&
                      posts?.[0]?.subscription?.tier &&
                      influencer?.[
                        `${
                          posts?.[0]?.subscription?.tier !== "basic"
                            ? posts?.[0]?.subscription?.tier + "S"
                            : "s"
                        }ubscriptionFees`
                      ]?.description?.length ? (
                        <ReadMoreReadLess
                          className="readMore"
                          text={reactHtmlParser(
                            influencer[
                              `${
                                posts?.[0]?.subscription?.tier !== "basic"
                                  ? posts?.[0]?.subscription?.tier + "S"
                                  : "s"
                              }ubscriptionFees`
                            ]?.description
                          )}
                        />
                      ) : influencer?.introduction?.length ? (
                        <ReadMoreReadLess
                          className="readMore"
                          text={reactHtmlParser(influencer.introduction)}
                        />
                      ) : (
                        `Welcome to ${APP_NAME} Subscribe to unlock all of my exclusive content!`
                      )}
                    </div>

                    {!subscriptionData.isSubscribed ? (
                      couponToken ||
                      (influencer &&
                        influencer.isFreeSubscription &&
                        influencer.isFreeSubscriptionsAllow) ? (
                        <Button
                          className="themeBtn btnSubscribe mx-1 mt-3"
                          style={{ padding: " 0 12px", fontSize: 12 }}
                          onClick={
                            () =>
                              couponToken || CARD_REQUIRED_ON_FREE === true
                                ? _toggleFreeSubscription(true, true) // card required
                                : _onPaymentConfirmation() // direct subscription without card
                          }
                        >
                          Subscribe For Free
                        </Button>
                      ) : (
                        <PaymentSubscriptionTier
                          influencer={influencer}
                          offers={offers}
                          bundles={bundles}
                          getInfluencerDetails={() =>
                            _getInfluencerDetails({
                              ...postDataPayload,
                              skip: 0,
                            })
                          }
                          toggleDiscordConfigurationModal={(
                            isOpen,
                            influencerId
                          ) =>
                            _toggleDiscordConfigurationModal(
                              isOpen,
                              influencerId
                            )
                          }
                        />
                      )
                    ) : influencer &&
                      subscriptionData &&
                      subscriptionData.isSubscriptionExpired ? (
                      <div className="d-flex">
                        <Button
                          className="themeBtn btnSubscribe mx-1 mt-3"
                          style={{ padding: " 0 12px", fontSize: 12 }}
                          disabled={loadingState?.posts}
                          onClick={() =>
                            influencer?.isActive === false
                              ? _showErrorToast(influencer?.username)
                              : _toggleCancelSubscriptionModal({
                                  isOpen: true,
                                  influencer,
                                  isRenewal: true,
                                  isResume: false,
                                })
                          }
                        >
                          Renew Subscription
                        </Button>
                        <Button
                          className="themeBtn btnSubscribe mx-1 mt-3"
                          style={{ padding: " 0 12px", fontSize: 12 }}
                          disabled={loadingState?.posts}
                          onClick={() =>
                            history.push(
                              `/manage-subscriptions?username=${influencer?.username}`
                            )
                          }
                        >
                          Manage Subscription
                        </Button>
                      </div>
                    ) : null}
                  </CardBody>
                )}
              </Card>

              <div className="tabsWrap-InfluencerDetails">
                <Nav tabs className="customTabs">
                  <NavItem>
                    <NavLink
                      active={activeTab === "1"}
                      onClick={() => {
                        _toggleActiveTab("1");
                      }}
                    >
                      <img
                        src="/assets/img/feed-active.png"
                        alt="Feed"
                        className="activeIcon"
                      />
                      <img
                        src="/assets/img/feed-inactive.png"
                        alt="Feed"
                        className="inactiveIcon"
                      />
                    </NavLink>
                  </NavItem>
                  {subscriptionData.isSubscribed &&
                  !subscriptionData.isSubscriptionExpired ? (
                    <>
                      {isVaultFeatureActive && (
                        <NavItem>
                          <NavLink
                            active={activeTab === "2"}
                            onClick={() => {
                              _toggleActiveTab("2");
                            }}
                          >
                            <img
                              src="/assets/img/vault-active.png"
                              alt="vault"
                              className="activeIcon"
                            />
                            <img
                              src="/assets/img/vault-inactive.png"
                              alt="vault"
                              className="inactiveIcon"
                            />
                          </NavLink>
                        </NavItem>
                      )}
                      {isEventFeatureActive && (
                        <NavItem>
                          <NavLink
                            active={activeTab === "3"}
                            onClick={() => {
                              _toggleActiveTab("3");
                            }}
                          >
                            <img
                              src="/assets/img/live-event-active.png"
                              alt="live event"
                              className="activeIcon"
                            />
                            <img
                              src="/assets/img/live-event-inactive.png"
                              alt="live event"
                              className="inactiveIcon"
                            />
                          </NavLink>
                        </NavItem>
                      )}
                      {isPPVFeatureActive && (
                        <NavItem>
                          <NavLink
                            active={activeTab === "4"}
                            onClick={() => {
                              _toggleActiveTab("4");
                            }}
                          >
                            <img
                              src="/assets/img/ppv-active.png"
                              alt="ppv"
                              className="activeIcon"
                              style={{ height: 20, marginTop: 4 }}
                            />
                            <img
                              src="/assets/img/ppv-inactive.png"
                              alt="ppv"
                              className="inactiveIcon"
                              style={{ height: 20, marginTop: 4 }}
                            />
                          </NavLink>
                        </NavItem>
                      )}
                    </>
                  ) : null}
                </Nav>
              </div>

              {activeTab === "1" ? (
                <div className="text-right bg-white">
                  <Button
                    id={`influencer_profile_isFilterPopoverOpen`}
                    className="customPopoverBtn filterBtnInfluencerDetails"
                    onClick={_toggleFilterPopover}
                  >
                    {loadingState.filters ? (
                      <i className="fa fa-spinner fa-spin mr-1" />
                    ) : (
                      <img
                        src={"/assets/img/filter-icon.png"}
                        alt="Sort Icon"
                        loading="lazy"
                      />
                    )}
                  </Button>
                  <UncontrolledPopover
                    className="customPopover"
                    placement="bottom"
                    target={`influencer_profile_isFilterPopoverOpen`}
                    trigger="legacy"
                    isOpen={isFilterPopoverOpen}
                    toggle={_toggleFilterPopover}
                  >
                    <PopoverBody>
                      {postFilterOptionsConfig.map((each) => (
                        <div key={each.value} className="radioWrap">
                          <CustomInput
                            id={`influencer_detail_page_contentFilter_${each.value}`}
                            type="radio"
                            name="contentFilter"
                            checked={contentFilter === each.value}
                            value={each.value}
                            onChange={() => _onChangeFilter(each.value)}
                            label={each.label}
                          />
                        </div>
                      ))}
                    </PopoverBody>
                  </UncontrolledPopover>
                </div>
              ) : null}

              {activeTab === "2" ? (
                <div className="text-right bg-white">
                  <Button
                    id={`influencer_profile_vaule_filtertype`}
                    className="customPopoverBtn filterBtnInfluencerDetails"
                    onClick={() => setIsOpenFolderPopover((prev) => !prev)}
                  >
                    <img
                      src="/assets/img/filter-icon.png"
                      alt="Filter"
                      loading="lazy"
                    />
                  </Button>

                  <UncontrolledPopover
                    className="customPopover"
                    placement="bottom"
                    isOpen={isOpenFolderPopover}
                    target={`influencer_profile_vaule_filtertype`}
                    toggle={() => setIsOpenFolderPopover((prev) => !prev)}
                    trigger="legacy"
                  >
                    <PopoverBody>
                      {vaultSortOptionsConfig.map((each) => (
                        <div key={each.value} className="radioWrap">
                          <CustomInput
                            type="radio"
                            id={`vault_page_${each.value}`}
                            name="radios"
                            checked={
                              foldersDataPayload?.filterType === each.value
                            }
                            value={each.value}
                            onChange={() => _onChangeFolderSort(each.value)}
                            label={each.label}
                          />
                        </div>
                      ))}
                    </PopoverBody>
                  </UncontrolledPopover>
                </div>
              ) : null}

              <ErrorBoundary>{_makeTabContent()}</ErrorBoundary>
            </Col>
          </Row>
        </Container>
      </div>

      <PaymentHoc
        isVisible={paymentHoc.isOpen}
        isFree={paymentHoc.isFree}
        onPaymentConfirmation={() => _onPaymentConfirmation()}
        onDismiss={() => _toggleFreeSubscription()}
      />

      <DiscordConfigurationModal
        isOpen={discordConfigurationModal.isOpen}
        influencerId={discordConfigurationModal.influencerId}
        toggle={() => _toggleDiscordConfigurationModal()}
      />

      <CancelSubscriptionModal
        isOpen={cancelSubscriptionModal.isOpen}
        influencer={cancelSubscriptionModal.influencer}
        isRenewal={cancelSubscriptionModal.isRenewal}
        isResume={false}
        toggle={() => _toggleCancelSubscriptionModal({})}
        renewSubscription={_renewSubscription}
      />
    </>
  );
};

export default InfluencerProfileDetails;
