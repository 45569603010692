import React, {
  Fragment,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { getAllLiveEvents, likeDislikeEvent } from "../http-calls";
import { deepClone, errorHandler } from "../helper-methods";
import ConfigureString from "../components/ConfigureString";
import { useDispatch } from "react-redux";
import { getAndUpdateAllCreatorBundles } from "../redux/actions";
import SkeletonLoading from "../components/SkeletonLoading";
import ErrorBoundary from "../components/ErrorBoundary";
import CustomLoader from "../components/custom/CustomLoader";

// code splitting
const LiveEventWrapper = React.lazy(() =>
  import("../components/LiveEventWrapper")
);

const LiveEventPage = () => {
  const dispatch = useDispatch();

  const timerIntervalRef = useRef({});
  const observer = useRef();

  const [liveEvents, setLiveEvents] = useState([]);
  const [liveEventsCount, setLiveEventsCount] = useState(0);
  const [dataPayload, setDataPayload] = useState({
    skip: 0,
    limit: 20,
    hasPastEvents: false,
  });
  const [loadingState, setLoadingState] = useState({
    data: false,
    filter: false,
  });
  const [activeTab, setActiveTab] = useState("upcoming"); // upcoming / past

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _getAndUpdateAllCreatorBundles = async () => {
    try {
      dispatch(getAndUpdateAllCreatorBundles());
    } catch (error) {
      errorHandler(error);
    }
  };

  const _getAllLiveEvents = async (payload) => {
    try {
      _manageLoadingState("data", true);

      const res = await getAllLiveEvents(payload);

      setLiveEvents((prev) =>
        payload?.skip ? prev.concat(res?.events) : res?.events
      );

      setLiveEventsCount(res?.count);

      setLoadingState({});
    } catch (error) {
      errorHandler(error);
      setLoadingState({});
    }
  };

  const _toggleHasPastEvents = (hasPastEvents = false) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload.skip = 0;
    newDataPayload.hasPastEvents = hasPastEvents;
    setDataPayload(newDataPayload);

    _getAllLiveEvents(newDataPayload);
  };

  // tabName = upcoming / past / invited
  const _toggleActiveTab = (tabName = "upcoming") => {
    setActiveTab(tabName);

    // store in local storage
    localStorage.liveEventTabName = tabName;

    _toggleHasPastEvents(tabName === "past" ? true : false);
  };

  const _addCommentEvent = (index, isAdded) => {
    try {
      const newLiveEvents = [...liveEvents];
      if (isAdded) {
        if (newLiveEvents?.[index]?.comments) {
          newLiveEvents[index].comments++;
        } else {
          newLiveEvents[index].comments = 1;
        }
      } else {
        if (newLiveEvents?.[index]?.comments) {
          newLiveEvents[index].comments--;
        } else {
          newLiveEvents[index].comments = 0;
        }
      }
      setLiveEvents(newLiveEvents);
    } catch (error) {
      console.log({ error });
    }
  };

  // Local Like or Dislike
  const _toggleLikeEvent = async (index, isLiked) => {
    if (timerIntervalRef?.like) clearInterval(timerIntervalRef.like);

    const newLiveEvents = [...liveEvents];
    const rollBackLiveEvents = deepClone(liveEvents);

    if (isLiked) {
      // Decrease likes count
      newLiveEvents[index]._isLiked = 0;
      newLiveEvents[index].likes -= 1;
    } else {
      // Increse likes count
      newLiveEvents[index]._isLiked = 1;
      newLiveEvents[index].likes += 1;
    }

    setLiveEvents(newLiveEvents);

    timerIntervalRef.like = setTimeout(async () => {
      try {
        await likeDislikeEvent(liveEvents[index].id, isLiked);
      } catch (error) {
        setLiveEvents(rollBackLiveEvents);
        errorHandler(error);
      }
    }, 1000);
  };

  const _updateCommentsCount = ({ index, value }) => {
    try {
      const newLiveEvents = [...liveEvents];

      newLiveEvents[index].comments = value;

      setLiveEvents(newLiveEvents);
    } catch (error) {
      console.log({ error });
    }
  };

  const lastElementRef = useCallback(
    (node) => {
      if (loadingState?.data) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && liveEvents?.length < liveEventsCount) {
          const newDataPayload = { ...dataPayload };
          newDataPayload["skip"] = liveEvents?.length || 0;
          setDataPayload(newDataPayload);
          _getAllLiveEvents(newDataPayload);
        }
      });

      if (node) observer.current.observe(node);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingState?.data, liveEvents]
  );

  useEffect(() => {
    _getAndUpdateAllCreatorBundles();

    // upcoming / past
    _toggleActiveTab(localStorage?.liveEventTabName || "upcoming");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="justify-content-center noMargin">
          <Col sm={12} md={11} lg={8} xl={12} className="noPadding">
            <div className="pgTitle customMarginMob">
              <div className="d-flex">
                <h2>
                  <ConfigureString keyString={"event"} />{" "}
                </h2>
                {loadingState?.data ? <CustomLoader className="ml-2" /> : null}
              </div>
            </div>

            <Nav tabs className="customTabs customTabsMyProfile mt-2">
              <NavItem>
                <NavLink
                  active={activeTab === "upcoming"}
                  onClick={() => {
                    _toggleActiveTab("upcoming");
                  }}
                >
                  <ConfigureString keyString="Upcoming event" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === "past"}
                  onClick={() => {
                    _toggleActiveTab("past");
                  }}
                >
                  <ConfigureString keyString="Past event" />
                </NavLink>
              </NavItem>
            </Nav>

            <Row className="liveEventWrapper">
              {liveEvents && liveEvents.length ? (
                liveEvents.map((each, index) => (
                  <Fragment key={`event_${each._id}_${index}`}>
                    <ErrorBoundary>
                      <Suspense
                        fallback={<SkeletonLoading type={"event"} count={1} />}
                      >
                        <LiveEventWrapper
                          {...(index === liveEvents.length - 1
                            ? { lastElementRef }
                            : {})}
                          event={each}
                          eventIndex={index}
                          onComment={(index, isAdded) =>
                            _addCommentEvent(index, isAdded)
                          }
                          getAllEvents={() =>
                            _getAllLiveEvents({
                              ...dataPayload,
                              skip: 0,
                              limit: liveEvents.length,
                            })
                          }
                          toggleLike={() =>
                            _toggleLikeEvent(index, each._isLiked)
                          }
                          updateCommentsCount={({ index, value }) =>
                            _updateCommentsCount({ index, value })
                          }
                        />
                      </Suspense>

                      {index === liveEvents.length - 1 && loadingState.data && (
                        <SkeletonLoading type={"event"} count={2} />
                      )}
                    </ErrorBoundary>
                  </Fragment>
                ))
              ) : loadingState.data ? (
                <SkeletonLoading type={"event"} count={4} />
              ) : (
                <div className="w-100 noContentFound">
                  <ConfigureString keyString={`No ${activeTab} event found`} />
                </div>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LiveEventPage;
