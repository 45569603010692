import React, { useState } from "react";
import { Button, Col } from "reactstrap";
import { DEFAULT_COVER_PICTURE } from "../config";
import { formatDate } from "../helper-methods";
import MultiMediaModal from "./modals/MultiMediaModal";
import SkeletonLoading from "./SkeletonLoading";

const VaultContentCard = ({
  content,
  contentIndex,
  folderContents,
  loading,
  lastElementRef,
  toggleFolderTipModal,
  imageQuality,
  setImageQuality,
}) => {
  const [multiMediaModal, setMultiMediaModal] = useState({
    contentIndex: null,
    isMultiMediaOpen: false,
    contents: null,
  });

  const _toggleMultiMediaModal = (
    isOpen = false,
    contents = null,
    activeIndex = 0
  ) => {
    setMultiMediaModal({
      isOpen,
      contents,
      activeIndex,
    });
  };

  return (
    <>
      <Col xs="6" md="4" xl="3" className="customColPadding">
        <div
          className="vaultMediaThumbWrap"
          {...(lastElementRef ? { ref: lastElementRef } : {})}
        >
          {/* folder content tip btn */}
          <Button
            className="tipVaultFile"
            onClick={() => toggleFolderTipModal(true, content)}
          >
            <img src="/assets/img/tip.png" alt="Tip" />
          </Button>

          <div
            className="shadowOverlayImg"
            onClick={() =>
              _toggleMultiMediaModal(true, folderContents, contentIndex)
            }
          />

          <img
            className="vaultMediaThumb"
            src={
              content.contentType === "image"
                ? content.thumbnail || "/assets/img/image_icon.svg"
                : content.contentType === "doc"
                ? "/assets/img/pdf_icon.png"
                : content.contentType === "audio"
                ? "/assets/img/mic.png"
                : content.contentType === "video"
                ? content.thumbnail || "/assets/img/video-file.png"
                : DEFAULT_COVER_PICTURE
            }
            onError={(e) => (e.target.src = DEFAULT_COVER_PICTURE)}
            alt="Vault Media"
            loading="lazy"
          />

          <div className="vaultMediaInfo">
            {/* {content.name ? <p>{content.name}</p> : null} */}

            <div className="d-flex justify-content-between">
              {/* <span>{formatFileSize(content.size)}</span> */}
              <span>{formatDate(content.createdAt)}</span>
            </div>
          </div>
        </div>
      </Col>

      {loading && lastElementRef && (
        <SkeletonLoading type={"vaultContentCard"} count={4} />
      )}

      <MultiMediaModal
        isOpen={multiMediaModal.isOpen}
        contents={multiMediaModal.contents}
        activeIndex={multiMediaModal.activeIndex}
        toggle={() => _toggleMultiMediaModal()}
        isHideTotalLength={true}
        imageQuality={imageQuality}
        setImageQuality={setImageQuality}
      />
    </>
  );
};

export default VaultContentCard;
