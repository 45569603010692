import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import PromptModal from "../components/modals/PromptModal";
import { PAYMENT_GATEWAY } from "../config";
import AddressUpdateComponent from "../components/AddressUpdateComponent";
import { addCard, makeCardDefault, removeCard } from "../http-calls";
import { useDispatch, useSelector } from "react-redux";
import { fetchCardsAsync } from "../redux/actions/paymentData";
import { errorHandler, showToast } from "../helper-methods";
import AddCardModal from "../components/modals/AddCardModal";
import { getAndUpdateUserData } from "../redux/actions/userData";
import CustomLoader from "../components/custom/CustomLoader";
import SkeletonLoading from "../components/SkeletonLoading";

const CardManagementPage = () => {
  const { cards, isLoading } = useSelector((state) => state?.paymentData || {});
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [addCardModal, setAddCardModal] = useState({
    isOpen: false,
    cardData: null,
  });
  const [deleteCardModal, setDeleteCardModal] = useState({
    isOpen: false,
    cardData: null,
  });

  const _toggleAddCardModal = (isOpen = false, cardData = null) => {
    setAddCardModal({ isOpen, cardData });
  };

  const _toggleDeleteCardModal = (isOpen = false, cardData = null) => {
    setDeleteCardModal({ isOpen, cardData });
  };

  const _createDeleteMessage = (cardData) => {
    let message = "Remove card ending with ";
    if (PAYMENT_GATEWAY === "square") {
      message = message + cardData?.cardLastFourDigits + "?";
    } else {
      message = message + cardData?.last4 + "?";
    }
    return message;
  };

  const _onCardAdded = async (payload) => {
    try {
      setLoading(true);
      await addCard(payload.token.id);
      _fetchCards();
      setLoading(false);
      showToast("New Card Added", "success");
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const _deleteCard = async (cardData) => {
    try {
      _toggleDeleteCardModal(false);
      setLoading(true);
      if (PAYMENT_GATEWAY === "square") {
        await removeCard(cardData?.cardToken);
      } else {
        await removeCard(cardData?.id);
      }
      _fetchCards();
      setLoading(false);
      showToast("Card removed Successfully", "success");
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const _makeDefault = async (cardData) => {
    try {
      setLoading(true);
      if (PAYMENT_GATEWAY === "square") {
        await makeCardDefault(cardData?.cardToken);
      } else {
        await makeCardDefault(cardData?.id);
      }
      _fetchCards();
      setLoading(false);
      showToast("Default Card Updated", "success");
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const _fetchCards = () => {
    dispatch(fetchCardsAsync());
  };

  const _getFanDetails = () => {
    dispatch(getAndUpdateUserData());
  };

  useEffect(() => {
    _fetchCards();
    _getFanDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="customPgHeight animated fadeIn">
        <Container className="noPadding">
          <Row className="justify-content-center noMargin bg-white">
            <Col xs="12" md="10" lg="8" xl="7" className="pgPadding">
              <div className="pgTitle">
                <h2>Card Management</h2>

                <Button
                  className="themeBtn addBtn"
                  onClick={() => _toggleAddCardModal(true)}
                >
                  Add Card
                </Button>
              </div>

              <Card className="paymentWrap">
                <CardBody>
                  <div className="d-flex align-items-center">
                    <h4 className="sectionHeader mb-sm-0">Your Cards</h4>
                    {loading ? (
                      <CustomLoader width={18} height={20} className="ml-2" />
                    ) : null}
                  </div>

                  {cards.length ? (
                    <ListGroup className="savedCardInfo">
                      {cards.map((card, cardIndex) => (
                        <ListGroupItem key={cardIndex}>
                          <div className="d-flex justify-content-between align-items-start">
                            <div className="d-flex align-items-start">
                              {card?.isDefault ? (
                                // {/* primary card will have the below div */}
                                <div className="greenBg">
                                  <i className="fa fa-credit-card-alt" />
                                </div>
                              ) : (
                                // {/* cards other than "primary" will have the below div */}
                                <div className="yellowBg">
                                  <i className="fa fa-credit-card-alt" />
                                </div>
                              )}
                              <div>
                                <h5>
                                  {/* don't remove the below div's. They are here for design purpose */}
                                  <div>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                  </div>{" "}
                                  <div>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                  </div>{" "}
                                  <div>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                  </div>{" "}
                                  {PAYMENT_GATEWAY === "stripe"
                                    ? card?.last4
                                    : card?.cardLastFourDigits}
                                </h5>
                                <div className="d-flex justify-content-between">
                                  <p>
                                    {`${card?.exp_month}/${card?.exp_year
                                      .toString()
                                      .substr(2)}`}
                                  </p>
                                  <p className="cardBrand">
                                    {card?.brand?.toUpperCase()}
                                  </p>
                                </div>
                              </div>
                            </div>

                            {/* when the saved credit card is made "primary", it would not have the delete option */}
                            {card?.isDefault ? (
                              <p style={{ marginTop: -2 }}>Default</p>
                            ) : (
                              <div className="d-flex flex-column flex-sm-row">
                                <Button
                                  color="link"
                                  size="small"
                                  className="makeDefault"
                                  disabled={isLoading || loading}
                                  onClick={() => _makeDefault(card)}
                                >
                                  Make Default
                                </Button>
                                <Button
                                  color="ghost-danger"
                                  size="small"
                                  disabled={isLoading || loading}
                                  onClick={() =>
                                    _toggleDeleteCardModal(true, card)
                                  }
                                >
                                  <i className="fa fa-trash-o" />
                                </Button>
                              </div>
                            )}
                          </div>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  ) : isLoading ? (
                    <>
                      <SkeletonLoading type={"cardManagement"} count={3} />
                    </>
                  ) : (
                    <div className="noContentFound my-3">
                      <p>No Cards added yet</p>
                    </div>
                  )}
                </CardBody>
              </Card>

              <AddressUpdateComponent />

              {/* Modal for "adding a new card" */}
              <>
                <AddCardModal
                  isOpen={addCardModal.isOpen}
                  toggle={() => _toggleAddCardModal(false)}
                  onCardAdded={(payload) => _onCardAdded(payload)}
                />
              </>
            </Col>
          </Row>
        </Container>
      </div>

      <PromptModal
        isVisible={deleteCardModal?.isOpen}
        card={deleteCardModal?.cardData}
        message={
          deleteCardModal?.cardData
            ? _createDeleteMessage(deleteCardModal?.cardData)
            : ""
        }
        onSuccess={() => _deleteCard(deleteCardModal?.cardData)}
        onDismiss={() => _toggleDeleteCardModal(false)}
      />
    </>
  );
};

export default CardManagementPage;
