import { createSlice } from "@reduxjs/toolkit";
import { updateCards, clearAllPaymentData } from "../actions";
import { fetchCardsAsync } from "../actions/paymentData";
import { errorHandler } from "../../helper-methods";

const initialState = {
  cards: [],
  isLoading: false,
};

const paymentDataSlice = createSlice({
  name: "paymentData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateCards, (state, action) => {
        state.cards = action.payload;
      })

      .addCase(clearAllPaymentData, (state, action) => {
        state.cards = [];
      })

      .addCase(fetchCardsAsync.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchCardsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.cards = action.payload;
      })

      .addCase(fetchCardsAsync.rejected, (state, action) => {
        state.isLoading = false;
        errorHandler(action.payload);
      });
  },
});

export const paymentDataReducer = paymentDataSlice.reducer;
