import { useRef, useState } from "react";
import { Button, Input, Label, Collapse, CardBody } from "reactstrap";
import Draggable from "react-draggable"; // The default
import useVideoPlayer from "./hooks/useVideoPlayer";
import useHls from "./hooks/useHls";
import { videoResolutionsConfig } from "../../../config/helper-config";
import VisibilitySensor from "react-visibility-sensor";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { useEffect } from "react";

const CustomVideoPlayer = ({
  className = "",
  feedId,
  hlsLink,
  src,
  thumbnail,
  pictureInPictureMode = false,
  theatreMode = false,
  fullScreenMode = false,
  autoPlay = false,
  muted = false,
  controls = false,
  duration: videoDuration = null,
  onEnded = () => {},
  onPlay = () => {},
  onPause = () => {},
  inlineStyle = {},
}) => {
  const videoContainerRef = useRef({ current: null });

  const videoRef = useRef({ current: null });

  const videoSettingsCollapseRef = useRef({ current: null });

  const {
    playerState,
    duration,
    handlePlaybackSpeed,
    getCurrentVideoDuration,
    handleSeekProgress,
    handleVolumeChange,
    handleTimeUpdate,
    handleSkipVideo,
    handleVideoEnd,
    toggleFullScreen,
    toggleFloatingMode,
    toggleMuteUnmute,
    toggleTheatreMode,
    togglePlayPause,
    toggleVideoLoading,
    loadedmetadata,
  } = useVideoPlayer({ videoRef, videoDuration });

  const videoPlayer = useSelector((state) => state?.videoPlayer);

  const isFloating = useMemo(() => {
    return (
      videoPlayer?.floatingFeedId &&
      feedId &&
      videoPlayer?.floatingFeedId === feedId
    );
  }, [videoPlayer, feedId]);

  const { onChangeResolution, resolutions, currentResolution } = useHls(
    videoRef,
    hlsLink,
    playerState
  );

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isOpenVideoSettings, setIsOpenVideoSettings] = useState(false);

  const _closeFloatingMode = () => {
    setPosition({ x: 0, y: 0 });

    handleVideoEnd();

    toggleFloatingMode(feedId);
  };

  const _toggleFloatingMode = () => {
    setPosition({ x: 0, y: 0 });

    toggleFloatingMode(feedId);

    setTimeout(() => {
      videoContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }, 0);
  };

  const _onStopDraggable = (event, data) => {
    try {
      const wrapperWidth = videoContainerRef.current.offsetLeft - 10;
      const wrapperHeight = videoContainerRef.current.offsetTop - 60;

      // Draggable element center coordinates (x,y)
      const center = {
        x: -wrapperWidth / 2,
        y: -wrapperHeight / 2,
      };

      // When we get the nearest viewport side (below), then we can
      // use these metrics to calculate the new draggable sticky `position`
      const position = {
        top: { y: -wrapperHeight, x: 0 },
        left: { y: -wrapperHeight, x: -wrapperWidth },
        bottom: { y: 0, x: -wrapperWidth },
        right: { y: 0, x: 0 },
      };

      // The smallest margin defines the nearest viewport side to draggable.
      let nearestSide = "";

      if (data.x < center.x && data.y > center.y) {
        nearestSide = "bottom";
      } else if (data.x < center.x && data.y < center.y) {
        nearestSide = "left";
      } else if (data.x > center.x && data.y < center.y) {
        nearestSide = "top";
      } else {
        nearestSide = "right";
      }

      setPosition({ x: position[nearestSide].x, y: position[nearestSide].y });
    } catch (error) {
      console.log({ error });
      setPosition({ x: 0, y: 0 });
    }
  };

  const _onChangeVisibility = (isVisible = false) => {
    if (!isVisible) {
      setPosition({ x: 0, y: 0 });
      handleVideoEnd();
    }
  };

  const _onPlay = (e) => {
    try {
      onPlay(e);
    } catch (error) {
      console.log({ error });
    }
  };

  const _onPause = () => {
    try {
      if (playerState.isPlaying) {
        togglePlayPause();
      }
      onPause();
    } catch (error) {
      console.log({ error });
    }
  };

  const _onEnded = () => {
    try {
      handleVideoEnd();
      onEnded();
    } catch (error) {
      console.log({ error });
    }
  };

  /**
   * Alert if clicked on outside of element
   */

  useEffect(() => {
    const _handleClickOutside = (event) => {
      const iconClickToOpen = document.createElement("img");
      iconClickToOpen.src = "/assets/img/video-player/setting.png";
      iconClickToOpen.alt = "Setting";
      iconClickToOpen.style = "height:24px";

      const isClickedOutside =
        videoSettingsCollapseRef.current &&
        !videoSettingsCollapseRef.current.contains(event.target) &&
        !event.target.isEqualNode(iconClickToOpen);

      if (isClickedOutside) {
        console.log("click outside");
        setIsOpenVideoSettings(false);
      }
    };

    if (isOpenVideoSettings) {
      document.addEventListener("mouseup", _handleClickOutside);
    } else {
      document.removeEventListener("mouseup", _handleClickOutside);
    }

    return () => {
      document.removeEventListener("mouseup", _handleClickOutside);
    };
  }, [isOpenVideoSettings]);

  const _toggleVideoSettings = () => {
    setIsOpenVideoSettings((prev) => !prev);
  };

  // useEffect(() => {
  //   if (src) {
  //     togglePlayPause();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [src]);

  useEffect(() => {
    if (muted) {
      toggleMuteUnmute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [muted]);

  useEffect(() => {
    if (autoPlay) {
      togglePlayPause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoPlay]);

  return (
    <>
      {isFloating && (
        <>
          <div className={`customVideoWrapper nowPlaying`}>Now Playing</div>
        </>
      )}

      <VisibilitySensor
        onChange={(isVisible) => _onChangeVisibility(isVisible)}
      >
        <Draggable
          disabled={isFloating ? false : true}
          position={position}
          onStop={_onStopDraggable}
          bounds="body"
          handle=".dragVideo"
        >
          <div
            ref={videoContainerRef}
            className={`customVideoWrapper ${className} ${
              playerState.isFullScreen
                ? "fullscreen"
                : isFloating
                ? "floating"
                : playerState.isTheatreMode
                ? "theatre"
                : ""
            }`}
            style={{ width: "100%", height: "100%" }}
            onMouseLeave={() => setIsOpenVideoSettings(false)}
          >
            <video
              className="video"
              src={src}
              playsInline
              webkit-playsinline="true"
              poster={thumbnail}
              ref={videoRef}
              onTimeUpdate={handleTimeUpdate}
              onEnded={_onEnded}
              onError={_onEnded}
              onPlay={_onPlay}
              onPause={_onPause}
              style={inlineStyle}
              onLoadStart={() => toggleVideoLoading(true)}
              // onLoadedData={() => toggleVideoLoading()}
              onLoadedMetadata={() => {
                loadedmetadata();
                toggleVideoLoading();
              }}
            />

            {controls && (
              <>
                {isFloating ? (
                  <>
                    {/* close video */}
                    <Button
                      color="link"
                      className="closeVideo"
                      onClick={_closeFloatingMode}
                    >
                      <i className="fa fa-times-circle" />
                    </Button>

                    {/* drag video */}
                    <div className="dragVideo">
                      <i className="fa fa-arrows" />
                    </div>

                    {/* enlarge video */}
                    <Button
                      color="link"
                      onClick={_toggleFloatingMode}
                      className="enlargeVideo"
                    >
                      <img
                        src="/assets/img/video-player/expand.png"
                        alt="Expand"
                      />
                    </Button>
                  </>
                ) : (
                  // play/pause btn at the center
                  <Button
                    color="link"
                    className={`playPauseVideo ${
                      playerState?.isPlaying ? "playingVideo" : ""
                    }`}
                    disabled={playerState?.loading}
                    onClick={togglePlayPause}
                  >
                    {playerState?.loading ? (
                      <i className="fa fa-spinner fa-spin mr-1" />
                    ) : (
                      <>
                        {playerState.isPlaying ? (
                          <img
                            src="/assets/img/video-player/pause.png"
                            alt="Pause"
                          />
                        ) : (
                          <i className="fa fa-play" />
                        )}
                      </>
                    )}
                  </Button>
                )}

                <div className="videoControlsWrap">
                  {/* video length progress bar */}
                  <div className="videoSeekBarWrap">
                    <div className="videoSeekBar">
                      <div
                        className="videoProgress"
                        style={{ width: `${playerState.progress}%` }}
                      />

                      {/* drag handle */}
                      <input
                        type="range"
                        className="dragHandle"
                        value={playerState.progress}
                        onChange={handleSeekProgress}
                      />
                    </div>
                  </div>

                  <div className="videoControls">
                    <div>
                      {/* Skip backward */}
                      <Button
                        color="link"
                        onClick={() => handleSkipVideo("backward")}
                      >
                        <img
                          src="/assets/img/video-player/backward.png"
                          alt="Back"
                        />
                      </Button>

                      {/* play/pause */}
                      <Button
                        color="link"
                        disabled={playerState?.loading}
                        onClick={togglePlayPause}
                      >
                        {playerState?.loading ? (
                          <i className="fa fa-spinner fa-spin mr-1" />
                        ) : (
                          <>
                            {playerState?.isPlaying ? (
                              <img
                                src="/assets/img/video-player/pause.png"
                                alt="Pause"
                                style={{ height: 27 }}
                              />
                            ) : (
                              <i className="fa fa-play" />
                            )}
                          </>
                        )}
                      </Button>

                      {/* Skip forward */}
                      <Button
                        color="link"
                        onClick={() => handleSkipVideo("forward")}
                      >
                        <img
                          src="/assets/img/video-player/forward.png"
                          alt="Forward"
                        />
                      </Button>

                      {/* video duration */}
                      {duration ? (
                        <div className="videoTimestamp">
                          <span>{getCurrentVideoDuration()}</span> /{" "}
                          <span>{duration}</span>
                        </div>
                      ) : null}
                    </div>

                    {/* floating video  */}
                    {isFloating ? (
                      <div className="videoVolume">
                        {/* mute/unmute */}
                        {playerState.isMuted ? (
                          <Button color="link" onClick={toggleMuteUnmute}>
                            <i className="fa fa-volume-off" />
                          </Button>
                        ) : (
                          <Button color="link" onClick={toggleMuteUnmute}>
                            <i className="fa fa-volume-up" />
                          </Button>
                        )}
                      </div>
                    ) : (
                      <div>
                        {/* mute/unmute with controller */}
                        <div className="videoVolume">
                          <div className="videoSeekBar">
                            <div
                              className="videoProgress"
                              style={{
                                width: `${
                                  playerState.isMuted
                                    ? 0
                                    : playerState.volume * 100
                                }%`,
                              }}
                            />

                            {/* volume indicator / controller */}
                            <input
                              type="range"
                              value={
                                playerState.isMuted
                                  ? 0
                                  : playerState.volume * 100
                              }
                              onChange={handleVolumeChange}
                              className="dragHandle"
                            />
                          </div>

                          {playerState.isMuted ? (
                            <Button color="link" onClick={toggleMuteUnmute}>
                              <i className="fa fa-volume-off" />
                            </Button>
                          ) : playerState.volume >= 0.5 ? (
                            <Button color="link" onClick={toggleMuteUnmute}>
                              <i className="fa fa-volume-up" />
                            </Button>
                          ) : (
                            <Button color="link" onClick={toggleMuteUnmute}>
                              <i className="fa fa-volume-down" />
                            </Button>
                          )}
                        </div>

                        {/* video setting */}
                        <Button
                          color="link"
                          onClick={() => _toggleVideoSettings()}
                        >
                          <img
                            src="/assets/img/video-player/setting.png"
                            alt="Setting"
                            style={{ height: 24 }}
                          />
                        </Button>

                        <div ref={videoSettingsCollapseRef}>
                          <Collapse
                            isOpen={isOpenVideoSettings}
                            className="videoSettings"
                          >
                            <CardBody style={{ padding: "10px 12px" }}>
                              {/* playback speed */}
                              <Label style={{ marginBottom: 3 }}>
                                Playback Speed
                              </Label>
                              <Input
                                type="select"
                                className="mb-2"
                                value={playerState.speed}
                                onChange={handlePlaybackSpeed}
                              >
                                <option value="0.25">0.25x</option>
                                <option value="0.5">0.5x</option>
                                <option value="1">1x</option>
                                <option value="1.25">1.25x</option>
                                <option value="1.5">1.5x</option>
                                <option value="1.75">1.75x</option>
                                <option value="2">2x</option>
                              </Input>

                              {/* Video Quality */}
                              <Label style={{ marginBottom: 3 }}>Quality</Label>
                              <Input
                                type="select"
                                onChange={onChangeResolution}
                                value={currentResolution}
                              >
                                <option value="auto">Auto</option>
                                {resolutions?.map((each, index) => (
                                  <option
                                    key={`VideoQualitySetter_${each}_${index}`}
                                    value={each}
                                  >
                                    {videoResolutionsConfig[each]}p
                                  </option>
                                ))}
                              </Input>
                            </CardBody>
                          </Collapse>
                        </div>

                        {/* Picture In Picture */}
                        {!playerState.isFullScreen &&
                          pictureInPictureMode &&
                          feedId && (
                            <Button color="link" onClick={_toggleFloatingMode}>
                              <img
                                src="/assets/img/video-player/picture-in-picture.png"
                                alt="Float"
                                style={{ height: 20 }}
                              />
                            </Button>
                          )}

                        {/* Theatre Mode => visible only if its not fullscreen */}
                        {theatreMode && (
                          <>
                            {!playerState.isFullScreen &&
                              (playerState.isTheatreMode ? (
                                <svg
                                  onClick={toggleTheatreMode}
                                  viewBox="0 0 24 24"
                                  className="icon"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M19 7H5c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zm0 8H5V9h14v6z"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  onClick={toggleTheatreMode}
                                  viewBox="0 0 24 24"
                                  className="icon"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M19 6H5c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 10H5V8h14v8z"
                                  />
                                </svg>
                              ))}
                          </>
                        )}

                        {fullScreenMode && (
                          <>
                            {/* Full Screen */}
                            {playerState.isFullScreen ? (
                              <Button color="link" onClick={toggleFullScreen}>
                                <img
                                  src="/assets/img/video-player/minimize.png"
                                  alt="minimize"
                                  style={{ height: 23 }}
                                />
                              </Button>
                            ) : (
                              <Button color="link" onClick={toggleFullScreen}>
                                <img
                                  src="/assets/img/video-player/maximize.png"
                                  alt="maximize"
                                  style={{ height: 23 }}
                                />
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </Draggable>
      </VisibilitySensor>
    </>
  );
};

export default CustomVideoPlayer;
