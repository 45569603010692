import React, { Component } from "react";
import { Button, Col, Input, Row, FormGroup, CustomInput } from "reactstrap";
import {
  deepClone,
  formatCurrencyValue,
  reactHtmlParser,
  showToast,
} from "../helper-methods";
import moment from "moment";
import PaymentHoc from "./PaymentHoc";
import ProcessPayment from "../payment";
import { connect } from "react-redux";
import { updateOnlyUserData } from "../redux/actions/userData";
import { DISCORD_CONFIGURATION } from "../config";
import { hideLoader, showLoader } from "../redux/actions";
import ReadMoreReadLess from "./custom/ReadMoreReadLess";

class PaymentSubscriptionTier extends Component {
  state = {
    selectedTier: "basic",
    selectedPaymentType: "",
    selectedPaymentObject: null,
    subscriptions: [],
    subscriptionCancelDialog: {
      selectedSubscription: null,
      isVisible: false,
    },
    subscriptionRenewDialog: {
      selectedSubscription: null,
      isVisible: false,
    },
    modals: [false],
    openManageSubscriptionModal: false,
    selectedSubscription: null,

    apiData: null,
    paymentConfirmationModal: {
      isVisible: false,
      amount: 0,
    },
    isRenewal: false,
  };

  _changeTier = (ev) => {
    this.setState(
      {
        selectedTier: ev.target.value,
        selectedPaymentType: "",
        selectedPaymentObject: null,
      },
      () => {}
    );
  };

  _getOffers = () => {
    const { selectedTier } = deepClone(this.state);
    const { offers } = deepClone(this.props);

    const selectedOffers = offers.filter(
      (offer) => offer.tier === selectedTier
    );

    return selectedOffers;
  };

  _getBundles = () => {
    const { selectedTier } = deepClone(this.state);
    const {
      // offers,
      bundles,
    } = deepClone(this.props);

    const selectedBundles = bundles.filter(
      (bundle) => bundle.tier === selectedTier
    );

    return selectedBundles;
  };

  _selectSubscription = (value, type) => {
    return new Promise((resolve) => {
      this.setState(
        {
          selectedPaymentObject: value,
          selectedPaymentType: type,
          apiData: {},
        },
        () => resolve(true)
      );
    });
  };

  _subscription = async () => {
    try {
      let {
        selectedPaymentObject,
        selectedPaymentType,
        paymentConfirmationModal,
        selectedTier,
        apiData,
      } = deepClone(this.state);

      const { influencer } = deepClone(this.props);

      apiData = {};

      if (
        selectedPaymentType &&
        selectedPaymentType.length &&
        (selectedPaymentType === "offer" || selectedPaymentType === "bundle")
      ) {
        if (selectedPaymentType === "offer") {
          apiData["offerId"] = selectedPaymentObject._id;
        } else {
          apiData["bundleId"] = selectedPaymentObject._id;
        }
        paymentConfirmationModal = {
          isVisible: true,
          amount: selectedPaymentObject.price,
        };
      } else {
        if (selectedTier === "basic" && influencer.subscriptionFees.amount) {
          apiData["tier"] = selectedTier;
          paymentConfirmationModal = {
            isVisible: true,
            amount: influencer.subscriptionFees.amount,
          };
        } else if (
          selectedTier === "plus" &&
          influencer.plusSubscriptionFees.amount
        ) {
          apiData["tier"] = selectedTier;
          paymentConfirmationModal = {
            isVisible: true,
            amount: influencer.plusSubscriptionFees.amount,
          };
        } else if (
          selectedTier === "premium" &&
          influencer.premiumSubscriptionFees.amount
        ) {
          apiData["tier"] = selectedTier;
          paymentConfirmationModal = {
            isVisible: true,
            amount: influencer.premiumSubscriptionFees.amount,
          };
        } else {
          const { bundles } = deepClone(this.props);

          const selectedTierBundles = bundles?.length
            ? bundles.filter((bundle) => bundle?.tier === selectedTier)
            : [];

          if (selectedTierBundles?.length) {
            let minimumSubscriptionPlan = selectedTierBundles[0];
            selectedTierBundles.forEach((bundle) => {
              if (
                bundle.subscriptionPeriod <
                minimumSubscriptionPlan.subscriptionPeriod
              ) {
                minimumSubscriptionPlan = bundle;
              }
            });
            await this._selectSubscription(minimumSubscriptionPlan, "bundle");

            apiData["bundleId"] = minimumSubscriptionPlan._id;
            paymentConfirmationModal = {
              isVisible: true,
              amount: minimumSubscriptionPlan.price,
            };
          } else {
            showToast("Please select bundle", "error");
            return;
          }
        }
      }
      this.setState({
        paymentConfirmationModal,
        apiData,
      });
    } catch (error) {
      console.log("error>>", error);
      showToast("Somthing went wrong, Try again after somtime.");
    }
  };

  _dismissPaymentConfirmation = () => {
    let { paymentConfirmationModal } = deepClone(this.state);

    paymentConfirmationModal = {
      isVisible: false,
      amount: 0,
    };
    this.setState({ paymentConfirmationModal });
  };

  _openCloseManageSubscriptionModal = (
    currentSubscription = null,
    clickedTier = "",
    openForBundle = false,
    isRenewal = false
  ) => {
    let { openManageSubscriptionModal, selectedSubscription, selectedTier } =
      deepClone(this.state);

    if (
      currentSubscription &&
      currentSubscription.tier === clickedTier &&
      !openForBundle &&
      !isRenewal
    ) {
      return;
    }

    openManageSubscriptionModal = !openManageSubscriptionModal;

    this.setState({
      selectedSubscription: currentSubscription
        ? currentSubscription
        : selectedSubscription
        ? currentSubscription
        : null,
      selectedTier: clickedTier.length
        ? clickedTier
        : selectedTier.length
        ? selectedTier
        : "",
      openManageSubscriptionModal,
      isRenewal,
    });
  };

  _getBasicPriceDetails = (subscription) => {
    let basic = {
      price: 0,
      isCurrentTier: "",
      isAvailable: true,
      bundles: [],
    };
    const { influencer } = this.state;

    if (!influencer) {
      return basic;
    }

    basic["price"] =
      influencer &&
      influencer.subscriptionFees &&
      influencer.subscriptionFees.amount
        ? influencer.subscriptionFees.amount
        : 0;

    basic["bundles"] =
      subscription.bundles && subscription.bundles.length
        ? subscription.bundles.filter((item) => item.tier === "basic")
        : [];

    basic["isCurrentTier"] =
      subscription.tier && subscription.tier === "basic" ? true : false;

    return basic;
  };

  _getPlusPriceDetails = (subscription) => {
    let plus = {
      price: 0,
      isCurrentTier: "",
      isAvailable: true,
      bundles: [],
    };
    const { influencer } = this.state;

    if (!influencer) {
      return plus;
    }

    plus["price"] =
      influencer &&
      influencer.plusSubscriptionFees &&
      influencer.plusSubscriptionFees.amount
        ? influencer.plusSubscriptionFees.amount
        : subscription.tier && subscription.tier === "plus"
        ? influencer.subscriptionFees.amount
        : 0;

    plus["bundles"] =
      subscription.bundles && subscription.bundles.length
        ? subscription.bundles.filter((item) => item.tier === "plus")
        : [];

    plus["isCurrentTier"] =
      subscription.tier && subscription.tier === "plus" ? true : false;

    return plus;
  };

  _getPremiumPriceDetails = (subscription) => {
    let premium = {
      price: 0,
      isCurrentTier: "",
      isAvailable: true,
      bundles: [],
    };
    const { influencer } = this.state;

    if (!influencer) {
      return premium;
    }

    premium["price"] =
      influencer &&
      influencer.premiumSubscriptionFees &&
      influencer.premiumSubscriptionFees.amount
        ? influencer.premiumSubscriptionFees.amount
        : subscription.tier && subscription.tier === "premium"
        ? influencer.subscriptionFees.amount
        : 0;

    premium["bundles"] =
      subscription.bundles && subscription.bundles.length
        ? subscription.bundles.filter((item) => item.tier === "premium")
        : [];

    premium["isCurrentTier"] =
      subscription.tier && subscription.tier === "premium" ? true : false;

    return premium;
  };

  _changeBundle = (bundle) => {
    let { selectedSubscription, apiData, paymentConfirmationModal } = deepClone(
      this.state
    );

    let bundles = [];
    if (selectedSubscription.tier === "basic") {
      bundles = this._getBasicPriceDetails(selectedSubscription).bundles;
    } else if (selectedSubscription.tier === "plus") {
      bundles = this._getPlusPriceDetails(selectedSubscription).bundles;
    } else if (selectedSubscription.tier === "premium") {
      bundles = this._getPremiumPriceDetails(selectedSubscription).bundles;
    }
    if (bundles.length) {
      let selectedBundle = bundles.filter(
        (item) => item.subscriptionPeriod === bundle
      )[0];

      if (selectedBundle && selectedBundle._id && selectedBundle._influencer) {
        apiData = {
          influencer: selectedBundle._influencer,
          bundleId: selectedBundle,
        };
        paymentConfirmationModal = {
          isVisible: true,
          amount: selectedBundle.price,
        };
        this.setState({ apiData, paymentConfirmationModal }, () => {});
      }
    }
  };

  _updateTier = (tier, bundle = "") => {
    let { selectedSubscription, apiData, paymentConfirmationModal } = deepClone(
      this.state
    );

    const currentTierIndex = this.tiers.indexOf(selectedSubscription.tier);

    const selectedTierIndex = this.tiers.indexOf(tier);

    let price = 0,
      remainingAmount = 0,
      bundles = [];
    if (tier === "basic") {
      price = this._getBasicPriceDetails(selectedSubscription).price;
      bundles = this._getBasicPriceDetails(selectedSubscription).bundles;
    } else if (tier === "plus") {
      price = this._getPlusPriceDetails(selectedSubscription).price;
      bundles = this._getPlusPriceDetails(selectedSubscription).bundles;
    } else if (tier === "premium") {
      price = this._getPremiumPriceDetails(selectedSubscription).price;
      bundles = this._getPremiumPriceDetails(selectedSubscription).bundles;
    }

    if (selectedTierIndex > currentTierIndex) {
      let totalDayPassed = moment().diff(
        moment(selectedSubscription.startDate),
        "days"
      );
      let totalDay = moment(selectedSubscription.endDate).diff(
        moment(selectedSubscription.startDate),
        "days"
      );
      if (totalDayPassed < 1) {
        totalDayPassed = 1;
      } else {
        totalDayPassed += 1;
      }
      remainingAmount = Math.ceil(
        selectedSubscription.amount -
          (selectedSubscription.amount / totalDay) * totalDayPassed
      );
    }

    let selectedBundle = [];
    if (bundles.length && bundle.length) {
      selectedBundle = bundles.filter(
        (item) => item.subscriptionPeriod === bundle
      );
    }

    apiData = {
      influencer: selectedSubscription._influencer._id,
      tier,
    };
    paymentConfirmationModal = {
      isVisible: true,
      amount:
        remainingAmount > 0
          ? remainingAmount
          : selectedBundle && selectedBundle.length
          ? selectedBundle[0].price
          : price,
    };

    this.setState({ apiData, paymentConfirmationModal }, () => {});
  };

  _onPaymentConfirmation = async (card = null) => {
    let { apiData, paymentConfirmationModal } = this.state;

    const { influencer } = deepClone(this.props);

    let data = {};

    if (card) {
      apiData.card = card;
    }

    try {
      this._dismissPaymentConfirmation();

      this.props.showLoader(`Subscribing to ${influencer?.username}`);

      data.url3DSecure = `/payment/intent/subscription/${influencer.id}`;
      data.url = `/subscription/${influencer.id}`;

      data.payload = {
        ...apiData,
      };

      data.influencer = this.props.influencer;

      const { transaction } = await ProcessPayment.startPaymentProcess(
        data,
        paymentConfirmationModal.amount,
        "Put"
      );

      // Take user to home page
      if (
        DISCORD_CONFIGURATION === true &&
        transaction?.subscriptionTier === "premium"
      ) {
        this.props.toggleDiscordConfigurationModal(true, transaction._to);
      }

      this.props.getInfluencerDetails();

      showToast(
        `Successfully subscribed to ${influencer?.username}`,
        "success"
      );
      this.props.hideLoader();
    } catch (error) {
      if (error?.isRiskAssessment) {
        this.props.updateOnlyUserData({
          ...this.props.userData.user,
          isRiskAssessment: error.isRiskAssessment,
        });
      }
      this.props.hideLoader();
      showToast(
        error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
    }
  };

  render() {
    const { influencer, bundles } = deepClone(this.props);

    const { selectedTier, paymentConfirmationModal, selectedPaymentObject } =
      deepClone(this.state);

    const plusSubscriptionFees = influencer?.plusSubscriptionFees?.amount || 0;
    const premiumSubscriptionFees =
      influencer?.premiumSubscriptionFees?.amount || 0;

    const plusBundles = bundles?.length
      ? bundles.filter((bundle) => bundle?.tier === "plus")
      : [];
    const premiumBundles = bundles?.length
      ? bundles.filter((bundle) => bundle?.tier === "premium")
      : [];

    return (
      <>
        <PaymentHoc
          isVisible={paymentConfirmationModal.isVisible}
          amount={paymentConfirmationModal.amount}
          isFree={paymentConfirmationModal.isFree}
          influencer={this.props.influencer}
          onPaymentConfirmation={(card) => this._onPaymentConfirmation(card)}
          onDismiss={() => this._dismissPaymentConfirmation()}
        />

        <div className="d-flex align-items-center" style={{ marginTop: 15 }}>
          {influencer?.multiTierSubscription &&
          (plusSubscriptionFees ||
            plusBundles?.length ||
            premiumSubscriptionFees ||
            premiumBundles?.length) ? (
            <FormGroup className="mb-0 mr-3">
              <Input
                type="select"
                name="select"
                id="tierSelect"
                value={selectedTier}
                onChange={(ev) => this._changeTier(ev)}
                style={{ minWidth: 120 }}
              >
                <option value="basic">Basic</option>
                {plusSubscriptionFees || plusBundles?.length ? (
                  <option value="plus">Plus</option>
                ) : null}
                {premiumSubscriptionFees || premiumBundles?.length ? (
                  <option value="premium">Premium</option>
                ) : null}
              </Input>
            </FormGroup>
          ) : null}

          <Button
            type="button"
            className="themeBtn btnSubscribe m-0"
            onClick={() => this._subscription()}
          >
            Subscribe
          </Button>
        </div>

        {influencer?.[
          `${
            selectedTier !== "basic" ? selectedTier + "S" : "s"
          }ubscriptionFees`
        ]?.description?.length ? (
          <div className="profileDesc">
            <ReadMoreReadLess
              className="readMore"
              text={reactHtmlParser(
                influencer[
                  `${
                    selectedTier !== "basic" ? selectedTier + "S" : "s"
                  }ubscriptionFees`
                ]?.description
              )}
            />
          </div>
        ) : null}

        {/* Hide the below section if bundle is not available for selected tier  */}
        {this._getBundles().length ? (
          <Row className="bundles-Tier">
            <Col sm="12">
              <div className="bundles-Tier-Heading">
                <h4>Bundles</h4>

                {influencer?.multiTierSubscription ? (
                  <Button
                    className="themeBtn addBtn my-0"
                    style={{ height: 34, lineHeight: "34px" }}
                    onClick={() => this._selectSubscription(null, "")}
                  >
                    Reset
                  </Button>
                ) : null}
              </div>
            </Col>
            {this._getBundles().map((bundle) => (
              <Col xs="6" md="4" lg="3" key={bundle._id}>
                {influencer?.multiTierSubscription ? (
                  <p>{selectedTier}</p>
                ) : null}
                <CustomInput
                  className="bundleOptions"
                  type="radio"
                  id={bundle._id}
                  name="customPaymentInput"
                  label={`${formatCurrencyValue(bundle.price)} for ${
                    bundle.subscriptionPeriod / 30
                  } ${
                    bundle.subscriptionPeriod / 30 === 1 ? "Month" : "Months"
                  }`}
                  value={bundle._id}
                  checked={
                    selectedPaymentObject &&
                    selectedPaymentObject._id &&
                    selectedPaymentObject._id === bundle._id
                      ? true
                      : false
                  }
                  onChange={() => this._selectSubscription(bundle, "bundle")}
                />
              </Col>
            ))}
          </Row>
        ) : null}

        {this._getOffers().length ? (
          <Row className="offers-Tier mb-0">
            <Col sm="12">
              <div className="bundles-Tier-Heading mt-0">
                <h4>Offers</h4>
              </div>
            </Col>
            {this._getOffers().map((offer) => (
              <Col xs="6" md="4" lg="3" key={offer._id}>
                <CustomInput
                  className="bundleOptions"
                  type="radio"
                  id={offer._id}
                  name="customPaymentInput"
                  label={`${formatCurrencyValue(offer.price)} for ${
                    offer.subscriptionPeriod / 30
                  } ${
                    offer.subscriptionPeriod / 30 === 1 ? "Month" : "Months"
                  }`}
                  value={offer._id}
                  checked={
                    selectedPaymentObject &&
                    selectedPaymentObject._id &&
                    selectedPaymentObject._id === offer._id
                      ? true
                      : false
                  }
                  onChange={() => this._selectSubscription(offer, "offer")}
                />
              </Col>
            ))}
          </Row>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    updateOnlyUserData: (userData) => dispatch(updateOnlyUserData(userData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentSubscriptionTier);
