import { directPayment } from "../http-calls";
import { CURRENCY_TYPE, SQUARE_API_KEY } from "../config/index.js";
// import { getCurrency } from "../helper-methods/index.js";

const verifyBuyer = async (
  payments,
  token,
  amount = null,
  user,
  fanDefaultCardCountry
) => {
  const verificationDetails = {
    amount: amount?.toString(),
    billingContact: {
      familyName: user?.name?.last,
      givenName: user?.name?.first,
      email: user?.email,
      country: fanDefaultCardCountry.country,
    },
    currencyCode: CURRENCY_TYPE?.toUpperCase(),
    intent: "CHARGE",
  };

  const verificationResults = await payments.verifyBuyer(
    token,
    verificationDetails
  );
  return verificationResults.token;
};

export const square3DSecurePayment = (
  data,
  resquestType = "Post",
  user,
  fanDefaultCardCountry,
  token
) => {
  return new Promise(async (resolve, reject) => {
    const locationId = data && data.influencer && data.influencer.locationId;

    let payments;
    try {
      payments = window.Square.payments(SQUARE_API_KEY, locationId);
    } catch (error) {
      const statusContainer = document.getElementById(
        "payment-status-container"
      );
      statusContainer.className = "missing-credentials";
      statusContainer.style.visibility = "visible";
      reject(error);
      return;
    }

    try {
      let getAmountPayload = {
        ...data.payload,
        sendAmount: true,
      };
      let amountResponse = await directPayment(
        data.url,
        getAmountPayload,
        resquestType
      );

      if (amountResponse) {
        let verificationToken = await verifyBuyer(
          payments,
          token,
          amountResponse.amount,
          user,
          fanDefaultCardCountry
        );

        let payloadWithToken = {
          ...data.payload,
          token: verificationToken,
          sendAmount: false,
        };

        let paymentResponse = await directPayment(
          data.url,
          payloadWithToken,
          resquestType
        );
        resolve(paymentResponse);
      }
    } catch (error) {
      console.log("error >> ", error);
      reject(error);
    }
  });
};
