import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { getAllSubscriptionDetails } from "../../http-calls";

const clearUserSubscriptions = createAction("clearUserSubscriptions");

const getAndUpdateUserSubscriptions = createAsyncThunk(
  "userSubscriptions/getAndUpdateUserSubscriptions",
  async (payload, thunkAPI) => {
    const res = await getAllSubscriptionDetails();
    return res?.subscriptions;
  }
);

export { getAndUpdateUserSubscriptions, clearUserSubscriptions };
