import { createSlice } from "@reduxjs/toolkit";
import { errorHandler } from "../../helper-methods";
import { updateOnlyUserData, updateUserData, clearUserData } from "../actions";
import { getAndUpdateUserData } from "../actions/userData";

const initialState = {
  token: null,
  user: null,
  isLoading: false,
};

const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateUserData, (state, action) => {
        state.token = action.payload?.token;
        state.user = action.payload?.user;
      })

      .addCase(updateOnlyUserData, (state, action) => {
        state.user = action.payload;
      })

      .addCase(clearUserData, (state, action) => {
        state.token = null;
        state.user = null;
      })

      .addCase(getAndUpdateUserData.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(getAndUpdateUserData.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoading = false;
      })

      .addCase(getAndUpdateUserData.rejected, (state, action) => {
        state.isLoading = false;
        errorHandler(action.payload);
      });
  },
});

export const userDataReducer = userDataSlice.reducer;
