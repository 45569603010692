import React, { useEffect } from "react";
import { hideLoader, showLoader } from "../redux/actions/loaderData";
import { getInfluencerDetails } from "../http-calls";
import { showToast } from "../helper-methods";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

const InfluencerPageSelector = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const params = useParams();

  const _getInfluencerDetails = async () => {
    try {
      dispatch(showLoader("Fetching your subscription details"));

      const res = await getInfluencerDetails(params?.id, {
        skip: 0,
        limit: 20,
      });

      dispatch(hideLoader());

      if (!res?.influencer) {
        history.replace("/login");
        return;
      }

      history.replace(`/influencer/${params?.id}`);
    } catch (error) {
      dispatch(hideLoader());

      showToast(
        error?.reason?.length
          ? error.reason.indexOf("Cast to ObjectId failed for value") > -1
            ? "No influencer found with this name"
            : error.reason
          : "Server Error. Try again after sometime",
        "error"
      );

      history.replace("/login");
    }
  };

  useEffect(() => {
    _getInfluencerDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default InfluencerPageSelector;
