import { createSlice } from "@reduxjs/toolkit";
import {
  clearNotifications,
  unreadNotificationCountUpdate,
  markReadSingleNotification,
  incrementUnreadNotificationCount,
} from "../actions";
import { fetchNotificationsAsync } from "../actions/notificationData";
import { errorHandler } from "../../helper-methods";

const initialState = {
  isLoading: false,
  notifications: [],
  total: 0,
  hasMore: false,
  unreadNotificationCount: 0,
};

const notificationDataSlice = createSlice({
  name: "notificationData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(clearNotifications, (state, action) => {
        state.notifications = [];
        state.total = 0;
        state.unreadNotificationCount = 0;
      })

      .addCase(incrementUnreadNotificationCount, (state, action) => {
        state.unreadNotificationCount++;
      })

      .addCase(unreadNotificationCountUpdate, (state, action) => {
        state.unreadNotificationCount = action.payload;
      })

      .addCase(markReadSingleNotification, (state, action) => {
        const findNotification = state.notifications.find(
          (each) => each._id === action.payload
        );
        if (findNotification) {
          findNotification.isRead = true;
          if (state.unreadNotificationCount) state.unreadNotificationCount--;
        }
      })

      .addCase(fetchNotificationsAsync.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchNotificationsAsync.fulfilled, (state, action) => {
        const { notifications, total, unreadNotificationCount, isConcat } =
          action.payload;

        if (isConcat) {
          state.notifications = state.notifications.concat(notifications);
        } else {
          state.notifications = notifications;
        }

        state.total = total;
        state.unreadNotificationCount = unreadNotificationCount;
        state.hasMore = total > state?.notifications?.length;
        state.isLoading = false;
      })

      .addCase(fetchNotificationsAsync.rejected, (state, action) => {
        state.isLoading = false;
        errorHandler(action?.error);
      });
  },
});

export const notificationDataReducer = notificationDataSlice.reducer;
