import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { formatCurrencyValue } from "../../helper-methods";

const StickerConfirmationModal = ({
  influencer,
  isOpen,
  sticker,
  onSuccess,
  onCancel,
}) => {
  const _onCancel = () => {
    onCancel();
  };

  const _onSuccess = () => {
    onSuccess(sticker);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _onCancel()}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      {sticker ? (
        <ModalBody>
          <h4 className="tipConfirmationTxt mb-2">
            Do you wish to send @{influencer?.username} this{" "}
            {formatCurrencyValue(sticker?.price)} sticker
          </h4>

          <img
            src={sticker?.url}
            alt="Sticker"
            className="stickerImg"
            loading="lazy"
          />

          <div className="text-center mb-3">
            <Button className="modalBtnCancel px-5" onClick={() => _onCancel()}>
              No
            </Button>
            <Button className="modalBtnSave px-5" onClick={() => _onSuccess()}>
              Yes
            </Button>
          </div>
        </ModalBody>
      ) : null}
    </Modal>
  );
};

export default StickerConfirmationModal;
