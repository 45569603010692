import React from "react";
import CustomLoader from "./CustomLoader";

const CustomSectionLoader = ({ isActive, loaderSize, height, width }) => {
  return (
    <div
      style={{
        width: width || "95%",
        height: height || 100,
        display: isActive ? "flex" : "none",
        position: isActive ? "absolute" : "unset",
        zIndex: isActive ? 99999999999 : "unset",
        backgroundColor: "white",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CustomLoader height={loaderSize || 100} width={loaderSize || 100} />
    </div>
  );
};

export default CustomSectionLoader;
