import React, { useEffect, useState } from "react";
import { Button, Label, Form, FormGroup, Input } from "reactstrap";
import { showToast, errorHandler, extractQueryParams } from "../helper-methods";
import { forgotPassword } from "../http-calls";
import { Link } from "react-router-dom";
import { APP_LOGO } from "../config";
import { RegexConfig } from "../config/RegexConfig";
import ForgotPasswordSuccess from "../components/ForgotPasswordSuccess";
import PublicFooter from "../components/PublicFooter";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const ForgotPasswordPage = () => {
  const history = useHistory();
  const userData = useSelector((state) => state?.userData);

  const [formFields, setFormFields] = useState({
    handle: "",
  });
  const [errors, setErrors] = useState({});
  const [isDirty, setIsDirty] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  // validate values filled in form fields
  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = {};
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "handle": {
              if (newFormFields[key]?.trim().length) {
                if (
                  RegexConfig.email.test(
                    String(newFormFields[key]).toLowerCase()
                  )
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Invalid email";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }

            default:
          }
        }
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  // on fields blur, update their dirty true then validate
  const _onBlurHandler = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = { ...isDirty };
    newIsDirty[key] = true;
    setIsDirty(newIsDirty);

    _validateFormFields({ newFormFields, newIsDirty });
  };

  // update form fiels as it changes
  const _updateFieldValue = (key, value) => {
    const newFormFields = { ...formFields };
    newFormFields[key] = value;
    setFormFields(newFormFields);
  };

  // marking all formfields dirty
  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newIsDirty = { ...isDirty };
      const newFormFields = { ...formFields };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  const _handleSubmit = async (e) => {
    try {
      if (e) e.preventDefault();
      const newIsDirty = await _markAllIsDirty();
      const newFormFields = { ...formFields };
      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        return;
      }

      setLoading(true);

      await forgotPassword(formFields.handle);

      setLoading(false);
      setIsSuccess(true);
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (userData?.token) {
      // Already logged in
      showToast("Logout to reset password");
      history.replace("/");
    } else {
      const queries = extractQueryParams();
      if (queries?.email?.length) {
        let newFormFields = { ...formFields };
        newFormFields.handle = queries?.email;
        setFormFields(newFormFields);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isSuccess) {
    return (
      <ForgotPasswordSuccess
        email={formFields.handle}
        resetSuccessState={() => setIsSuccess(false)}
      />
    );
  }

  return (
    <React.Fragment>
      <div className="animated fadeIn authFormWrapper">
        <div className="loginWrapper mt-auto">
          <img
            src={APP_LOGO}
            alt="Project Logo"
            className="projectLogo mt-0"
            onClick={() => {
              history.replace("/");
            }}
            loading="lazy"
          />

          <div className="authPgFormWrap">
            <h4>Forgot Password</h4>
            <Form onSubmit={(e) => _handleSubmit(e)}>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  type="text"
                  autoComplete="Email"
                  placeholder="Email"
                  value={formFields.handle}
                  onChange={(e) => _updateFieldValue("handle", e.target.value)}
                  onBlur={() => _onBlurHandler("handle")}
                />

                {errors?.handle ? (
                  <p className="form-error">{errors.handle}</p>
                ) : null}
              </FormGroup>

              <Button
                className="themeBtn loginBtn"
                type="submit"
                disabled={loading}
              >
                <span>
                  {loading ? (
                    <i className="fa fa-spinner fa-spin mr-1" />
                  ) : null}{" "}
                  Forgot Password
                </span>
              </Button>

              <Link to="/login" className="backToLogin">
                Back to Login
              </Link>
            </Form>
          </div>
        </div>

        <PublicFooter />
      </div>
    </React.Fragment>
  );
};

export default ForgotPasswordPage;
