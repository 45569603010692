import { cardPayment } from "./cardPayment";
import {
  getFanDefaultCardCountry,
  createPaymentIntent,
  directPayment,
} from "../http-calls";
import { europeCountyList } from "../config/helper-config";
import { minAmountFor3DSecureEnable } from "../config/app-config";
import { store } from "../redux/store.js";
import { PAYMENT_GATEWAY } from "../config";
import {
  subscribeForPayment,
  unsubscribeForPayment,
} from "../chat-client/index";
import { openUrlForHumboldtPayment } from "../helper-methods";
import { square3DSecurePayment } from "./square3DSecurePayment";

export default class ProcessPayment {
  static startPaymentProcess = async (
    data,
    amountToPay = null,
    resquestType = "Post"
  ) => {
    // console.log("APIDATA >>>", data);
    return new Promise(async (resolve, reject) => {
      try {
        const globalState = store && store.getState();

        const user =
          globalState && globalState.userData && globalState.userData.user;

        const token =
          globalState &&
          globalState.paymentData &&
          globalState.paymentData.cards &&
          globalState.paymentData.cards.length
            ? globalState.paymentData.cards.find(
                (card) => card.isDefault === true
              ).cardToken
            : null;

        const isThreeDSecure =
          globalState &&
          globalState.userData &&
          globalState.userData.user &&
          globalState.userData.user.isThreeDSecure;

        // always true: to risk assess, any payment being made
        const isRiskAssessment = true; // globalState?.userData?.user?.isRiskAssessment;

        // console.log("isRiskAssessment >>", isRiskAssessment);

        const fanDefaultCardCountry = await getFanDefaultCardCountry();

        // Check 4 options for 3D Payment
        // Country is EU
        // OR
        // Amount > $75
        // OR
        // User Flagged by Admin
        // OR
        // Risk Assessment > 60%

        if (
          (fanDefaultCardCountry &&
            fanDefaultCardCountry.country &&
            europeCountyList.includes(fanDefaultCardCountry.country)) ||
          minAmountFor3DSecureEnable <= amountToPay ||
          isThreeDSecure ||
          isRiskAssessment
        ) {
          if (PAYMENT_GATEWAY === "square") {
            try {
              let res = await square3DSecurePayment(
                data,
                resquestType,
                user,
                fanDefaultCardCountry,
                token
              );
              resolve(res);
            } catch (error) {
              reject(error);
            }
          } else {
            createPaymentIntent(data.url3DSecure, data.payload, resquestType)
              .then(async (intentResponse) => {
                if (PAYMENT_GATEWAY === "stripe") {
                  // If payment Stripe
                  try {
                    const confirmPaymentResponse = await cardPayment(
                      intentResponse.secret,
                      intentResponse.defaultCard
                    );
                    resolve({
                      ...confirmPaymentResponse,
                      transaction: intentResponse.transaction,
                    });
                  } catch (error) {
                    // Error
                    reject(error);
                  }
                } else {
                  // Open humboldt payment window
                  openUrlForHumboldtPayment(
                    intentResponse.url,
                    "width=730,height=345,left=100,top=100,resizable=yes,scrollbars=yes",
                    "Humboldt Payment"
                  );

                  // Subscribe fan for payment response
                  subscribeForPayment(
                    globalState &&
                      globalState.userData &&
                      globalState.userData &&
                      globalState.userData.user &&
                      globalState.userData.user._id,
                    (response) => {
                      if (response.payment) {
                        resolve(response);
                        unsubscribeForPayment();
                      } else {
                        reject(response.payment);
                        unsubscribeForPayment();
                      }
                    }
                  );
                }
              })
              .catch((err) => {
                // Error
                reject(err);
              });
          }
        } else {
          directPayment(data.url, data.payload, resquestType)
            .then((response) => {
              // Success
              resolve(response);
            })
            .catch((err) => {
              // Error
              reject(err);
            });
        }
      } catch (error) {
        // Error
        reject(error);
      }
    });
  };
}
