import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { errorHandler } from "../../helper-methods";
import { updateFanDiscordUserId } from "../../http-calls";

const DiscordConfigurationModal = ({ isOpen, toggle, influencerId }) => {
  const [discordUserId, setDiscordUserId] = useState("");
  const [isDiscordUserIdInputError, setIsDiscordUserIdInputError] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const _resetState = () => {
    setDiscordUserId("");
    setIsDiscordUserIdInputError(false);
  };

  const _closeModal = () => {
    _resetState();
    toggle();
  };

  const _onConnect = () => {
    if (discordUserId?.trim().length) {
      setIsDiscordUserIdInputError(false);
      setLoading(true);

      updateFanDiscordUserId({
        discordUserId: discordUserId.trim(),
        influencerId,
      })
        .then((res) => {
          window.open(res.authLink, "_blank");
          _closeModal();
        })
        .catch((error) => {
          setLoading(false);
          errorHandler(error);
        });
    } else {
      setIsDiscordUserIdInputError(true);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={_closeModal}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalHeader toggle={_closeModal}>Discord Connection</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Discord User Id</Label>
          <Input
            type="text"
            value={discordUserId}
            onChange={(e) => setDiscordUserId(e.target.value)}
            placeholder="Enter your Discord User Id"
            disabled={loading}
            onBlur={() =>
              setIsDiscordUserIdInputError((prev) =>
                discordUserId?.trim().length ? false : true
              )
            }
          />
          {isDiscordUserIdInputError ? (
            <div className="form-error">*Required</div>
          ) : null}
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button
          className="modalBtnYes float-right"
          disabled={loading}
          onClick={() => _onConnect()}
        >
          {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
          Connect
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DiscordConfigurationModal;
