import React, { useRef } from "react";
import { addCardAndMakeDefault, createPaymentIntent } from "../../http-calls";
import { fetchCardsAsync } from "../../redux/actions/paymentData";
import { updateOnlyUserData } from "../../redux/actions/userData";
import {
  Label,
  Button,
  InputGroup,
  Input,
  InputGroupAddon,
  Modal,
  ModalBody,
  InputGroupText,
  ModalFooter,
  ModalHeader,
  CustomInput,
} from "reactstrap";
import { AppConfig } from "../../config/app-config";
import ApplePayment from "../../payment/ApplePayment";
import ProcessPayment from "../../payment/index";
import {
  errorHandler,
  formatCurrencyValue,
  isValidPrice,
  showToast,
} from "../../helper-methods";
import { CURRENCY_SYMBOL } from "../../config";
import CustomSectionLoader from "../custom/CustomSectionLoader";
import InjectedStripeElement from "../StripeElement";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const ContributeToGoalModal = ({
  isVisible,
  onDismiss,
  feed,
  influencer,
  onTipSuccess,
}) => {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.userData);

  const addCardRef = useRef({ current: null });

  const [preferredType, setPreferredType] = useState("exisiting"); // new
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [defaultCard, setDefaultCard] = useState(null);
  const [formFields, setFormFields] = useState({
    amount: {
      value: "",
      error: null,
      isDirty: false,
    },
  });
  const [isConfirmationTextShow, setIsConfirmationTextShow] = useState(false);

  const _validateAmount = (newFormFields) => {
    return new Promise((resolve) => {
      let isFormValid = true;

      const minimumAmount =
        feed?.minimumPaymentForGoal &&
        feed?.minimumPaymentForGoal > AppConfig.mintransactionAmount
          ? feed.minimumPaymentForGoal
          : AppConfig.mintransactionAmount;

      if (newFormFields.amount?.isDirty) {
        if (newFormFields.amount?.value?.length) {
          if (
            isNaN(newFormFields.amount?.value) ||
            +newFormFields.amount?.value < minimumAmount ||
            +newFormFields.amount?.value > AppConfig.maxTransactionAmount
          ) {
            newFormFields.amount.isDirty = true;
            newFormFields.amount.error =
              +newFormFields.amount?.value < minimumAmount
                ? `*Minimum ${CURRENCY_SYMBOL}${minimumAmount}`
                : `*Maximum limit is ${CURRENCY_SYMBOL}${AppConfig.maxTransactionAmount}`;
            isFormValid = false;
          } else {
            newFormFields.amount.isDirty = false;
            newFormFields.amount.error = null;
          }
        } else {
          newFormFields.amount.isDirty = true;
          newFormFields.amount.error = "*Required";
          isFormValid = false;
        }
      }

      setFormFields(newFormFields);
      resolve(isFormValid);
    });
  };

  const _onAmountFieldUpdate = (value) => {
    if (!isValidPrice(value) || String(value).length > 10) {
      return;
    }

    const newFormFields = { ...formFields };
    newFormFields.amount.value = value;
    newFormFields.amount.isDirty = true;
    setFormFields(newFormFields);
    _validateAmount(newFormFields);
  };

  const _markAmountFormFieldDirty = () => {
    return new Promise((resolve) => {
      const newFormFields = { ...formFields };
      newFormFields.amount.isDirty = true;
      setFormFields(newFormFields);

      resolve(newFormFields);
    });
  };

  const _onTypeChange = (e) => {
    setPreferredType(e.target.value);
  };

  const _getDefaultCard = () => {
    return new Promise(async (resolve, reject) => {
      _showLoader();
      const res = await fetchCardsAsync()(dispatch);
      const cards = res?.payload;
      if (cards?.length) {
        await _setDefaultCard(cards);
        _hideLoader();
      } else {
        // No cards available
        setPreferredType("new");
        setDefaultCard(null);
        _hideLoader();
        resolve();
      }
    });
  };

  useEffect(() => {
    if (isVisible) {
      _getDefaultCard();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const _setDefaultCard = (cards) => {
    return new Promise((resolve, reject) => {
      let newDefaultCard = null;
      cards.forEach((card) => {
        if (card.isDefault) {
          newDefaultCard = card;
          return;
        }
      });
      setDefaultCard(newDefaultCard);
      resolve();
    });
  };

  const _resetState = () => {
    setPreferredType("exisiting"); // new
    setIsLoaderActive(false);
    setDefaultCard(null);
    setFormFields({
      amount: {
        value: "",
        error: null,
        isDirty: false,
      },
    });
    setIsConfirmationTextShow(false);
  };

  const _onDismiss = () => {
    // Reset state
    _resetState();
    onDismiss();
  };

  const _showLoader = () => {
    setIsLoaderActive(true);
  };

  const _hideLoader = () => {
    setIsLoaderActive(false);
  };

  const _confirmationTextShow = async () => {
    const newFormFields = await _markAmountFormFieldDirty();

    const isValidAmount = await _validateAmount(newFormFields);

    if (!isValidAmount) {
      return;
    }

    setIsConfirmationTextShow(true);
  };

  const _processPayment = async () => {
    try {
      setIsConfirmationTextShow(false);

      const newFormFields = await _markAmountFormFieldDirty();

      const isValidAmount = await _validateAmount(newFormFields);

      if (!isValidAmount) {
        return;
      }

      if (preferredType === "new") {
        // Try to add card
        addCardRef.current();
      } else {
        // preferredType === 'existing'
        _payTip();
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const _onStripeError = (e) => {
    _hideLoader();
  };

  // Add card
  const _onCardAdded = async (payload) => {
    try {
      const newFormFields = await _markAmountFormFieldDirty();

      const isValidAmount = await _validateAmount(newFormFields);

      if (!isValidAmount) {
        return;
      }

      await addCardAndMakeDefault(payload.token.id);
      // Card added
      // Process payment
      _payTip();
    } catch (error) {
      onDismiss();
      errorHandler(error);
    }
  };

  const _payTip = async () => {
    const newFormFields = await _markAmountFormFieldDirty();

    const isValidAmount = await _validateAmount(newFormFields);

    if (!isValidAmount) {
      return;
    }

    const tipAmount = +newFormFields.amount?.value;

    _showLoader();

    try {
      await ProcessPayment.startPaymentProcess(
        {
          url3DSecure: "/payment/intent/goal/post",
          url: "/payment/goal/post",
          influencer: feed._influencer,
          payload: {
            influencerId: feed._influencer.id,
            amount: +tipAmount.toFixed(2),
            postId: feed._id,
          },
        },
        +tipAmount.toFixed(2)
      );
      _resetState();
      onDismiss();
      onTipSuccess();
    } catch (error) {
      if (error && error.isRiskAssessment) {
        dispatch(
          updateOnlyUserData({
            ...userData.user,
            isRiskAssessment: error.isRiskAssessment,
          })
        );
      }
      _hideLoader();
      _resetState();
      onDismiss();
      if (error && error.reason && error.reason.length) {
        showToast(error.reason, "error");
      } else {
        showToast("Something went wrong", "error");
      }
    }
  };

  // Payment Intent For Apple Pay
  const _createPaymentIntent = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const newFormFields = await _markAmountFormFieldDirty();

        const isValidAmount = await _validateAmount(newFormFields);

        if (!isValidAmount) {
          reject({ reason: "Invalid Amount" });
          return;
        }

        const tipAmount = +newFormFields.amount?.value;

        const res = await createPaymentIntent("/payment/intent/goal/post", {
          influencerId: feed._influencer.id,
          amount: +tipAmount.toFixed(2),
          postId: feed._id,
        });

        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  };

  // Payment Done with Apple Pay
  const _onPaymentSuccess = () => {
    _resetState();
    onTipSuccess();
  };

  return (
    <Modal
      isOpen={isVisible}
      toggle={_onDismiss}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <Modal
        isOpen={
          isConfirmationTextShow && formFields.amount?.value ? true : false
        }
        toggle={_onDismiss}
        className="modal-dialog-centered"
      >
        <ModalBody>
          <h4 className="tipConfirmationTxt">
            Are you sure you want to contribute{" "}
            {formatCurrencyValue(formFields.amount?.value)} to @
            {influencer.username}
            's goal?
          </h4>

          <div className="text-center mb-3">
            <Button className="modalBtnCancel px-5" onClick={_onDismiss}>
              No
            </Button>
            <Button className="modalBtnSave px-5" onClick={_processPayment}>
              Yes
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <ModalHeader toggle={() => _onDismiss()}>Amount</ModalHeader>
      <ModalBody>
        <Label>Contribute to @{influencer.username}</Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText className="customInputGroup">
              <i className="fa fa-dollar" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type="text"
            value={formFields.amount?.value}
            onChange={(e) => _onAmountFieldUpdate(e.target.value)}
            name="amount"
            disabled={isLoaderActive}
            placeholder="Enter Amount"
          />
        </InputGroup>
        {formFields.amount?.error ? (
          <p className="form-error mt-1" style={{ paddingLeft: 45 }}>
            {formFields.amount?.error}
          </p>
        ) : null}

        <CustomSectionLoader isActive={isLoaderActive} loaderSize={30} />

        <div className="mt-4">
          {defaultCard ? (
            <div className="d-flex paymentRadioBtn">
              <CustomInput
                type="radio"
                id="radio1"
                name="cardType"
                value="exisiting"
                defaultChecked
                onChange={_onTypeChange}
              />
              <Label
                style={{ fontWeight: 400, marginBottom: 0 }}
                htmlFor="radio1"
              >
                {defaultCard
                  ? `Primary Card ending with ${defaultCard.last4} ${defaultCard.exp_month}/${defaultCard.exp_year}`
                  : null}
              </Label>
            </div>
          ) : null}
          <div>
            {defaultCard ? (
              <div className="d-flex paymentRadioBtn">
                <CustomInput
                  type="radio"
                  id="radio2"
                  name="cardType"
                  value="new"
                  onChange={_onTypeChange}
                />
                <Label
                  style={{ fontWeight: 400, marginBottom: 0 }}
                  htmlFor="radio2"
                >
                  Add new Card
                </Label>
              </div>
            ) : null}
            {preferredType === "new" ? (
              <div className="stripeWrapper">
                <>
                  <InjectedStripeElement
                    onCardAdded={(payload) => _onCardAdded(payload)}
                    onStripeError={_onStripeError}
                    showLoader={_showLoader}
                    hideLoader={_hideLoader}
                    addCard={(ref) => (addCardRef.current = ref)}
                  />
                </>
              </div>
            ) : null}
          </div>
        </div>
      </ModalBody>

      {/* Payment with apple/google/link Pay */}
      {+formFields.amount?.value &&
      !formFields.amount?.error &&
      +formFields.amount?.value >= AppConfig.mintransactionAmount ? (
        <ApplePayment
          content={{
            text: "Tip",
            price: formFields.amount?.value,
          }}
          onDismiss={() => _onDismiss()}
          createPaymentIntent={() => _createPaymentIntent()}
          onPaymentSuccess={() => _onPaymentSuccess()}
        />
      ) : null}

      <ModalFooter className="customModalFooter">
        <div className="secureCheckoutTxtWrap">
          <a href="http://www.credit-card-logos.com/">
            <img
              alt="Credit Card Logos"
              title="Credit Card Logos"
              src="http://www.credit-card-logos.com/images/visa_credit-card-logos/visa_mastercard_logo_6.gif"
              width="120"
              loading="lazy"
            />
          </a>

          <p className="secureCheckoutTxt">
            Merchant country: United States <br />
            Secure checkout page Secure Sockets Layer (SSL) 128 bit encryption
          </p>
        </div>

        <div className="d-flex flex-shrink-0 align-self-end">
          <Button className="modalBtnCancel" onClick={() => _onDismiss()}>
            Cancel
          </Button>
          <Button
            className="modalBtnSave"
            onClick={() => _confirmationTextShow()}
            disabled={isLoaderActive}
          >
            {isLoaderActive ? (
              <i className="fa fa-spinner fa-spin mr-1" />
            ) : null}{" "}
            Click To Contribute
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ContributeToGoalModal;
