import { createSlice } from "@reduxjs/toolkit";
import { deleteNameConfigurable } from "../actions";
import { fetchAndAddNameConfigurable } from "../actions/nameConfigurable";

const initialState = {
  vault: {
    isActive: false,
    name: null,
  },
  shoutout: {
    isActive: false,
    name: null,
  },
  ppv: {
    isActive: false,
    name: null,
  },
  event: {
    isActive: false,
    name: null,
  },
};

const nameConfigurableSlice = createSlice({
  name: "nameConfigurable",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteNameConfigurable, (state, action) => {
        state.vault = {};
        state.shoutout = {};
        state.ppv = {};
        state.event = {};
      })

      .addCase(fetchAndAddNameConfigurable.pending, (state) => {
        state.loading = true;
      })

      .addCase(fetchAndAddNameConfigurable.fulfilled, (state, action) => {
        state.vault = action.payload.vault;
        state.shoutout = action.payload.shoutout;
        state.ppv = action.payload.ppv;
        state.event = action.payload.event;

        state.loading = false;
      });
  },
});

export const nameConfigurableReducer = nameConfigurableSlice.reducer;
