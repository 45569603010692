import React from "react";
import Skeleton from "react-loading-skeleton";
import { Col } from "reactstrap";

const SkeletonVaultContentCard = ({ count }) => {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <Col
          xs="6"
          sm="6"
          lg="4"
          className="vaultCustomCol"
          key={`item-${index}`}
        >
          <div className="vaultMediaThumbWrap">
            <Skeleton height={"100%"} width={"100%"} borderRadius={16} />
          </div>
        </Col>
      ))}
    </>
  );
};

export default SkeletonVaultContentCard;
