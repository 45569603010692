import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonSearchList = ({ count = 10 }) => {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <div
          className="d-flex justify-content-between align-items-center mb-3"
          key={index}
        >
          <div className="d-flex align-items-center">
            <Skeleton circle height={60} width={60} className="mr-2" />
            <div>
              <Skeleton
                height={15}
                width={Math.floor(Math.random() * 70 + 65)}
              />
              <Skeleton height={13} width={50} />
            </div>
          </div>

          <div>
            <Skeleton height={34} width={100} borderRadius={20} />
          </div>
        </div>
      ))}
    </>
  );
};

export default SkeletonSearchList;
