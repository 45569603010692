import React, { useEffect, useState } from "react";
import { Button, Label, FormGroup, Form, Input } from "reactstrap";
import {
  APP_LOGO,
  FRONTEND_CREATOR_BASE_URL,
  SHOW_SOCIAL_ICON_ONLY,
} from "../config";
import { RegexConfig } from "../config/RegexConfig";
import {
  errorHandler,
  extractQueryParams,
  getDeviceDetails,
  getOneSignalDeviceId,
  setupDescription,
  showToast,
} from "../helper-methods";
import { useHistory } from "react-router-dom";
import {
  login,
  socialLogin,
  // otplessLogin,
} from "../http-calls";
import PublicFooter from "../components/PublicFooter";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader, updateUserData } from "../redux/actions";
import GoogleLoginComponent from "../components/socialLogin/GoogleLoginComponent";
import FacebookLoginComponent from "../components/socialLogin/FacebookLoginComponent";
import AppleLoginComponent from "../components/socialLogin/AppleLoginComponent";
import ErrorBoundary from "../components/ErrorBoundary";

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [formFields, setFormFields] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState("");
  const [referedInfluencerName, setReferedInfluencerName] = useState("");
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);
  const [showInstallableTextOnSafari, setShowInstallableTextOnSafari] =
    useState(false);
  const [deviceDetails, setDeviceDetails] = useState({});

  const _togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const _forgotPassword = () => {
    const newFormFields = { ...formFields };
    let redirectUrl = "/forgot-password";
    if (newFormFields?.email?.length) {
      redirectUrl += "?email=" + newFormFields?.email;
    }
    history.push(redirectUrl);
  };

  const _redirectToCreatorLogin = () => {
    window.open(`${FRONTEND_CREATOR_BASE_URL}/login`, "_self");
  };

  const _gotoRegistrationPage = () => {
    if (redirectTo) {
      history.replace("/register?redirectTo=" + redirectTo);
    } else {
      history.replace("/register");
    }
  };

  const _hideInstallableTextOnSafari = () => {
    setShowInstallableTextOnSafari(false);
  };

  // extract info from url if available
  const _extractInfoFromURL = () => {
    // store redirect params if available
    if (history?.location?.search) {
      setRedirectTo(history?.location?.pathname + history?.location?.search);
    }

    const queries = extractQueryParams();

    // Check if email is available
    if (queries?.email?.length) {
      const newFormFields = { ...formFields };
      newFormFields.email = queries.email;
      setFormFields(newFormFields);
    }

    if (queries?.influencerUsername?.length) {
      setReferedInfluencerName(queries?.influencerUsername);
    }
  };

  const _setDeviceDetails = async () => {
    // detect app open from pwa installed app
    if (window.matchMedia("(display-mode: standalone)").matches) {
      setShowInstallableTextOnSafari(false);
    } else {
      const UA = navigator.userAgent;
      // detect app run on iphone & ipad
      const iPhone_iPad = !!UA.match(/iPad/i) || !!UA.match(/iPhone/i);

      const newDeviceDetails = await getDeviceDetails();
      setDeviceDetails(newDeviceDetails);

      if (
        iPhone_iPad &&
        newDeviceDetails?.name?.toLowerCase()?.includes("safari")
      ) {
        setShowInstallableTextOnSafari(true);
      }
    }
  };

  // validate values filled in form fields
  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = {};
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "email": {
              if (newFormFields[key]?.trim().length) {
                if (
                  RegexConfig.email.test(
                    String(newFormFields[key]).toLowerCase()
                  )
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Invalid email";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "password": {
              if (newFormFields[key]?.trim().length) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  // on fields blur, update their dirty true then validate
  const _onBlurHandler = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = { ...isDirty };
    newIsDirty[key] = true;
    setIsDirty(newIsDirty);

    _validateFormFields({ newFormFields, newIsDirty });
  };

  // update form fiels as it changes
  const _updateFieldValue = (key, value) => {
    const newFormFields = { ...formFields };
    newFormFields[key] = value;
    setFormFields(newFormFields);
  };

  // marking all formfields dirty
  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newIsDirty = { ...isDirty };
      const newFormFields = { ...formFields };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  // after login check if any redirect is required and redirect accordingly
  const _redirectIfRequired = (loginResponse) => {
    if (redirectTo?.split("/")[1] === "influencer") {
      history.replace(redirectTo);
    } else if (
      !loginResponse?.user?.isSubscribed &&
      (loginResponse?.user?.hasOwnProperty("_cameVia") ||
        loginResponse?.user?.hasOwnProperty("influencerUsername"))
    ) {
      history.replace(
        "/influencer/" + loginResponse?.user?.influencerUsername ||
          loginResponse?.user?._cameVia
      );
    } else {
      const { redirectTo } = extractQueryParams();

      history.replace(
        redirectTo || loginResponse?.user?.isSubscribed ? "/" : "/search"
      );
    }
  };

  const _completedAuthorization = (loginResponse) => {
    try {
      if (!loginResponse?.user?.userType === "Fan") {
        showToast("Account doesn't exist");
        return;
      }

      // login success
      dispatch(updateUserData(loginResponse));

      _redirectIfRequired(loginResponse);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _login = async (e) => {
    try {
      if (e) e.preventDefault();

      const newIsDirty = await _markAllIsDirty();

      const newFormFields = { ...formFields };

      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        return;
      }

      setLoading(true);

      let newDeviceDetails = { ...deviceDetails };
      if (!newDeviceDetails) {
        newDeviceDetails = await getDeviceDetails();
      }

      // always resolve
      const oneSignalDeviceId = await getOneSignalDeviceId();

      const payload = {
        handle: formFields?.email,
        password: formFields?.password,
        deviceDetails: {
          ...newDeviceDetails,
          allowNotification: oneSignalDeviceId ? true : false,
          deviceId: oneSignalDeviceId
            ? oneSignalDeviceId
            : newDeviceDetails?.deviceId,
        },
        allowNotification: oneSignalDeviceId ? true : false,
        ipCountry: newDeviceDetails?.ipCountry,
        userType: "Fan",
      };

      const loginResponse = await login(payload);

      _completedAuthorization(loginResponse);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const _socialLogin = async (platform, accessToken, name = null) => {
    try {
      dispatch(showLoader(`Login through ${platform}...`));

      let newDeviceDetails = { ...deviceDetails };
      if (!newDeviceDetails) {
        newDeviceDetails = await getDeviceDetails();
      }

      // always resolve
      const oneSignalDeviceId = await getOneSignalDeviceId();

      const payload = {
        accessToken: accessToken,
        name,
        socialAccount: platform === "facebook" ? "fb" : platform,
        deviceDetails: {
          ...newDeviceDetails,
          allowNotification: oneSignalDeviceId ? true : false,
          deviceId: oneSignalDeviceId
            ? oneSignalDeviceId
            : newDeviceDetails?.deviceId,
        },
        allowNotification: oneSignalDeviceId ? true : false,
        reffererInfluencerUsername: referedInfluencerName,
        ipCountry: newDeviceDetails?.ipCountry,
        mode: "web",
        userType: "Fan",
      };

      const loginResponse = await socialLogin(payload);

      _completedAuthorization(loginResponse);

      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      errorHandler(error);
    }
  };

  const _installApp = async () => {
    setIsInstallable(false);
    // Hide the app provided install promotion
    // hideInstallPromotion();
    // Show the install prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    const { outcome } = await deferredPrompt.userChoice;
    // Optionally, send analytics event with outcome of user choice
    console.log(`User response to the install prompt: ${outcome}`);
    // We've used the prompt, and can't use it again, throw it away
    setDeferredPrompt(null);
  };

  // Initialize deferredPrompt for use later to show browser install prompt.
  window.addEventListener("beforeinstallprompt", (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    setDeferredPrompt(e);
    setIsInstallable(true);
    // alert("event >>")
    // Update UI notify the user they can install the PWA
    // showInstallPromotion();
    // Optionally, send analytics event that PWA install promo was shown.
  });

  window.addEventListener("appinstalled", () => {
    // Hide the app-provided install promotion
    // hideInstallPromotion();
    // Clear the deferredPrompt so it can be garbage collected
    setDeferredPrompt(null);
    // Optionally, send analytics event to indicate successful install
  });

  //---------------otpless service---------------------
  // const _otplessInitialize = () => {
  //   try {
  //     const loadScript = function (src) {
  //       const tag = document.createElement("script");
  //       tag.async = false;
  //       tag.src = src;
  //       tag.id = "otpless_script";
  //       const body = document.getElementsByTagName("body")[0];
  //       body.appendChild(tag);
  //     };
  //     loadScript("https://otpless.com/auth.js");
  //     const otplessInit = Reflect?.get(window, "otplessInit");
  //     if (otplessInit) {
  //       otplessInit();
  //     }
  //     window.otpless = (otplessUser) => {
  //       _otplessLogin(otplessUser);
  //     };
  //   } catch (error) {
  //     console.log("error>>", error);
  //   }
  // };

  // const _otplessLogin = async (otplessUser) => {
  //   try {
  //     const deviceDetails = await _getDeviceDetails();
  //     const loginResponse = await otplessLogin({
  //       email: otplessUser?.email?.email,
  //       phone: otplessUser?.mobile?.number,
  //       deviceDetails,
  //       allowNotification: deviceDetails.allowNotification,
  //       ipCountry: deviceDetails.ipCountry,
  //     });

  //     if (loginResponse?.user?.type !== "Fan") {
  //       showToast("Account doesn't exist", "error");
  //       return;
  //     }

  //     // Login success
  //     dispatch(updateUserData(loginResponse));
  //     // hide loader
  //     _redirectIfRequired(loginResponse)
  //   } catch (error) {
  //     // hide loader
  //     errorHandler(error);
  //   }
  // };

  // const _otplessRemoveScript = () => {
  //   const otpless_script = document.getElementById("otpless_script");
  //   const OTPless_Auth_Script = document.getElementById("OTPless-Auth-Script");
  //   otpless_script?.remove && otpless_script.remove();
  //   OTPless_Auth_Script?.remove && OTPless_Auth_Script.remove();
  // };
  // ------------------------otpless service-------------------------

  useEffect(() => {
    setupDescription();

    _extractInfoFromURL();
    _setDeviceDetails();

    // _initializeOTPLess()
    // return () => {
    //   _otplessRemoveScript();
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="animated fadeIn authFormWrapper">
      <div className="loginWrapper">
        <img
          src={APP_LOGO}
          alt="Project Logo"
          className="projectLogo"
          loading="lazy"
        />

        {isInstallable && (
          <Button className="installPWA" onClick={() => _installApp()}>
            Download App
          </Button>
        )}

        <div className="authPgFormWrap">
          <h4
            className="cursorPointer"
            onClick={() => _redirectToCreatorLogin()}
          >
            Subscriber Login
          </h4>
          <Form onSubmit={(e) => _login(e)}>
            <FormGroup>
              <Label>Email</Label>
              <Input
                type="email"
                placeholder="Email"
                autoComplete="Email"
                value={formFields?.email}
                onChange={(e) => _updateFieldValue("email", e.target.value)}
                onBlur={() => _onBlurHandler("email")}
              />

              {errors?.email ? (
                <p className="form-error">{errors?.email}</p>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label>Password</Label>
              <div className="position-relative">
                <Input
                  type={showPassword ? "text" : "password"}
                  className="passwordInput"
                  placeholder="Password"
                  autoComplete="current-password"
                  value={formFields.password}
                  onChange={(e) =>
                    _updateFieldValue("password", e.target.value)
                  }
                  onBlur={() => _onBlurHandler("password")}
                />
                <div className="eyeIcon">
                  <i
                    className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
                    onClick={() => _togglePasswordVisibility()}
                  />
                </div>
              </div>

              {errors?.password ? (
                <p className="form-error">{errors?.password}</p>
              ) : null}
            </FormGroup>

            <Button
              className="forgotPassword"
              onClick={() => _forgotPassword()}
            >
              Forgot Password?
            </Button>
            <div className="clearfix"></div>

            <Button
              className="themeBtn loginBtn"
              type="submit"
              disabled={loading}
            >
              <span>
                {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}
                Login
              </span>
            </Button>

            <div className="orTxt">
              <span>OR</span>
            </div>

            <div
              className={`${
                SHOW_SOCIAL_ICON_ONLY ? "socialLoginWrap" : "text-center mb-3"
              }`}
            >
              <ErrorBoundary>
                <GoogleLoginComponent
                  onSuccess={(res) => _socialLogin("google", res.access_token)}
                />
              </ErrorBoundary>

              <ErrorBoundary>
                <FacebookLoginComponent
                  onSuccess={(res) => _socialLogin("facebook", res.accessToken)}
                />
              </ErrorBoundary>

              <ErrorBoundary>
                <AppleLoginComponent
                  onSuccess={(res) =>
                    _socialLogin("apple", res.accessToken, res.name)
                  }
                />
              </ErrorBoundary>

              {/* continue with whatsapp / otpless btn */}
              {/* <div id="otpless" /> */}
            </div>

            <Button
              className="registerBtn"
              onClick={() => _gotoRegistrationPage()}
            >
              Don't have an account yet? <span>Sign Up</span>
            </Button>

            <Button
              className="registerBtn"
              onClick={() => _redirectToCreatorLogin()}
            >
              Are you a creator? <span>Sign In</span>
            </Button>
          </Form>
        </div>
      </div>

      {showInstallableTextOnSafari && (
        <div className="addPWATooltipWrap">
          <div className="addPWATooltip">
            <i className="fa fa-plus-square" />
            <div>
              Install this webapp on your device. Tap
              <img
                src={"assets/img/arrow.png"}
                alt="Add Icon"
                loading="lazy"
              />{" "}
              and then Add to homescreen.
              <Button onClick={() => _hideInstallableTextOnSafari()}>
                <i className="fa fa-times" />
              </Button>
              <div class="arrowBottom"></div>
            </div>
          </div>
        </div>
      )}

      <PublicFooter />
    </div>
  );
};

export default LoginPage;
