import React, { useEffect, useRef, useState } from "react";
import {
  TabContent,
  TabPane,
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import {
  errorHandler,
  extractQueryParams,
  getDeviceDetails,
  getDialCode,
  setupDescription,
  showToast,
} from "../helper-methods";
import PublicFooter from "../components/PublicFooter";
import { APP_LOGO, SHOW_SOCIAL_ICON_ONLY } from "../config";
import { countryCodes } from "../config/country-codes";
import { States } from "../config/states";
import { useHistory } from "react-router-dom";
import { checkDuplicateFields, signUp, socialLogin } from "../http-calls";
import { hideLoader, showLoader, updateUserData } from "../redux/actions";
import { useDispatch } from "react-redux";
import { RegexConfig } from "../config/RegexConfig";
import GoogleLoginComponent from "../components/socialLogin/GoogleLoginComponent";
import FacebookLoginComponent from "../components/socialLogin/FacebookLoginComponent";
import AppleLoginComponent from "../components/socialLogin/AppleLoginComponent";
import ErrorBoundary from "../components/ErrorBoundary";

const SignUpPublicPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [formFields, setFormFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    // confirmPassword: "",
    phoneCountry: "US",
    country: "US",
    state: "",
    phone: "",
  });

  const [errors, setErrors] = useState({});
  const [isDirty, setIsDirty] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [redirectTo, setRedirectTo] = useState(null);
  const [refererInfluencerUsername, setRefererInfluencerUsername] =
    useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [duplicateCheckLoading, setDuplicateCheckLoading] = useState(false);
  const [isAvailable, setIsAvailable] = useState({
    email: false,
    phone: false,
  });

  const validateTimer = useRef(null);

  const _switchActiveTab = (tab = 1) => {
    setActiveTab((prev) => {
      if (prev !== tab) {
        setActiveTab(tab);
      }
    });
  };

  const _togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const _extractInfoFromURL = () => {
    try {
      let { redirectTo, influnecerId } = extractQueryParams();
      if (redirectTo?.length) {
        setRedirectTo(redirectTo);
      }
      if (influnecerId?.length) {
        setRefererInfluencerUsername(influnecerId);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  // after signup check if any redirect is required and perform accordingly
  const _redirectIfRequired = (loginResponse) => {
    if (redirectTo?.split("/")[1] === "influencer") {
      history.replace(redirectTo);
    } else if (
      !loginResponse?.user?.isSubscribed &&
      (loginResponse?.user?.hasOwnProperty("_cameVia") ||
        loginResponse?.user?.hasOwnProperty("influencerUsername"))
    ) {
      history.replace(
        "/influencer/" + loginResponse?.user?.influencerUsername ||
          loginResponse?.user?._cameVia
      );
    } else {
      history.replace("/updateProfile");
    }
  };

  // check for availabilty of email, phone
  const _validateAndCheckAvailablity = async (key, value) => {
    const newErrors = {};
    let isFormValid = true;

    if (value?.trim()?.length) {
      if (RegexConfig?.[key]?.test(String(value).toLowerCase())) {
        setDuplicateCheckLoading(true);
        const payload = {
          [key]: value,
        };

        const res = await checkDuplicateFields(payload);
        setDuplicateCheckLoading(false);

        if (res?.isDuplicateFound) {
          setIsAvailable((prev) => ({ ...prev, [key]: false }));
          newErrors[key] = `This ${
            key === "email" ? "email" : "phone number"
          } is already in use`;
          isFormValid = false;
        } else {
          setIsAvailable((prev) => ({ ...prev, [key]: true }));
          newErrors[key] = null;
          setIsDirty({ [key]: false });
        }
      } else {
        newErrors[key] = `*Invalid ${
          key === "email" ? "email" : "phone number"
        }`;
        isFormValid = false;
      }
    } else {
      newErrors[key] = "*Required";
      isFormValid = false;
    }

    setErrors((prev) => ({ ...prev, ...newErrors }));
    return isFormValid;
  };

  // on fields blur, update their dirty true then validate
  const _onBlurHandler = (key, forTab) => {
    let newFormFields = { ...formFields };
    let newIsDirty = { ...isDirty };
    newIsDirty[key] = true;
    setIsDirty(newIsDirty);

    _validateFormFields(newFormFields, newIsDirty, forTab);
  };

  const _updateFieldValue = (key, value) => {
    if (
      key === "phone" &&
      (isNaN(value) || value.includes(".") || Number(value) < 0)
    ) {
      return;
    }

    const newFormFields = { ...formFields };
    newFormFields[key] = value;

    if (key === "country") {
      newFormFields.state = "";
      // setErrors({ state: null });
    }

    setFormFields(newFormFields);

    if (key === "email" || key === "phone") {
      if (validateTimer.current) clearInterval(validateTimer.current);
      validateTimer.current = setTimeout(() => {
        _validateAndCheckAvailablity(key, value);
      }, 1000);
    }
  };

  // marking all formfields dirty
  const _markAllIsDirty = (forTab) => {
    return new Promise((resolve) => {
      const newIsDirty = { ...isDirty };

      if (forTab === 1) {
        newIsDirty["firstName"] = true;
        newIsDirty["lastName"] = true;
        newIsDirty["email"] = true;
        newIsDirty["password"] = true;
      } else if (forTab === 2) {
        newIsDirty["phone"] = true;
        newIsDirty["state"] = true;
        newIsDirty["country"] = true;
      }
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  const _validateFormFields = (newFormFields, newIsDirty, activeTab) => {
    const newErrors = { ...errors };
    return new Promise((resolve) => {
      let isFormValid = true;

      Object.keys(newFormFields).forEach(async (key) => {
        if (newIsDirty[key]) {
          if (activeTab === 1) {
            switch (key) {
              case "firstName":
              case "lastName": {
                if (newFormFields?.[key]?.trim()?.length) {
                  if (
                    RegexConfig?.name?.test(
                      String(newFormFields[key]).toLowerCase()
                    )
                  ) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] =
                      "Invalid Name, It must start with a character";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }

              case "password": {
                if (newFormFields[key]?.trim().length) {
                  if (
                    RegexConfig.password.test(
                      String(newFormFields[key]).toLowerCase()
                    )
                  ) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] =
                      "*Minimum eight characters, at least one letter, one number and one special character";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }

              // case "confirmPassword": {
              //   if (
              //     newFormFields?.[key]?.trim()?.length
              //   ) {
              //     if (
              //       newFormFields?.["password"]?.trim() ===
              //         newFormFields?.["confirmPassword"]?.trim()
              //     ) {
              //       newErrors[key] = = null;
              //       newIsDirty[key] = false;
              //     } else {
              //       newErrors[key] = = "*Both password must match";
              //       isFormValid = false;
              //     }
              //   } else {
              //        newErrors[key] = "*Required";
              //        isFormValid = false;
              //   }
              //   break;
              // }

              case "email": {
                // when there is length to email field _updateFieldValue() will handle it
                if (!newFormFields?.[key]?.trim()?.length) {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              default:
            }
          } else if (activeTab === 2) {
            switch (key) {
              case "state": {
                if (newFormFields?.[key]?.trim()?.length) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "phone": {
                // when there is length to phone field _updateFieldValue() will handle it
                if (!newFormFields?.[key]?.trim()?.length) {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              default:
            }
          }
        }
      });
      setIsDirty(newIsDirty);
      setErrors(newErrors);

      resolve(isFormValid);
    });
  };

  const _completedAuthorization = (loginResponse) => {
    try {
      if (!loginResponse?.user?.userType === "Fan") {
        showToast("Account doesn't exist");
        return;
      }

      // login success
      dispatch(updateUserData(loginResponse));

      _redirectIfRequired(loginResponse);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _socialLogin = async (platform, accessToken, name = null) => {
    try {
      dispatch(showLoader(`Login through ${platform}...`));

      const deviceDetails = await getDeviceDetails();

      const payload = {
        accessToken: accessToken,
        name,
        socialAccount: platform === "facebook" ? "fb" : platform,
        deviceDetails,
        allowNotification: deviceDetails?.allowNotification,
        reffererInfluencerUsername: refererInfluencerUsername,
        ipCountry: deviceDetails?.ipCountry,
        mode: "web",
        userType: "Fan",
      };

      const loginResponse = await socialLogin(payload);

      _completedAuthorization(loginResponse);

      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      errorHandler(error);
    }
  };

  const _handleSignUp = async (payload) => {
    try {
      setIsLoading(true);

      const deviceDetails = await getDeviceDetails();
      const signUpResponse = await signUp({
        ...payload,
        deviceDetails,
        allowNotification: deviceDetails?.allowNotification,
        mode: "web",
        userType: "Fan",
        ipCountry: deviceDetails?.ipCountry,
      });

      dispatch(updateUserData(signUpResponse));
      setIsLoading(false);

      _redirectIfRequired(signUpResponse);
    } catch (error) {
      setIsLoading(false);
      errorHandler(error);
    }
  };

  // on tab change
  const _onTabSubmit = async (event, forTab) => {
    if (event) event.preventDefault();

    switch (forTab) {
      case 1: {
        const newIsDirty = await _markAllIsDirty(forTab);
        const newFormFields = { ...formFields };
        const isFormValid = await _validateFormFields(
          newFormFields,
          newIsDirty,
          forTab
        );

        if (!isFormValid || !isAvailable?.email) {
          return;
        }

        _switchActiveTab(2);

        break;
      }
      case 2: {
        const newIsDirty = await _markAllIsDirty(forTab);

        const newFormFields = { ...formFields };

        const isFormValid = await _validateFormFields(
          newFormFields,
          newIsDirty,
          forTab
        );

        if (!isFormValid || !isAvailable?.phone) {
          return;
        }

        // create payload
        const phoneCountryCode = getDialCode(
          formFields?.phoneCountry
        )?.dial_code;

        const signupData = {
          name: {
            first: formFields?.firstName?.trim(),
            last: formFields?.lastName?.trim(),
          },
          email: formFields?.email?.trim(),
          phoneCountry: formFields?.phoneCountry || "US",
          phone: phoneCountryCode
            ? `(${phoneCountryCode})${formFields?.phone}`
            : formFields?.phone,
          password: formFields?.password?.trim(),
          address: {
            state: formFields?.state?.trim() || "",
            country: formFields?.country?.trim() || "US",
          },
        };

        if (refererInfluencerUsername) {
          signupData["reffererInfluencerUsername"] = refererInfluencerUsername;
        }
        _handleSignUp(signupData);

        break;
      }
      default:
    }
  };

  useEffect(() => {
    setupDescription();
    _extractInfoFromURL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="animated fadeIn authFormWrapper">
      <div className="loginWrapper" style={{ maxWidth: "unset" }}>
        <img
          src={APP_LOGO}
          alt="Project Logo"
          className="projectLogo"
          onClick={() => history.replace("/login")}
          loading="lazy"
        />

        <TabContent activeTab={activeTab} className="signUpWrap">
          {/* tabId=1 */}
          <TabPane tabId={1} className="p-0">
            <div className="authPgFormWrap">
              <h4 className="mb-2">Sign Up</h4>
              <Form onSubmit={(event) => _onTabSubmit(event, 1)}>
                <Row>
                  <Col xs="6" className="pr-1">
                    <FormGroup>
                      <Label>First Name</Label>
                      <Input
                        type="text"
                        placeholder="First Name"
                        value={formFields?.firstName}
                        autoComplete="true"
                        onChange={(e) =>
                          _updateFieldValue("firstName", e.target.value)
                        }
                        onBlur={() => _onBlurHandler("firstName", 1)}
                      />
                      {errors?.firstName ? (
                        <p className="form-error">{errors?.firstName}</p>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col xs="6" className="pl-1">
                    <FormGroup>
                      <Label>Last Name</Label>
                      <Input
                        type="text"
                        placeholder="Last Name"
                        value={formFields.lastName}
                        autoComplete="true"
                        onChange={(e) =>
                          _updateFieldValue("lastName", e.target.value)
                        }
                        onBlur={() => _onBlurHandler("lastName", 1)}
                      />
                      {errors?.lastName ? (
                        <p className="form-error">{errors?.lastName}</p>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup className="mt-0">
                  <Label>Email</Label>
                  <div className="position-relative">
                    <Input
                      type="text"
                      placeholder="Enter your email address"
                      autoComplete="new-email"
                      value={formFields.email}
                      // disabled={duplicateCheckLoading}
                      onChange={(e) =>
                        _updateFieldValue("email", e.target.value)
                      }
                      onBlur={() => _onBlurHandler("email", 1)}
                    />

                    {duplicateCheckLoading ? (
                      <div className="spinnerLogin">
                        <i className="fa fa-spinner fa-spin mr-1" />
                      </div>
                    ) : null}
                  </div>

                  {errors?.email ? (
                    <p className="form-error">{errors?.email}</p>
                  ) : null}
                </FormGroup>

                <FormGroup>
                  <Label>Password</Label>
                  <div className="position-relative">
                    <Input
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      name="signup_password"
                      autoComplete="off"
                      value={formFields?.password}
                      onChange={(e) =>
                        _updateFieldValue("password", e.target.value)
                      }
                      onBlur={() => _onBlurHandler("password", 1)}
                    />
                    <div className="eyeIcon">
                      <i
                        className={
                          showPassword ? "fa fa-eye" : "fa fa-eye-slash"
                        }
                        onClick={() => _togglePasswordVisibility()}
                      />
                    </div>
                  </div>
                  {errors?.password ? (
                    <p className="form-error">{errors?.password}</p>
                  ) : null}
                </FormGroup>

                {/* <FormGroup>
                  <Label>Confirm Password</Label>
                  <div className="position-relative">
                    <Input
                      type="text"
                      placeholder="Reenter your password"
                      name="signup_confirmPassword"
                      style={
                        !showPassword.confirmPassword
                          ? { WebkitTextSecurity: "disc", paddingRight: 35 }
                          : { paddingRight: 35 }
                      }
                      value={formFields.confirmPassword}
                      onChange={(e) =>
                        _updateFieldValue("confirmPassword", e.target.value)
                      }
                      autoComplete="off"
                    />
                    <div className="eyeIcon">
                      <i
                        className={
                          showPassword ? "fa fa-eye" : "fa fa-eye-slash"
                        }
                        onClick={() => _togglePasswordVisibility()}
                      />
                    </div>
                  </div>
                  {errors?.confirmPassword ? (
                    <p className="form-error">{errors?.confirmPassword}</p>
                  ) : null}
                </FormGroup> */}

                <Button className="themeBtn loginBtn" type="submit">
                  Sign Up
                </Button>

                <div className="orTxt">
                  <span>OR</span>
                </div>

                <div
                  className={`${
                    SHOW_SOCIAL_ICON_ONLY
                      ? "socialLoginWrap"
                      : "text-center mb-3"
                  }`}
                >
                  <ErrorBoundary>
                    <GoogleLoginComponent
                      onSuccess={(res) =>
                        _socialLogin("google", res.access_token)
                      }
                    />
                  </ErrorBoundary>

                  <ErrorBoundary>
                    <FacebookLoginComponent
                      onSuccess={(res) =>
                        _socialLogin("facebook", res.accessToken)
                      }
                    />
                  </ErrorBoundary>

                  <ErrorBoundary>
                    <AppleLoginComponent
                      onSuccess={(res) =>
                        _socialLogin("apple", res.accessToken, res.name)
                      }
                    />
                  </ErrorBoundary>
                </div>
              </Form>
              <Button
                className="registerBtn"
                onClick={() => history.replace("/login")}
              >
                Already have an account? <span>Login</span>
              </Button>
            </div>
          </TabPane>

          {/* tabId=2 */}
          <TabPane tabId={2} className="p-0">
            <div className="authPgFormWrap">
              <Form onSubmit={(event) => _onTabSubmit(event, 2)}>
                <FormGroup>
                  <Label>Country</Label>
                  <Input
                    type="select"
                    placeholder="Choose your country"
                    name="country"
                    value={formFields?.country}
                    onChange={(e) =>
                      _updateFieldValue("country", e.target.value)
                    }
                  >
                    {countryCodes.map((countryCode) => (
                      <option key={countryCode?.code} value={countryCode.code}>
                        {countryCode.name}
                      </option>
                    ))}
                  </Input>
                  {errors?.country ? (
                    <p className="form-error">{errors?.country}</p>
                  ) : null}
                </FormGroup>

                <FormGroup>
                  <Label>State / Province</Label>
                  {formFields?.country === "IN" ||
                  formFields?.country === "US" ? (
                    <Input
                      type="select"
                      name="state"
                      value={formFields.state}
                      onChange={(e) =>
                        _updateFieldValue("state", e.target.value)
                      }
                      onBlur={() => _onBlurHandler("state", 2)}
                    >
                      <option value="">Select State</option>
                      {States[formFields.country].map((state) => (
                        <option key={state.code} value={state.code}>
                          {state.name}
                        </option>
                      ))}
                    </Input>
                  ) : (
                    <Input
                      type="text"
                      autoComplete="true"
                      placeholder="Enter State"
                      value={formFields.state}
                      onChange={(e) =>
                        _updateFieldValue("state", e.target.value)
                      }
                      onBlur={() => _onBlurHandler("state", 2)}
                    />
                  )}

                  {errors?.state ? (
                    <p className="form-error">{errors?.state}</p>
                  ) : null}
                </FormGroup>

                <Row>
                  <Col xs="6" className="pr-1">
                    <FormGroup className="mt-0">
                      <Label>Country Code</Label>

                      <Input
                        type="select"
                        name="phoneCountry"
                        onChange={(e) =>
                          _updateFieldValue("phoneCountry", e.target.value)
                        }
                        value={formFields?.phoneCountry}
                      >
                        {countryCodes.map((countryCode) => (
                          <option
                            key={countryCode?.code}
                            value={countryCode?.code}
                          >
                            {countryCode?.name} ({countryCode?.dial_code})
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>

                  <Col xs="6" className="pl-1">
                    <FormGroup className="mt-0">
                      <Label>Phone Number</Label>
                      <div className="position-relative">
                        <Input
                          name="phone"
                          type="text"
                          placeholder="Enter your Phone Number"
                          autoComplete="nope"
                          value={formFields?.phone}
                          // disabled={duplicateCheckLoading}
                          onChange={(e) =>
                            _updateFieldValue("phone", e.target.value)
                          }
                          onBlur={() => _onBlurHandler("phone", 2)}
                        />

                        {duplicateCheckLoading ? (
                          <div className="spinnerLogin">
                            <i className="fa fa-spinner fa-spin mr-1" />
                          </div>
                        ) : null}
                      </div>

                      {errors?.phone ? (
                        <p className="form-error">{errors?.phone}</p>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>

                <Button
                  className="themeBtn loginBtn"
                  type="submit"
                  disabled={isLoading}
                >
                  <span>
                    {isLoading ? (
                      <i className="fa fa-spinner fa-spin mr-1" />
                    ) : null}{" "}
                    Next
                  </span>
                </Button>
              </Form>
            </div>
          </TabPane>
        </TabContent>
      </div>

      <PublicFooter />
    </div>
  );
};

export default SignUpPublicPage;
