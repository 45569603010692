import React from "react";
import { Label, FormGroup, Input, Progress } from "reactstrap";
// import { connect } from "react-redux";

const PollOptionComponent = ({
  feed,
  option,
  optionIndex,
  feedIndex,
  selectPollOption,
}) => {
  const _getPercentageValue = (feed, option, precision = 0) => {
    if (feed?.opinionCount && option?.count) {
      return ((option.count * 100) / feed.opinionCount).toFixed(precision);
    }
    return 0;
  };

  const _defaultSelectedPollAnswer = (option) => {
    if (feed?._opinions?.length) {
      if (feed._opinions[0].option === option._id) {
        return true;
      }
    }
    return false;
  };

  return (
    <FormGroup check className="radio" key={optionIndex}>
      <Label check className="form-check-label">
        <Input
          className="form-check-input"
          type="radio"
          onChange={() => selectPollOption(feed, option, feedIndex)}
          value={option.text}
          checked={_defaultSelectedPollAnswer(option)}
        />
        <span className="pollOptions">{option.text}</span>
        <>
          <Progress value={_getPercentageValue(feed, option)} />
          <span className="pollPercent">
            {_getPercentageValue(feed, option)}%
          </span>
        </>
      </Label>
    </FormGroup>
  );
};

export default PollOptionComponent;
