import React, { Component } from "react";
import { connect } from "react-redux";
import ConfigureString from "../components/ConfigureString";
import CustomLoader from "../components/custom/CustomLoader";

class FullPageLoader extends Component {
  render() {
    const { loaderData } = this.props;
    if (loaderData?.isVisible || loaderData === undefined) {
      return (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            zIndex: 9999999,
            opacity: 0.95,
            backgroundColor: "white",
          }}
        >
          <div className="loaderWrap">
            <CustomLoader type="Oval" height={70} width={70} />

            {loaderData?.loaderText ? (
              <p>
                <ConfigureString keyString={loaderData.loaderText} />
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state?.loaderData || {},
  };
};

export default connect(mapStateToProps, null)(FullPageLoader);
