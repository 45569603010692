import React from "react";
import { useSelector } from "react-redux";
import { Button, Spinner } from "reactstrap";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import {
  formatCurrencyValue,
  getLowResolutionLink,
  isShowStartCallButton,
  parseForShowingMessage,
} from "../../helper-methods";
import ChatAudio from "./ChatAudio";
import ChatImage from "./ChatImage";
import ChatMultiFile from "./ChatMultiFile";
import ChatVideo from "./ChatVideo";
import SkeletonLoading from "../SkeletonLoading";

const MessageItem = ({
  messageData,
  lastElementRef,
  loading,
  prevDateHeading,
  currDateHeading,
  startCall,
  publishMessageOnChannel,
  imageQuality,
  setImageQuality,
}) => {
  const { selectedThread } = useSelector((state) => state?.chatData || {});

  // send status to influencer payment has done
  const _sendMessageAsPurchased = (message) => {
    if (!message) {
      return;
    }

    const skipMessage = {
      doSkip: true,
      _thread: message._thread,
      _from: message._from._id || message._from,
      _to: message._to._id || message._to,
      timestamp: message.timestamp,
      messageId: message.tempMessageId || message.messageId,
      hasPurchased: true,
    };

    publishMessageOnChannel(skipMessage);
  };

  const _markMessageAsPurchased = (message) => {
    _sendMessageAsPurchased(message);
  };

  const _renderMessage = (message) => {
    if (message?._shoutoutOrder) {
      const durationText = message?.shoutoutDuration
        ? message.shoutoutDuration > 90
          ? message.shoutoutDuration / 60 + " mins"
          : message.shoutoutDuration + " sec"
        : "N/A";

      const messageTypeText = message?.shoutoutType
        ? `${message.shoutoutType.includes("audio") ? "voice" : "video"} ${
            message.shoutoutType === "liveaudio" ||
            message.shoutoutType === "live"
              ? "call"
              : "message"
          }`
        : "N/A";

      return (
        <>
          {/* This is the msg(example) that will be sent by the fan to the creator */}
          <div className="voiceVideoMsg">
            You have requested for a {durationText} {messageTypeText} with this
            note:
            <div>{parseForShowingMessage(message.text)}</div>
          </div>
          {/* the below btn will only be shown when the creator starts the call(audio/video) */}
          {(message.shoutoutType === "liveaudio" ||
            message.shoutoutType === "live") &&
          message.status === "accepted" &&
          isShowStartCallButton(message) ? (
            <Button className="themeBtn" onClick={() => startCall(message)}>
              Join {messageTypeText} from @
              {selectedThread?.influencer?.username}
            </Button>
          ) : null}
        </>
      );
    }

    // Need to decide
    // Is public
    // Has Media
    // Has Text
    let hasImage = false;
    let hasAudio = false;
    let hasVideo = false;
    let hasText = false;
    let hasTip = false;
    let hasMultipleFile = false;

    // Check if it has media
    if (message.content?.length) {
      // Has media
      if (message.content.length > 1) {
        hasMultipleFile = true;
      } else {
        switch (message.content[0].mediaType) {
          case "image": {
            hasImage = true;
            break;
          }
          case "video": {
            hasVideo = true;
            break;
          }
          case "audio": {
            hasAudio = true;
            break;
          }
          default: {
          }
        }
      }
    }

    if (message.isTip) {
      hasTip = true;
    } else {
      if (message.text && message.text.length) {
        hasText = true;
      }
    }

    return (
      <>
        {hasMultipleFile ? (
          <ChatMultiFile
            message={message}
            influencer={selectedThread?.influencer}
            markAsPurchased={() => _markMessageAsPurchased(message)}
            imageQuality={imageQuality}
            setImageQuality={setImageQuality}
          />
        ) : null}

        {hasImage ? (
          <ChatImage
            message={message}
            influencer={selectedThread?.influencer}
            markAsPurchased={() => _markMessageAsPurchased(message)}
            imageQuality={imageQuality}
            setImageQuality={setImageQuality}
          />
        ) : null}
        {hasVideo ? (
          <ChatVideo
            message={message}
            influencer={selectedThread?.influencer}
            markAsPurchased={() => _markMessageAsPurchased(message)}
          />
        ) : null}
        {hasAudio ? (
          <ChatAudio
            message={message}
            influencer={selectedThread?.influencer}
            markAsPurchased={() => _markMessageAsPurchased(message)}
          />
        ) : null}
        {hasText ? (
          <div
            className={`msg ${
              message.isMyMessage ? "bgThemeColor" : "bgOffWhite"
            }`}
          >
            <pre>{parseForShowingMessage(message.text)}</pre>
          </div>
        ) : null}
        {hasTip ? (
          <div className="tipWrap">
            {`You sent a ${formatCurrencyValue(message.text)} tip`}
          </div>
        ) : null}
      </>
    );
  };

  return (
    <>
      {lastElementRef && loading && (
        <SkeletonLoading type="conversation" count={2} />
      )}

      <div {...(lastElementRef ? { ref: lastElementRef } : {})}>
        {currDateHeading !== prevDateHeading ? (
          <div className="chatDate">{currDateHeading}</div>
        ) : null}

        <div>
          {messageData?.isMyMessage ? (
            <div className="senderWrap">
              <div className="d-flex flex-column align-items-end">
                {_renderMessage(messageData)}
              </div>
              {/* msg sent time  */}
              <div className="sentTime">
                {messageData?.time}{" "}
                {messageData?.isError ? (
                  <i
                    className="fa fa-exclamation-circle"
                    style={{ color: "red" }}
                    aria-hidden="true"
                  />
                ) : !messageData?.isUploading ? (
                  messageData?.isRead ? (
                    <i className="fa fa-check seenTick" />
                  ) : (
                    <i className="fa fa-check greyCheck" />
                  )
                ) : null}
                {!messageData?.isError && messageData?.isUploading ? (
                  <Spinner
                    style={{
                      width: "1rem",
                      height: "1rem",
                    }}
                  />
                ) : null}
              </div>
            </div>
          ) : (
            <div className="receiverWrap">
              <div className="d-flex align-items-start">
                <img
                  className="senderImg"
                  src={
                    getLowResolutionLink(messageData?._from?.profilePicture) ||
                    getLowResolutionLink(
                      selectedThread?.influencer?.profilePicture
                    ) ||
                    DEFAULT_PROFILE_PICTURE
                  }
                  onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                  alt="Profile Img"
                  loading="lazy"
                />

                <div className="d-flex flex-column">
                  {_renderMessage(messageData)}
                </div>
              </div>

              <div className="sentTime">{messageData?.time}</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MessageItem;
