import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { getTemplateName } from "../../http-calls";

const deleteNameConfigurable = createAction("deleteNameConfigurable");

const fetchAndAddNameConfigurable = createAsyncThunk(
  "nameConfigurable/fetchAndAddNameConfigurable",
  async (payload, thunkAPI) => {
    const res = await getTemplateName();
    return res?.templateNames;
  }
);

export { deleteNameConfigurable, fetchAndAddNameConfigurable };
