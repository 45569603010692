import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonTable = ({ rows, col }) => {
  return (
    <>
      {[...Array(rows)].map((_, index) => (
        <tr key={`row-${index}`}>
          {[...Array(col)].map((_, index) => (
            <td key={`col-${index}`}>
              <Skeleton height={14} width={80} count={4} />
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

export default SkeletonTable;
