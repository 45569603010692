import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonConversation = ({ count }) => {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <div key={`SkeletonConversation_${index}`}>
          {/* sender message */}
          <div className="senderWrap">
            <div className="d-flex flex-column align-items-end">
              <Skeleton
                height={30}
                width={Math.random() * 80 + 80}
                borderRadius={15}
              />
            </div>
            {/* msg sent time  */}
            <div className="sentTime">
              <Skeleton height={12} width={40} className="mt-1" />
            </div>
          </div>

          {/* receiver message */}
          <div className="receiverWrap">
            <div className="d-flex align-items-start">
              {/* user profile picture */}
              <Skeleton circle height={40} width={40} className="mr-1" />

              <div className="d-flex flex-column">
                <Skeleton
                  height={30}
                  width={Math.random() * 80 + 80}
                  borderRadius={15}
                />
                <Skeleton height={12} width={40} className="mt-1" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default SkeletonConversation;
