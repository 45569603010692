import React, { Component } from "react";
import { DEFAULT_COVER_PICTURE } from "../../config";
import MultiMediaModal from "../modals/MultiMediaModal";
import { Button } from "reactstrap";
import {
  deepClone,
  showToast,
  calculateContentType,
  formatCurrencyValue,
} from "../../helper-methods";
import { createPaymentIntent } from "../../http-calls";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../redux/actions/loaderData";
import ProcessPayment from "../../payment";
import PaymentHoc from "../PaymentHoc";
import { updateOnlyUserData } from "../../redux/actions";
import SvgIcons from "../SvgIcons";

class ChatMultiFile extends Component {
  state = {
    isGettingContent: false,
    isContentLoaded: false,
    hasPurchased: false,
    paymentModal: {
      isVisible: false,
    },
    multiMediaModal: {
      isOpen: false,
      contents: null,
      activeIndex: null,
    },
  };

  constructor(props) {
    super(props);
    this.audioSource = React.createRef();
    this.videoSource = React.createRef();
  }

  _onMultiMediaOpenMuteAudioVideo = () => {
    // this.videoSource.current.pause();
    if (this.audioSource && this.audioSource.current) {
      this.audioSource.current.pause();
    }
  };

  _toggleMultiMediaModal = (
    isOpen = false,
    contents = null,
    activeIndex = 0
  ) => {
    this.setState({
      multiMediaModal: {
        isOpen,
        contents,
        activeIndex,
      },
    });
  };

  _purchasePremiumContent = async (card = null) => {
    const { message } = this.props;
    this._dismissPaymentModal();
    this.props.showLoader("Unlocking your content");
    let apiData = {};
    apiData.url3DSecure = "/payment/intent/message";
    apiData.url = "/payment/message";
    apiData.payload = {
      influencerId: message._from._id || message._from, // Influencer id
      messageId: message.messageId || message.tempMessageId,
      contentId: message.content && message.content[0]?._contentId,
    };

    let amount =
      message.content && message.content[0] && message.content[0].amount
        ? message.content[0].amount
        : message.amount
        ? message.amount
        : null;

    if (card) {
      apiData.payload.card = card;
    }

    apiData.influencer = this.props.message._from;

    // return new Promise(async (resolve, reject) => {
    try {
      await ProcessPayment.startPaymentProcess(apiData, amount);
      this.setState({
        hasPurchased: true,
      });
      this.props.markAsPurchased();
      this.props.hideLoader();

      // const fanDefaultCardCountry = await getFanDefaultCardCountry();

      // if (
      //   fanDefaultCardCountry &&
      //   fanDefaultCardCountry.country &&
      //   europeCountyList.includes(fanDefaultCardCountry.country)
      // ) {
      //   const purchaseResponse = await payForMessageIntent();
      //   // Once purchased successfully, set hasPurchased
      //   this.props.hideLoader();

      //   const confirmPaymentResponse = await confirmPayment(
      //     purchaseResponse.secret,
      //     purchaseResponse.defaultCard
      //   );

      //   if (confirmPaymentResponse) {
      //     this.setState({
      //       hasPurchased: true,
      //     });
      //     this.props.markAsPurchased();
      //   }
      // } else {
      //   try {
      //     const purchaseResponse = await payForMessage(
      //       message._from._id || message._from, // Influencer id
      //       message.messageId || message.tempMessageId,
      //       message.content[0]._contentId
      //     );
      //     console.log("purchaseResponse", purchaseResponse);
      //     // Once purchased successfully, set hasPurchased
      //     this.setState({
      //       hasPurchased: true,
      //     });
      //     this.props.markAsPurchased();
      //     this.props.hideLoader();
      //   } catch (error) {
      //     showToast("Server error. Try again after sometime.");
      //     this.props.hideLoader();
      //   }
      // }
    } catch (error) {
      if (error && error.isRiskAssessment) {
        this.props.updateOnlyUserData({
          ...this.props.userData.user,
          isRiskAssessment: error.isRiskAssessment,
        });
      }
      console.log("err >>", error);
      showToast(
        error && error.reason && error.reason.length
          ? error.reason
          : "Server Error"
      );
      this.props.hideLoader();
    }
    // });
  };

  _showPaymentModal = () => {
    const { paymentModal } = deepClone(this.state);
    paymentModal.isVisible = true;
    this.setState({ paymentModal });
  };

  _dismissPaymentModal = () => {
    const { paymentModal } = deepClone(this.state);
    paymentModal.isVisible = false;
    this.setState({ paymentModal });
  };

  _loadContent = () => {
    const { message } = this.props;
    if (message.isMyMessage && message.isLocalMessage) {
      this.setState({
        isContentLoaded: true,
      });
    } else {
      this.setState({ isContentLoaded: true });
    }
  };

  _getActiveContentItem = (item, message, index) => {
    if (item.mediaType) {
      switch (item.mediaType) {
        case "image": {
          return (
            <div
              className="groupMediaWrap"
              onClick={() =>
                this._toggleMultiMediaModal(true, message?.content, index)
              }
            >
              <img
                src={
                  item?.previewBlob ||
                  item?._contentId?.url?.[this.props.imageQuality] ||
                  DEFAULT_COVER_PICTURE
                }
                alt="Img"
                loading="lazy"
              />
            </div>
          );
        }
        case "video": {
          return (
            <div
              className="groupMediaWrap"
              onClick={() =>
                this._toggleMultiMediaModal(true, message?.content, index)
              }
            >
              <div className="shadowOverlayImg" />

              <img
                src={item?._contentId?.thumbnail || DEFAULT_COVER_PICTURE}
                alt="Img"
                loading="lazy"
              />

              <Button className="videoPlayBtn">
                <i className="fa fa-play" />
              </Button>
            </div>
          );
        }
        case "audio": {
          return (
            <div
              className="groupMediaWrap"
              onClick={() =>
                this._toggleMultiMediaModal(true, message?.content, index)
              }
            >
              <img src="/assets/img/mic.png" alt="Microphone" loading="lazy" />
            </div>
          );
        }
        default: {
          return null;
        }
      }
    }
  };

  _renderAlbumPreview = () => {
    const { message } = deepClone(this.props);

    let contents = null;
    let albumPreview = null;
    let seeMoreLength = null;
    let seeMore = false;

    contents = message.content;

    if (contents && contents.length) {
      if (contents.length > 4) {
        seeMoreLength = contents.length - 4;
        contents = contents.slice(0, 4);
        seeMore = true;
      }
      albumPreview = (
        <div className="d-flex flex-wrap position-relative">
          {React.Children.toArray(
            contents.map((content, index) =>
              this._getActiveContentItem(content, message, index)
            )
          )}
          {seeMore ? (
            <div
              className="seeMoreMedia cursorPointer"
              onClick={() =>
                this._toggleMultiMediaModal(true, message?.content, 3)
              }
            >
              <Button color="link">+{seeMoreLength}</Button>
            </div>
          ) : null}
        </div>
      );
    }

    return albumPreview;
  };

  _renderMessageView = () => {
    const { message, influencer } = this.props;
    // const { isMediaModalOpen, showMediaLink, shownContentType } = this.state;
    let {
      // isContentLoaded,
      // isGettingContent,
      hasPurchased,
    } = this.state;

    // if (message.isPublic && message.isMyMessage && message.isLocalMessage) {
    //   isContentLoaded = true;
    // }
    if (message.isPublic || message.isMyMessage) {
      // Generate messsage view
      return <div className="mediaPhotoWrap">{this._renderAlbumPreview()}</div>;
    } else {
      // Not public
      // Check if already purchased
      if (message.hasPurchased || hasPurchased) {
        // Already purchased
        return (
          <>
            <div className="mediaPhotoWrap">{this._renderAlbumPreview()}</div>

            <div className="paidMessageText">
              Paid{" "}
              {message.content &&
              message.content[0] &&
              message.content[0].amount
                ? formatCurrencyValue(message.content[0].amount)
                : message.amount
                ? formatCurrencyValue(message.amount)
                : ""}{" "}
              for this message
            </div>
          </>
        );
      } else {
        // Not purchased (Show lockedview)
        return (
          <div className="mediaPhotoWrap">
            <img src={DEFAULT_COVER_PICTURE} alt="Media" loading="lazy" />
            <div className="postLockedOverlay">
              <div className="feedContentCount">
                {calculateContentType(message.content, "image") ? (
                  <div>
                    <i
                      className="fa fa-image"
                      style={{
                        verticalAlign: -1,
                        marginLeft: 1,
                      }}
                    />
                    <span>
                      {calculateContentType(message.content, "image")}
                    </span>
                  </div>
                ) : null}

                {calculateContentType(message.content, "video") ? (
                  <div>
                    <i className="fa fa-video-camera" />
                    <span>
                      {calculateContentType(message.content, "video")}
                    </span>
                  </div>
                ) : null}
                {calculateContentType(message.content, "audio") ? (
                  <div>
                    <i
                      className="fa fa-microphone"
                      style={{ verticalAlign: -1.5 }}
                    />
                    <span>
                      {calculateContentType(message.content, "audio")}
                    </span>
                  </div>
                ) : null}
              </div>

              <SvgIcons type="lock" className="iconLock" />

              <Button
                className="paymentBtnTier"
                onClick={this._showPaymentModal}
                disabled={influencer && !influencer.paymentEnabled}
              >
                Unlock for{" "}
                {message?.content?.[0]?.amount
                  ? formatCurrencyValue(message.content[0].amount)
                  : message?.amount
                  ? formatCurrencyValue(message.amount)
                  : ""}
              </Button>
            </div>
          </div>
        );
      }
    }
  };

  // Payment Intent For Apple Pay
  _createPaymentIntent = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { apiData } = this.state;

        const res = await createPaymentIntent(
          apiData?.url3DSecure,
          apiData?.payload
        );

        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  };

  // Payment Done via Apple pay
  _paymentSuccess = () => {
    // Once purchased successfully, set hasPurchased
    this.setState({
      hasPurchased: true,
    });
    this.props.markAsPurchased();
  };

  render() {
    const { message } = this.props;
    const { multiMediaModal, paymentModal } = this.state;

    return (
      <>
        {this._renderMessageView()}

        <PaymentHoc
          content={{
            name: "Messages",
            price: message?.content?.[0]?.amount
              ? message.content[0].amount
              : message?.amount
              ? message.amount
              : null,
          }}
          createPaymentIntent={() => this._createPaymentIntent()} // create intent fo Apple pay
          onPaymentSuccess={() => this._paymentSuccess()} // success with apple pay
          onDismiss={this._dismissPaymentModal}
          isVisible={paymentModal.isVisible}
          onPaymentConfirmation={(card) => this._purchasePremiumContent(card)}
          amount={
            message?.content?.[0]?.amount
              ? message.content[0].amount
              : message?.amount
              ? message.amount
              : null
          }
        />

        <MultiMediaModal
          isOpen={multiMediaModal.isOpen}
          contents={multiMediaModal.contents}
          activeIndex={multiMediaModal.activeIndex}
          toggle={() => this._toggleMultiMediaModal()}
          imageQuality={this.props.imageQuality}
          setImageQuality={this.props.setImageQuality}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    chatData: state.chatData,
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateOnlyUserData: (userData) => dispatch(updateOnlyUserData(userData)),
    showLoader: (loaderText) => dispatch(showLoader(loaderText)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatMultiFile);
