import { CURRENCY_SYMBOL } from ".";

export const defaultAmounts = [
  { label: `${CURRENCY_SYMBOL}5`, value: `5` },
  { label: `${CURRENCY_SYMBOL}10`, value: `10` },
  { label: `${CURRENCY_SYMBOL}20`, value: `20` },
  { label: `${CURRENCY_SYMBOL}50`, value: `50` },
];

export const notificationTabs = [
  { label: "All Activities", value: "all" },
  { label: "Interactions", value: "interactions" },
  { label: "Post", value: "post" },
  { label: "Alerts", value: "alerts" },
];

export const vaultSortOptionsConfig = [
  {
    label: "Influencer",
    value: "Influencer",
  },
  {
    label: "Locked",
    value: "Locked",
  },
  {
    label: "Unlocked",
    value: "Unlocked",
  },
  {
    label: "Most Popular",
    value: "Most Popular",
  },
  {
    label: "High Price",
    value: "High Price",
  },
  {
    label: "Low Price",
    value: "Low Price",
  },
];

export const settingsPageTabs = [
  { label: "Profile", value: "profile" },
  { label: "Notifications", value: "notifications" },
];

export const EmailNotificationsList = [
  { label: "New Uploaded Content", value: "post" },
  { label: "New Audio Post", value: "audio" },
  { label: "New Image Post", value: "image" },
  { label: "New Video Post", value: "video" },
  { label: "New Poll Post", value: "poll" },
  { label: "New Text Post", value: "newTextPost" },
  { label: "New Direct Message", value: "message" },
  { label: "New Comments", value: "comment" },
  {
    label: "New Live Stream",
    value: "newLiveStream",
  },
  {
    label: "Live Stream Started",
    value: "liveStreamStarted",
  },
  {
    label: "New Pay Per View Content",
    value: "newPPVContent",
  },
  {
    label: "New Vault Folder",
    value: "newVaultFolder",
  },
  // {
  //   label: "Receive Daily Digest",
  //   value: "dailyDigest",
  // },
];

export const PushNotificationsList = [
  { label: "New Uploaded Content", value: "post" },
  { label: "New Audio Post", value: "audio" },
  { label: "New Image Post", value: "image" },
  { label: "New Video Post", value: "video" },
  { label: "New Poll Post", value: "poll" },
  { label: "New Text Post", value: "newTextPost" },
  { label: "New Direct Message", value: "message" },
  { label: "New Comments", value: "comment" },
  {
    label: "New Live Stream",
    value: "newLiveStream",
  },
  {
    label: "Live Stream Started",
    value: "liveStreamStarted",
  },
  {
    label: "New Pay Per View Content",
    value: "newPPVContent",
  },
  {
    label: "New Vault Folder",
    value: "newVaultFolder",
  },
];

export const transactionTypes = [
  { value: "subscription", label: "Subscription" },
  { value: "post", label: "Post" },
  { value: "tips", label: "Donation" },
  { value: "message", label: "Message Post" },
  { value: "liveEvent", label: "event" },
  { value: "payperview", label: "ppv" },
  { value: "vault", label: "vault" },
];

export const purchaseTypeConfig = [
  {
    label: "Free",
    value: "free",
  },
  {
    label: "Purchased",
    value: "purchased",
  },
  {
    label: "Not Purchased",
    value: "notpurchased",
  },
];

export const postedDateConfig = [
  {
    label: "Last 7 Days",
    value: 7,
  },
  {
    label: "Last 30 days",
    value: 30,
  },
  {
    label: "Last 3 Months",
    value: 90,
  },
  {
    label: "Last 1 year",
    value: 365,
  },
];

export const durationConfig = [
  {
    label: "Short (Upto 4 mins)",
    value: { upper: 4, lower: 0 },
  },
  {
    label: "Medium (4 mins - 20 mins)",
    value: { upper: 20, lower: 4 },
  },
  {
    label: "Long (More than 20 mins)",
    value: { lower: 20, upper: 0 },
  },
];

export const postFilterOptionsConfig = [
  {
    label: "Post",
    value: "post",
  },
  {
    label: "Image",
    value: "image",
  },
  {
    label: "Video",
    value: "video",
  },
  {
    label: "Audio",
    value: "audio",
  },
  {
    label: "Locked",
    value: "locked",
  },
];

// 3d-payment-for-europe-country
export const europeCountyList = [
  "AT",
  "BE",
  "BG",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "HU",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SK",
  "SI",
  "ES",
  "SE",
];

export const videoResolutionsConfig = {
  threeTwoZero: 320,
  fiveFourZero: 540,
  sevenTwoZero: 720,
  oneZeroEightZero: 1080,
};

export const bitrateConfig = {
  fiveFourZero: 200000,
  oneZeroEightZero: 400000,
  sevenTwoZero: 800000,
  threeTwoZero: 1500000,
};

export const tiersConfig = ["basic", "plus", "premium"];
