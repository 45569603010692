import React from "react";
import { Alert } from "reactstrap";
import { APP_LOGO } from "../../config";

const CustomPushNotification = ({
  title,
  description,
  onDismiss = () => {},
}) => {
  const _onDismiss = () => {
    onDismiss();
  };

  if (!title || !description) return <></>;

  return (
    <Alert className="customPushNotification" isOpen={true} toggle={_onDismiss}>
      <div>
        <h4 className="alert-heading">{title}</h4>
        <p>{description}</p>
      </div>

      <img src={APP_LOGO} alt="logo" />
    </Alert>
  );
};

export default CustomPushNotification;
