import React from "react";
import SkeletonLoading from ".";

const SkeletonSearchInfluencers = ({ loadMore = false }) => {
  if (loadMore) {
    return (
      <>
        <div className="my-2">
          <SkeletonLoading type={"box"} count={1} height={150} />
        </div>
        <div className="my-2">
          <SkeletonLoading type={"box"} count={1} height={150} />
        </div>
        <div className="my-2">
          <SkeletonLoading type={"box"} count={1} height={150} />
        </div>
        <div className="my-2">
          <SkeletonLoading type={"box"} count={1} height={150} />
        </div>
        <div className="my-2">
          <SkeletonLoading type={"box"} count={1} height={150} />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="my-4">
        <div className="my-2">
          <SkeletonLoading type={"box"} count={1} height={20} width={100} />
        </div>
        <div className="my-2">
          <SkeletonLoading type={"box"} count={1} height={150} />
        </div>
        <div className="my-2">
          <SkeletonLoading type={"box"} count={1} height={150} />
        </div>
      </div>

      <div className="my-4">
        <div className="my-2">
          <SkeletonLoading type={"box"} count={1} height={20} width={100} />
        </div>
        <div className="my-2">
          <SkeletonLoading type={"box"} count={1} height={150} />
        </div>
        <div className="my-2">
          <SkeletonLoading type={"box"} count={1} height={150} />
        </div>
      </div>
    </>
  );
};

export default SkeletonSearchInfluencers;
