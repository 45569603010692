import React from "react";
import { NavItem, NavLink } from "reactstrap";

const PageTab = ({ tabValue, tabLabel, onTabChange, selectedTab }) => {
  const _onTabChange = (tabValue) => {
    onTabChange(tabValue);
  };

  return (
    <NavItem>
      <NavLink
        active={selectedTab === tabValue}
        onClick={() => _onTabChange(tabValue)}
      >
        {tabLabel}
      </NavLink>
    </NavItem>
  );
};

export default PageTab;
