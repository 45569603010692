import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonComments = ({ count }) => {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <div className="d-flex mb-2" key={index}>
          <Skeleton circle height={40} width={40} className="mr-2" />

          <div className="w-100">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex">
                <div className="userName-Comment">
                  <Skeleton
                    height={14}
                    width={Math.floor(Math.random() * 80 + 75)}
                    className="m-0"
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-column">
              <Skeleton
                height={40}
                width={Math.floor(Math.random() * 120 + 100)}
                borderRadius={15}
                className="m-0"
              />
              <Skeleton height={13} width={70} className="m-0" />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default SkeletonComments;
