import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { searchInfluencers } from "../../http-calls";

const updateSearchedInfluencers = createAction("updateSearchedInfluencers");
const clearSearchResult = createAction("clearSearchResult");

const searchInluencersAsync = createAsyncThunk(
  "searchInfluencers/searchInfluencersAsync",
  async (payload, thunkAPI) => {
    const response = await searchInfluencers(payload);
    return { response: response, metaData: payload };
  }
);

export { updateSearchedInfluencers, clearSearchResult, searchInluencersAsync };
