import React, { useEffect, useState } from "react";
import {
  Button,
  CustomInput,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";
import { getResolution } from "../helper-methods";
import VisibilitySensor from "react-visibility-sensor";

const ImageQualitySetting = ({
  imageQuality = "1080",
  setImageQuality = () => {},
  customClassname = "",
  customPopOverClassname = "",
  id = "",
  isFeed = false,
}) => {
  const imageQualityConfig = {
    360: "threeSixZero",
    480: "fourEightZero",
    720: "sevenTwoZero",
    1080: "oneZeroEightZero",
    Original: "raw",
  };

  const [isImageQualityPopoverOpen, setIsImageQualityPopoverOpen] =
    useState(false);

  const [selectedQuality, setSelectedQuality] = useState("");

  const _toggleImageQualityPopover = () => {
    setIsImageQualityPopoverOpen((prev) => !prev);
  };

  const _onChangeQuality = (selectedValue) => {
    // local state
    setSelectedQuality(selectedValue);

    // parent state
    setImageQuality(selectedValue);
  };

  const _onChangeVisibility = (isVisible) => {
    if (!isVisible && isImageQualityPopoverOpen) {
      _toggleImageQualityPopover();
    }
  };

  // update local state whenever parent state changes
  useEffect(() => {
    if (imageQuality) {
      setSelectedQuality(imageQuality);
    }
  }, [imageQuality]);

  // default quality will be picked based on screen resolution
  useEffect(() => {
    const resolution = getResolution();

    if (imageQualityConfig?.[resolution] && !selectedQuality) {
      setSelectedQuality(imageQuality || imageQualityConfig?.[resolution]);
      setImageQuality(imageQuality || imageQualityConfig?.[resolution]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VisibilitySensor onChange={(isVisible) => _onChangeVisibility(isVisible)}>
      <div className={customClassname || ""}>
        {isFeed ? (
          <div
            onClick={() => _toggleImageQualityPopover()}
            id={`image_quality_isPopoverOpen_${id}`}
          >
            <i className="fa fa-gear" />
          </div>
        ) : (
          <Button
            onClick={() => _toggleImageQualityPopover()}
            id={`image_quality_isPopoverOpen_${id}`}
            className="themeBtn addBtn"
          >
            Image Quality
          </Button>
        )}

        <UncontrolledPopover
          className={`customPopover ${customPopOverClassname}`}
          placement="bottom"
          target={`image_quality_isPopoverOpen_${id}`}
          trigger="legacy"
          isOpen={isImageQualityPopoverOpen}
          toggle={() => _toggleImageQualityPopover()}
        >
          <PopoverBody>
            {Object.keys(imageQualityConfig).map((each) => (
              <div key={imageQualityConfig[each]} className="radioWrap">
                <CustomInput
                  id={`image_quality_${imageQualityConfig[each]}`}
                  type="radio"
                  name="imageQuality"
                  checked={selectedQuality === imageQualityConfig[each]}
                  value={imageQualityConfig[each]}
                  onChange={() => _onChangeQuality(imageQualityConfig[each])}
                  label={each}
                />
              </div>
            ))}
          </PopoverBody>
        </UncontrolledPopover>
      </div>
    </VisibilitySensor>
  );
};

export default ImageQualitySetting;
