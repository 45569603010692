import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";

const CustomPrompt = ({
  isOpen,
  message,
  successButtonText,
  closeButtonText,
  onSuccess,
  onDismiss,
}) => {
  const _onDismiss = () => {
    onDismiss();
  };

  const _onSuccess = () => {
    onSuccess();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _onDismiss()}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      {message?.length ? (
        <ModalBody className="py-4 text-center">
          <h4 className="mb-3 fs-18">{message}</h4>

          <div className="mt-5">
            <Button className="modalBtnNo" onClick={() => _onDismiss()}>
              {closeButtonText?.length ? closeButtonText : "No"}
            </Button>

            <Button className="modalBtnYes" onClick={() => _onSuccess()}>
              {successButtonText?.length ? successButtonText : "Yes"}
            </Button>
          </div>
        </ModalBody>
      ) : null}
    </Modal>
  );
};

export default CustomPrompt;
