import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchInfluencerList } from "../../http-calls";

const getAndUpdateInfluencerList = createAsyncThunk(
  "filterListData/getAndUpdateInfluencerList",
  async (payload, thunkAPI) => {
    const res = await fetchInfluencerList();

    return res?.influencers;
  }
);

export { getAndUpdateInfluencerList };
