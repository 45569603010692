import React, { useEffect } from "react";
import { useMemo } from "react";
import { Container, Row, Col } from "reactstrap";
import {
  errorHandler,
  extractQueryParams,
  getMessageParticipants,
  getWindowDimensions,
} from "../helper-methods";
import ThreadListGroup from "../components/messages/ThreadListGroup";
import { useSelector } from "react-redux";
import ConversationCard from "../components/messages/ConversationCard";
import { createThread, getAThreadForList } from "../http-calls";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader, updateSelectedThread } from "../redux/actions";
import { useHistory } from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary";

const MessagesPage = () => {
  const history = useHistory();

  const chatData = useSelector((state) => state?.chatData);

  const dispatch = useDispatch();

  const { screenWidth } = useMemo(() => {
    return getWindowDimensions();
  }, []);

  const _initialiseOpenCard = async () => {
    try {
      let { creatorId, threadId } = extractQueryParams();

      if (creatorId) {
        dispatch(showLoader());
        const res = await createThread(creatorId);
        threadId = res.threadId;
      }

      if (threadId) {
        dispatch(showLoader());
        const { threads } = await getAThreadForList(threadId);
        const { fan, influencer } = getMessageParticipants(threads);
        threads.fan = fan;
        threads.influencer = influencer;
        dispatch(updateSelectedThread(threads));
        history.replace("/messages");
      }

      dispatch(hideLoader());
    } catch (error) {
      errorHandler(error);
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    _initialiseOpenCard();

    return () => {
      dispatch(updateSelectedThread(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customPgHeight animated fadeIn customPgHeightMsg">
      <Container fluid style={{ padding: "0 15px" }}>
        <Row className="justify-content-center">
          <Col xs="12" lg="11" className="noPadding">
            <div className="msgWrapWeb">
              {/* thread user list */}
              <div
                className={`chatListWeb ${
                  screenWidth < 768 && chatData?.selectedThread ? "d-none" : ""
                }`}
              >
                <ErrorBoundary>
                  <ThreadListGroup />
                </ErrorBoundary>
              </div>

              {/* conversation list */}
              <ErrorBoundary>
                <ConversationCard screenWidth={screenWidth} />
              </ErrorBoundary>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MessagesPage;
