import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  Label,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { fetchCardsAsync } from "../redux/actions/paymentData";
import { makeCardDefault, addCard } from "../http-calls";
import CustomSectionLoader from "../components/custom/CustomSectionLoader";
import { AppConfig } from "../config/app-config";
import { SQUARE_API_KEY } from "../config/index";
import { formatCurrencyValue } from "../helper-methods";
import { CURRENCY_SYMBOL } from "../config/index";
class SquarePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preferredType: "exisiting", // 'new'
      isLoaderActive: false,
      defaultCard: null,
      selectedCard: null,
      card: null,
    };
  }

  _loadSquareInterface = async () => {
    if (!window.Square) {
      throw new Error("Square.js failed to load properly");
    }

    let payments;
    try {
      payments = window.Square.payments(
        SQUARE_API_KEY,
        this.props.influencer && this.props.influencer.locationId
      );
    } catch {
      const statusContainer = document.getElementById(
        "payment-status-container"
      );
      statusContainer.className = "missing-credentials";
      statusContainer.style.visibility = "visible";
      return;
    }

    let card;
    try {
      card = await this._initializeCard(payments);
      this.setState({ card });
    } catch (e) {
      console.error("Initializing Card failed", e);
      return;
    }
  };

  _initializeCard = async (payments) => {
    // console.log("payments >>", payments);
    const card = await payments.card();
    await card.attach("#card-container");
    this._hideLoader();
    return card;
  };

  _showLoader = () => {
    this.setState({ isLoaderActive: true });
  };

  _hideLoader = () => {
    this.setState({ isLoaderActive: false });
  };

  _setDefaultCard = (cards) => {
    return new Promise((resolve, reject) => {
      let defaultCard = null;
      cards.forEach((card) => {
        if (card.isDefault) {
          defaultCard = card;
          return;
        }
      });
      this.setState({ defaultCard }, () => {
        resolve();
      });
    });
  };

  _getDefaultCard = () => {
    return new Promise(async (resolve, reject) => {
      this._showLoader();
      const res = await this.props.fetchCardsAsync();
      const cards = res?.payload;
      if (cards?.length) {
        await this._setDefaultCard(cards);
        this._hideLoader();
      } else {
        // No cards available
        this.setState({ preferredType: "new", defaultCard: null }, () => {
          this._loadSquareInterface();
          resolve();
        });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { isVisible } = this.props;

    if (isVisible && isVisible !== prevProps.isVisible) {
      this._getDefaultCard();
    }
  }

  _onTypeChange = (e, card = null) => {
    if (card) {
      this.setState({
        preferredType: e.target.value,
        selectedCard: card,
      });
    } else {
      this.setState({ preferredType: e.target.value });
    }

    if (e.target.value === "new") {
      this._loadSquareInterface();
    }
  };

  _makeCardAsDefault = () => {
    const { selectedCard } = this.state;
    return new Promise((resolve, reject) => {
      makeCardDefault(selectedCard && selectedCard.cardToken)
        .then((res) => {
          if (!res.error) {
            resolve();
          }
        })
        .catch((err) => {
          console.log("error >> ", err);
          reject();
        });
    });
  };

  _tokenize = async () => {
    const tokenResult = await this.state.card.tokenize();
    if (tokenResult.status === "OK") {
      return tokenResult.token;
    } else {
      throw new Error(
        `Tokenization errors: ${JSON.stringify(tokenResult.errors)}`
      );
    }
  };

  _processPayment = async () => {
    const { preferredType, selectedCard } = this.state;
    if (preferredType === "new") {
      // Try to add card
      let token = await this._tokenize();
      await addCard(token);
      this.props.onPaymentConfirmation();
    } else {
      // preferredType === 'existing'
      if (selectedCard) {
        await this._makeCardAsDefault();
      }
      this.props.onPaymentConfirmation();
    }
  };

  _onDismiss = () => {
    // Reset state
    this.setState({
      preferredType: "exisiting",
      isLoaderActive: false,
    });
    this.props.onDismiss();
  };

  render() {
    const { isVisible, amount, isFree } = this.props;
    const { isLoaderActive, preferredType, defaultCard } = this.state;

    return (
      <Modal
        isOpen={isVisible}
        toggle={this._onDismiss}
        className="modal-dialog-centered modal-dialog-scrollable"
      >
        <ModalHeader toggle={this._onDismiss}>
          {isFree ? (
            "Enter Card Details"
          ) : (
            <>
              Select preferred payment method to pay{" "}
              {amount && (
                <span className="themeColor">
                  {amount < AppConfig.mintransactionAmount
                    ? formatCurrencyValue(
                        Number(amount) + AppConfig.extraChargeAmount
                      )
                    : formatCurrencyValue(amount)}
                </span>
              )}
            </>
          )}
        </ModalHeader>
        <ModalBody>
          <CustomSectionLoader
            isActive={isLoaderActive}
            loaderSize={30}
            height={
              preferredType === "new"
                ? defaultCard
                  ? 245
                  : 110
                : defaultCard
                ? 175
                : 110
            }
          />

          <div>
            {defaultCard
              ? this.props.paymentData.cards.map((card) => (
                  <FormGroup check className="radio mb-3" key={card.id}>
                    <Input
                      className="form-check-input"
                      style={{ marginTop: 2 }}
                      type="radio"
                      name="cardType"
                      value="exisiting"
                      defaultChecked={card.isDefault}
                      onChange={(e) => this._onTypeChange(e, card)}
                      id="radio1"
                    />
                    <Label className="form-check-label" htmlFor="radio1">
                      {card.isDefault
                        ? `Primary Card Ending with ${
                            card.cardLastFourDigits
                          } (${card.expiryMonth}/${card.expiryYear
                            .toString()
                            .substr(2)})`
                        : `Card ending with ${card.cardLastFourDigits} (${
                            card.expiryMonth
                          }/${card.expiryYear.toString().substr(2)})`}
                    </Label>
                    <br />
                  </FormGroup>
                ))
              : null}
            <div>
              {defaultCard ? (
                <>
                  <FormGroup check className="radio mb-3">
                    <Input
                      className="form-check-input"
                      style={{ marginTop: 2 }}
                      type="radio"
                      name="cardType"
                      value="new"
                      onChange={this._onTypeChange}
                      id="radio2"
                    />
                    <Label className="form-check-label" htmlFor="radio2">
                      Add New Card
                    </Label>
                  </FormGroup>
                </>
              ) : null}
              {preferredType === "new" ? (
                <div className="stripeWrapper mb-2">
                  <div id="card-container"></div>
                </div>
              ) : null}
            </div>
            {amount < AppConfig.mintransactionAmount
              ? `Your transaction cost is less than ${CURRENCY_SYMBOL}${
                  AppConfig.mintransactionAmount
                }, so you will be charged an additional transaction fee of ${
                  AppConfig.extraChargeAmount * 100
                } cents. So total transaction cost will be ${CURRENCY_SYMBOL}${Number(
                  Number(amount) + AppConfig.extraChargeAmount
                ).toFixed(2)}`
              : null}
          </div>
        </ModalBody>
        <ModalFooter className="customModalFooter">
          <div className="secureCheckoutTxtWrap">
            <a href="http://www.credit-card-logos.com/">
              <img
                alt="Credit Card Logos"
                title="Credit Card Logos"
                src="http://www.credit-card-logos.com/images/visa_credit-card-logos/visa_mastercard_logo_6.gif"
                width="120"
                loading="lazy"
              />
            </a>

            <p className="secureCheckoutTxt">
              Merchant country: United States <br />
              Secure checkout page Secure Sockets Layer (SSL) 128 bit encryption
            </p>
          </div>

          <div className="d-flex flex-shrink-0 align-self-end">
            <Button
              className="modalBtnCancel mr-3"
              onClick={() => this._onDismiss()}
            >
              Cancel
            </Button>
            <Button
              className="modalBtnSave"
              onClick={() => this._processPayment()}
            >
              {isFree ? "Save" : "Process Payment"}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    paymentData: state.paymentData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCardsAsync: () => dispatch(fetchCardsAsync()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SquarePayment);
