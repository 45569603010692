import React, {
  Fragment,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Label,
  Collapse,
} from "reactstrap";
import {
  deepClone,
  errorHandler,
  getWindowDimensions,
} from "../helper-methods";
import {
  fetchAllPayPerViews,
  likeDislikePPV,
  updateFavoritePPV,
  increaseViewCount,
} from "../http-calls";
import ConfigureString from "../components/ConfigureString";
import {
  getAndUpdateAllCreatorBundles,
  getAndUpdateInfluencerList,
  saveLocalFilters,
} from "../redux/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  durationConfig,
  postedDateConfig,
  purchaseTypeConfig,
} from "../config/helper-config";
import SkeletonLoading from "../components/SkeletonLoading";
import ErrorBoundary from "../components/ErrorBoundary";

// code splitting
const PayPerViewWrapper = React.lazy(() =>
  import("../components/PayPerViewWrapper")
);

const PayPerViewPage = () => {
  const dispatch = useDispatch();

  const filterListData = useSelector((state) => state?.filterListData);
  const localFilters = useSelector((state) => state?.localFilters);

  const timerIntervalRef = useRef({});
  const observer = useRef();

  const [payPerViews, setPayPerViews] = useState([]);
  const [payPerViewsCount, setPayPerViewsCount] = useState(0);
  const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(false);
  const [filters, setFilters] = useState({
    purchaseType: "",
    influencerId: "",
    postedAfterDays: "",
    duration: "",
  });
  const [dataPayload, setDataPayload] = useState({
    skip: 0,
    limit: 20,
    filter: {},
    search: "",
  });
  const [loadingState, setLoadingState] = useState({
    data: false,
    filter: false,
    search: false,
  });

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _getAndUpdateAllCreatorBundles = async () => {
    try {
      dispatch(getAndUpdateAllCreatorBundles());
    } catch (error) {
      errorHandler(error);
    }
  };

  // Fetch all PPV
  const _fetchAllPayPerViews = (payload) => {
    _manageLoadingState("data", true);

    fetchAllPayPerViews(payload)
      .then((res) => {
        setPayPerViews((prev) =>
          payload?.skip ? prev.concat(res.ppvs) : res.ppvs
        );
        setPayPerViewsCount(res.count);
        setLoadingState({});
      })
      .catch((error) => {
        errorHandler(error);
        setLoadingState({});
      });
  };

  const _onFilterChange = (newFilters = {}) => {
    _manageLoadingState("filter", true);

    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["filter"] = {};

    dispatch(saveLocalFilters({ key: "ppv", value: deepClone(newFilters) }));

    Object.keys(newFilters).forEach((eachFilterKey) => {
      if (newFilters[eachFilterKey]?.length) {
        if (eachFilterKey === "duration") {
          newDataPayload.filter[eachFilterKey] = JSON.parse(
            newFilters[eachFilterKey]
          );
        } else {
          newDataPayload.filter[eachFilterKey] = newFilters[eachFilterKey];
        }
      }
    });

    setDataPayload(newDataPayload);

    _fetchAllPayPerViews(newDataPayload);
  };

  const _onChangeFilters = (key, value) => {
    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);

    _onFilterChange(newFilters);
  };

  const _onChangeSearch = (searchValue) => {
    if (timerIntervalRef?.search) clearInterval(timerIntervalRef.search);
    _manageLoadingState("search", true);

    const newDataPayload = { ...dataPayload };
    newDataPayload["search"] = searchValue;
    newDataPayload["skip"] = 0;
    setDataPayload(newDataPayload);

    timerIntervalRef.search = setTimeout(() => {
      _fetchAllPayPerViews(newDataPayload);
    }, 1000);
  };

  const _clearFilters = () => {
    const newFilters = { ...filters };
    Object.keys(newFilters).forEach((key) => {
      newFilters[key] = "";
    });
    setFilters(newFilters);

    _onFilterChange(newFilters);
  };

  // Fetch influencer list for dropdown
  const _getAndUpdateInfluencerList = () => {
    dispatch(getAndUpdateInfluencerList());
  };

  // Local Like or Dislike
  const _toggleLikePpv = (index, isLiked) => {
    if (timerIntervalRef?.like) clearInterval(timerIntervalRef.like);

    const newPayPerViews = [...payPerViews];
    const rollBackPayperViews = deepClone(payPerViews);

    if (isLiked) {
      // Decrease likes count
      newPayPerViews[index]._isLiked = 0;
      newPayPerViews[index].likes -= 1;
    } else {
      // Increase likes count
      newPayPerViews[index]._isLiked = 1;
      newPayPerViews[index].likes += 1;
    }

    setPayPerViews(newPayPerViews);

    timerIntervalRef.like = setTimeout(async () => {
      try {
        await likeDislikePPV(payPerViews[index].id, isLiked);
      } catch (error) {
        setPayPerViews(rollBackPayperViews);
        errorHandler(error);
      }
    }, 1000);
  };

  // isFavourite toggle in local
  const _toggleFavouritePpv = (index, isFavourite) => {
    if (timerIntervalRef?.favourite) clearInterval(timerIntervalRef.favourite);

    const newPayPerViews = [...payPerViews];

    if (isFavourite) {
      newPayPerViews[index].isFavourite = false;
    } else {
      newPayPerViews[index].isFavourite = true;
    }

    setPayPerViews(newPayPerViews);

    timerIntervalRef.favourite = setTimeout(async () => {
      try {
        await updateFavoritePPV(payPerViews[index].id, !isFavourite);
      } catch (error) {
        console.log({ error });
      }
    }, 1000);
  };

  const _addCommentPpv = (index, isAdded) => {
    try {
      const newPayPerViews = [...payPerViews];
      if (isAdded) {
        if (newPayPerViews?.[index]?.comments) {
          newPayPerViews[index].comments++;
        } else {
          newPayPerViews[index].comments = 1;
        }
      } else {
        if (newPayPerViews?.[index]?.comments) {
          newPayPerViews[index].comments--;
        } else {
          newPayPerViews[index].comments = 0;
        }
      }
      setPayPerViews(newPayPerViews);
    } catch (error) {
      console.log({ error });
    }
  };

  const _increaseViewCount = (index) => {
    try {
      const newPayPerViews = [...payPerViews];

      payPerViews[index].viewCount += 1;

      setPayPerViews(newPayPerViews);

      increaseViewCount(payPerViews[index].id);
    } catch (error) {
      console.log({ error });
    }
  };

  const _updateCommentsCount = ({ index, value }) => {
    try {
      const newPayPerViews = [...payPerViews];

      payPerViews[index].comments = value;

      setPayPerViews(newPayPerViews);
    } catch (error) {
      console.log({ error });
    }
  };

  const lastElementRef = useCallback(
    (node) => {
      if (loadingState?.data) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          payPerViews?.length < payPerViewsCount
        ) {
          const newDataPayload = { ...dataPayload };
          newDataPayload["skip"] = payPerViews?.length || 0;
          setDataPayload(newDataPayload);
          _fetchAllPayPerViews(newDataPayload);
        }
      });

      if (node) observer.current.observe(node);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingState?.data, payPerViews]
  );

  useEffect(() => {
    _getAndUpdateAllCreatorBundles();

    if (localFilters?.filters?.ppv) {
      const newFilters = { ...filters };
      Object.keys(newFilters).forEach((key) => {
        if (localFilters.filters.ppv?.[key]) {
          newFilters[key] = localFilters.filters.ppv[key];
        }
      });
      setFilters(newFilters);
      _onFilterChange(newFilters);
    } else {
      _fetchAllPayPerViews(dataPayload);
    }

    _getAndUpdateInfluencerList();

    const { screenWidth } = getWindowDimensions();

    if (screenWidth >= 768) {
      setIsOpenFilterCollapse(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="noMargin justify-content-center">
          <Col sm={12} md={11} lg={8} xl={12} className="noPadding">
            <div className="pgTitle customMarginMob">
              <h2>
                <ConfigureString keyString={"ppv"} />
              </h2>

              <Button
                onClick={() => setIsOpenFilterCollapse((prev) => !prev)}
                className="customPopoverBtn showFilter"
              >
                <img
                  src="/assets/img/filter-icon.png"
                  alt="Filter Icon"
                  height="24"
                />
              </Button>
            </div>

            <Collapse isOpen={isOpenFilterCollapse}>
              <div className="customFilterWrap">
                <FormGroup>
                  <Label>Type</Label>
                  <Input
                    type="select"
                    name="purchaseType"
                    value={filters.purchaseType}
                    onChange={(e) =>
                      _onChangeFilters("purchaseType", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {purchaseTypeConfig.map((each) => (
                      <option key={each.value} value={each.value}>
                        {each.label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>

                <FormGroup>
                  <Label>Influencer</Label>
                  <Input
                    type="select"
                    name="influencerId"
                    value={filters.influencerId}
                    onChange={(e) =>
                      _onChangeFilters("influencerId", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {filterListData?.influencers?.map((each) => (
                      <option key={each._id} value={each._id}>
                        @{each.username}
                      </option>
                    ))}
                  </Input>
                </FormGroup>

                <FormGroup>
                  <Label>Posted</Label>
                  <Input
                    type="select"
                    name="postedAfterDays"
                    value={filters.postedAfterDays}
                    onChange={(e) =>
                      _onChangeFilters("postedAfterDays", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {postedDateConfig.map((each) => (
                      <option key={each.value} value={each.value}>
                        {each.label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>

                <FormGroup>
                  <Label>Duration</Label>
                  <Input
                    type="select"
                    name="duration"
                    value={filters.duration}
                    onChange={(e) =>
                      _onChangeFilters("duration", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {durationConfig.map((each) => (
                      <option
                        key={JSON.stringify(each.value)}
                        value={JSON.stringify(each.value)}
                      >
                        {each.label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>

                <InputGroup className="mt-3 mt-md-1">
                  <Input
                    placeholder="Search"
                    type="text"
                    autoComplete="off"
                    name="search"
                    value={dataPayload.search}
                    onChange={(e) => _onChangeSearch(e.target.value)}
                  />
                  <InputGroupAddon addonType="append">
                    <Button
                      className="border-0"
                      style={{
                        background: "#eff0f6",
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                      }}
                    >
                      {loadingState.search ? (
                        <i className="fa fa-spinner fa-spin" />
                      ) : dataPayload.search ? (
                        <i
                          className="fa fa-times"
                          onClick={() => _onChangeSearch("")}
                        />
                      ) : (
                        <i className="fa fa-search" />
                      )}
                    </Button>
                  </InputGroupAddon>
                </InputGroup>

                <Button className="themeBtn addBtn" onClick={_clearFilters}>
                  Reset
                </Button>
              </div>
            </Collapse>

            <Row className="liveEventWrapper">
              {payPerViews?.length ? (
                payPerViews.map((each, index) => (
                  <Fragment key={`ppv_${each?.id}_${index}`}>
                    <ErrorBoundary>
                      <Suspense
                        fallback={<SkeletonLoading type={"event"} count={1} />}
                      >
                        <PayPerViewWrapper
                          {...(index === payPerViews.length - 1
                            ? { lastElementRef }
                            : {})}
                          ppv={each}
                          ppvIndex={index}
                          onComment={(index, isAdded) =>
                            _addCommentPpv(index, isAdded)
                          }
                          getAllPPV={() =>
                            _fetchAllPayPerViews({
                              ...dataPayload,
                              skip: 0,
                              limit: payPerViews.length,
                            })
                          }
                          toggleLike={() =>
                            _toggleLikePpv(index, each._isLiked)
                          }
                          toggleFavourite={() =>
                            _toggleFavouritePpv(index, each.isFavourite)
                          }
                          increaseViewCount={() => _increaseViewCount(index)}
                          updateCommentsCount={({ index, value }) =>
                            _updateCommentsCount({ index, value })
                          }
                        />
                      </Suspense>
                    </ErrorBoundary>

                    {index === payPerViews.length - 1 && loadingState.data && (
                      <SkeletonLoading type={"event"} count={2} />
                    )}
                  </Fragment>
                ))
              ) : loadingState.data ? (
                <SkeletonLoading type={"event"} count={4} />
              ) : (
                <div className="w-100 noContentFound">
                  <ConfigureString keyString={"No ppv found"} />
                </div>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PayPerViewPage;
