import React from "react";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import {
  copyPostPublicLink,
  formatDate,
  getCommentIconClassName,
  getLikeIconClassName,
  getLowResolutionLink,
  mentionUserInputParse,
  reactHtmlParser,
} from "../../helper-methods";
import ReadMoreReadLess from "../custom/ReadMoreReadLess";
import PollOptionComponent from "../PollOptionComponent";

const PollViewer = ({
  feed,
  feedIndex,
  isSubscriptionRequired,
  selectPollOption,
  toggleCommentSection,
  toggleLikePost,
  toggleFlagModal,
  goToPostDetailsPage,
  scrollToTop = () => {},
}) => {
  const influencersOnlineData = useSelector(
    (state) => state?.influencersOnlineData
  );

  const _subscribe = () => {
    try {
      window.scrollTo(0, 0);
      scrollToTop();
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <Card className="cardFeed">
      <CardHeader
        className="justify-content-start"
        onClick={() => goToPostDetailsPage(isSubscriptionRequired)}
      >
        <div className="mr-1 position-relative">
          <img
            src={
              getLowResolutionLink(feed?._influencer?.profilePicture) ||
              DEFAULT_PROFILE_PICTURE
            }
            onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
            alt="Profile Img"
            className="userImg"
            loading="lazy"
          />
          {/* Online status */}
          {influencersOnlineData?.influencers?.[feed?._influencer?._id] ? (
            <span className="fa fa-circle onlineStatus" />
          ) : null}
        </div>
        <div>
          {/* <h4>{post._influencer ? post._influencer.name.full : ""}</h4> */}
          <div className="profileID">
            @{feed?._influencer?.username || "N/A"}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        {feed?.description?.length ? (
          <div className="customPaddingMob">
            <ReadMoreReadLess
              className="readMore"
              text={reactHtmlParser(feed.description)}
            />
          </div>
        ) : null}

        {feed?.text?.length ? (
          <div className="customPaddingMob">
            {mentionUserInputParse(feed?.text)}
          </div>
        ) : null}

        {/* poll options should always be wrapped inside the below div */}
        {isSubscriptionRequired ? (
          <div className="postLockedOverlay">
            {/* <h3>
                <i className="fa fa-image"/>{" "}
                Lockked Post
              </h3> */}

            <svg
              className="iconLock"
              xmlns="http://www.w3.org/2000/svg"
              width="185.578"
              height="257"
              viewBox="0 0 185.578 257"
            >
              <g id="padlock_846099" transform="translate(-35.211 0.5)">
                <g id="Group_2" data-name="Group 2">
                  <g id="Group_1" data-name="Group 1">
                    <path
                      id="Path_3"
                      data-name="Path 3"
                      d="M195.813,108.869V68.328a67.977,67.977,0,0,0-20.3-48.845A65.812,65.812,0,0,0,128.787,0h-.868C90.525.054,60.133,30.664,60.133,68.328v40.541a27.683,27.683,0,0,0-24.422,27.841v91.068A28.051,28.051,0,0,0,63.552,256h128.9a28.051,28.051,0,0,0,27.841-28.221V136.711A27.813,27.813,0,0,0,195.813,108.869ZM70.933,68.328h.054c0-31.695,25.562-57.637,56.985-57.637h.054a56.214,56.214,0,0,1,39.835,16.39A57.471,57.471,0,0,1,184.9,68.328v40.6h-11.94v-40.6a45.731,45.731,0,0,0-13.514-32.834,44,44,0,0,0-31.1-12.917h-.326c-24.965,0-45.154,20.515-45.154,45.7v40.65H70.933v-40.6Zm91.231,0v40.6H93.782v-40.6c0-19.212,15.3-34.842,34.3-34.842h.326a33.284,33.284,0,0,1,23.5,9.769A34.983,34.983,0,0,1,162.164,68.328Zm47.759,159.613h0a17.209,17.209,0,0,1-17.2,17.2H63.769a17.209,17.209,0,0,1-17.2-17.2v-90.96a17.209,17.209,0,0,1,17.2-17.2h128.95a17.209,17.209,0,0,1,17.2,17.2v90.96Z"
                      stroke="#000"
                      strokeWidth="1"
                    />
                  </g>
                </g>
                <g id="Group_4" data-name="Group 4">
                  <g id="Group_3" data-name="Group 3">
                    <path
                      id="Path_4"
                      data-name="Path 4"
                      d="M146.751,179.1A19.565,19.565,0,1,0,122.546,203.3v15.2a5.427,5.427,0,0,0,10.854,0V203.3A19.514,19.514,0,0,0,146.751,179.1Zm-18.778,14.111a8.738,8.738,0,1,1,8.738-8.738A8.732,8.732,0,0,1,127.973,193.208Z"
                      stroke="#707070"
                      strokeWidth="1"
                    />
                  </g>
                </g>
              </g>
            </svg>

            {/* <h4>"Subscribe to Unlock"</h4> */}

            <Button className="paymentBtnTier" onClick={() => _subscribe()}>
              Subscribe
            </Button>
          </div>
        ) : (
          <>
            <div className="pollOptionWrap">
              {feed?.options?.length
                ? feed.options.map((option, optionIndex) => (
                    <PollOptionComponent
                      key={optionIndex}
                      option={option}
                      feed={feed}
                      feedIndex={feedIndex}
                      optionIndex={optionIndex}
                      selectPollOption={selectPollOption}
                    />
                  ))
                : null}
            </div>
            {/* total vote count */}
            {feed?._opinions?.length ? (
              <div className="totalVotes">
                {feed.opinionCount
                  ? `${feed.opinionCount} ${
                      feed.opinionCount > 1 ? "Votes" : "Vote"
                    }`
                  : "0 Vote"}
              </div>
            ) : null}
          </>
        )}
      </CardBody>

      <CardFooter className={"footerFeed"}>
        <div className="d-flex">
          <Button
            className={
              "mr-2 mr-sm-3 " +
              (isSubscriptionRequired ? "disabled-feed-options" : "")
            }
            onClick={() => toggleLikePost()}
          >
            <i className={getLikeIconClassName(feed)} />
          </Button>

          <Button
            className={
              "mr-2 mr-sm-3 " +
              (isSubscriptionRequired ? "disabled-feed-options" : "")
            }
            onClick={() => toggleCommentSection()}
          >
            <i className={getCommentIconClassName(feed)} />
          </Button>

          <Button
            className={
              "ml-auto " +
              (isSubscriptionRequired
                ? feed?.isPublic && feed?.isFree
                  ? ""
                  : "disabled-feed-options"
                : "")
            }
            title="Share"
            onClick={() =>
              copyPostPublicLink({
                influencerUsername: feed?._influencer?.username,
                postId: feed?._id,
              })
            }
          >
            <img
              src="/assets/img/quick-link.png"
              alt="share"
              loading="lazy"
              style={{ verticalAlign: -2 }}
            />
          </Button>

          <Button
            title="Report"
            className={
              "ml-3 ml-sm-3 " +
              (isSubscriptionRequired ? "disabled-feed-options" : "")
            }
            onClick={() => toggleFlagModal(true)}
          >
            <i className="fa fa-flag-o" />
          </Button>
        </div>

        {feed?.likes || feed?.comments ? (
          <div className="mt-1">
            {feed?.likes ? (
              <span>
                {feed?.likes} {feed?.likes > 1 ? "Likes" : "Like"}
              </span>
            ) : null}
            {feed?.comments ? (
              <span>
                {feed?.comments} {feed?.comments > 1 ? "Comments" : "Comment"}
              </span>
            ) : null}
          </div>
        ) : null}

        {/* post time */}
        <div className="postTime-Feed">{formatDate(feed?.startDate)}</div>
      </CardFooter>
    </Card>
  );
};

export default PollViewer;
