import { createSlice } from "@reduxjs/toolkit";
import { updateInfluencerStatus, storeAllInfluencers } from "../actions";

const initialState = {
  influencers: {},
};

const influencersOnlineDataSlice = createSlice({
  name: "influencersOnlineData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(storeAllInfluencers, (state, action) => {
        state.influencers = action.payload;
      })

      .addCase(updateInfluencerStatus, (state, action) => {
        const { id, status } = action.payload;

        state.influencers[id] = status;
      });
  },
});

export const influencersOnlineDataReducer = influencersOnlineDataSlice.reducer;
