import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SvgIcons from "../components/SvgIcons";
import { formatNumberInShort } from "../helper-methods";

const Footer = ({ isVisibleSidebar, toggleSideBar, forHeader }) => {
  const history = useHistory();

  const unreadNotificationCount = useSelector(
    (state) => state?.notificationData?.unreadNotificationCount
  );

  const unreadMessageCount = useSelector(
    (state) => state?.chatData?.unreadMessageCount
  );

  const _navigateTo = (route) => {
    if (isVisibleSidebar) {
      toggleSideBar();
    }
    history.push(route);
  };

  const _isActiveTab = (route) => {
    if (isVisibleSidebar) return false;
    return history?.location?.pathname === route ? true : false;
  };

  return (
    <>
      {/* footer/bottom menu */}
      <nav
        className={`navbar fixed-bottom bottomMenu ${forHeader ? "top-0" : ""}`}
      >
        <ul className="navbar-nav">
          <li className={`nav-item ${_isActiveTab("/feed") ? "active" : ""}`}>
            <div className="nav-link" onClick={() => _navigateTo("/feed")}>
              <SvgIcons type="homeInactive" className="inactiveIcon" />
              <SvgIcons type="homeActive" className="activeIcon" />
            </div>
          </li>

          <li className={`nav-item ${_isActiveTab("/search") ? "active" : ""}`}>
            <div className="nav-link" onClick={() => _navigateTo("/search")}>
              <SvgIcons type="search" />
            </div>
          </li>

          <li
            className={`nav-item ${_isActiveTab("/messages") ? "active" : ""}`}
          >
            <div className="nav-link" onClick={() => _navigateTo("/messages")}>
              <SvgIcons
                type="messageInactiveIcon"
                className="inactiveIcon"
                id="Outline"
              />

              <SvgIcons
                type="messageActiveIcon"
                className="activeIcon"
                id="Filled"
              />

              {unreadMessageCount && unreadMessageCount > 0 ? (
                <span className="badge badge-pill">
                  {formatNumberInShort(unreadMessageCount)}
                </span>
              ) : null}
            </div>
          </li>

          <li
            className={`nav-item ${
              _isActiveTab("/notifications") ? "active" : ""
            }`}
          >
            <div
              className="nav-link"
              onClick={() => _navigateTo("/notifications")}
            >
              <SvgIcons
                type="inactiveBellIcon"
                className="inactiveIcon"
                id="Outline"
              />

              <SvgIcons
                type="activeBellIcon"
                className="activeIcon"
                id="Layer_1"
              />

              {unreadNotificationCount && unreadNotificationCount > 0 ? (
                <span className="badge badge-pill">
                  {formatNumberInShort(unreadNotificationCount)}
                </span>
              ) : null}
            </div>
          </li>

          <li className={`nav-item ${isVisibleSidebar ? "active" : ""}`}>
            <div className="nav-link" onClick={() => toggleSideBar()}>
              <SvgIcons type={"hamburger"} />
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Footer;
