import React from "react";
import Loader from "react-loader-spinner";
import colors from "../../assets/styles/scss/style.scss";

const CustomLoader = ({
  className = "",
  width = 26,
  height = 26,
  type = "TailSpin",
}) => {
  return (
    <Loader
      type={type}
      color={colors?.themeColor}
      height={height}
      width={width}
      className={className}
    />
  );
};

export default CustomLoader;
