import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonSusbscription = ({ count }) => {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <div key={index} className="p-sm-3 bg-white mb-4" style={{borderRadius: 16}}>
          {/* influencer pic, username, pause/resume button */}
          <div className="d-flex justify-content-between align-items-center mb-2">
            <Skeleton circle height={60} width={60} />

            <Skeleton height={30} width={130} borderRadius={5} className="d-inline-block d-md-none" />
          </div>

          <Skeleton height={14} width={Math.random() * 73 + 70} />

          {/* Social media account */}
          <span className="d-flex mb-2">
            <Skeleton height={30} width={30} className="mr-2" />
            <Skeleton height={30} width={30} className="mr-2" />
            <Skeleton height={30} width={30} className="mr-2" />
            <Skeleton height={30} width={30} className="mr-2" />
            <Skeleton height={30} width={30} />
          </span>

          {/* subscription prices(basic, plus, premium), see bundle button */}
          <span className="d-flex mb-3">
            <Skeleton height={30} width={50} className="mr-2" />
            <Skeleton height={30} width={50} className="mr-2" />
            <Skeleton height={30} width={50} />
          </span>

          {/* description */}
          <Skeleton
            height={13}
            width={Math.random() * 140 + 200}
            className="mb-3"
          />

          {/* start, end date */}
          <div className="d-flex justify-content-between align-items-end">
            <span>
              <Skeleton height={14} width={50} />
              <Skeleton height={16} width={90} />
            </span>

            <span>
              <Skeleton height={14} width={50} />
              <Skeleton height={16} width={90} />
            </span>

            <Skeleton
              height={30}
              width={130}
              borderRadius={6}
              className="d-none d-md-inline-flex align-self-end"
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default SkeletonSusbscription;
