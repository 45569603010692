import { createSlice } from "@reduxjs/toolkit";
import {
  getAndUpdateUserSubscriptions,
  clearUserSubscriptions,
} from "../actions";

const initialState = {
  subscriptions: [],
};

const userSubscriptionsSlice = createSlice({
  name: "userSubscriptions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(clearUserSubscriptions, (state, action) => {
        state.subscriptions = [];
      })

      .addCase(getAndUpdateUserSubscriptions.pending, (state) => {
        state.loading = true;
      })

      .addCase(getAndUpdateUserSubscriptions.fulfilled, (state, action) => {
        state.subscriptions = action.payload;
        state.loading = false;
      });
  },
});

export const userSubscriptionsReducer = userSubscriptionsSlice.reducer;
