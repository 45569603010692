import { AppNavbarBrand } from "@coreui/react";
import React from "react";
import { unreadNotificationCountUpdate } from "../redux/actions/notificationData";
import { APP_LOGO } from "../config";
import Footer from "./Footer";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const Header = ({ isVisibleSidebar, toggleSideBar }) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const notificationData = useSelector((state) => state?.notificationData);

  const _realTimeNotificationUpdated = () => {
    try {
      var OneSignal = window.OneSignal || [];

      OneSignal.on("notificationDisplay", (event) => {
        console.log("OneSignal notification displayed:", event);
        let unreadNotificationCount =
          notificationData?.unreadNotificationCount || 0;
        if (event?.heading !== "Message") {
          if (unreadNotificationCount) {
            unreadNotificationCount += 1;
          } else {
            unreadNotificationCount = 1;
          }
          dispatch(unreadNotificationCountUpdate(unreadNotificationCount));
        }
      });
    } catch (error) {
      console.log({ error });
    }
  };

  const _redirectToFeedPage = () => {
    if (isVisibleSidebar) {
      toggleSideBar();
    }
    history.push("/feed");
  };

  useEffect(() => {
    _realTimeNotificationUpdated();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* <Button
          color="link"
          className="menuBtn"
          onClick={() => props.toggleSideBar()}
        >
          <img src={"/assets/img/menu-icon.png"} alt="Menu" loading="lazy" />
        </Button> */}

      <AppNavbarBrand
        full={{
          src: APP_LOGO,
          width: 125,
          height: 16,
          alt: "Project Logo",
        }}
        onClick={() => _redirectToFeedPage()}
      />

      <div className="d-sm-down-none ml-1">
        <Footer
          forHeader={true}
          toggleSideBar={() => toggleSideBar()}
          isVisibleSidebar={isVisibleSidebar}
        />
      </div>
    </>
  );
};

export default Header;
