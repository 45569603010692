import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Form, FormGroup, Input } from "reactstrap";
import { RegexConfig } from "../config/RegexConfig";
import { errorHandler, showToast } from "../helper-methods";
import { updateFanDetails } from "../http-calls";

const SocialMediaLinks = () => {
  const { user: fan } = useSelector((state) => state?.userData || {});

  const [loading, setLoading] = useState(false);
  const [formFields, setFormFields] = useState({
    twitter: "",
    youtube: "",
    facebook: "",
    tiktok: "",
    instagram: "",
  });

  const [errors, setErrors] = useState({});
  const [isDirty, setIsDirty] = useState({});

  const _preFillData = (fan) => {
    const newFormFields = { ...formFields };
    fan.social.forEach((socialLink) => {
      newFormFields[socialLink?.["name"]] = socialLink?.["accountUrl"];
    });
    setFormFields(newFormFields);
  };

  const _createPayload = (formFields) => {
    let payload = [];

    for (const social in formFields) {
      if (formFields[social].length) {
        payload.push({
          name: social.trim(),
          accountUrl: formFields[social].trim(),
        });
      }
    }
    return payload;
  };

  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newIsDirty = { ...isDirty };
      const newFormFields = { ...formFields };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  const _handleSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      const newFormFields = { ...formFields };
      const newAllDirty = await _markAllIsDirty();
      const isFormValid = await _validateFormFields(newFormFields, newAllDirty);

      if (!isFormValid) {
        return errorHandler({ reason: "Fill Social Media Links Correctly" });
      }

      setLoading(true);
      const payload = _createPayload(newFormFields);
      await updateFanDetails({ social: payload });
      showToast("Social Links Updated", "success");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const _validateFormFields = (newFormFields, newIsDirty) => {
    let newErrors = { ...errors };

    return new Promise((resolve) => {
      let isFormValid = true;
      Object.keys(newFormFields).forEach((key) => {
        if (!newIsDirty[key]) return;

        switch (key) {
          case "twitter": {
            if (formFields.twitter.length) {
              if (
                RegexConfig.url.test(String(formFields.twitter).toLowerCase())
              ) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "Link Not Valid";
                isFormValid = false;
              }
            }
            break;
          }
          case "youtube": {
            if (formFields.youtube.length) {
              if (
                RegexConfig.url.test(String(formFields.youtube).toLowerCase())
              ) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "Link Not Valid";
                isFormValid = false;
              }
            }
            break;
          }
          case "facebook": {
            if (formFields.facebook.length) {
              if (
                RegexConfig.url.test(String(formFields.facebook).toLowerCase())
              ) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "Link Not Valid";
                isFormValid = false;
              }
            }
            break;
          }
          case "tiktok": {
            if (formFields.tiktok.length) {
              if (
                RegexConfig.url.test(String(formFields.tiktok).toLowerCase())
              ) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "Link Not Valid";
                isFormValid = false;
              }
            }
            break;
          }
          case "instagram": {
            if (formFields.instagram.length) {
              if (
                RegexConfig.url.test(String(formFields.instagram).toLowerCase())
              ) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "Link Not Valid";
                isFormValid = false;
              }
            }
            break;
          }

          default:
        }
      });

      setIsDirty(newIsDirty);
      setErrors(newErrors);
      resolve(isFormValid);
    });
  };

  const _onBlurHandler = (key) => {
    let newFormFields = { ...formFields };
    let newIsDirty = { ...isDirty };
    newIsDirty[key] = true;
    setIsDirty(newIsDirty);

    _validateFormFields(newFormFields, newIsDirty);
  };

  const _updateFieldValue = (key, value) => {
    let newFormFields = { ...formFields };
    newFormFields[key] = value;
    setFormFields(newFormFields);
  };

  useEffect(() => {
    if (fan?.social?.length) {
      _preFillData(fan);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fan]);

  return (
    <>
      <div className="header_Settings mt-4">
        <h4>Socials</h4>
      </div>
      <Form onSubmit={(e) => _handleSubmit(e)}>
        <FormGroup>
          <div className="d-flex align-items-center">
            <img
              style={{ height: 40 }}
              src="/assets/img/social/twitter-icon.png"
              alt="twitter"
              className="mr-2"
              loading="lazy"
            />
            <Input
              type="text"
              placeholder="Enter"
              value={formFields.twitter}
              onChange={(e) => _updateFieldValue("twitter", e.target.value)}
              onBlur={() => _onBlurHandler("twitter")}
            />
          </div>
          {errors?.twitter ? (
            <div className="form-error" style={{paddingLeft: 50}}>{errors.twitter}</div>
          ) : null}
        </FormGroup>

        <FormGroup>
          <div className="d-flex align-items-center">
            <img
              style={{ height: 40 }}
              src="/assets/img/social/youtube-icon.png"
              alt="youtube"
              className="mr-2"
              loading="lazy"
            />
            <Input
              type="text"
              placeholder="Enter"
              value={formFields?.youtube}
              onChange={(e) => _updateFieldValue("youtube", e.target.value)}
              onBlur={() => _onBlurHandler("youtube")}
            />
          </div>
          {errors?.youtube ? (
            <div className="form-error" style={{paddingLeft: 50}}>{errors.youtube}</div>
          ) : null}
        </FormGroup>

        <FormGroup>
          <div className="d-flex align-items-center">
            <img
              style={{ height: 40 }}
              src="/assets/img/social/fb-icon.png"
              alt="facebook"
              className="mr-2"
              loading="lazy"
            />
            <Input
              type="text"
              placeholder="Enter"
              value={formFields?.facebook}
              onChange={(e) => _updateFieldValue("facebook", e.target.value)}
              onBlur={() => _onBlurHandler("facebook")}
            />
          </div>
          {errors?.facebook ? (
            <div className="form-error" style={{paddingLeft: 50}}>{errors.facebook}</div>
          ) : null}
        </FormGroup>

        <FormGroup>
          <div className="d-flex align-items-center">
            <img
              style={{ height: 40 }}
              src="/assets/img/social/tiktok-icon.png"
              alt="tiktok"
              className="mr-2"
              loading="lazy"
            />
            <Input
              type="text"
              placeholder="Enter"
              value={formFields?.tiktok}
              onChange={(e) => _updateFieldValue("tiktok", e.target.value)}
              onBlur={() => _onBlurHandler("tiktok")}
            />
          </div>
          {errors?.tiktok ? (
            <div className="form-error" style={{paddingLeft: 50}}>{errors.tiktok}</div>
          ) : null}
        </FormGroup>

        <FormGroup>
          <div className="d-flex align-items-center">
            <img
              style={{ height: 40 }}
              src="/assets/img/social/instagram-icon.png"
              alt="instagram"
              className="mr-2"
              loading="lazy"
            />
            <Input
              type="text"
              placeholder="Enter"
              value={formFields?.instagram}
              onChange={(e) => _updateFieldValue("instagram", e.target.value)}
              onBlur={() => _onBlurHandler("instagram")}
            />
          </div>
          {errors?.instagram ? (
            <div className="form-error" style={{paddingLeft: 50}}>{errors.instagram}</div>
          ) : null}
        </FormGroup>

        <Button type="submit" className="themeBtn saveBtn" disabled={loading}>
          {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}Save
        </Button>
      </Form>
    </>
  );
};

export default SocialMediaLinks;
