import { AppSwitch } from "@coreui/react";
import React, { Fragment, useState } from "react";
import { Button, CustomInput, Label } from "reactstrap";
import {
  EmailNotificationsList,
  PushNotificationsList,
} from "../config/helper-config";
import { deepClone, errorHandler, showToast } from "../helper-methods";
import { useDispatch } from "react-redux";
import ConfigureString from "./ConfigureString";
import { updateFanDetails } from "../http-calls";
import { updateOnlyUserData } from "../redux/actions";

const SettingsNotification = ({ fanDetails }) => {
  const dispatch = useDispatch();

  const [allowNotification, setAllowNotification] = useState({
    email: fanDetails?.notifications?.email
      ? Object.values(fanDetails?.notifications?.email).find((item) => item)
      : false,
    push: fanDetails?.notifications?.push
      ? Object.values(fanDetails?.notifications?.push).find((item) => item)
      : false,
  });
  const [subNotifications, setSubNotifications] = useState(
    fanDetails?.notifications
  );
  const [isLoading, setIsLoading] = useState(false);

  const _toggleChild = (parentField, childField) => {
    const newSubNotifications = deepClone(subNotifications);
    newSubNotifications[parentField][childField] =
      !subNotifications?.[parentField]?.[childField];
    setSubNotifications(newSubNotifications);
  };

  const _toggleCompleteList = (parent, value) => {
    let newSubNotifications = deepClone(subNotifications);
    for (let key in newSubNotifications?.[parent]) {
      newSubNotifications[parent][key] = value;
    }
    setSubNotifications(newSubNotifications);
  };

  // invoked when parent is clicked
  const _onParentValueChange = (parentField) => {
    // set peticular parent to toggle
    let newAllowNotification = { ...allowNotification };

    const toggleValue = !newAllowNotification[parentField];
    newAllowNotification[parentField] = toggleValue;
    setAllowNotification(newAllowNotification);

    // then toggle all the childs to same toggle-value
    _toggleCompleteList(parentField, toggleValue);
  };

  const _updateNotificationSettings = async () => {
    try {
      setIsLoading(true);
      dispatch(
        updateOnlyUserData({ ...fanDetails, notifications: subNotifications })
      );
      await updateFanDetails({ notifications: subNotifications });
      showToast("Notifications Settings Updated", "success");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      errorHandler(error);
    }
  };

  return (
    <>
      <Fragment>
        <div className="header_Settings">
          <h4>Email Notifications</h4>

          <AppSwitch
            variant={"pill"}
            className="customSwitch"
            checked={allowNotification.email || false}
            onChange={() => _onParentValueChange("email")}
          />
        </div>

        <Label>Receive E-mails About</Label>

        {EmailNotificationsList.map((item, index) => (
          <div className="notificationOptions" key={`email_${item.value}`}>
            <Label htmlFor={`email_${item.value}`}>
              <ConfigureString keyString={item?.label} />
            </Label>

            <CustomInput
              type="checkbox"
              id={`email_${item.value}`}
              disabled={!allowNotification.email}
              checked={
                subNotifications?.email
                  ? subNotifications?.email?.[item.value]
                  : false
              }
              onChange={() => _toggleChild("email", item.value)}
            />
          </div>
        ))}
      </Fragment>
      <hr
        style={{ marginTop: 25, borderTopColor: "rgba(204, 204, 204, 0.5)" }}
      />

      <Fragment>
        <div className="header_Settings" style={{ marginTop: 27 }}>
          <h4>Push Notifications</h4>

          <AppSwitch
            variant={"pill"}
            className="customSwitch"
            checked={allowNotification.push || false}
            onChange={() => _onParentValueChange("push")}
          />
        </div>

        <Label>Receive Website Alerts About</Label>

        {PushNotificationsList.map((item, index) => (
          <div className="notificationOptions" key={`push_${item.value}`}>
            <Label htmlFor={`push_${item.value}`}>
              <ConfigureString keyString={item?.label} />
            </Label>

            <CustomInput
              type="checkbox"
              id={`push_${item.value}`}
              checked={
                subNotifications?.["push"]
                  ? subNotifications?.["push"]?.[item?.value]
                  : false
              }
              onChange={() => _toggleChild("push", item.value)}
              disabled={!allowNotification?.push}
            />
          </div>
        ))}
      </Fragment>
      <Button
        onClick={() => _updateNotificationSettings()}
        className="themeBtn saveBtn"
        disabled={isLoading}
      >
        {isLoading ? <i className="fa fa-spinner fa-spin mr-1" /> : null} Save
      </Button>
    </>
  );
};

export default SettingsNotification;
