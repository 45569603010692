import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonCardManagement = ({ count }) => {
  return (
    <>
      <div className="d-flex justify-content-between my-2">
        <div className="d-flex">
          <Skeleton height={45} width={45} borderRadius={6} className="mr-2" />
          
          <div className="d-flex flex-column">
            <Skeleton height={17} width={180} />
            
            <div className="d-flex justify-content-between">
              <Skeleton height={20} width={40} />
              <Skeleton height={20} width={40} />
            </div>
          </div>
        </div>

        <div>
          <Skeleton height={20} width={70} />
        </div>
      </div>

      {[...Array(count)].map((_, index) => (
        <div className="d-flex justify-content-between my-2" key={index}>
          <div className="d-flex">
            <Skeleton height={45} width={45} borderRadius={6} className="mr-2" />
            
            <div className="d-flex flex-column">
              <Skeleton height={17} width={180} />

              <div className="d-flex justify-content-between">
                <Skeleton height={20} width={40} />
                <Skeleton height={20} width={40} />
              </div>
            </div>
          </div>

          <div className="d-flex flex-column flex-sm-row justify-content-between align-items-end align-items-sm-start">
            <Skeleton height={20} width={70} />
            <Skeleton height={20} width={25} className="ml-0 ml-sm-2" />
          </div>
        </div>
      ))}
    </>
  );
};

export default SkeletonCardManagement;
