import React from "react";
import { Link } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import { getLowResolutionLink } from "../../helper-methods";

const CancelSubscriptionModal = ({
  isOpen,
  selectedSubscription,
  influencer,
  toggle,
  isRenewal,
  isResume,
  renewSubscription = () => {},
  pauseSubscription = () => {},
}) => {
  const _closeModal = () => {
    toggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={_closeModal}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalHeader toggle={_closeModal}>
        {isResume
          ? "Resume Subscription"
          : `${isRenewal ? "Renew" : "Cancel"} Subscription`}
      </ModalHeader>
      <ModalBody>
        <Link to={`/influencer/${influencer?.username}`}>
          <img
            src={
              getLowResolutionLink(influencer?.profilePicture) ||
              DEFAULT_PROFILE_PICTURE
            }
            alt="Profile Pic"
            className="img60by60 d-block mx-auto mb-2"
            loading="lazy"
          />
        </Link>
        <div className="influencerName d-block mx-auto text-center">
          <Link to={`/influencer/${influencer?.username}`} className="ml-0">
            @{influencer?.username}
          </Link>
        </div>

        <p className="areYouSureTxt">
          {isResume
            ? "Are you sure you want to resume your subscription?"
            : `Are you sure you want to ${
                isRenewal ? "renew" : "cancel"
              } your subscription?`}
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          className={isRenewal || isResume ? "modalBtnNo" : "modalBtnYes"}
          onClick={_closeModal}
        >
          No
        </Button>
        <Button
          className={isRenewal || isResume ? "modalBtnYes" : "modalBtnNo"}
          onClick={isRenewal ? renewSubscription : pauseSubscription}
        >
          {isResume ? "Yes, Resume" : `Yes, ${isRenewal ? "Renew" : "Cancel"}`}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CancelSubscriptionModal;
