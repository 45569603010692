import React from "react";
import SkeletonLoading from "../SkeletonLoading";

const ThreadNotFound = ({ loading, text }) => {
  if (loading) {
    return <SkeletonLoading type={"threadList"} count={4} />;
  }

  return (
    <div className="noContentFound" style={{fontWeight: '400'}}>
      <img
        src="/assets/img/no-message.png"
        className="d-block mx-auto mb-2"
        style={{ height: 100 }}
        alt="no-message"
        loading="lazy"
      />
      {text}
    </div>
  );
};

export default ThreadNotFound;
