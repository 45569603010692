import React, { useEffect, useRef } from "react";
import {
  Button,
  Collapse,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
} from "reactstrap";
import { deepClone, getFeatureStatus } from "../helper-methods";
import { getConfiguredString } from "../helper-methods/configureString";
import { transactionTypes } from "../config/helper-config";
import CustomDateRangePicker from "./CustomDateRangePicker";
import { useSelector } from "react-redux";
import { getAndUpdateInfluencerList } from "../redux/actions";
import { useDispatch } from "react-redux";
import moment from "moment";

const PaymentHistoryPageFilters = ({
  isOpenFilterCollapse,
  filters,
  setFilters,
  dataPayload,
  setDataPayload,
  onFilterChange,
  fetchPaymentHistory,
  loadingState,
  manageLoadingState,
}) => {
  const dispatch = useDispatch();

  const searchInterval = useRef({ current: null });

  const filterListData = useSelector((state) => state?.filterListData || {});

  // resets all the filters fields
  const _clearFilters = () => {
    const newFilters = { ...filters };
    Object.keys(newFilters).forEach((key) => {
      newFilters[key] = "";
    });
    setFilters(newFilters);

    onFilterChange(newFilters);
  };

  // handles change in creator filter or payment type filter change
  const _onChangeFilters = (key, value) => {
    let newFilters = deepClone(filters);
    newFilters[key] = value;
    setFilters(newFilters);

    onFilterChange(newFilters);
  };

  // handles date change
  const _onDatesChange = ({ startDate, endDate }) => {
    const newFilters = { ...filters };
    newFilters.startDate = startDate?._d ? startDate.toISOString() : null;
    newFilters.endDate = endDate?._d ? endDate.toISOString() : null;
    setFilters(newFilters);

    if ((startDate && endDate) || (!startDate && !endDate)) {
      onFilterChange(newFilters);
    }
  };

  //handle change in search field
  const _onChangeSearch = (searchValue) => {
    if (searchInterval?.current) clearInterval(searchInterval.current);

    manageLoadingState("search", true);

    const newDataPayload = { ...dataPayload };
    newDataPayload["search"] = searchValue;
    setDataPayload(newDataPayload);

    searchInterval.current = setTimeout(() => {
      fetchPaymentHistory(newDataPayload);
    }, 1000);
  };

  // Fetch influencer list for dropdown
  const _getAndUpdateInfluencerList = () => {
    dispatch(getAndUpdateInfluencerList());
  };

  useEffect(() => {
    _getAndUpdateInfluencerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Collapse isOpen={isOpenFilterCollapse}>
      <div className="customFilterWrap px-0 px-sm-2">
        <FormGroup>
          <Label>Select Creator</Label>
          <Input
            type="select"
            name="influencer"
            value={filters?._to}
            onChange={(e) => _onChangeFilters("_to", e.target.value)}
          >
            <option value="">All</option>
            {filterListData?.influencers?.map((each) => (
              <option key={each._id} value={each._id}>
                @{each.username}
              </option>
            ))}
          </Input>
        </FormGroup>

        <FormGroup>
          <Label>Transaction Type</Label>
          <Input
            type="select"
            name="transactionType"
            value={filters?.paymentType}
            onChange={(e) => _onChangeFilters("paymentType", e.target.value)}
          >
            <option value="">All</option>
            {transactionTypes.length
              ? transactionTypes.map((each) =>
                  getFeatureStatus(each.value) ? (
                    <option key={each?.value} value={each?.value}>
                      {getConfiguredString(each?.label)}
                    </option>
                  ) : null
                )
              : null}
          </Input>
        </FormGroup>

        <FormGroup className="dateRange">
          <div className="d-flex">
            <Label className="w-50 pl-1">From</Label>
            <Label className="w-50 pl-3">To</Label>
          </div>
          <CustomDateRangePicker
            startDate={filters?.startDate ? moment(filters?.startDate) : null}
            endDate={filters?.endDate ? moment(filters?.endDate) : null}
            startDateId={`startDate_payment_Histoty_filter`}
            endDateId={`endDate_payment_Histoty_filter`}
            onDatesChange={({ startDate, endDate }) =>
              _onDatesChange({
                startDate,
                endDate,
              })
            }
          />
        </FormGroup>

        <InputGroup className="mt-3 mt-sm-1">
          <Input
            placeholder="Transaction ID"
            autoComplete="off"
            name="search"
            value={dataPayload?.search}
            onChange={(e) => _onChangeSearch(e.target.value)}
          />
          <InputGroupAddon addonType="append">
            <Button
              className="border-0"
              style={{
                background: "#eff0f6",
                borderTopRightRadius: 8,
                borderBottomRightRadius: 8,
              }}
            >
              {loadingState.search ? (
                <i className="fa fa-spinner fa-spin" />
              ) : dataPayload.search ? (
                <i
                  className="fa fa-times"
                  onClick={() => _onChangeSearch("")}
                />
              ) : (
                <i className="fa fa-search" />
              )}
            </Button>
          </InputGroupAddon>
        </InputGroup>

        <Button className="themeBtn addBtn h-center mt-3 mt-md-1" onClick={() => _clearFilters()}>
          Reset
        </Button>
      </div>
    </Collapse>
  );
};

export default PaymentHistoryPageFilters;
