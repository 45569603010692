import React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Button } from "reactstrap";
import { SHOW_SOCIAL_ICON_ONLY, SOCIAL_CREDENTIALS } from "../../config";
import { errorHandler } from "../../helper-methods";

const FacebookLoginComponent = ({ className = "", btnText, onSuccess }) => {
  const _responseFacebook = async (response) => {
    if (response?.status !== "unknown") {
      onSuccess(response);
    }
  };

  const _onError = (error) => {
    if (error?.details) {
      errorHandler(error.details);
    } else {
      errorHandler(error);
    }
  };

  return (
    <FacebookLogin
      appId={SOCIAL_CREDENTIALS?.FACEBOOK_APP_ID}
      fields="name,email"
      scope="email"
      returnScopes={true}
      callback={(res) => _responseFacebook(res)}
      onFailure={(err) => _onError(err)}
      render={(renderProps) => (
        <Button
          className={`${
            SHOW_SOCIAL_ICON_ONLY ? "socialBtnIconOnly" : "socialLoginBtn"
          }`}
          onClick={renderProps.onClick}
        >
          {!SHOW_SOCIAL_ICON_ONLY ? (
            btnText ? (
              btnText
            ) : (
              <>Continue With</>
            )
          ) : null}
          <img src="/assets/img/fb-logo.png" alt="Facebook" loading="lazy" />
        </Button>
      )}
    />
  );
};

export default FacebookLoginComponent;
