import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { APP_NAME, APP_TUTORIAL_LINK } from "../../config";
import { useSelector } from "react-redux";
import { updateFanDetails } from "../../http-calls";
import { errorHandler } from "../../helper-methods";
import { useDispatch } from "react-redux";
import { getAndUpdateUserData } from "../../redux/actions/userData";

const WelcomeFanModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector((state) => state?.userData?.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && !user.isWelcomePopupShown) {
      setIsOpen(true);
    }
  }, [user]);

  const _updateFanDetails = async () => {
    try {
      await updateFanDetails({ isWelcomePopupShown: true });
      dispatch(getAndUpdateUserData());
    } catch (error) {
      errorHandler(error);
    }
  };

  const _closeModal = () => {
    setIsOpen(false);
    _updateFanDetails();
  };

  const _redirectToTutorialPage = () => {
    // "redirect to tutorial url"
    window.open(APP_TUTORIAL_LINK, "_blank");
    _closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered"
    >
      <ModalBody>
        <div className="welcomeModalTxt">
          <h4>Welcome to</h4>
          <h4>{APP_NAME}</h4>
          <p>See all the new features we've added through our tutorial.</p>
        </div>
        <Button
          className="themeBtn loginBtn"
          onClick={() => _redirectToTutorialPage()}
        >
          See Tutorials
        </Button>{" "}
        <Button
          color="link"
          className="remainAnonymous"
          onClick={() => _closeModal()}
        >
          Skip for now
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default WelcomeFanModal;
