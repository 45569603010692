import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isUserAuthenticated } from "../guards/auth-guard";

const ProtectedRoute = ({ component: Component, queryParam = "", ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isUserAuthenticated()) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: rest.redirectRoute,
                search: window.location.search || queryParam || "",
                extras: { ...rest.location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
