import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { getAllNotifications } from "../../http-calls";

const clearNotifications = createAction("clearNotifications");
const unreadNotificationCountUpdate = createAction(
  "unreadNotificationCountUpdate"
);
const incrementUnreadNotificationCount = createAction(
  "incrementUnreadNotificationCount"
);
const markReadSingleNotification = createAction("markReadSingleNotification");

const fetchNotificationsAsync = createAsyncThunk(
  "notificationData/fetchNotificationsAsync",
  async (payload, thunkAPI) => {
    const pagination = payload
      ? payload
      : {
          filter: {},
          skip: 0,
          limit: 20,
        };

    const res = await getAllNotifications(pagination);

    return {
      notifications: res.notifications,
      total: res.count,
      unreadNotificationCount: res.unreadNotificationCount,
      isConcat: pagination?.skip > 0 ? true : false,
    };
  }
);

export {
  clearNotifications,
  unreadNotificationCountUpdate,
  markReadSingleNotification,
  fetchNotificationsAsync,
  incrementUnreadNotificationCount,
};
