import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { countryCodes } from "../config/country-codes";
import { RegexConfig } from "../config/RegexConfig";
import { States } from "../config/states";
import { useDispatch, useSelector } from "react-redux";
import { errorHandler, showToast } from "../helper-methods";
import { updateFanDetails } from "../http-calls";
import { updateOnlyUserData } from "../redux/actions";
import CustomLoader from "./custom/CustomLoader";

const AddressUpdateComponent = () => {
  const dispatch = useDispatch();
  const { user: fanDetails } = useSelector((state) => state?.userData || {});

  const [formFields, setFormFields] = useState({
    city: fanDetails?.address?.city?.length ? fanDetails?.address?.city : "",
    country: fanDetails?.address?.country?.length
      ? fanDetails?.address?.country
      : "US",
    state: fanDetails?.address?.state?.length ? fanDetails?.address?.state : "",
    street: fanDetails?.address?.street?.length
      ? fanDetails?.address?.street
      : "",
    zip: fanDetails?.address?.zip?.length ? fanDetails?.address?.zip : "",
  });

  const [errors, setErrors] = useState({});
  const [isDirty, setIsDirty] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // on fields blur, update their dirty true then validate
  const _onBlurHandler = (key) => {
    let newFormFields = { ...formFields };
    let newIsDirty = { ...isDirty };
    newIsDirty[key] = true;
    setIsDirty(newIsDirty);

    _validateForm(newFormFields, newIsDirty);
  };

  // marking all formfields dirty
  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newIsDirty = { ...isDirty };
      const newFormFields = { ...formFields };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  const _updateFieldValue = (key, value) => {
    if (
      key === "zip" &&
      (isNaN(value) || value.includes(".") || Number(value) < 0)
    ) {
      return;
    }

    let newFormFields = { ...formFields };
    newFormFields[key] = value;

    if (key === "country") {
      newFormFields["state"] = "";
    }

    setFormFields(newFormFields);
  };

  const _validateForm = (newFormFields, newIsDirty) => {
    const newErrors = { ...errors };
    return new Promise((resolve) => {
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        switch (key) {
          case "street": {
            if (newFormFields?.street?.length) {
              newIsDirty[key] = false;
              newErrors[key] = null;
            } else {
              newErrors[key] = "Street is required";
              isFormValid = false;
            }
            break;
          }
          case "city": {
            if (newFormFields.city?.length) {
              newIsDirty[key] = false;
              newErrors[key] = null;
            } else {
              newErrors[key] = "City is required";
              isFormValid = false;
            }
            break;
          }
          case "state": {
            if (newFormFields?.state?.length) {
              newIsDirty[key] = false;
              newErrors[key] = null;
            } else {
              newErrors[key] = "State is required";
              isFormValid = false;
            }
            break;
          }
          case "zip": {
            if (newFormFields?.zip?.length) {
              if (newFormFields.country === "IN") {
                if (
                  newFormFields?.zip?.length === 6 &&
                  RegexConfig.sixDigitIndianPinCode.test(
                    String(newFormFields.zip)
                  )
                ) {
                  newIsDirty[key] = false;
                  newErrors[key] = null;
                } else {
                  newErrors[key] = "Invalid Zip/Postal Code";
                }
              } else {
                newIsDirty[key] = false;
                newErrors[key] = null;
              }
            } else {
              newErrors[key] = "Zip/Postal Code is required";
              isFormValid = false;
            }
            break;
          }
          case "country": {
            if (newFormFields?.country?.length) {
              newIsDirty[key] = false;
              newErrors[key] = null;
            } else {
              errors[key] = "Country is required";
              isFormValid = false;
            }
            break;
          }
          default: {
          }
        }
      });
      setErrors(newErrors);
      setIsDirty(newIsDirty);
      resolve(isFormValid);
    });
  };

  const _handleSubmit = async (e) => {
    if (e) e.preventDefault();
    try {
      const newFormFields = { ...formFields };
      const newIsDirty = await _markAllIsDirty();
      const isFormValid = await _validateForm(newFormFields, newIsDirty);
      if (!isFormValid) {
        return errorHandler({ reason: "Fill Details Correctly" });
      }

      setIsLoading(true);
      const response = await updateFanDetails({ address: formFields });
      dispatch(updateOnlyUserData(response?.user));
      showToast("Address Updated", "success");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      errorHandler(error);
    }
  };

  return (
    <Card className="paymentWrap">
      <CardBody>
        <div className="d-flex align-items-center justify-content-start">
          <h4 className="sectionHeader mb-3">BILLING DETAILS</h4>
          {isLoading ? (
            <CustomLoader height={20} width={18} className="ml-2 mb-2" />
          ) : null}
        </div>
        <Form onSubmit={(e) => _handleSubmit(e)} autoComplete="off">
          <Row className="noMargin">
            <Col md={12} className="customPadding">
              <FormGroup>
                <div>
                  <Label>Street</Label>

                  <Input
                    type="text"
                    placeholder="Street Name"
                    value={formFields.street}
                    onChange={(e) =>
                      _updateFieldValue("street", e.target.value)
                    }
                    onBlur={() => _onBlurHandler("street")}
                  />
                </div>
                <div className="form-error">
                  {errors?.street ? <p>{errors?.street}</p> : null}
                </div>
              </FormGroup>
            </Col>
            <Col md={12} className="customPadding">
              <FormGroup>
                <div>
                  <Label>City</Label>

                  <Input
                    type="text"
                    placeholder="City Name"
                    value={formFields.city}
                    onChange={(e) => _updateFieldValue("city", e.target.value)}
                    onBlur={() => _onBlurHandler("city")}
                  />
                </div>
                <div className="form-error">
                  {errors?.city ? <p>{errors?.city}</p> : null}
                </div>
              </FormGroup>
            </Col>
            <Col md={6} className="customPadding">
              <FormGroup>
                <div>
                  <Label>State / Province</Label>

                  {formFields.country === "IN" ||
                  formFields.country === "US" ? (
                    <Input
                      type="select"
                      value={formFields?.state}
                      onChange={(e) =>
                        _updateFieldValue("state", e.target.value)
                      }
                      onBlur={() => _onBlurHandler("state")}
                    >
                      {States[formFields?.country].map((state, stateIndex) => (
                        <option key={stateIndex} value={state?.code}>
                          {state?.name}
                        </option>
                      ))}
                    </Input>
                  ) : (
                    <Input
                      type="text"
                      placeholder="State/Province Name"
                      value={formFields?.state}
                      onChange={(e) =>
                        _updateFieldValue("state", e.target.value)
                      }
                      onBlur={() => _onBlurHandler("state")}
                    />
                  )}
                </div>

                <div className="form-error">
                  {errors?.state ? <p>{errors?.state}</p> : null}
                </div>
              </FormGroup>
            </Col>
            <Col md={6} className="customPadding">
              <FormGroup>
                <div>
                  <Label>Postal/Zip</Label>

                  <Input
                    type="number"
                    placeholder="Postal/Zip Code"
                    value={formFields?.zip}
                    onChange={(e) => _updateFieldValue("zip", e.target.value)}
                    onBlur={() => _onBlurHandler("zip")}
                  />
                </div>

                <div className="form-error">
                  {errors?.zip ? <p>{errors?.zip}</p> : null}
                </div>
              </FormGroup>
            </Col>
            <Col md={12} className="customPadding">
              <FormGroup>
                <div>
                  <Label>Country</Label>

                  <Input
                    type="select"
                    name="selectCountry"
                    id="selectCountry"
                    value={formFields?.country}
                    onChange={(e) =>
                      _updateFieldValue("country", e.target.value)
                    }
                    onBlur={() => _onBlurHandler("country")}
                  >
                    {countryCodes.map((countryCode, countryIndex) => (
                      <option key={countryIndex} value={countryCode?.code}>
                        {countryCode?.name}
                      </option>
                    ))}
                  </Input>
                </div>

                <div className="form-error">
                  {errors?.country ? <p>{errors?.country}</p> : null}
                </div>
              </FormGroup>
            </Col>
            <Col md={12}>
              <Button
                className="themeBtn saveBtn mt-3"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <i className="fa fa-spinner fa-spin mr-1" />
                ) : null}{" "}
                Save
              </Button>
            </Col>
          </Row>
        </Form>

        <div>
          <img
            src="/assets/img/shield.png"
            alt="Shield"
            className="shieldIcon"
            loading="lazy"
          />

          <p className="fullySecureTxt">100% Encrypted & Secure</p>
        </div>
      </CardBody>
    </Card>
  );
};

export default AddressUpdateComponent;
