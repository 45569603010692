import React, { useRef, useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  capitalize,
  errorHandler,
  formatCurrencyValue,
  getLowResolutionLink,
  isValidPrice,
  showToast,
} from "../../helper-methods";
import { CURRENCY_SYMBOL, DEFAULT_PROFILE_PICTURE } from "../../config";
import { defaultAmounts } from "../../config/helper-config";
import ProcessPayment from "../../payment";
import { useDispatch } from "react-redux";
import {
  hideLoader,
  showLoader,
  updateOnlyUserData,
} from "../../redux/actions";
import { useSelector } from "react-redux";
import ApplePayment from "../../payment/ApplePayment";
import { AppConfig } from "../../config/app-config";
import { createPaymentIntent } from "../../http-calls";

const FolderTipModal = ({ isOpen, toggle, folder, content, influencer }) => {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.userData);

  const tipInputRef = useRef();

  const [formFields, setFormFields] = useState({
    amount: {
      value: "",
      error: null,
      isDirty: false,
    },
  });
  const [isCustomAmount, setIsCustomAmount] = useState(false);
  const [isConfirmationTextShow, setIsConfirmationTextShow] = useState(false);

  const _resetModalState = () => {
    setFormFields({
      amount: {
        value: "",
        error: null,
        isDirty: false,
      },
    });
    setIsCustomAmount(false);
    setIsConfirmationTextShow(false);
  };

  const _onCustomToggle = (isCustom = false) => {
    setIsCustomAmount(isCustom);
    setFormFields({
      amount: {
        value: "",
        error: null,
        isDirty: false,
      },
    });
  };

  const _closeModal = () => {
    _resetModalState();
    toggle();
  };

  const _validateAmount = (newFormFields) => {
    return new Promise((resolve) => {
      let isFormValid = true;

      if (newFormFields.amount?.isDirty) {
        if (newFormFields.amount?.value?.length) {
          if (
            isNaN(newFormFields.amount?.value) ||
            +newFormFields.amount?.value < AppConfig.mintransactionAmount ||
            +newFormFields.amount?.value > AppConfig.maxTransactionAmount
          ) {
            newFormFields.amount.isDirty = true;
            newFormFields.amount.error =
              +newFormFields.amount?.value < AppConfig.mintransactionAmount
                ? `*Minimum ${CURRENCY_SYMBOL}${AppConfig.mintransactionAmount}`
                : `*Maximum limit is ${CURRENCY_SYMBOL}${AppConfig.maxTransactionAmount}`;
            isFormValid = false;
          } else {
            newFormFields.amount.isDirty = false;
            newFormFields.amount.error = null;
          }
        } else {
          newFormFields.amount.isDirty = true;
          newFormFields.amount.error = "*Required";
          isFormValid = false;
        }
      }

      setFormFields(newFormFields);
      resolve(isFormValid);
    });
  };

  const _onAmountFieldUpdate = (value) => {
    if (!isValidPrice(value) || String(value).length > 10) {
      return;
    }

    if (tipInputRef?.current) {
      try {
        tipInputRef.current.style = `width: ${
          String(value)?.length * 32 + 1
        }px`;
      } catch (error) {
        console.log({ error });
      }
    }

    const newFormFields = { ...formFields };
    newFormFields.amount.value = value;
    newFormFields.amount.isDirty = true;
    setFormFields(newFormFields);
    _validateAmount(newFormFields);
  };

  const _markAmountFormFieldDirty = () => {
    return new Promise((resolve) => {
      const newFormFields = { ...formFields };
      newFormFields.amount.isDirty = true;
      setFormFields(newFormFields);

      resolve(newFormFields);
    });
  };

  const _confirmationTextShow = async () => {
    const newFormFields = await _markAmountFormFieldDirty();

    const isValidAmount = await _validateAmount(newFormFields);

    if (!isValidAmount) {
      return;
    }

    setIsConfirmationTextShow(true);
  };

  const _onSendTip = async () => {
    try {
      const newFormFields = await _markAmountFormFieldDirty();

      const isValidAmount = await _validateAmount(newFormFields);

      if (!isValidAmount) {
        return;
      }

      dispatch(showLoader("processing your payment"));

      const tipAmount = +newFormFields.amount?.value;

      let apiData = {
        url3DSecure: "/payment/intent/tip",
        url: "/payment/tip",
        influencer: influencer,
        payload: {
          influencerId: influencer?._id,
          amount: +tipAmount.toFixed(2),
          folderId: folder?._id,
          contentId: content?._id,
        },
      };

      await ProcessPayment.startPaymentProcess(apiData, +tipAmount.toFixed(2));

      // Payment successful
      showToast(
        influencer?.settings?.tipText?.length
          ? capitalize(influencer.settings.tipText) + " paid successfully"
          : "Tip paid successfully",
        "success"
      );
      _closeModal();

      dispatch(hideLoader());
    } catch (error) {
      if (error && error.isRiskAssessment) {
        dispatch(
          updateOnlyUserData({
            ...userData?.user,
            isRiskAssessment: error.isRiskAssessment,
          })
        );
      }
      dispatch(hideLoader());
      errorHandler(error);
    }
  };

  const _onPaymentSuccess = () => {
    // Payment successful
    showToast(
      influencer?.settings?.tipText?.length
        ? capitalize(influencer.settings.tipText) + " paid successfully"
        : "Tip paid successfully",
      "success"
    );
    _closeModal();
  };

  // Payment Intent For Apple Pay
  const _createPaymentIntent = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const newFormFields = await _markAmountFormFieldDirty();

        const isValidAmount = await _validateAmount(newFormFields);

        if (!isValidAmount) {
          reject({ reason: "Invalid Amount" });
          return;
        }

        const tipAmount = +newFormFields.amount?.value;

        const res = await createPaymentIntent("/payment/intent/tip", {
          influencerId: influencer?._id,
          amount: +tipAmount.toFixed(2),
          folderId: folder?._id,
          contentId: content?._id,
        });

        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      {isConfirmationTextShow && formFields.amount?.value ? (
        <>
          <ModalBody>
            <h4 className="tipConfirmationTxt">
              Are you sure you want to tip @{influencer?.username}{" "}
              {formatCurrencyValue(formFields.amount?.value)}
            </h4>

            <div className="text-center mb-3">
              <Button
                className="modalBtnCancel px-5"
                onClick={() => _closeModal()}
              >
                No
              </Button>
              <Button
                className="modalBtnSave px-5"
                onClick={() => _onSendTip()}
              >
                Yes
              </Button>
            </div>
          </ModalBody>
        </>
      ) : (
        <>
          <ModalHeader toggle={() => _closeModal()}>
            {isCustomAmount ? (
              <>
                <Button
                  className="customBackBtn"
                  style={{ verticalAlign: -2 }}
                  onClick={() => _onCustomToggle()}
                >
                  <i className="fa fa-chevron-left" />
                </Button>
                Enter Amount
              </>
            ) : (
              <>
                <img
                  className="senderImg"
                  src={
                    getLowResolutionLink(influencer?.profilePicture) ||
                    DEFAULT_PROFILE_PICTURE
                  }
                  onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                  alt="Creator Img"
                  loading="lazy"
                />
                Send Me a Tip
              </>
            )}
          </ModalHeader>

          <ModalBody>
            {isCustomAmount ? (
              <div className="mb-3">
                {/* message price input field */}
                <div className="customTipAmt">
                  {CURRENCY_SYMBOL}
                  <div ref={tipInputRef}>
                    <Input
                      type="text"
                      value={formFields.amount?.value}
                      onChange={(e) => _onAmountFieldUpdate(e.target.value)}
                      placeholder="0"
                    />
                  </div>
                </div>
                {formFields.amount?.error ? (
                  <p className="form-error text-center">
                    {formFields.amount?.error}
                  </p>
                ) : null}
              </div>
            ) : (
              <>
                <div className="tipAmtWrap">
                  {defaultAmounts.map((each) => (
                    <Button
                      className={
                        formFields.amount?.value === each.value ? "active" : ""
                      }
                      key={each.value}
                      onClick={() => _onAmountFieldUpdate(each.value)}
                    >
                      {each.label}
                    </Button>
                  ))}
                  <Button
                    // className={!formFields.amount?.value ? "active" : ""}
                    onClick={() => _onCustomToggle(true)}
                  >
                    Custom
                  </Button>
                </div>
                {formFields.amount?.error ? (
                  <p className="form-error mt-1">{formFields.amount?.error}</p>
                ) : null}
              </>
            )}
          </ModalBody>

          {/* Payment with apple/google/link Pay */}
          {+formFields.amount?.value &&
          !formFields.amount?.error &&
          Number(formFields.amount?.value) >= AppConfig.mintransactionAmount ? (
            <ApplePayment
              content={{
                name: "Tip",
                price: formFields.amount?.value,
              }}
              onDismiss={() => toggle()}
              createPaymentIntent={() => _createPaymentIntent()}
              onPaymentSuccess={() => _onPaymentSuccess()}
            />
          ) : null}

          <ModalFooter>
            <Button className="modalBtnCancel" onClick={() => _closeModal()}>
              Cancel
            </Button>
            <Button className="modalBtnSave" onClick={_confirmationTextShow}>
              Tip
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default FolderTipModal;
