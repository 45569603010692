import React from "react";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { DEFAULT_COVER_PICTURE, DEFAULT_PROFILE_PICTURE } from "../../config";
import {
  copyPostPublicLink,
  formatCurrencyValue,
  formatDate,
  getCommentIconClassName,
  getLikeIconClassName,
  getLowResolutionLink,
  mentionUserInputParse,
} from "../../helper-methods";
import ConfigureString from "../ConfigureString";
import SvgIcons from "../SvgIcons";

const FolderViewer = ({
  feed,
  isSubscriptionRequired,
  toggleCommentSection,
  toggleLikePost,
  toggleFlagModal,
  goToPostDetailsPage,
  toggleFeedTipModal,
  togglePaymentHoc,
  scrollToTop = () => {},
}) => {
  const influencersOnlineData = useSelector(
    (state) => state?.influencersOnlineData
  );

  const _subscribe = () => {
    try {
      window.scrollTo(0, 0);
      scrollToTop();
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <Card className="cardFeed">
      <CardHeader onClick={() => goToPostDetailsPage(isSubscriptionRequired)}>
        <div className="d-flex align-items-center">
          <div className="mr-1 position-relative">
            <img
              src={
                getLowResolutionLink(feed?._influencer?.profilePicture) ||
                DEFAULT_PROFILE_PICTURE
              }
              onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
              alt="Profile Img"
              className="userImg"
              loading="lazy"
            />
            {/* Online Status */}
            {influencersOnlineData?.influencers?.[feed?._influencer?._id] ? (
              <span className="fa fa-circle onlineStatus" />
            ) : null}
          </div>
          <div>
            {/* <h4>
                  {post._influencer ? post._influencer.name.full : ""}
                </h4> */}
            <div className="profileID">
              @{feed?._influencer?.username || "N/A"}
            </div>
          </div>
        </div>

        <p className="found_you">
          <ConfigureString keyString={"vault"} />
        </p>
      </CardHeader>

      <CardBody>
        <div className="customPaddingMob">
          {feed?.text?.length ? (
            <div>{mentionUserInputParse(feed.text)}</div>
          ) : null}

          <div className="sharedContent sharedContentVault">
            <div className="mediaPhotoWrap-Feed">
              <img
                className="mediaPhoto-Feed"
                src={feed?._folder?.preview || DEFAULT_COVER_PICTURE}
                onError={(e) => (e.target.src = DEFAULT_COVER_PICTURE)}
                alt="Feed Img"
                loading="lazy"
              />
              <div className="shadowOverlayImg" />
              {!feed?._folderPaid || isSubscriptionRequired ? (
                <div className="postLockedOverlay">
                  <div className="feedContentCount">
                    <div>
                      <i className="fa fa-folder-o" />
                      {feed._folder?._contents?.length || 0}
                    </div>
                  </div>

                  <SvgIcons type="lock" className="iconLock" />

                  <Button
                    disabled={!feed?._influencer?.paymentEnabled}
                    className="paymentBtnTier"
                    onClick={() => {
                      if (isSubscriptionRequired) {
                        _subscribe();
                      } else {
                        togglePaymentHoc(true);
                      }
                    }}
                  >
                    {isSubscriptionRequired
                      ? "Subscribe"
                      : `Unlock Now ${formatCurrencyValue(
                          feed?._folder?.price
                        )}`}
                  </Button>
                </div>
              ) : (
                <div className="postLockedOverlay">
                  <Button
                    className="paymentBtnTier"
                    onClick={() => goToPostDetailsPage(isSubscriptionRequired)}
                  >
                    Open Folder
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </CardBody>

      <CardFooter className={"footerFeed"}>
        <div className="d-flex">
          <Button
            className={
              "mr-2 mr-sm-3 " +
              (isSubscriptionRequired ? "disabled-feed-options" : "")
            }
            onClick={() => toggleLikePost()}
          >
            <i className={getLikeIconClassName(feed)} />
          </Button>

          <Button
            className={
              "mr-2 mr-sm-3 " +
              (isSubscriptionRequired ? "disabled-feed-options" : "")
            }
            onClick={() => toggleCommentSection()}
          >
            <i className={getCommentIconClassName(feed)} />
          </Button>

          <Button
            className={
              "mr-2 mr-sm-3 " +
              (isSubscriptionRequired ? "disabled-feed-options" : "")
            }
            onClick={() => toggleFeedTipModal(true)}
            disabled={!feed?._influencer?.paymentEnabled}
          >
            <img src="/assets/img/dollar.png" alt="Dollar" loading="lazy" />
          </Button>

          <Button
            className={
              "ml-auto " +
              (isSubscriptionRequired
                ? feed?.isPublic && feed?.isFree
                  ? ""
                  : "disabled-feed-options"
                : "")
            }
            title="Share"
            onClick={() =>
              copyPostPublicLink({
                influencerUsername: feed?._influencer?.username,
                postId: feed?._id,
              })
            }
          >
            <img
              src="/assets/img/quick-link.png"
              alt="share"
              loading="lazy"
              style={{ verticalAlign: -2 }}
            />
          </Button>

          <Button
            title="Report"
            className={
              "ml-3 ml-sm-3 " +
              (isSubscriptionRequired ? "disabled-feed-options" : "")
            }
            onClick={() => toggleFlagModal(true)}
          >
            <i className="fa fa-flag-o" />
          </Button>
        </div>

        {feed?.likes || feed?.comments ? (
          <div className="mt-1">
            {feed?.likes ? (
              <span>
                {feed?.likes} {feed?.likes > 1 ? "Likes" : "Like"}
              </span>
            ) : null}
            {feed?.comments ? (
              <span>
                {feed?.comments} {feed?.comments > 1 ? "Comments" : "Comment"}
              </span>
            ) : null}
          </div>
        ) : null}

        {/* post time */}
        <div className="postTime-Feed">{formatDate(feed?.startDate)}</div>
      </CardFooter>
    </Card>
  );
};

export default FolderViewer;
