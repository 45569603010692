import React from "react";
import { Button, Card, CardBody, CardHeader, CardFooter } from "reactstrap";
import { DEFAULT_COVER_PICTURE } from "../config";
import {
  deepClone,
  formatDate,
  mentionUserInputParse,
  reactHtmlParser,
  getCommentIconClassName,
  getLowResolutionLink,
  getAspectRatioClass,
  calculateContentTypeForPublic,
  copyPostPublicLink,
} from "../helper-methods";
import { DEFAULT_PROFILE_PICTURE } from "../config/index";
import ReadMoreReadLess from "./custom/ReadMoreReadLess";
import ErrorBoundary from "./ErrorBoundary";
import FeedViewerCarousel from "./FeedViewer/FeedViewerCarousel";
import AudioPlayer from "./custom/AudioPlayer";
import CustomVideoPlayer from "./custom/CustomVideoPlayer";
import { useHistory } from "react-router-dom";
import SvgIcons from "./SvgIcons";

const PublicFeedViewer = ({
  lastElementRef,
  feed,
  subscribe = () => {},
  redirectToPublicPage,
  customClassname,
}) => {
  const history = useHistory();

  const _toogleCommentSection = () => {
    _subscribe();
  };

  const _onLikeBtnClick = () => {
    _subscribe();
  };

  const _subscribe = () => {
    subscribe();
  };

  const _goToPostDetailsPage = () => {
    _subscribe();
  };

  const _redirectToPublicPage = () => {
    history.push(`/${feed?._influencer?.username}`);
  };

  const _getFirstContent = (content, preview = null) => {
    switch (content.contentType || content._content.contentType) {
      case "image": {
        return (
          <div className="mediaPhotoWrap-Feed">
            <img
              className="mediaPhoto-Feed"
              src={content?._content?.url || preview || DEFAULT_COVER_PICTURE}
              onError={(e) => (e.target.src = DEFAULT_COVER_PICTURE)}
              alt="post Cover Img"
              loading="lazy"
            />

            <Button
              className="subscribeInfBtn subscribeInfBtnPublic"
              onClick={() => _subscribe()}
            >
              Subscribe
            </Button>
          </div>
        );
      }

      case "video": {
        return (
          <ErrorBoundary>
            {/* custom video player support hls */}
            <div className="position-relative">
              <CustomVideoPlayer
                className={`${getAspectRatioClass(
                  feed?._contents?.[0]?._content?.aspectRatio
                )}`}
                feedId={content?._id}
                thumbnail={preview || content?._content?.thumbnail}
                src={content?._content?.url}
                hlsLink={content?._content?.hlsLink}
                controls
              />

              <Button
                className="subscribeInfBtn subscribeInfBtnPublic"
                onClick={() => _subscribe()}
              >
                Subscribe
              </Button>
            </div>
          </ErrorBoundary>
        );
      }

      case "audio": {
        return (
          <ErrorBoundary>
            <div className="position-relative">
              <AudioPlayer url={content?._content?.url} />

              <Button
                className="subscribeInfBtn subscribeInfBtnPublic"
                onClick={() => _subscribe()}
              >
                Subscribe
              </Button>
            </div>
          </ErrorBoundary>
        );
      }

      default: {
        return (
          <img
            className="mediaPhoto-Feed"
            src={preview || DEFAULT_COVER_PICTURE}
            onError={(e) => (e.target.src = DEFAULT_COVER_PICTURE)}
            alt="thumbnail"
            loading="lazy"
          />
        );
      }
    }
  };

  const _contentMediaTypeCount = (feed) => {
    const mediaCount = calculateContentTypeForPublic(feed);

    return (
      <>
        {mediaCount?.image ? (
          <div>
            <i
              className="fa fa-image"
              style={{
                verticalAlign: -1,
                marginLeft: 1,
              }}
            />
            <span>{mediaCount?.image}</span>
          </div>
        ) : null}

        {mediaCount?.video ? (
          <div>
            <i className="fa fa-video-camera" />
            <span>{mediaCount?.video}</span>
          </div>
        ) : null}

        {mediaCount?.audio ? (
          <div>
            <i className="fa fa-microphone" style={{ verticalAlign: -1.5 }} />
            <span>{mediaCount?.audio}</span>
          </div>
        ) : null}

        {mediaCount?.folder ? (
          <div>
            <i className="fa fa-folder-o" />
            <span>{mediaCount?.folder}</span>
          </div>
        ) : null}

        {mediaCount?.poll ? (
          <div>
            <i className="fa fa-bar-chart" />
            {/* <span>{mediaCount?.poll}</span> */}
          </div>
        ) : null}
      </>
    );
  };

  const _postLockOverlay = () => {
    return (
      <>
        <div className="shadowOverlayImg" />

        <div className="postLockedOverlay">
          <SvgIcons type="lock" className="iconLock" />

          <div className="feedContentCount">{_contentMediaTypeCount(feed)}</div>

          <Button className="paymentBtnTier" onClick={() => _subscribe()}>
            Subscribe
          </Button>
        </div>
      </>
    );
  };

  const _renderPost = () => {
    let post = deepClone(feed);

    if (
      post.category &&
      (post.category === "general" || post?.category === "goal")
    ) {
      if (post.hasOwnProperty("_folder")) {
        return (
          <>
            <div className="customPaddingMob">
              {post?.text?.length ? (
                <div>{mentionUserInputParse(post.text)}</div>
              ) : null}

              <div className="mediaPhotoWrap-Feed">
                <img
                  className="mediaPhoto-Feed"
                  src={post?._folder?.preview || DEFAULT_COVER_PICTURE}
                  onError={(e) => (e.target.src = DEFAULT_COVER_PICTURE)}
                  alt="Feed Img"
                  loading="lazy"
                />

                {_postLockOverlay()}
              </div>
            </div>
          </>
        );
      } else if (post.hasOwnProperty("_payperview")) {
        return (
          <>
            <div className="customPaddingMob">
              {post?.text?.length ? (
                <div>{mentionUserInputParse(post?.text)}</div>
              ) : null}

              <div className="customPaddingMob">
                {post?._payperview?.title ? (
                  <div className="ppvVideoName">{post?._payperview?.title}</div>
                ) : null}

                {post?._payperview?.description?.length ? (
                  <ReadMoreReadLess
                    className="readMore"
                    text={post._payperview.description}
                  />
                ) : null}
              </div>

              <div className="mediaPhotoWrap-Feed">
                <img
                  className="mediaPhoto-Feed"
                  src={post?._payperview?.coverImage || DEFAULT_COVER_PICTURE}
                  alt="Feed Img"
                  loading="lazy"
                />
                {_postLockOverlay()}
              </div>
            </div>
          </>
        );
      } else if (post.hasOwnProperty("_event")) {
        return (
          <>
            <div className="customPaddingMob">
              {post?.text?.length ? (
                <div>{mentionUserInputParse(post?.text)}</div>
              ) : null}

              <div className="customPaddingMob">
                {post?._event?.name ? (
                  <h5 className="liveStreamEventName">{post._event.name}</h5>
                ) : null}

                {post?._event?.description?.length ? (
                  <ReadMoreReadLess
                    className="readMore"
                    text={post._event.description}
                  />
                ) : null}
              </div>

              <div className="mediaPhotoWrap-Feed">
                <img
                  className="mediaPhoto-Feed"
                  src={post?._event?.coverImage || DEFAULT_COVER_PICTURE}
                  alt="Feed Img"
                  loading="lazy"
                />
                {_postLockOverlay()}
              </div>
            </div>
          </>
        );
      } else if (post?._contents?.length > 1) {
        return (
          <>
            {post?.text?.length ? (
              <div className="customPaddingMob">
                {mentionUserInputParse(post?.text)}
              </div>
            ) : null}

            <div className={"feedCarousel"}>
              {post?.isPublic && post?.isFree ? (
                <ErrorBoundary>
                  {/* multi post carousel */}
                  <FeedViewerCarousel
                    contents={post?._contents}
                    feed={feed}
                    carouselFullScreenMode={false}
                    pictureInPictureMode={false}
                  />
                  <Button
                    className="subscribeInfBtn subscribeInfBtnPublic"
                    onClick={() => _subscribe()}
                  >
                    Subscribe
                  </Button>
                </ErrorBoundary>
              ) : (
                <>
                  <img
                    className="mediaPhoto-Feed"
                    src={post.preview || DEFAULT_COVER_PICTURE}
                    onError={(e) => (e.target.src = DEFAULT_COVER_PICTURE)}
                    alt="Cover Img"
                    loading="lazy"
                  />
                  {_postLockOverlay()}
                </>
              )}
            </div>
          </>
        );
      } else if (post?._contents?.length === 1) {
        return (
          <>
            {post?.text?.length ? (
              <div className="customPaddingMob">
                {mentionUserInputParse(post.text)}
              </div>
            ) : null}

            {post?.isPublic && post?.isFree ? (
              _getFirstContent(post?._contents?.[0], post.preview)
            ) : (
              <div className="mediaPhotoWrap-Feed">
                <img
                  className="mediaPhoto-Feed"
                  src={
                    post?._contents?.[0]?._content?.thumbnail ||
                    post?._contents?.[0]?._content?.preview ||
                    post?.thumbnail ||
                    post?.preview ||
                    DEFAULT_COVER_PICTURE
                  }
                  onError={(e) => (e.target.src = DEFAULT_COVER_PICTURE)}
                  alt="thumbnail"
                  loading="lazy"
                />
                {_postLockOverlay()}
              </div>
            )}
          </>
        );
      } else if (post?._contents?.length === 0) {
        // text only post
        return (
          <>
            {post?.text?.length ? (
              <div className="customPaddingMob">
                {mentionUserInputParse(post.text)}
              </div>
            ) : null}
          </>
        );
      }
    } else if (post?.category && post.category === "poll") {
      return (
        <>
          {post?.description?.length ? (
            <>
              <div className="customPaddingMob">
                <ReadMoreReadLess
                  className="readMore"
                  text={reactHtmlParser(post?.description)}
                />
              </div>
              <br />
            </>
          ) : null}

          {post?.text?.length ? (
            <div className="customPaddingMob">
              {mentionUserInputParse(post?.text)}
            </div>
          ) : null}

          {/* poll options should always be wrapped inside the below div */}
          <div className={"mediaPhotoWrap-Feed postLocked"}>
            <img
              className="mediaPhoto-Feed"
              src={post?._contents?.[0]?.thumbnail || DEFAULT_COVER_PICTURE}
              onError={(e) => (e.target.src = DEFAULT_COVER_PICTURE)}
              alt="Cover Img"
              loading="lazy"
            />

            {_postLockOverlay()}
          </div>
        </>
      );
    }
    return <div />;
  };

  return (
    <div
      {...(lastElementRef ? { ref: lastElementRef } : {})}
      {...(customClassname ? { className: customClassname } : {})}
    >
      <Card className="cardFeed">
        <CardHeader
          onClick={() =>
            redirectToPublicPage
              ? _redirectToPublicPage()
              : _goToPostDetailsPage()
          }
        >
          <div className="d-flex justify-content-start align-items-center">
            <img
              src={
                getLowResolutionLink(feed?._influencer?.profilePicture) ||
                DEFAULT_PROFILE_PICTURE
              }
              onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
              alt="Profile Img"
              className="userImg mr-1"
              loading="lazy"
            />

            <div>
              {/* <h4>{post._influencer ? post._influencer.name.full : ""}</h4> */}
              <Button color="link" className="profileID publicFeedProfileID">
                @{feed?._influencer?.username || "N/A"}
              </Button>
            </div>
          </div>
        </CardHeader>

        <CardBody>{_renderPost()}</CardBody>

        <CardFooter className={"footerFeed "}>
          <div className="d-flex">
            <Button className="mr-2 mr-sm-3" onClick={() => _onLikeBtnClick()}>
              <i className="fa fa-heart-o" />
            </Button>

            <Button
              className="mr-2 mr-sm-3"
              onClick={() => _toogleCommentSection()}
            >
              <i className={getCommentIconClassName(feed)} />
            </Button>

            <Button
              className="ml-auto"
              title="Share"
              onClick={() =>
                copyPostPublicLink({
                  influencerUsername: feed?._influencer?.username,
                  postId: feed?._id,
                })
              }
            >
              <img
                src="/assets/img/quick-link.png"
                alt="share"
                loading="lazy"
                style={{ verticalAlign: -2 }}
              />
            </Button>
          </div>

          {feed?.likes || feed?.comments ? (
            <div className="mt-1">
              {feed.likes ? (
                <span>
                  {feed.likes} {feed.likes > 1 ? "Likes" : "Like"}
                </span>
              ) : null}
              {feed.comments ? (
                <span>
                  {feed.comments} {feed.comments > 1 ? "Comments" : "Comment"}
                </span>
              ) : null}
            </div>
          ) : null}

          {/* post time */}
          <div className="postTime-Feed">{formatDate(feed?.startDate)}</div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default PublicFeedViewer;
