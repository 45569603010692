import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row, Button } from "reactstrap";
import { errorHandler } from "../helper-methods";
import { getPostDetails, givenPollOption } from "../http-calls";
import FeedViewer from "../components/FeedViewer";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import SkeletonLoading from "../components/SkeletonLoading";
import ErrorBoundary from "../components/ErrorBoundary";

const PostDetailsPage = () => {
  const history = useHistory();

  const params = useParams();

  const feedData = useSelector((state) => state?.feedData);

  const [feed, setFeed] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const _fetchPostDetails = async () => {
    try {
      setIsLoading(true);

      const res = await getPostDetails(params?.id);

      setFeed(res.post);
      setIsLoading(false);
    } catch (error) {
      history.push("/feed");
      errorHandler(error);
      setIsLoading(false);
    }
  };

  const _selectPollOption = async (feed, option, index) => {
    try {
      const data = {
        postId: feed._id,
        optionId: option._id,
      };

      const newFeed = { ...feed };
      newFeed._opinions = [{ option: option._id }];
      setFeed(newFeed);

      await givenPollOption(data);

      _fetchPostDetails();
    } catch (error) {
      _fetchPostDetails();
      errorHandler(error);
    }
  };

  const _onCommentPost = (index, isAdded) => {
    try {
      const newFeed = { ...feed };

      let commentsCount = 0;
      if (isAdded) {
        if (feed?.hasOwnProperty("comments")) {
          commentsCount = feed["comments"] + 1;
        } else {
          commentsCount = 1;
        }
      } else {
        if (+feed?.comments) {
          commentsCount = feed["comments"] - 1;
        }
      }
      newFeed["comments"] = commentsCount;
      setFeed(newFeed);
    } catch (error) {
      console.log({ error });
      _fetchPostDetails();
    }
  };

  const _updateCommentsCount = ({ value }) => {
    try {
      const newFeed = feed;
      newFeed.comments = value;
      setFeed(newFeed);
    } catch (error) {
      console.log({ error });
    }
  };

  const _toggleLikePost = async (index, isLiked) => {
    try {
      const newFeed = { ...feed };
      if (isLiked) {
        newFeed._isLiked = 1;
        if (+newFeed.likes) {
          newFeed.likes += 1;
        } else {
          newFeed.likes = 1;
        }
      } else {
        newFeed._isLiked = 0;
        if (+newFeed.likes) {
          newFeed.likes -= 1;
        } else {
          newFeed.likes = 0;
        }
      }
      setFeed(newFeed);
    } catch (error) {
      console.log({ error });
      _fetchPostDetails();
    }
  };

  useEffect(() => {
    _fetchPostDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="justify-content-center noMargin">
          <Col sm={12} lg={10} xl={8} className="noPadding bg-white">
            <div className="pgTitle customMarginMob">
              <Button
                className="customBackBtn"
                onClick={() => history.goBack()}
              >
                <i className="fa fa-chevron-left" />
              </Button>
            </div>
            {feed ? (
              <ErrorBoundary>
                <FeedViewer
                  feed={feed}
                  feedIndex={0}
                  feedBundles={feedData?.bundles}
                  onTierUpgrade={() => _fetchPostDetails()}
                  selectPollOption={(feed, option) =>
                    _selectPollOption(feed, option)
                  }
                  toggleLikePost={(index, isLiked) =>
                    _toggleLikePost(index, isLiked)
                  }
                  onComment={(index, isAdded) => _onCommentPost(index, isAdded)}
                  onPaymentComplete={() => _fetchPostDetails()}
                  updateCommentsCount={({ value }) =>
                    _updateCommentsCount({ value })
                  }
                />
              </ErrorBoundary>
            ) : isLoading ? (
              <SkeletonLoading type={"post"} count={1} />
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PostDetailsPage;
