import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  CustomInput,
} from "reactstrap";
import {
  capitalize,
  errorHandler,
  formatCurrencyValue,
  getLowResolutionLink,
  reactHtmlParser,
  showToast,
} from "../../helper-methods";
import ConfigureString from "../ConfigureString";
import ReadMoreReadLess from "../custom/ReadMoreReadLess";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import { getInfluencerShoutOutPricing } from "../../http-calls";
import { getConfiguredString } from "../../helper-methods/configureString";
import { DescriptionConfig } from "../../config/app-config";

const ChatShoutoutModal = ({
  isOpen,
  shoutoutType,
  toggleChatShoutoutModal,
  influencer,
  createShoutOutRequest,
}) => {
  const [formFields, setFormFields] = useState({
    selectedType: "", //audio, liveAudio, video, live
    duration: "", //predefined values
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [isDirty, setIsDirty] = useState({});
  const [shoutoutInfluencerDetails, setShoutoutInfluencerDetails] =
    useState(null);
  const [filterShoutoutPrices, setFilterShoutoutPrices] = useState([]);
  const [selectedTypePrices, setSelectedTypePrices] = useState([]);
  const [loading, setLoading] = useState(false);

  const _resetModalStates = () => {
    setFormFields({
      selectedType: "",
      duration: "",
      message: "",
    });
    setErrors({});
    setIsDirty({});
    setShoutoutInfluencerDetails([]);
    setSelectedTypePrices([]);
    setLoading(false);
  };

  const _closeModal = () => {
    setLoading(false);
    _resetModalStates();
    toggleChatShoutoutModal();
  };

  const _createShoutOutRequest = async () => {
    try {
      setLoading(true);
      const newIsDirty = await _markAllIsDirty();
      const newFormFields = { ...formFields };
      const isFormValid = await _validateForm({ newFormFields, newIsDirty });

      if (!isFormValid) {
        setLoading(false);
        showToast("Fill Fields correctly", "error");
        return;
      }

      const selectedShoutout = filterShoutoutPrices.find(
        (each) => each.type === formFields?.selectedType
      );

      const selectedShoutoutPrice = selectedShoutout?.prices?.find(
        (each) => each.duration === formFields?.duration
      );

      if (!selectedShoutout && selectedShoutoutPrice) {
        setLoading(false);
        showToast("Please select shoutout", "error");
        return;
      }

      const payload = {
        pricingId: selectedShoutout._id,
        duration: formFields.duration,
        message: `${formFields.message.trim()}`,
        price: selectedShoutoutPrice,
        shoutoutType: formFields.selectedType,
        isForMessage: true,
      };

      createShoutOutRequest(payload);
      _closeModal();
    } catch (error) {
      errorHandler(error);
      _closeModal();
    }
  };

  const _getInfluencerShoutOutPricing = async () => {
    try {
      setLoading(true);
      const res = await getInfluencerShoutOutPricing(influencer?.username);

      if (!res?.shoutOutPricings?.length) {
        errorHandler({
          reason: `Please contact creator to allow you to ${
            shoutoutType ? capitalize(shoutoutType) : null
          } ${getConfiguredString("shoutout")}`,
        });

        _closeModal();
        return;
      }

      // based on shoutout type (audio/video); prices of (audio/video)(message/call) will be filtered
      let filterShoutoutPrices = [];
      if (res?.shoutOutPricings?.length) {
        filterShoutoutPrices = res.shoutOutPricings.filter(
          (each) =>
            (shoutoutType === "audio" &&
              (each.type === "audio" || each.type === "liveaudio")) ||
            (shoutoutType === "video" &&
              (each.type === "video" || each.type === "live"))
        );
      }

      setFilterShoutoutPrices(filterShoutoutPrices);
      setShoutoutInfluencerDetails(res?.influencer);
      setLoading(false);
    } catch (error) {
      errorHandler(error);
      _closeModal();
    }
  };

  const _validateForm = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = {};

      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "message": {
              if (newFormFields?.[key].trim().length) {
                if (
                  newFormFields?.[key].length > DescriptionConfig.minLetter &&
                  newFormFields?.[key].length < DescriptionConfig.maxLetter
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[
                    key
                  ] = `Message should have minimum ${DescriptionConfig.minLetter} characters & maximum ${DescriptionConfig.maxLetter} characters`;
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "selectedType": {
              if (newFormFields?.[key]?.length) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "duration": {
              if (newFormFields?.[key]) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);
      resolve(isFormValid);
    });
  };

  // marking all formfields dirty
  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newIsDirty = { ...isDirty };
      const newFormFields = { ...formFields };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  const _onchangeFormFields = async (key, value) => {
    const newFormFields = { ...formFields };
    const newErrors = { ...errors };
    const newIsDirty = { ...isDirty };

    if (key === "selectedType") {
      newFormFields["duration"] = "";
      newIsDirty["duration"] = false;
      newErrors.duration = null;
      setErrors(newErrors);
    }

    newFormFields[key] = value;
    newIsDirty[key] = true;
    setIsDirty(newIsDirty);
    setFormFields(newFormFields);

    _validateForm({ newFormFields, newIsDirty });
  };

  useEffect(() => {
    if (isOpen) {
      _getInfluencerShoutOutPricing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    const newSelectedTypePrices =
      filterShoutoutPrices?.length && formFields.selectedType
        ? filterShoutoutPrices.find(
            (each) => each.type === formFields.selectedType
          )?.prices
        : null;

    setSelectedTypePrices(newSelectedTypePrices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFields?.selectedType]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalHeader toggle={() => _closeModal()}>
        {shoutoutType ? capitalize(shoutoutType) : null}{" "}
        <ConfigureString keyString={"shoutout"} />{" "}
        {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}
      </ModalHeader>
      <ModalBody>
        <div className="d-flex align-items-center">
          <img
            src={
              getLowResolutionLink(
                shoutoutInfluencerDetails?.shoutOutProfilePicture
              ) || DEFAULT_PROFILE_PICTURE
            }
            onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
            alt="avatar"
            className="img60by60"
            loading="lazy"
          />
          <div className="influencerName mt-0 ml-2">
            <h3>
              @
              {shoutoutInfluencerDetails?.username || influencer?.username
                ? shoutoutInfluencerDetails?.username || influencer?.username
                : "N/A"}
            </h3>
          </div>
        </div>

        {shoutoutInfluencerDetails?.shoutOutDescription?.length ? (
          <div className="bioShoutOut">
            <ReadMoreReadLess
              text={reactHtmlParser(
                shoutoutInfluencerDetails.shoutOutDescription
              )}
            />
          </div>
        ) : null}

        <FormGroup>
          <Label>Type</Label>
          <Input
            type="select"
            disabled={loading}
            value={formFields.selectedType}
            onChange={(e) =>
              _onchangeFormFields("selectedType", e.target.value)
            }
          >
            <option value="">Select type</option>
            {filterShoutoutPrices?.length
              ? filterShoutoutPrices.map((each) => (
                  <option key={each.type} value={each.type}>
                    {shoutoutType === "audio" ? "Voice" : "Video"}{" "}
                    {each.type === "liveaudio" || each.type === "live"
                      ? "call"
                      : "message"}
                  </option>
                ))
              : null}
          </Input>
          {errors?.selectedType ? (
            <div className="form-error">{errors?.selectedType}</div>
          ) : null}
        </FormGroup>

        {/* price radio button */}
        {selectedTypePrices?.length ? (
          <>
            <div className="d-flex justify-content-between mb-3">
              {selectedTypePrices.map(
                (priceObj, index) =>
                  priceObj.price && (
                    <div
                      className="d-flex flex-column align-items-center"
                      key={index}
                    >
                      <CustomInput
                        type="radio"
                        id={`selected_price_radio_${priceObj._id}`}
                        name="customRadio"
                        value={formFields?.duration}
                        onChange={() =>
                          _onchangeFormFields("duration", priceObj.duration)
                        }
                        checked={priceObj.duration === formFields.duration}
                        disabled={loading}
                        label=""
                      />
                      <div className="pricingShoutOutWrap">
                        {shoutoutType === "audio" ? (
                          <i className="fa fa-microphone" />
                        ) : (
                          <i className="fa fa-play-circle" />
                        )}

                        <div className="pricingShoutOut">
                          {formatCurrencyValue(priceObj.price)}
                        </div>
                        <div className="durationShoutOut">
                          {priceObj.duration > 90
                            ? priceObj.duration / 60 + " Mins"
                            : priceObj.duration + " Sec"}
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
            {errors?.duration ? (
              <div className="form-error">{errors?.duration}</div>
            ) : null}
          </>
        ) : null}

        <FormGroup>
          <Label>Message</Label>
          <Input
            type="textarea"
            rows="3"
            placeholder="Enter your message"
            value={formFields.message}
            onChange={(e) => _onchangeFormFields("message", e.target.value)}
            disabled={loading}
          />
          {errors?.message ? (
            <div className="form-error">{errors?.message}</div>
          ) : null}
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button className="modalBtnCancel" onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button
          className="modalBtnSave"
          disabled={loading}
          onClick={() => _createShoutOutRequest()}
        >
          {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null} Send
          Request
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ChatShoutoutModal;
