import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";

const ForgotPasswordSuccess = ({ email, resetSuccessState }) => {
  const history = useHistory();

  const _redirectToLogin = () => {
    history.replace({
      pathname: "/login",
      search: email ? `?email=${email}` : "",
    });
  };

  const _openEmail = () => {
    window.open("https://mail.google.com", "_blank");
    _redirectToLogin();
  };

  return (
    <div className="animated fadeIn authFormWrapper">
      <div className="loginWrapper mt-auto">
        <img
          src="/assets/img/check-email.png"
          alt="Email"
          className="checkEmail"
          loading="lazy"
        />
        <div className="authPgFormWrap">
          <h4 className="mb-3 mt-2">Check your mail</h4>
          <p className="text-center">
            We have sent a password recover instructions to your email.
          </p>

          <Button
            className="themeBtn loginBtn"
            style={{ marginTop: 40 }}
            onClick={() => _openEmail()}
          >
            Open Email App
          </Button>

          <Button
            color="link"
            className="backToLogin"
            style={{ marginTop: 22, maxWidth: "none" }}
            onClick={() => _redirectToLogin()}
          >
            Skip, I'll confirm later
          </Button>
        </div>
      </div>

      <div className="loginFooter justify-content-center">
        <p className="mb-0">
          Did not receive the email? Check your spam filter, or{" "}
        </p>
        <Button
          color="link"
          className="backToLogin p-0 pl-1 m-0 themeColor"
          style={{ maxWidth: "none" }}
          onClick={() => resetSuccessState()}
        >
          try another email address
        </Button>
      </div>
    </div>
  );
};

export default ForgotPasswordSuccess;
