import { createSlice } from "@reduxjs/toolkit";
import { saveLocalFilters, clearAllFilters } from "../actions";

const initialState = {
  filters: {},
};

const localFiltersSlice = createSlice({
  name: "localFilters",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveLocalFilters, (state, action) => {
        const { key, value } = action.payload;
        state.filters[key] = value;
      })

      .addCase(clearAllFilters, (state, action) => {
        state.filters = {};
      });
  },
});

export const localFiltersReducer = localFiltersSlice.reducer;
