import { Button } from "reactstrap";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import { SHOW_SOCIAL_ICON_ONLY, SOCIAL_CREDENTIALS } from "../../config";
import { errorHandler } from "../../helper-methods";

const GoogleLoginComponent = ({ btnText, className, onSuccess, onError }) => {
  return (
    <GoogleOAuthProvider clientId={SOCIAL_CREDENTIALS?.GOOGLE_CLIENT_ID}>
      <MyCustomButton {...{ btnText, className, onSuccess, onError }} />
    </GoogleOAuthProvider>
  );
};

const MyCustomButton = ({ btnText, className, onSuccess }) => {
  const _onSuccess = (response) => {
    onSuccess(response);
  };

  const _onError = (error) => {
    if (error?.details) {
      errorHandler(error.details);
    } else {
      errorHandler(error);
    }
  };

  const _onClickGoogle = useGoogleLogin({
    onSuccess: (response) => _onSuccess(response),
    onError: (error) => _onError(error),
  });

  return (
    <Button
      className={`${
        SHOW_SOCIAL_ICON_ONLY ? "socialBtnIconOnly" : "socialLoginBtn"
      }`}
      onClick={() => _onClickGoogle()}
    >
      {!SHOW_SOCIAL_ICON_ONLY ? btnText ? btnText : <>Continue With</> : null}
      <img src="/assets/img/google-logo.png" alt="Google" loading="lazy" />
    </Button>
  );
};

export default GoogleLoginComponent;
