import React, { useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  FormGroup,
  Label,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { flagPost } from "../../http-calls";
import { errorHandler } from "../../helper-methods";

const ReportPostModal = ({ isVisible, feed, onSuccess, onDismiss }) => {
  const [formFields, setFormFields] = useState({
    reason: "Explicit Content",
    description: "",
  });
  const [errors, setErrors] = useState({});
  const [isDirty, setIsDirty] = useState({});
  const [loading, setLoading] = useState(false);

  const _resetState = () => {
    setLoading(false);
    setFormFields({
      reason: "Explicit Content",
      description: "",
    });
    setErrors({});
    setIsDirty({});
  };

  const _onSuccess = () => {
    // success toast is shown in parent component
    _resetState();
    onSuccess();
  };

  const _onDismiss = () => {
    _resetState();
    onDismiss();
  };

  const _handleOutSideClick = () => {
    if (!loading) {
      _resetState();
      _onDismiss();
    }
  };

  // validate formfields
  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve, reject) => {
      const newErrors = {};
      let isFormValid = true;
      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "reason": {
              break;
            }
            case "description": {
              if (formFields?.reason === "Other") {
                if (formFields?.description?.trim()?.length) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "Please Describe";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = null;
                newIsDirty[key] = false;
              }
              break;
            }
            default:
          }
        }
      });
      setErrors(newErrors);
      setIsDirty(newIsDirty);
      resolve(isFormValid);
    });
  };

  // on fields blur, update their dirty true then validate
  const _onBlurHandler = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = { ...isDirty };
    newIsDirty[key] = true;
    setIsDirty(newIsDirty);

    _validateFormFields({ newFormFields, newIsDirty });
  };

  // update form fiels as it changes
  const _updateFieldValue = (key, value) => {
    const newFormFields = { ...formFields };
    newFormFields[key] = value;
    setFormFields(newFormFields);
  };

  // marking all formfields dirty
  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newIsDirty = { ...isDirty };
      const newFormFields = { ...formFields };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  // submitting post report request
  const _report = async () => {
    try {
      const newIsDirty = await _markAllIsDirty();
      const newFormFields = { ...formFields };
      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        return errorHandler({ reason: "Fill fields correctly" });
      }

      setLoading(true);
      const payload = {
        category: "post",
        id: feed._id,
        subject: formFields?.reason,
        reportedAgainst: feed?._influencer?._id,
      };
      if (formFields?.description?.length) {
        payload["text"] = formFields.description;
      }
      await flagPost(payload);
      _onSuccess();
    } catch (error) {
      setLoading(false);
      errorHandler(error);
      _onDismiss();
    }
  };

  return (
    <Modal
      isOpen={isVisible}
      toggle={() => _handleOutSideClick()}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalHeader toggle={() => _onDismiss()}>
        Mark Post as Inappropriate
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label style={{fontSize: 14, marginBottom: 5}}>Reason</Label>
          <Input
            type="select"
            name="select"
            id="select"
            value={formFields.reason}
            onChange={(e) => _updateFieldValue("reason", e.target.value)}
          >
            <option value="Explicit Content">Explicit Content</option>
            <option value="Hate Speech">Hate Speech</option>
            <option value="Promotes Illegal Activity">
              Promotes Illegal Activity
            </option>
            <option value="Racism">Racism</option>
            <option value="Violence">Violence</option>
            <option value="Other">Other</option>
          </Input>
        </FormGroup>

        <FormGroup style={{marginBottom: 5}}>
          <Label style={{fontSize: 14, marginBottom: 5}}>Describe</Label>
          <Input
            type="textarea"
            className="form-control"
            rows="3"
            value={formFields.description}
            onChange={(e) => _updateFieldValue("description", e.target.value)}
            onBlur={() => _onBlurHandler("description")}
          />
          {errors?.description ? (
            <p className="form-error">{errors?.description}</p>
          ) : null}
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button className="modalBtnCancel" onClick={() => _onDismiss()}>
          Cancel
        </Button>
        <Button
          className="modalBtnSave"
          onClick={() => _report()}
          disabled={loading}
        >
          <span>
            {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
            Report Here
          </span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ReportPostModal;
