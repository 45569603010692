import { checkAndRemoveExtraCharecter } from "../helper-methods";
import { screenshot } from "../http-calls";

export const screenshotDector = (e) => {
  return new Promise(async (resolve, reject) => {
    let pathname = e.view.location.pathname.split("/")[1];

    let urlSplit = e.view.location.href.split("/");
    let idOrUsername = urlSplit[urlSplit.length - 1];

    let apiData = await findPageAndCreateApiData(pathname, idOrUsername);

    if (Object.keys(apiData).length !== 0) {
      try {
        await screenshot(apiData);
        resolve(true)
      } catch (error) {
        resolve(false)
      }
    } resolve(false)
  })
};

const findPageAndCreateApiData = (pathname, idOrUsername) => {
  let apiData = {};

  return new Promise((resolve) => {
    switch (pathname) {
      case "post":
        apiData.type = pathname;
        apiData.id = checkAndRemoveExtraCharecter(idOrUsername, "#");
        break;

      case "vault":
        apiData.type = "folder";
        apiData.id = checkAndRemoveExtraCharecter(idOrUsername, "#");
        break;

      case "message":
        apiData.type = pathname;
        apiData.id = idOrUsername.split("?threadId=")[1];
        break;
      case "influencer":
        apiData.username = checkAndRemoveExtraCharecter(idOrUsername, "#");
        break;

      default:
        break;
    }
    resolve(apiData);
  });
};
