import { STRIPE_API_KEY } from "../config";
import { showToast } from "../helper-methods";

export const cardPayment = (clientSecret, defaultCard) => {
  const stripe = window.Stripe(STRIPE_API_KEY, {
    apiVersion: "2020-08-27",
  });

  return new Promise(async (resolve, reject) => {
    const { paymentIntent, error: confirmError } =
      await stripe.confirmCardPayment(
        clientSecret,
        { payment_method: defaultCard },
        { handleActions: true }
      );

    if (confirmError) {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      console.log("confirmError -->> ", confirmError);
      showToast(
        confirmError && confirmError.message
          ? confirmError.message
          : "Server error",
        "error"
      );
      reject(confirmError);
    } else {
      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.

      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow. If using an API version older than "2019-02-11" instead
      // instead check for: `paymentIntent.status === "requires_source_action"`.
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const { error } = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          console.log("error :>> ", error);
          // The payment failed -- ask your customer for a new payment method.
          reject(error);
        } else {
          // console.log("succeeded :>> 1");
          // console.log("paymentIntent >>", paymentIntent);
          resolve(paymentIntent);
          // The payment has succeeded.
        }
      } else {
        resolve(paymentIntent);
        // The payment has succeeded.
      }
    }
  });
};
