import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  ListGroupItem,
  Col,
  Container,
  Input,
  Row,
  ListGroup,
} from "reactstrap";
import { APP_LOGO, DEFAULT_PROFILE_PICTURE } from "../config";
import WelcomeFanModal from "../components/modals/WelcomeFanModal";
import {
  capitalize,
  errorHandler,
  formatNumberInShort,
  getLowResolutionLink,
  getWindowDimensions,
} from "../helper-methods";
import { CURRENCY_SYMBOL } from "../config";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { searchInluencersAsync } from "../redux/actions/searchInfluencers";
import SkeletonLoading from "../components/SkeletonLoading";

const SearchInfluencers = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [screenWidth, setScreenWidth] = useState(0);

  const {
    isLoading,
    error,
    hasMore,
    searchResult: influencers,
  } = useSelector((state) => state?.searchInfluencer || {});

  const [payload, setPayload] = useState({
    search: "",
    skip: 0,
    limit: 20,
  });

  const debouncingTimer = useRef();

  const _getCarouselMediaForCover = useCallback((influencer) => {
    const newCarousel = {};

    if (!influencer?.carouselMediaForCover?.url) {
      return newCarousel;
    }

    newCarousel.url = influencer?.carouselMediaForCover?.url;

    const carouselCrop = influencer?.carouselCrop;

    if (carouselCrop?.length) {
      const findCropObj = carouselCrop?.find(
        (each) => each.carouselIndex === 0 || each.carouselIndex === 1
      );

      if (findCropObj) {
        newCarousel.crop = findCropObj;
      }
    }

    return newCarousel;
  }, []);

  const _searchInfluencers = (payload) => {
    dispatch(searchInluencersAsync(payload));
  };

  const observer = useRef();

  const _lastElementRef = useCallback(
    (node) => {
      if (isLoading) {
        return;
      }

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          const newPayload = { ...payload };
          newPayload.skip = influencers.length;
          setPayload(newPayload);
          _searchInfluencers(newPayload);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, hasMore]
  );

  const _onSearchInputUpdate = (searchQuery = "") => {
    if (debouncingTimer.current) {
      clearTimeout(debouncingTimer.current);
    }

    const newPayload = { ...payload };
    newPayload.skip = 0;
    newPayload.search = searchQuery;
    setPayload(newPayload);

    debouncingTimer.current = setTimeout(() => {
      _searchInfluencers(newPayload);
    }, 750);
  };

  const _clearSearchText = () => {
    const newPayload = { ...payload };
    newPayload.skip = 0;
    newPayload.limit = 20;
    newPayload.search = "";
    setPayload(newPayload);
    _searchInfluencers(newPayload);
  };

  const redirectToInfluencerProfilePage = (influencer) => {
    history.push(`/influencer/${influencer.username}`);
  };

  const _changeWidth = () => {
    const { screenWidth } = getWindowDimensions();
    if (screenWidth <= 1000) {
      setScreenWidth(screenWidth);
    }
  };

  useEffect(() => {
    _searchInfluencers(payload);

    _changeWidth();

    window.addEventListener("resize", _changeWidth, true);

    return () => {
      window.removeEventListener("resize", _changeWidth, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="justify-content-center noMargin">
          <Col
            xs="12"
            md="10"
            lg="8"
            xl="7"
            className="noPadding bg-white pb-2"
          >
            <div className="pgTitle customMarginMob justify-content-start">
              <Button
                className="customBackBtn"
                onClick={() => history.goBack()}
              >
                <i className="fa fa-chevron-left" />
              </Button>
              <h2>Search</h2>
            </div>

            <div className="searchChatListWrap searchCreators">
              {/* <i className="fa fa-search searchIcon" /> */}
              <Input
                type="text"
                value={payload.search}
                placeholder="Try Creator's Name/Profession/URL or Keywords"
                onChange={(e) => _onSearchInputUpdate(e.target.value)}
                style={{ paddingLeft: 10 }}
              />
              {payload?.search?.length ? (
                isLoading ? (
                  <div className="spinnerLogin">
                    <i className="fa fa-spinner fa-spin mr-1" />
                  </div>
                ) : (
                  <i
                    className="fa fa-times clearSearch cursorPointer"
                    onClick={() => _clearSearchText()}
                  />
                )
              ) : null}
            </div>

            {/* creator category */}
            <div className="creatorCategory">
              {/* <ButtonToggle>Male</ButtonToggle>
              <ButtonToggle>Female</ButtonToggle>
              <ButtonToggle>Fitness</ButtonToggle>
              <ButtonToggle>Non-Profit</ButtonToggle>
              <ButtonToggle>Business</ButtonToggle>
              <ButtonToggle>Entrepreneur</ButtonToggle>
              <ButtonToggle>Health & Wellness</ButtonToggle>
              <ButtonToggle>Gaming</ButtonToggle>
              <ButtonToggle>Travel</ButtonToggle>
              <ButtonToggle>Art</ButtonToggle>
              <ButtonToggle>Dance</ButtonToggle>
              <ButtonToggle>Fashion</ButtonToggle>
              <ButtonToggle>Music</ButtonToggle>
              <ButtonToggle>Humor</ButtonToggle>
              <ButtonToggle>Education</ButtonToggle>
              <ButtonToggle>Inspiration</ButtonToggle>
              <ButtonToggle>Model</ButtonToggle> */}
            </div>

            {influencers?.length ? (
              <>
                <ListGroup>
                  {influencers.map((influencer, influencerIndex) => (
                    <Fragment key={`${influencer?._id}_${influencerIndex}`}>
                      {influencerIndex === 0 ? (
                        <p className="searchResultsTitle">Suggested</p>
                      ) : null}
                      {influencerIndex === 2 ? (
                        <p className="searchResultsTitle">Top Creators</p>
                      ) : null}
                      <div
                        {...(screenWidth <= 575.98
                          ? {
                              style: {
                                width: screenWidth,
                                height: screenWidth / 3,
                              },
                            }
                          : {})}
                        className="searchResultsNewWrap"
                      >
                        <div
                          className="searchResultsNew"
                          ref={
                            influencerIndex === influencers.length - 1
                              ? _lastElementRef
                              : null
                          }
                        >
                          {_getCarouselMediaForCover(influencer)?.url ? (
                            <div className="imgCropWrap">
                              <img
                                src={_getCarouselMediaForCover(influencer)?.url}
                                alt="cover"
                                onError={(e) => (e.target.src = APP_LOGO)}
                                className={
                                  "infCoverImg " +
                                    _getCarouselMediaForCover(influencer)?.crop
                                      ?.height ===
                                  100
                                    ? "widthAuto"
                                    : "heightAuto"
                                }
                                style={{
                                  transform: `translate(-${
                                    (_getCarouselMediaForCover(influencer)?.crop
                                      ?.x ||
                                    _getCarouselMediaForCover(influencer)?.crop
                                      ?.x === 0
                                      ? _getCarouselMediaForCover(influencer)
                                          ?.crop?.x
                                      : 0) + "%"
                                  }, -${
                                    (_getCarouselMediaForCover(influencer)?.crop
                                      ?.y ||
                                    _getCarouselMediaForCover(influencer)?.crop
                                      ?.y === 0
                                      ? _getCarouselMediaForCover(influencer)
                                          ?.crop?.y
                                      : 0) + "%"
                                  })`,
                                }}
                              />
                            </div>
                          ) : (
                            <div className="defaultCoverImg">
                              <img src={APP_LOGO} alt="cover" />
                            </div>
                          )}

                          <div className="searchResultsContentNew">
                            <div className="d-flex justify-content-between">
                              <p>
                                {influencer?.subscriptionFees?.amount
                                  ? `${CURRENCY_SYMBOL}${influencer.subscriptionFees.amount}`
                                  : ""}
                              </p>
                              <Button
                                color="link"
                                onClick={() =>
                                  redirectToInfluencerProfilePage(influencer)
                                }
                              >
                                {influencer.isSubscribed
                                  ? influencer.isExpired
                                    ? "Renew Subscription"
                                    : "View Profile"
                                  : "Subscribe"}
                              </Button>
                            </div>

                            <div className="d-flex align-items-center">
                              <img
                                src={
                                  getLowResolutionLink(
                                    influencer?.profilePicture
                                  ) || DEFAULT_PROFILE_PICTURE
                                }
                                onError={(e) =>
                                  (e.target.src = DEFAULT_PROFILE_PICTURE)
                                }
                                alt="Profile"
                              />

                              <div className="ml-2 ml-sm-3">
                                <h4>{capitalize(influencer?.name?.first)}</h4>
                                <Button
                                  color="link"
                                  onClick={() =>
                                    redirectToInfluencerProfilePage(influencer)
                                  }
                                >
                                  @{influencer.username}
                                </Button>
                              </div>
                            </div>

                            <ListGroup horizontal>
                              {influencer?.address?.country && (
                                <ListGroupItem>
                                  {influencer?.address?.country}
                                </ListGroupItem>
                              )}
                              <ListGroupItem>
                                {formatNumberInShort(
                                  influencer?.count?.photos || 0
                                )}{" "}
                                {influencer?.count?.photos > 1
                                  ? "Photos"
                                  : "Photo"}
                              </ListGroupItem>
                              {!influencer?.address?.country && (
                                <ListGroupItem>
                                  {formatNumberInShort(
                                    influencer?.count?.videos || 0
                                  )}{" "}
                                  {influencer?.count?.videos > 1
                                    ? "Videos"
                                    : "Video"}
                                </ListGroupItem>
                              )}
                              <ListGroupItem>
                                {formatNumberInShort(
                                  influencer?.count?.likes || 0
                                )}{" "}
                                {influencer?.count?.likes > 1
                                  ? "Likes"
                                  : "Like"}
                              </ListGroupItem>
                              <ListGroupItem>
                                {formatNumberInShort(
                                  influencer?.count?.subscriptions || 0
                                )}{" "}
                                Fanz
                              </ListGroupItem>
                            </ListGroup>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  ))}
                </ListGroup>
              </>
            ) : isLoading ? (
              <SkeletonLoading type="searchInfluencers" />
            ) : payload?.search?.length ? (
              <h4 className="noContentFound mb-3">No creator found!!</h4>
            ) : (
              <h4 className="noContentFound mb-3">
                Search for creators by keyword, name or category
              </h4>
            )}

            {isLoading && hasMore ? (
              <SkeletonLoading type="searchInfluencers" loadMore={true} />
            ) : null}
          </Col>
        </Row>
      </Container>

      {/* welcome fan modal */}
      <WelcomeFanModal />
    </div>
  );
};

export default SearchInfluencers;
