import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Label,
  Button,
} from "reactstrap";
import { getFolderDetails, getFolderContents } from "../http-calls";
import { errorHandler, reactHtmlParser } from "../helper-methods";
import FolderTipModal from "../components/modals/FolderTipModal";
import ConfigureString from "../components/ConfigureString";
import { useHistory, useParams } from "react-router-dom";
import CustomLoader from "../components/custom/CustomLoader";
import VaultContentCard from "../components/VaultContentCard";
import ErrorBoundary from "../components/ErrorBoundary";
import SkeletonLoading from "../components/SkeletonLoading";
import ReadMoreReadLess from "../components/custom/ReadMoreReadLess";
import ImageQualitySetting from "../components/ImageQualitySetting";

const VaultDetailsPage = () => {
  const history = useHistory();
  const params = useParams();

  const observer = useRef();

  const [folder, setFolder] = useState(null);
  const [folderContents, setFolderContents] = useState([]);
  const [folderContentsTotal, setFolderContentsTotal] = useState(0);
  const [folderContentsPayload, setFolderContentsPayload] = useState({
    skip: 0,
    limit: 20,
  });
  const [loadingState, setLoadingState] = useState({
    folder: false,
    contents: false,
  });
  const [folderTipModal, setFolderTipModal] = useState({
    isOpen: false,
    content: null,
  });

  const [imageQuality, setImageQuality] = useState("oneZeroEightZero");

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _getFolderDetails = () => {
    _manageLoadingState("folder", true);

    getFolderDetails(params?.id)
      .then((res) => {
        setFolder(res?.folder);
        _manageLoadingState("folder", false);
      })
      .catch((error) => {
        errorHandler(error);
        history.push({
          pathname: "/vault",
          query: { id: params?.id },
        });
        _manageLoadingState("folder", false);
      });
  };

  const _getFolderContents = async (payload) => {
    try {
      _manageLoadingState("contents", true);

      const res = await getFolderContents(params?.id, payload);

      if (!res?.contents) {
        _manageLoadingState("contents", false);
        return;
      }

      let finalContents = [];
      if (payload.skip > 0) {
        if (folderContents?.length) {
          finalContents = folderContents.concat(res.contents);
        }
      } else {
        finalContents = res?.contents;
      }

      setFolderContents(finalContents);
      setFolderContentsTotal(res.count);
    } catch (error) {
      errorHandler(error);
    } finally {
      _manageLoadingState("contents", false);
    }
  };

  const lastElementRef = useCallback(
    (node) => {
      if (loadingState?.contents) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          folderContents?.length < folderContentsTotal
        ) {
          const newFolderContentsPayload = { ...folderContentsPayload };
          newFolderContentsPayload.skip = folderContents?.length || 0;
          setFolderContentsPayload(newFolderContentsPayload);
          _getFolderContents(newFolderContentsPayload);
        }
      });

      if (node) observer.current.observe(node);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingState?.contents, folderContents]
  );

  const _toggleFolderTipModal = (isOpen = false, content = null) => {
    setFolderTipModal({
      isOpen,
      content,
    });
  };

  useEffect(() => {
    _getFolderDetails();
    _getFolderContents(folderContentsPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="noMargin mb-2">
          <Col xs="12" className="pgPadding">
            <div className="pgTitle">
              <Breadcrumb className="customBreadcrumb">
                <BreadcrumbItem>
                  {/* back btn */}
                  <Button
                    className="customBackBtn"
                    onClick={() => history.goBack()}
                  >
                    <i className="fa fa-chevron-left" />
                  </Button>
                  <h2>
                    <ConfigureString keyString={"The vault"} />
                  </h2>
                </BreadcrumbItem>
                {folder?.name && (
                  <BreadcrumbItem active>{folder.name}</BreadcrumbItem>
                )}
                {loadingState.folder && <CustomLoader className="ml-2" />}
              </Breadcrumb>

              {/* folder tip btn. There is an another tip btn at the bottom of the page(it is for below tab view) */}
              <Button
                className="themeBtn addBtn d-none d-md-inline-block"
                disabled={loadingState.folder}
                onClick={() => _toggleFolderTipModal(true)}
              >
                Tip
              </Button>
            </div>

            {folder?.description?.length ? (
              <div className="creatorInfoVault">
                <Label>Folder Details</Label>
                <ReadMoreReadLess
                  count={50}
                  text={reactHtmlParser(folder.description)}
                />
              </div>
            ) : null}

            <div className="vaultFolderDetailsWrap">
              <div className="d-flex justify-content-between my-3 align-items-center">
                <h4 className="filesTxt">Files</h4>

                <ImageQualitySetting
                  imageQuality={imageQuality}
                  setImageQuality={setImageQuality}
                />
              </div>

              {folderContents?.length ? (
                <Row className="vaultFolderRow">
                  {folderContents.map((content, index) => (
                    <Fragment key={content._id}>
                      <ErrorBoundary>
                        <VaultContentCard
                          content={content}
                          contentIndex={index}
                          folderContents={folderContents}
                          loading={loadingState.contents}
                          toggleFolderTipModal={_toggleFolderTipModal}
                          {...(index === folderContents.length - 1
                            ? { lastElementRef }
                            : {})}
                          imageQuality={imageQuality}
                          setImageQuality={setImageQuality}
                        />
                      </ErrorBoundary>
                    </Fragment>
                  ))}
                </Row>
              ) : loadingState.contents ? (
                <Row className="vaultFolderRow">
                  <SkeletonLoading type={"vaultContentCard"} count={4} />
                </Row>
              ) : (
                <div className="noContentFound">
                  <ConfigureString keyString={"No vault found"} />
                </div>
              )}
            </div>

            {/* float folder tip btn */}
            <Button
              className="tipVault"
              onClick={() => _toggleFolderTipModal(true)}
            >
              <img src="/assets/img/tip.png" alt="Tip" loading="lazy" />
            </Button>
          </Col>
        </Row>
      </Container>

      <FolderTipModal
        isOpen={folderTipModal.isOpen}
        content={folderTipModal.content}
        toggle={() => _toggleFolderTipModal()}
        folder={folder}
        influencer={folder?._owner}
      />
    </div>
  );
};

export default VaultDetailsPage;
