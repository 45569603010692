import React, { useState } from "react";
import { useRef } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import InjectedStripeElement from "../StripeElement";

const AddCardModal = ({ isOpen, toggle, onCardAdded }) => {
  const addCardRef = useRef({ current: null });

  const [loading, setLoading] = useState(false);

  const _closeModal = () => {
    setLoading(false);
    toggle();
  };

  const _showLoader = () => {
    setLoading(true);
  };

  const _hideLoader = () => {
    setLoading(false);
  };

  const _addCard = () => {
    try {
      addCardRef.current();
    } catch (error) {
      console.log({ error });
    }
  };

  const _onCardAdded = (payload) => {
    onCardAdded(payload);
    _closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalHeader toggle={() => _closeModal()}>Add Card</ModalHeader>
      <ModalBody>
        <div className="stripeWrapper" style={{marginBottom: 10}}>
          <InjectedStripeElement
            onCardAdded={(payload) => _onCardAdded(payload)}
            onStripeError={(error) => console.log({ error })}
            showLoader={_showLoader}
            hideLoader={_hideLoader}
            addCard={(ref) => (addCardRef.current = ref)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={loading}
          className="modalBtnCancel"
          onClick={() => _closeModal()}
        >
          Cancel
        </Button>
        <Button
          className="modalBtnSave"
          disabled={loading}
          onClick={() => _addCard()}
        >
          {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null} Add
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddCardModal;
