import React, { useState, useEffect } from "react";
import { Button, UncontrolledCollapse } from "reactstrap";
import { fetchEvent, getLiveEventStreamingData } from "../http-calls";
import TextareaAutosize from "react-textarea-autosize";
import { DEFAULT_PROFILE_PICTURE } from "../config";
import {
  showToast,
  capitalize,
  canSubscriberJoin,
  formatCurrencyValue,
  formatTimeFromNow,
  errorHandler,
  getLowResolutionLink,
} from "../helper-methods";
import TipSetterModal from "../components/modals/TipSetterModal";
import ProcessPayment from "../payment/index";
import { updateOnlyUserData } from "../redux/actions/userData";
import cuid from "cuid";
import { RegexConfig } from "../config/RegexConfig";
import StickerConfirmationModal from "../components/modals/StickerConfirmationModal";
import { newSocket } from "../socket-io";
import { autoHeightStyleComment } from "../assets/styles/js";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import SkeletonLoading from "../components/SkeletonLoading";
import { AudienceStreaming } from "../components/LiveStreaming";
import ErrorBoundary from "../components/ErrorBoundary";

const LiveEventStreamingPage = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const params = useParams();

  const userData = useSelector((state) => state?.userData);

  const [eventData, setEventData] = useState(null);
  const [eventStreamingData, setEventStreamingData] = useState(null);
  const [receiveNewMessage, setReceiveNewMessage] = useState(null);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [stickers, setStickers] = useState([]);
  const [tipSetterModal, setTipSetterModal] = useState({
    isOpen: false,
  });
  const [stickerConfirmationModal, setStickerConfirmationModal] = useState({
    isOpen: false,
    sticker: null,
  });

  const _getLiveEventStreamingData = async () => {
    try {
      const res = await getLiveEventStreamingData(params?.id);

      /**
       *  eventStreamingData = {
       *    appId
       *    roomId
       *    token
       *    userName
       *    userRole
       *  }
       */
      setEventStreamingData(res?.response);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _fetchEvent = async () => {
    try {
      const res = await fetchEvent(params?.id);

      setEventData(res.event);
      setComments(res.liveComments);
      setStickers(res.event?._influencer?.stickers);

      if (canSubscriberJoin(res.event)) {
        _subscribeToThreadChannel(res.event);
        _getLiveEventStreamingData();
      } else {
        history.push(`/live-events/${res.event?._id}`);
        showToast("Please complete payment");
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const _postComment = (newComment = "", isTip = false) => {
    let message = {
      _from: userData?.user?.id,
      _event: eventData?._id,
      tempCommentId: cuid(),
      _formData: {
        profilePicture:
          userData?.user?.profilePicture || DEFAULT_PROFILE_PICTURE,
        name: userData?.user?.name?.full
          ? userData.user.name.full
          : `@${userData?.user?.username}`,
      },
      text: newComment.length ? newComment : comment.length ? comment : "",
      when: new Date(),
      isTip: isTip,
    };

    setComment("");

    _publishMessageOnChannel(message);
  };

  const _onTipPaymentSuccess = (tip, sticker = null) => {
    // Payment successful
    if (sticker?.url) {
      _postComment(
        `{"STICKER":"${sticker?.url}", "PRICE":"${sticker?.price}"}`,
        true
      );
    } else {
      _postComment(
        `${userData.user.name.full} tipped ${formatCurrencyValue(tip)}`,
        true
      );
    }
  };

  const _onAmountUpdate = async (newAmount, sticker = null) => {
    _toggleStickerConfirmationModal();

    const tip = Number(newAmount).toFixed(2);

    const tipPaymentPayload = {
      url3DSecure: "/payment/intent/tip",
      url: "/payment/tip",
      influencer: eventData?._influencer,
      payload: {
        influencerId: eventData?._influencer?._id,
        amount: Number(tip),
      },
    };

    try {
      await ProcessPayment.startPaymentProcess(tipPaymentPayload, Number(tip));

      _onTipPaymentSuccess(tip, sticker);
    } catch (error) {
      if (error && error.isRiskAssessment) {
        dispatch(
          updateOnlyUserData({
            ...userData.user,
            isRiskAssessment: error.isRiskAssessment,
          })
        );
      }
      errorHandler(error);
    }
  };

  const _toggleTipSetterModal = (isOpen = false) => {
    setTipSetterModal({
      tipSetterModal: { isOpen },
    });
  };

  const _appendReceiveMessage = (newMessage) => {
    const newComments = [...comments];
    newComments.unshift(newMessage);
    setComments(newComments);
  };

  useEffect(() => {
    if (receiveNewMessage) {
      _appendReceiveMessage(receiveNewMessage);
      setReceiveNewMessage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiveNewMessage]);

  const _subscribeToThreadChannel = (eventData) => {
    try {
      if (eventData?._id) {
        const params = { room: eventData._id };
        newSocket.emit("subscribe", params, function (res) {
          console.log("subscribed>>", res);
          if (res.error) {
            errorHandler(res);
          }
        });

        newSocket.on("neweventcomment", (res) => {
          console.log("receive>>", res);
          if (res.error) {
            errorHandler(res);
          } else {
            setReceiveNewMessage(res);
          }
        });
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const _publishMessageOnChannel = (comment) => {
    try {
      newSocket.emit("neweventcomment", comment, (res) => {
        console.log("send>>", res);
        if (res.error) {
          errorHandler(res);
        }
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const _unsubscribeToThreadChannel = () => {
    try {
      if (eventData?._id) {
        const params = { room: eventData._id };
        // remove all callback of neweventcomment event
        newSocket.removeAllListeners("neweventcomment");
        // unsubscribe event - pause callback
        newSocket.emit("unsubscribe", params, function (res) {
          console.log("unsubscribed>>", res);
          if (res.error) {
            console.log("error>>", res.error);
          }
        });
      }
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _updateComment = (value, isEmoji = false) => {
    if (isEmoji) {
      setComment((prev) => prev + value);
    } else {
      setComment(value);
    }
  };

  const _onEnterPressed = (e) => {
    const code = e.keyCode || e.which;

    if (code === 13 && !e.shiftKey && !e.ctrlKey) {
      if (e) e.preventDefault();

      if (comment?.trim()?.length) {
        _postComment();
      }
    }
  };

  const _renderComment = (comment, isTip = false) => {
    let parsedComment = "";

    if (comment.includes("STICKER") && comment.includes("PRICE") && isTip) {
      try {
        const sticker = JSON.parse(comment);
        if (RegexConfig.url.test(sticker.STICKER)) {
          return (
            <div>
              <pre>
                <img
                  src={sticker.STICKER}
                  alt="Sticker"
                  className="stickerLiveStreaming"
                  loading="lazy"
                />
              </pre>
              {sticker?.PRICE
                ? `${formatCurrencyValue(sticker?.PRICE)} Paid`
                : null}
            </div>
          );
        }
      } catch (error) {}
    }

    switch (comment) {
      case "LIKE_ICON":
        parsedComment = (
          <pre>
            <img
              src={"/assets/img/like-icon.png"}
              alt="Emoji"
              className="emojiImgAsComment-liveStreaming"
              loading="lazy"
            />
          </pre>
        );
        break;
      case "HUG_ICON":
        parsedComment = (
          <pre>
            <img
              src={"/assets/img/hug-icon.png"}
              alt="Emoji"
              className="emojiImgAsComment-liveStreaming"
              loading="lazy"
            />
          </pre>
        );
        break;
      case "KISS_ICON":
        parsedComment = (
          <pre>
            <img
              src={"/assets/img/kiss-icon.png"}
              alt="Emoji"
              className="emojiImgAsComment-liveStreaming"
              loading="lazy"
            />
          </pre>
        );
        break;
      case "HEART_ICON":
        parsedComment = (
          <pre>
            <img
              src={"/assets/img/heart-icon.png"}
              alt="Emoji"
              className="emojiImgAsComment-liveStreaming"
              loading="lazy"
            />
          </pre>
        );
        break;
      default:
        parsedComment = comment;
        break;
    }
    return parsedComment;
  };

  const _toggleStickerConfirmationModal = (isOpen = false, sticker = null) => {
    setStickerConfirmationModal({
      isOpen,
      sticker,
    });
  };

  useEffect(() => {
    _fetchEvent();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  useEffect(() => {
    return () => {
      _unsubscribeToThreadChannel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="customPgHeight animated fadeIn">
        <div className="liveEventStreamingWrap">
          {/* video streaming here */}
          {eventStreamingData ? (
            <ErrorBoundary>
              <AudienceStreaming eventStreamingData={eventStreamingData} />
            </ErrorBoundary>
          ) : (
            <div className="myCallContainer">
              <SkeletonLoading
                type="box"
                count={1}
                height={"100%"}
                width={"100%"}
                borderRadius={0}
              />
            </div>
          )}

          {/* comment section */}
          <UncontrolledCollapse
            toggler="#toggler"
            className="liveStreamCommentWrap"
          >
            <div className="commentSectionWrap-liveStream">
              {eventData ? (
                <>
                  <div className="liveStreamBrief">
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          getLowResolutionLink(
                            eventData?._influencer?.profilePicture
                          ) || DEFAULT_PROFILE_PICTURE
                        }
                        onError={(e) =>
                          (e.target.src = DEFAULT_PROFILE_PICTURE)
                        }
                        alt="Profile"
                        className="userImg"
                        loading="lazy"
                      />

                      <p className="creatorName-liveStream">
                        <span>
                          {eventData?._influencer?.username
                            ? `@${eventData._influencer.username}`
                            : "N/A"}
                        </span>{" "}
                        is live now
                      </p>
                    </div>

                    {/* live event name */}
                    <div className="liveStreamName">
                      {eventData?.name || "N/A"}
                    </div>
                  </div>

                  <div className="commentSection comment-LiveStreamEvent">
                    {comments?.length ? (
                      React.Children.toArray(
                        comments.map((comment) => (
                          <div className="d-flex" style={{ marginTop: 12 }}>
                            <img
                              src={
                                getLowResolutionLink(
                                  comment?._formData?.profilePicture
                                ) || DEFAULT_PROFILE_PICTURE
                              }
                              onError={(e) =>
                                (e.target.src = DEFAULT_PROFILE_PICTURE)
                              }
                              alt="Profile"
                              className="userImg-Comment"
                              loading="lazy"
                            />
                            <div>
                              <div
                                className="d-flex align-items-center"
                                style={{ marginBottom: 2 }}
                              >
                                <p className="userName-Comment text-white">
                                  {capitalize(comment?._formData?.name)}
                                </p>
                                <div className="commentTime text-white">
                                  {formatTimeFromNow(comment?.when)}
                                </div>
                              </div>
                              <div className="comments-Post">
                                {_renderComment(comment?.text, comment?.isTip)}
                              </div>
                            </div>
                          </div>
                        ))
                      )
                    ) : (
                      <p className="noComments">No comments yet</p>
                    )}
                  </div>

                  <div className="commentArea-liveStream">
                    <div className="d-flex position-relative">
                      <img
                        src={
                          getLowResolutionLink(
                            userData?.user?.profilePicture
                          ) || DEFAULT_PROFILE_PICTURE
                        }
                        onError={(e) =>
                          (e.target.src = DEFAULT_PROFILE_PICTURE)
                        }
                        alt="Profile"
                        className="userImg-Comment"
                        loading="lazy"
                      />

                      <TextareaAutosize
                        minRows="1"
                        style={autoHeightStyleComment}
                        placeholder="Write a message..."
                        onChange={(e) => _updateComment(e.target.value)}
                        onKeyPress={(e) => _onEnterPressed(e)}
                        value={comment}
                      />

                      <Button
                        className="sendMsg"
                        style={{ bottom: 7 }}
                        disabled={!comment.trim().length}
                        onClick={() => _postComment()}
                      >
                        <img
                          src="/assets/img/send.png"
                          alt="Send"
                          loading="lazy"
                        />
                      </Button>
                    </div>
                    <div className="liveStreamingBtnOptions">
                      <Button
                        color="link"
                        onClick={() => _postComment("LIKE_ICON")}
                      >
                        <img
                          src={"/assets/img/like-icon.png"}
                          alt="Emoji"
                          loading="lazy"
                        />
                      </Button>
                      <Button
                        color="link"
                        onClick={() => _postComment("HEART_ICON")}
                      >
                        <img
                          src={"/assets/img/heart-icon.png"}
                          alt="Emoji"
                          loading="lazy"
                        />
                      </Button>
                      <Button
                        color="link"
                        onClick={() => _postComment("HUG_ICON")}
                      >
                        <img
                          src={"/assets/img/hug-icon.png"}
                          alt="Emoji"
                          loading="lazy"
                        />
                      </Button>
                      <Button
                        color="link"
                        onClick={() => _postComment("KISS_ICON")}
                      >
                        <img
                          src={"/assets/img/kiss-icon.png"}
                          alt="Emoji"
                          loading="lazy"
                        />
                      </Button>
                      <Button
                        color="link"
                        onClick={() => _toggleTipSetterModal(true)}
                      >
                        <img
                          src={"/assets/img/dollar-livestream.png"}
                          alt="Dollar"
                          loading="lazy"
                        />
                      </Button>
                    </div>

                    {stickers?.length ? (
                      <div className="stickerOptions">
                        {stickers.map(
                          (sticker) =>
                            sticker &&
                            sticker.isActive &&
                            sticker.url &&
                            sticker.price && (
                              <Button
                                key={sticker._id}
                                color="link"
                                onClick={() =>
                                  _toggleStickerConfirmationModal(true, sticker)
                                }
                              >
                                <img
                                  src={sticker.url}
                                  alt="Sticker"
                                  className="stickerImg"
                                  loading="lazy"
                                />
                              </Button>
                            )
                        )}
                      </div>
                    ) : null}
                  </div>
                </>
              ) : (
                <SkeletonLoading type="liveStreamComment" />
              )}
            </div>
          </UncontrolledCollapse>
          {/* comment section toggle button */}
          <Button id="toggler" className="liveStreamCommentToggle">
            <i className="fa fa-comment-o" />
          </Button>
        </div>
      </div>

      <TipSetterModal
        isOpen={tipSetterModal.isOpen}
        influencer={eventData?._influencer}
        toggle={() => _toggleTipSetterModal()}
        onPaymentSuccess={_onTipPaymentSuccess}
      />

      <StickerConfirmationModal
        influencer={eventData?._influencer}
        isOpen={stickerConfirmationModal.isOpen}
        sticker={stickerConfirmationModal.sticker}
        onSuccess={(sticker) => _onAmountUpdate(sticker?.price, sticker)}
        onCancel={() => _toggleStickerConfirmationModal()}
      />
    </>
  );
};

export default LiveEventStreamingPage;
