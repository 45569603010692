export const RegexConfig = {
  name: /^[A-Za-z][\w!@#$%^&*()_+{}[\]:;"'<>,.?/|`~\s-]*$/, //for variables name type convention
  phone: /^(\+\d{1,3}[- ]?)?\d{8,11}$/,
  email:
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  url: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\\+\\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\\+\\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\\+~%\\/.\w-_]*)?\??(?:[-\\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
  creditCards: {
    visaRegEx: /^(?:4[0-9]{12}(?:[0-9]{3})?)$/,
    mastercardRegEx: /^(?:5[1-5][0-9]{14})$/,
    amexpRegEx: /^(?:3[47][0-9]{13})$/,
    discovRegEx: /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/,
  },
  sixDigitIndianPinCode: /^[1-9][0-9]{5}$/,
  password: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  username: /^[a-z0-9_-]{3,16}$/,
};
