import React, { useState } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import VisibilitySensor from "react-visibility-sensor";
import { Button, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { DEFAULT_COVER_PICTURE, DEFAULT_PROFILE_PICTURE } from "../../config";
import {
  copyPostPublicLink,
  formatDate,
  getAspectRatioClass,
  getCommentIconClassName,
  getLikeIconClassName,
  getLowResolutionLink,
  mentionUserInputParse,
} from "../../helper-methods";
import CustomVideoPlayer from "../custom/CustomVideoPlayer";
import ErrorBoundary from "../ErrorBoundary";
import FeedPaymentTier from "../FeedPaymentTier";
import SvgIcons from "../SvgIcons";

const VideoViewer = ({
  feed,
  feedIndex,
  feedBundles,
  isSubscriptionRequired,
  goToPostDetailsPage,
  isPublic,
  isLocked,
  contentTypeCount,
  goalDiv,
  toggleCommentSection,
  toggleLikePost,
  toggleFlagModal,
  toggleFeedTipModal,
  unlockPost,
  onTierUpgrade,
  onPaymentComplete,
  scrollToTop = () => {},
}) => {
  const previewVideoTimerRef = useRef();

  const influencersOnlineData = useSelector(
    (state) => state?.influencersOnlineData
  );

  const [isPlayVideoPreview, setIsPlayVideoPreview] = useState(false);

  const _subscribe = () => {
    try {
      window.scrollTo(0, 0);
      scrollToTop();
    } catch (error) {
      console.log({ error });
    }
  };

  const _startStopPreviewVideo = (isPlayVideoPreview = false) => {
    if (feed?.previewVideoUrl || feed?.previewVideoHlsLink) {
      setIsPlayVideoPreview(isPlayVideoPreview);
    }
  };

  const _onChangeVisibility = (isVisible) => {
    if (previewVideoTimerRef?.current)
      clearTimeout(previewVideoTimerRef.current);

    if (feed?.previewVideoUrl || feed?.previewVideoHlsLink) {
      if (isVisible) {
        previewVideoTimerRef.current = setTimeout(() => {
          _startStopPreviewVideo(true);
        }, 4000);
      } else {
        _startStopPreviewVideo(false);
      }
    }
  };

  return (
    <Card className="cardFeed">
      <CardHeader
        className="justify-content-start"
        onClick={() => goToPostDetailsPage(isSubscriptionRequired)}
      >
        <div className="mr-1 position-relative">
          <img
            src={
              getLowResolutionLink(feed?._influencer?.profilePicture) ||
              DEFAULT_PROFILE_PICTURE
            }
            onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
            alt="Profile Img"
            className="userImg"
            loading="lazy"
          />
          {/* Online status */}
          {influencersOnlineData?.influencers?.[feed?._influencer?._id] ? (
            <span className="fa fa-circle onlineStatus" />
          ) : null}
        </div>
        <div>
          {/* <h4>
              {post._influencer ? post._influencer.name.full : ""}
            </h4> */}
          <div className="profileID">
            @{feed?._influencer?.username || "N/A"}
          </div>
        </div>
      </CardHeader>

      <CardBody>
        {feed?.text?.length ? (
          <div className="customPaddingMob">
            {mentionUserInputParse(feed?.text)}
          </div>
        ) : null}

        {!isPublic && isLocked ? (
          <div className="position-relative">
            <VisibilitySensor
              onChange={(isVisible) => _onChangeVisibility(isVisible)}
            >
              {(feed?.previewVideoUrl || feed?.previewVideoHlsLink) &&
              isPlayVideoPreview ? (
                <>
                  {/* post preview video */}
                  {/* custom video player support hls */}
                  <CustomVideoPlayer
                    className={`${getAspectRatioClass(
                      feed?.previewVideoUrlAspectRatio
                    )}`}
                    onEnded={() => _startStopPreviewVideo()}
                    src={feed?.previewVideoUrl}
                    hlsLink={feed?.previewVideoHlsLink}
                    duration={feed?.previewVideoUrlDuration}
                    autoPlay
                    muted
                  />
                  <div className="videoPreviewTag-Feed">Video Preview</div>
                </>
              ) : (
                <img
                  className="mediaPhoto-Feed"
                  src={
                    feed?.preview ||
                    feed?._contents?.[0]?.thumbnail ||
                    DEFAULT_COVER_PICTURE
                  }
                  onError={(e) => (e.target.src = DEFAULT_COVER_PICTURE)}
                  style={{
                    filter: !feed?.preview ? "blur(20px)" : "",
                  }}
                  alt="Cover Img"
                  loading="lazy"
                />
              )}
            </VisibilitySensor>

            <div className="postLockedOverlay">
              <div className="feedContentCount">
                {contentTypeCount?.video ? (
                  <div>
                    <i className="fa fa-video-camera" />
                    <span>{contentTypeCount.video}</span>
                  </div>
                ) : null}
              </div>

              <SvgIcons type="lock" className="iconLock" />

              {isSubscriptionRequired ? (
                <Button
                  disabled={!feed?._influencer?.paymentEnabled}
                  className="paymentBtnTier"
                  onClick={() => _subscribe()}
                >
                  Subscribe
                </Button>
              ) : (
                <FeedPaymentTier
                  subscription={feed?.subscription}
                  influencer={feed?._influencer}
                  paymentFor={feed}
                  feedBundles={feedBundles}
                  requestUpdate={() => onTierUpgrade()}
                  unlockMethod={(amountToPay, card) =>
                    unlockPost(amountToPay, card)
                  }
                  onPaymentComplete={onPaymentComplete}
                  feedIndex={feedIndex}
                />
              )}
            </div>
          </div>
        ) : (
          <ErrorBoundary>
            {/* custom video player support hls */}
            <CustomVideoPlayer
              className={`${getAspectRatioClass(
                feed?._contents?.[0]?._content?.aspectRatio
              )}`}
              src={feed?._contents?.[0]?._content?.url}
              hlsLink={feed?._contents?.[0]?._content?.hlsLink}
              thumbnail={
                feed?.preview || feed?._contents?.[0]?._content?.thumbnail
              }
              feedId={feed?._id}
              duration={
                feed?._contents?.[0]?._content?.duration ||
                feed?._contents?.[0]?.duration
              }
              pictureInPictureMode
              fullScreenMode
              controls
            />
          </ErrorBoundary>
        )}
      </CardBody>

      {/* goal div add here */}
      {goalDiv ? goalDiv : null}

      <CardFooter className={"footerFeed"}>
        <div className="d-flex">
          <Button
            className={
              "mr-2 mr-sm-3 " +
              (isSubscriptionRequired ? "disabled-feed-options" : "")
            }
            onClick={() => toggleLikePost()}
          >
            <i className={getLikeIconClassName(feed)} />
          </Button>

          <Button
            className={
              "mr-2 mr-sm-3 " +
              (isSubscriptionRequired ? "disabled-feed-options" : "")
            }
            onClick={() => toggleCommentSection()}
          >
            <i className={getCommentIconClassName(feed)} />
          </Button>

          <Button
            className={
              "mr-2 mr-sm-3 " +
              (isSubscriptionRequired ? "disabled-feed-options" : "")
            }
            onClick={() => toggleFeedTipModal(true)}
            disabled={!feed?._influencer?.paymentEnabled}
          >
            <img src="/assets/img/dollar.png" alt="Dollar" loading="lazy" />
          </Button>

          <Button
            className={
              "ml-auto " +
              (isSubscriptionRequired
                ? feed?.isPublic && feed?.isFree
                  ? ""
                  : "disabled-feed-options"
                : "")
            }
            title="Share"
            onClick={() =>
              copyPostPublicLink({
                influencerUsername: feed?._influencer?.username,
                postId: feed?._id,
              })
            }
          >
            <img
              src="/assets/img/quick-link.png"
              alt="share"
              loading="lazy"
              style={{ verticalAlign: -2 }}
            />
          </Button>

          <Button
            title="Report"
            className={
              "ml-3 ml-sm-3 " +
              (isSubscriptionRequired ? "disabled-feed-options" : "")
            }
            onClick={() => toggleFlagModal(true)}
          >
            <i className="fa fa-flag-o" />
          </Button>
        </div>

        {feed?.likes || feed?.comments ? (
          <div className="mt-1">
            {feed?.likes ? (
              <span>
                {feed?.likes} {feed?.likes > 1 ? "Likes" : "Like"}
              </span>
            ) : null}
            {feed?.comments ? (
              <span>
                {feed?.comments} {feed?.comments > 1 ? "Comments" : "Comment"}
              </span>
            ) : null}
          </div>
        ) : null}

        {/* post time */}
        <div className="postTime-Feed">{formatDate(feed?.startDate)}</div>
      </CardFooter>
    </Card>
  );
};

export default VideoViewer;
