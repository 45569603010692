import {
  APP_BASE_URL,
  APP_NAME,
  CONTACT_SUPPORT_URL,
  TERMS_OF_SERVICE_URL,
} from ".";

export const faqs = [
  {
    category: "General Questions",
    icon: "fa fa-rocket",
    faqs: [
      {
        title: `What is ${APP_NAME}?`,
        content: `Whether you're a youtuber, trainer, model, artist, public figure or influencer, ${APP_NAME} is a platform you should be using. ${APP_NAME} helps influencers and content creators take back control. 
          <|<>|><|<>|>
          Gain freedom and stability with the support of your biggest fans. ${APP_NAME} enables you to get paid for your creative work. 
          <|<>|><|<>|>
          See our home page for more information: [[${APP_NAME}||${APP_BASE_URL}]]         
          `,
        videos: [],
      },
      {
        title: "Why Submit My Email & Password Twice?",
        content: `Why you should verify your email: A verified email address means that you will receive important notifications system alerts. 
          <|<>|><|<>|>
          Your email and password give you access to your account. Sometimes emails can be spelled incorrectly during registration. 
          <|<>|><|<>|>
          Double verification ensures your credentials were entered correctly and ensures that you will be able to recover your account access if you forget your password and need to reset your login information. 
          <|<>|><|<>|>
          Click on forgot password at the login page to initiate a password reset.           
          `,
        videos: [],
      },
      {
        title: "Notification Settings",
        content: `Want to change your notifications or alert settings? Log into your ${APP_NAME} account using your current email address where you receive notifications.
          <|<>|><|<>|>
          You can find all login links in the footer of our website at [[${APP_NAME}||${APP_BASE_URL}]]. Login using your username and password then go to “Settings ⚙” and select notifications and adjust your notifications for your profile as you like.          
          `,
        videos: [],
      },
      {
        title: "I Can't Login To My Account Anymore?",
        content: `First ensure you're logging into the right place. There is a creator login and a fan login. You can find the login portals at [[${APP_NAME}||${APP_BASE_URL}]] in the footer. Select the one you need. 
          <|<>|><|<>|>
          If that doesn't work clear your cache or attempt to login via an incognito browser on your device. If you still can't login try to reset your password by selecting “Forgot Password” If you still can't get access into your account please submit a detailed support ticket explaining who you are, your username, account email and the exact situation you need help with. 
          <|<>|><|<>|>
          Sharing details help us improve and serve our users better. [[Support||${CONTACT_SUPPORT_URL}]]
          <|<>|>
          Our technical support reps will be able to provide you with access if your account has been flagged by mistake and there has been no breach of our [[Terms of Service||${TERMS_OF_SERVICE_URL}]]
          <|<>|><|<>|>
          <b>NOTICE:</b> Using a VPN, 3rd party apps, or proxy services will increase the likelihood of your account being flagged and even banned by our platform/system. 
          `,
        videos: [],
      },
    ],
  },

  {
    category: "Fans Following Creators",
    icon: "fa fa-plus-square-o",
    faqs: [
      {
        title: "Why Should I Subscribe?",
        content: `Apart from having exclusive access to all the private content being posted by ${APP_NAME} creators, you also have the incentive of being able to communicate with them directly via a direct messaging interface. 
          <|<>|><|<>|>
          With so many great new features and content released monthly, following a ${APP_NAME} creator brings you closer to your favorite creator and their content than ever before. 
          <|<>|><|<>|>
          Want to unlock access to new content creators? You can search for ${APP_NAME} creators to subscribe to by name, keyword, or category [[Search Now||/search ]]          
          `,
        videos: [],
      },
      {
        title: "How Am I Charged For Subscribing?",
        content: `When you choose to unlock/subscribe to get access to your favorite creator, your credit/debit card will be charged the creator's listed cost. 
          <|<>|><|<>|>
          We accept all major credit cards. Visa, MasterCard, Maestro, American Express, JCB, Discover. 
          <|<>|><|<>|>
          ${APP_NAME} uses a US based Tier 1 encrypted payment processor to complete and serve all transactions through the 24 countries we operate. 
          <|<>|><|<>|>
          After subscribing, the creator's content will be unlocked and you will be able to view and engage with it. 
          <|<>|><|<>|>
          Search for creators to subscribe to          
          `,
        videos: [],
      },
      {
        title: "How Do I Subscribe/Follow Creators?",
        content: `Make sure you have a valid and active credit card. You can add one to your account in your “manage cards” tab in your fan dashboard. 
          <|<>|><|<>|>
          You can search for new creators to subscribe to [[here||/search ]] 
          <|<>|><|<>|>
          Selecting the “subscribe” button will give you the option to pay and unlock the content in the creator's channel. 
          <|<>|><|<>|>
          Once subscribed and unlocked, you will see all previous posts and all future posts during your active subscription periods.          
          `,
        videos: [],
      },
      {
        title: "What Payment Methods Are Accepted?",
        content: `At this time, we proudly process ALL major credit and debit cards. Visa, MasterCard, Maestro, Discover, American Express. Prepaid cards, Crypto, Western Union, Gift Cards, PayPal, and others non listed payment methods are NOT supported at this time.`,
        videos: [],
      },
      {
        title: "What Will Be Displayed On My Bank Statement?",
        content: `Your card statement will show ${APP_NAME} LLC`,
        videos: [],
      },
      {
        title:
          "I'm Trying To Access A Creators Page “This page is not available”?",
        content: `${APP_NAME} creators can restrict access to their account for certain countries, states, or IP addresses. If you get this message, this is usually the case in such situations. Users can also choose to restrict individual accounts access as they need.
          <|<>|><|<>|>
          First try to contact the creator and determine if their account is still active. If you need additional help you can create a [[Support||${CONTACT_SUPPORT_URL}]] ticket          
          `,
        videos: [],
      },
      {
        title: "I Can't Subscribe?",
        content: `First try to clear your device's browser cache. 
          <|<>|><|<>|>
          Make sure you are using an up to date web-browser application, like Safari, Internet Explorer, Chrome or Firefox. (Ensure you have an up to date browser.) 
          <|<>|><|<>|>
          Be sure you also have a valid credit card linked to your account in your “Manage Cards” Tab. 
          <|<>|><|<>|>
          Our platform detects attempts to utilize fraudulent Credit Cards. The system perma-bans accounts and IP's if/when fraudulent checkouts are attempted by fans. 
          <|<>|><|<>|>
          NOTE: You can NOT unlock or subscribe to a creator's content who has a paid subscription without first paying to unlock and subscribe. 
          <|<>|><|<>|>
          Need additional help? [[Submit A Support Ticket||${CONTACT_SUPPORT_URL}]]
          `,
        videos: [],
      },
      {
        title: "Cancel a subscription?",
        content: `You can cancel your subscription at any time. When you cancel, you will still be able to access the user's profile / content until the end of your existing subscription period. Navigate to the “Manage Subscription” tab in your dashboard to manage or cancel your subscription. 
          <|<>|><|<>|>
          <b>NOTICE:</b> Once you have canceled your subscription and the bundle offer has ended you will NOT be able to see the content the creator uploads. 
          `,
        videos: [],
      },
      {
        title: "Subscription Didn't Renew Automatically?",
        content: `Subscriptions may not auto-renew if: 
          <|<>|>
          1) your credit card was declined or failed for technical reasons 
          <|<>|><|<>|>
          2) you were removed by the creator or an administrator for violating terms 
          <|<>|><|<>|>
          Please review your account settings, make sure you have a valid credit card added in your “manage cards” section and try to subscribe again.
          <|<>|><|<>|>
          Need some additional help? [[Contact Support||${CONTACT_SUPPORT_URL}]]
          <|<>|><|<>|>
          <b>Can I Get A Refund?</b> 
          <|<>|><|<>|>
          <b style="color:#ff2323">***NOTICE: ALL PURCHASES MADE THROUGH [[${APP_NAME?.toUpperCase()}||${APP_BASE_URL}]] ARE NON REFUNDABLE.***</b>
          <|<>|><|<>|>
          You may unsubscribe from your favorite creator for any reason and at any time to ensure that you will not be billed for that subscription again.
          <|<>|><|<>|>
          Select “Manage Subscription” inside of your account dashboard to cancel or manage your subscription.
          `,
        videos: [],
      },
      {
        title: "Can I deactivate my account?",
        content: `Both fans and creators can deactivate their accounts whenever they choose inside “Profile Settings.” 
          <|<>|><|<>|>
          Ensure that you do not have paid subscribers or subscriptions at the time of the deletion. Notice: If you have active subscribers your account will not be deleted until the subscription period has ended. 
          <|<>|><|<>|>
          Important: any active subscription will be lost and will never automatically renew. Once deleted, your account and all of your data will NOT be able to be restored. You will have to start over from scratch.          
          `,
        videos: [],
      },
    ],
  },
];
