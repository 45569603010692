export const MonthsConfig = [
  {
    month: "1",
    short_name: "Jan",
    full_name: "January",
  },
  {
    month: "2",
    short_name: "Feb",
    full_name: "February",
  },
  {
    month: "3",
    short_name: "Mar",
    full_name: "March",
  },
  {
    month: "4",
    short_name: "Apr",
    full_name: "April",
  },
  {
    month: "5",
    short_name: "May",
    full_name: "May",
  },
  {
    month: "6",
    short_name: "Jun",
    full_name: "June",
  },
  {
    month: "7",
    short_name: "Jul",
    full_name: "July",
  },
  {
    month: "8",
    short_name: "Aug",
    full_name: "August",
  },
  {
    month: "9",
    short_name: "Sep",
    full_name: "September",
  },
  {
    month: "10",
    short_name: "Oct",
    full_name: "October",
  },
  {
    month: "11",
    short_name: "Nov",
    full_name: "November",
  },
  {
    month: "12",
    short_name: "Dec",
    full_name: "December",
  },
];

export const MonthsInEngConfig = [
  { month: "1", short_name: "Jan", full_name: "January" },
  { month: "2", short_name: "Feb", full_name: "February" },
  { month: "3", short_name: "Mar", full_name: "March" },
  { month: "4", short_name: "Apr", full_name: "April" },
  { month: "5", short_name: "May", full_name: "May" },
  { month: "6", short_name: "Jun", full_name: "June" },
  { month: "7", short_name: "Jul", full_name: "July" },
  { month: "8", short_name: "Aug", full_name: "August" },
  { month: "9", short_name: "Sep", full_name: "September" },
  { month: "10", short_name: "Oct", full_name: "October" },
  { month: "11", short_name: "Nov", full_name: "November" },
  { month: "12", short_name: "Dec", full_name: "December" },
];

export const MonthsWithDays = {
  1: 31,
  2: 28,
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31,
};
