import React, { Component } from "react";

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    error: null,
  };

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch = (error, info) => {
    console.log({ error, info });
  };

  render() {
    const { hasError, error } = this.state;

    if (hasError) {
      return (
        <div>
          <h4>Something went wrong</h4>
          <p>{error.message}</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
