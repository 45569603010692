import React from "react";
import { PAYMENT_GATEWAY } from "../config/index";
import PaymentModal from "./modals/PaymentModal";
import SquarePayment from "../payment/SquarePayment";
import HumboldtPayment from "../payment/HumboldtPayment";

const PaymentHoc = (props) => {
  switch (PAYMENT_GATEWAY) {
    case "stripe":
      return (
        <PaymentModal
          {...props}
          createPaymentIntent={() => props.createPaymentIntent()}
          onPaymentSuccess={() => props.onPaymentSuccess()}
          onPaymentConfirmation={() => props.onPaymentConfirmation()}
          onDismiss={() => props.onDismiss()}
        />
      );

    case "humboldt":
      return (
        <HumboldtPayment
          {...props}
          createPaymentIntent={() => props.createPaymentIntent()}
          onPaymentSuccess={() => props.onPaymentSuccess()}
          onPaymentConfirmation={(card) => props.onPaymentConfirmation(card)}
          onDismiss={() => props.onDismiss()}
        />
      );

    case "square":
      return <SquarePayment {...props} />;

    default:
      break;
  }
};
export default PaymentHoc;
