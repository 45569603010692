import React, { useState } from "react";
import { useRef } from "react";
import VisibilitySensor from "react-visibility-sensor";

const AudioPlayer = ({ className = "", wrapperClassName = "", url }) => {
  const audioRef = useRef();

  const [isAudioPlay, setIsAudioPlay] = useState(false);

  const _onToggleAudioPlayPause = (isPlay = false) => {
    try {
      if (!isPlay && audioRef?.current?.pause) {
        audioRef.current.pause();
      }
      setIsAudioPlay(isPlay);
    } catch (error) {
      setIsAudioPlay(false);
    }
  };

  return (
    <VisibilitySensor
        onChange={(isVisible) =>
          !isVisible ? _onToggleAudioPlayPause(false) : {}
        }
      >
        <div className={`${className}`}>
          <div className={`audioWrapper ${wrapperClassName}`}>
            <div
              className={`audioRipple ${isAudioPlay ? "audioRippleStart" : ""}`}
            >
              <img
                src="/assets/img/microphone.png"
                alt="Microphone"
                loading="lazy"
              />
            </div>
            <audio
              ref={audioRef}
              controls
              onPlay={() => _onToggleAudioPlayPause(true)}
              onPause={() => _onToggleAudioPlayPause(false)}
              controlsList="nodownload"
              src={url}
            />
          </div>
        </div>
      </VisibilitySensor>
  );
};

export default AudioPlayer;
