import React from "react";
import Modal from "react-modal";
import AudioPlayer from "../custom/AudioPlayer";
import CustomVideoPlayer from "../custom/CustomVideoPlayer";
import ErrorBoundary from "../ErrorBoundary";
import ImageQualitySetting from "../ImageQualitySetting";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
};

const ShowMediaModal = ({
  isOpen,
  externalLink,
  contentType,
  toggle,
  thumbnail,
  hlsLink,
  duration,
  imageQuality,
  setImageQuality,
}) => {
  const _closeModal = () => {
    toggle();
  };

  const _renderContent = () => {
    switch (contentType) {
      case "image": {
        return (
          <>
            <ImageQualitySetting
              imageQuality={imageQuality}
              setImageQuality={setImageQuality}
              customClassname="imgQuality"
              customPopOverClassname="imgQualityPopover"
            />
            <img
              src={externalLink?.[imageQuality]}
              alt="mediaFeed"
              className="mediaPhoto-Feed"
              loading="lazy"
            />
          </>
        );
      }
      case "video": {
        return (
          <>
            <ErrorBoundary>
              {/* custom video player support hls */}
              <CustomVideoPlayer
                className="showMediaModal"
                src={externalLink}
                hlsLink={hlsLink}
                thumbnail={thumbnail}
                duration={duration}
                controls
              />
            </ErrorBoundary>
          </>
        );
      }
      case "audio": {
        return (
          <>
            <ErrorBoundary>
              <AudioPlayer
                className="w-100 h-100 d-flex justify-content-center align-items-center bg-white rounded"
                url={externalLink}
              />
            </ErrorBoundary>
          </>
        );
      }
      default:
        return null;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => _closeModal()}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <button onClick={() => _closeModal()} className="closeModalFeed">
        <i className="fa fa-close modalFeed_closeIcon" />
      </button>

      {externalLink ? <>{_renderContent()}</> : <>No Content found</>}
    </Modal>
  );
};

export default ShowMediaModal;
