import React, { useEffect, useState } from "react";
import { CardBody, Card, CardHeader, Collapse, Col } from "reactstrap";
import { deepClone } from "../helper-methods";

const FaqAccordion = ({ categoryMode = false, faqs }) => {
  const [formattedFaqs, setFormattedFaqs] = useState([]);

  const _formatAccordian = (faqs, categoryMode) => {
    let formattedFaqs = [];

    if (categoryMode) {
      for (let categoryName in faqs) {
        faqs[categoryName].forEach((faq) => {
          faq["accordianIsOpen"] = false;
        });
        formattedFaqs.push({
          categoryName,
          faqs: faqs[categoryName],
        });
      }
    } else {
      if (faqs?.length) {
        faqs.forEach((faq) => {
          faq["accordianIsOpen"] = false;
        });
        formattedFaqs = [...faqs];
      }
    }
    setFormattedFaqs(formattedFaqs);
  };

  const _toggleAccordion = (faqIndex, categoryIndex = null) => {
    const newFormattedFaqs = deepClone(formattedFaqs);
    if (categoryMode) {
      newFormattedFaqs[categoryIndex].faqs[faqIndex].accordianIsOpen =
        !formattedFaqs[categoryIndex].faqs[faqIndex].accordianIsOpen;
    } else {
      newFormattedFaqs[faqIndex]["accordianIsOpen"] =
        !formattedFaqs[faqIndex].accordianIsOpen;
    }
    setFormattedFaqs(newFormattedFaqs);
  };

  useEffect(() => {
    _formatAccordian(faqs, categoryMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faqs]);

  return (
    <React.Fragment>
      <Col className="noPadding mb-3" xs={12}>
        {categoryMode ? (
          formattedFaqs?.map((faqObj, faqObjIndex) => (
            <div key={faqObjIndex}>
              <h2 className="faqCategoryTitle">{faqObj?.categoryName}</h2>

              <div id="accordion">
                {faqObj?.faqs?.map((faq, faqIndex) => (
                  <Card key={faqIndex}>
                    <CardHeader
                      id="headingOne"
                      onClick={() => _toggleAccordion(faqIndex, faqObjIndex)}
                      aria-expanded={faq?.accordianIsOpen}
                      aria-controls="collapseOne"
                    >
                      <h5 className="faqQues">{faq?.title}</h5>
                    </CardHeader>
                    <Collapse
                      isOpen={faq?.accordianIsOpen}
                      data-parent="#accordion"
                      id="collapseOne"
                      aria-labelledby="headingOne"
                    >
                      <CardBody>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: faq?.htmlContent,
                          }}
                        />
                        {faq?.videos?.length
                          ? faq.videos.map((video, videoIndex) => (
                              <div
                                className="youtubeEmbedWrapper"
                                key={videoIndex}
                              >
                                <iframe
                                  title="myFrame"
                                  frameBorder="0"
                                  height="298"
                                  src={video}
                                  width="530"
                                ></iframe>
                              </div>
                            ))
                          : null}
                      </CardBody>
                    </Collapse>
                  </Card>
                ))}
              </div>
            </div>
          ))
        ) : formattedFaqs?.length ? (
          formattedFaqs.map((faq, faqIndex) => (
            <div id="accordion" key={faqIndex}>
              <Card>
                <CardHeader
                  id="headingOne"
                  onClick={() => _toggleAccordion(faqIndex)}
                  aria-expanded={faq?.accordianIsOpen}
                  aria-controls="collapseOne"
                >
                  <h5 className="faqQues">{faq.title}</h5>

                  <i className="fa fa-chevron-down" />
                </CardHeader>
                <Collapse
                  isOpen={faq?.accordianIsOpen}
                  data-parent="#accordion"
                  id="collapseOne"
                  aria-labelledby="headingOne"
                >
                  <CardBody>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: faq.htmlContent,
                      }}
                    />
                    {faq.videos.length ? (
                      <div className="youtubeEmbedWrapper">
                        <iframe
                          title="myFrame"
                          frameBorder="0"
                          height="298"
                          src={faq.videos[0]}
                          width="530"
                        ></iframe>
                      </div>
                    ) : null}
                  </CardBody>
                </Collapse>
              </Card>
            </div>
          ))
        ) : (
          <p className="noContentFound mt-3">No Result Found</p>
        )}
      </Col>
    </React.Fragment>
  );
};

export default FaqAccordion;
