import React from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import {
  formatCurrencyValue,
  formatDate,
  formatPaymentTitle,
  formatTimeIn12hFormat,
  getLowResolutionLink,
  getPaymentTypeInFormat,
} from "../../helper-methods";

const PaymentDetailModal = ({
  isOpen,
  historyData,
  toggle,
  getRelatedContentLink,
}) => {
  if (!historyData?._id) return <></>;

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggle()}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalHeader toggle={() => toggle()}>Transaction Detail</ModalHeader>
      <ModalBody>
        <div className="img100by100Wrap mx-auto">
          <img
            src={
              getLowResolutionLink(historyData?._to?.profilePicture) ||
              DEFAULT_PROFILE_PICTURE
            }
            alt="Avatar"
            className="img100by100"
            loading="lazy"
          />
        </div>

        <p className="transactionTxt">
          {formatPaymentTitle(historyData)} @{historyData?._to?.username}
        </p>

        <p className="transactionAmt">
          {formatCurrencyValue(historyData?.amount)}
        </p>

        {/* completed status */}
        {historyData.status === "released" ? (
          <div className="transactionStatus">
            <i className="fa fa-check-circle" /> Transaction complete
          </div>
        ) : null}

        {/* refunded status */}
        {historyData.status === "refunded" ? (
          <div className="transactionStatus">
            <i className="fa fa-undo" /> Refund
          </div>
        ) : null}

        <ListGroup className="transactionDetails">
          <ListGroupItem>
            Transaction Type
            <div>{getPaymentTypeInFormat(historyData)}</div>
          </ListGroupItem>
          <ListGroupItem>
            Transaction ID
            <div>{historyData?.transactionId || "N/A"}</div>
          </ListGroupItem>
          <ListGroupItem>
            Payment Method
            <div>
              {historyData.hasOwnProperty("method") &&
              historyData.method === "card" &&
              historyData.hasOwnProperty("cardBrand") &&
              historyData.hasOwnProperty("cardLastFourDigit") ? (
                <span>
                  {historyData?.cardBrand} {historyData?.cardLastFourDigit}
                </span>
              ) : historyData?.method === "stripe" ? (
                "Apple Pay"
              ) : (
                "N/A"
              )}
            </div>
          </ListGroupItem>
          <ListGroupItem>
            Amount
            <div>{formatCurrencyValue(historyData?.amount)}</div>
          </ListGroupItem>
          <ListGroupItem>
            Date
            <div>
              {formatDate(historyData?.time)},{" "}
              {formatTimeIn12hFormat(historyData?.time)}
            </div>
          </ListGroupItem>
          <ListGroupItem>
            Related Content
            <div>{getRelatedContentLink(historyData) || "N/A"}</div>
          </ListGroupItem>
        </ListGroup>
      </ModalBody>
      <ModalFooter>
        <Button className="modalBtnSave" onClick={() => toggle()}>
          Back
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

export default PaymentDetailModal;
