import { FAYE_CONFIG } from "../config";

var subscribeForPaymentResponse;
var subscriptionUnreadMessageCount;
var Subscribe_To_Thread = {};

export const subscribeToThread = (threadId, callbackMethod) => {
  const client = new window.Faye.Client(FAYE_CONFIG.HOST_URL);

  if (!Subscribe_To_Thread[threadId]) {
    Subscribe_To_Thread[threadId] = client.subscribe(
      `/chat/${threadId}`,
      callbackMethod
    );
  } else {
    if (
      Subscribe_To_Thread[threadId] &&
      Subscribe_To_Thread[threadId]._cancelled
    ) {
      Subscribe_To_Thread[threadId] = client.subscribe(
        `/chat/${threadId}`,
        callbackMethod
      );
    }
  }

  return Subscribe_To_Thread[threadId];
};

export const unsubscribeToThread = (threadId) => {
  try {
    if (Subscribe_To_Thread[threadId]) {
      Subscribe_To_Thread[threadId].unsubscribe();
    }
  } catch (err) {}
};

export const sendMessage = (threadId, payload) => {
  const client = new window.Faye.Client(FAYE_CONFIG.HOST_URL);
  client.publish(`/chat/${threadId}`, payload);
};

export const subscribeLiveEventThread = (eventId, callbackMethod) => {
  const client = new window.Faye.Client(FAYE_CONFIG.HOST_URL);
  return client.subscribe(`/live/${eventId}`, callbackMethod);
};

export const sendMessageOnLiveEvent = (eventId, payload) => {
  const client = new window.Faye.Client(FAYE_CONFIG.HOST_URL);
  client.publish(`/live/${eventId}`, payload);
};

export const subscribeLiveUnreadMessageCount = (
  currentUserId,
  callbackMethod
) => {
  const client = new window.Faye.Client(FAYE_CONFIG.HOST_URL);
  subscriptionUnreadMessageCount = client.subscribe(
    `/count/${currentUserId}`,
    callbackMethod
  );
  return subscriptionUnreadMessageCount;
};

export const unsubscribeLiveUnreadMessageCount = () => {
  if (subscriptionUnreadMessageCount) {
    subscriptionUnreadMessageCount.cancel();
  }
};

// For Humboldt 3D Secure Payment
export const subscribeForPayment = (userId, callbackMethod) => {
  const client = new window.Faye.Client(FAYE_CONFIG.HOST_URL);
  subscribeForPaymentResponse = client.subscribe(
    `/payment/${userId}`,
    callbackMethod
  );
  return subscribeForPaymentResponse;
};

export const unsubscribeForPayment = () => {
  if (subscribeForPaymentResponse) {
    subscribeForPaymentResponse.cancel();
  }
};
