import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import ConfigureString from "../components/ConfigureString";
import InfluencerVaultsComponent from "../components/InfluencerVaultsComponent";
import CustomLoader from "../components/custom/CustomLoader";
import { useDispatch, useSelector } from "react-redux";
import { errorHandler } from "../helper-methods";
import SkeletonLoading from "../components/SkeletonLoading";
import ErrorBoundary from "../components/ErrorBoundary";
import { getAndUpdateUserSubscriptions } from "../redux/actions";

const VaultsPage = () => {
  const dispatch = useDispatch();

  const userSubscriptions = useSelector((state) => state?.userSubscriptions);

  const [loading, setLoading] = useState(false);

  const _getAndUpdateUserSubscriptions = async () => {
    try {
      setLoading(true);

      await getAndUpdateUserSubscriptions()(dispatch);

      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    _getAndUpdateUserSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="customPgHeight animated fadeIn">
        <Container className="noPadding">
          <Row className="justify-content-center noMargin mb-2">
            <Col sm={12} lg={11} className="pgPadding">
              <div className="pgTitle">
                <div className="d-flex">
                  <h2>
                    <ConfigureString keyString={"The vault"} />
                  </h2>
                  <div className="ml-2">
                    {loading ? <CustomLoader /> : null}
                  </div>
                </div>
              </div>

              {userSubscriptions?.subscriptions?.length ? (
                userSubscriptions.subscriptions.map((each, index) => (
                  <Fragment key={each._id}>
                    <ErrorBoundary>
                      <InfluencerVaultsComponent data={each} />
                    </ErrorBoundary>

                    {index < userSubscriptions?.subscriptions?.length - 1 ? (
                      <div className="customBorderVault" />
                    ) : null}
                  </Fragment>
                ))
              ) : loading ? (
                <SkeletonLoading type={"vault"} count={6} />
              ) : (
                <div className="noContentFound" style={{ marginTop: 40 }}>
                  <ConfigureString keyString={"No vault found"} />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default VaultsPage;
