import React, { useEffect, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ImageCropUploaderModal = ({
  isOpen,
  selectedPhoto,
  resetPhoto,
  onSuccess,
  onDismiss,
}) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [croppedImageBlobData, setcroppedImageBlobData] = useState();
  const [crop, setCrop] = useState({
    unit: "%",
    width: 80,
    aspect: 4 / 3,
    x: 0,
    y: 0,
  });

  const imageRef = useRef(null);

  const _onSuccess = () => {
    onSuccess(croppedImageUrl, croppedImageBlobData);
  };

  const _onClose = () => {
    resetPhoto();
    onDismiss();
  };

  const _onImageLoaded = (image) => {
    imageRef.current = image;
  };

  const _onCropChange = (crop) => {
    setCrop(crop);
  };

  const _onCropComplete = (crop) => {
    _makeClientCrop(crop);
  };

  const _getCroppedImage = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    // canvas width and height
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        let newfileUrl = window.URL.createObjectURL(blob);
        resolve({
          fileUrl: newfileUrl,
          blob: blob,
        });
      }, "image/jpg");
    });
  };

  const _makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImage = await _getCroppedImage(
        imageRef.current,
        crop,
        "newFile.jpg"
      );
      setCroppedImageUrl(croppedImage.fileUrl);
      setcroppedImageBlobData(croppedImage.blob);
    }
  };

  const _onFileChange = (selectedPhoto) => {
    if (selectedPhoto?.type?.includes("image")) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImageSrc(reader.result));
      reader.readAsDataURL(selectedPhoto);
    }
  };

  useEffect(() => {
    _onFileChange(selectedPhoto);
  }, [selectedPhoto]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => _onClose()}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <ModalHeader id="alert-dialog-slide-title">Crop Image</ModalHeader>
      <ModalBody>
        {imageSrc?.length ? (
          <>
            <ReactCrop
              src={imageSrc}
              style={{ margin: "0 auto", display: "table" }}
              crop={crop}
              onImageLoaded={_onImageLoaded}
              onComplete={_onCropComplete}
              onChange={_onCropChange}
            />
          </>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button className="modalBtnCancel" onClick={() => _onClose()}>
          Cancel
        </Button>
        <Button className="modalBtnSave" onClick={() => _onSuccess()}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ImageCropUploaderModal;
