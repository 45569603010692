import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAndUpdateUserData } from "../redux/actions/userData";
import {
  Button,
  Col,
  Container,
  Nav,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { settingsPageTabs } from "../config/helper-config";
import PageTab from "../components/PageTab";
import { extractQueryParams } from "../helper-methods";
import SettingsNotification from "../components/SettingsNotification";
import ProfileSettingsComponents from "../components/ProfileSettings";
import CustomLoader from "../components/custom/CustomLoader";
import ErrorBoundary from "../components/ErrorBoundary";

const SettingsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { user: fanDetails, isLoading } = useSelector(
    (state) => state?.userData || {}
  );

  const [activeTab, setActiveTab] = useState("profile"); //profile, notifications

  const _getFanDetails = () => {
    dispatch(getAndUpdateUserData());
  };

  const _onTabChange = (newTab) => {
    setActiveTab(newTab);
  };

  useEffect(() => {
    _getFanDetails();

    const queryParams = extractQueryParams();
    if (queryParams?.tabId) {
      switch (queryParams.tabId) {
        case "profile":
          setActiveTab("profile");
          break;
        case "notifications":
          setActiveTab("notifications");
          break;
        default:
          setActiveTab("profile");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="justify-content-center noMargin  pb-3">
          <Col sm={12} md={10} lg={9} className="pgPadding">
            <div className="pgTitle justify-content-start">
              <Button
                className="customBackBtn"
                onClick={() => history.goBack()}
              >
                <i className="fa fa-chevron-left" />
              </Button>

              <h2>Settings</h2>

              {isLoading ? <CustomLoader className="mx-2" /> : null}
            </div>

            <div className="bg-white p-0 p-sm-3" style={{ borderRadius: 10 }}>
              <Nav tabs className="customTabs customTabs-Notifications">
                {settingsPageTabs.map((tab) => (
                  <PageTab
                    key={tab?.value}
                    tabValue={tab?.value}
                    tabLabel={tab?.label}
                    onTabChange={_onTabChange}
                    selectedTab={activeTab}
                  />
                ))}
              </Nav>

              <TabContent
                activeTab={activeTab}
                className="customTabContent customTabContent-Notifications"
              >
                <TabPane tabId="profile">
                  {fanDetails ? (
                    <>
                      <ErrorBoundary>
                        <ProfileSettingsComponents />
                      </ErrorBoundary>
                    </>
                  ) : null}
                </TabPane>

                <TabPane tabId="notifications">
                  {fanDetails?.notifications ? (
                    <>
                      <ErrorBoundary>
                        <SettingsNotification fanDetails={fanDetails} />
                      </ErrorBoundary>
                    </>
                  ) : null}
                </TabPane>
              </TabContent>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SettingsPage;
