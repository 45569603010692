import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAllCards } from "../../http-calls";

const updateCards = createAction("updateCards");
const clearAllPaymentData = createAction("clearAllPaymentData");

const fetchCardsAsync = createAsyncThunk(
  "paymentData/fetchCardsAsync",
  async (payload, thunkAPI) => {
    const res = await fetchAllCards();
    return res?.cards;
  }
);

export { updateCards, clearAllPaymentData, fetchCardsAsync };
