import React, { Component } from "react";
import { Button, Label, CustomInput } from "reactstrap";
import {
  deepClone,
  errorHandler,
  formatCurrencyValue,
  isSubscriptionPriceExist,
  showToast,
} from "../helper-methods";
import PaymentHoc from "./PaymentHoc";
import ProcessPayment from "../payment";
import { changeSubscriptionAmount, getAllBundles } from "../http-calls";
import { hideLoader, showLoader, updateOnlyUserData } from "../redux/actions";
import { connect } from "react-redux";

class PaymentTierComponent extends Component {
  state = {
    selectedPaymentTier: "",
    paymentConfirmationModal: {
      isVisible: false,
      amount: 0,
    },
    selectedBundleId: null,
    isBundleLoading: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (!state.selectedPaymentTier.length) {
      return {
        selectedPaymentTier: props?.subscription?.tier || "",
      };
    }
    return null;
  }

  _isChecked = (name) => {
    const { selectedPaymentTier } = deepClone(this.state);
    if (selectedPaymentTier === name) {
      return true;
    } else return false;
  };

  _onChangePaymentOption = (e) => {
    this.setState({
      selectedPaymentTier: e.target.name,
    });
  };

  _paymentProcess = async () => {
    try {
      const { paymentConfirmationModal, selectedPaymentTier } = deepClone(
        this.state
      );
      const { subscription, paymentFor, influencer } = deepClone(this.props);

      if (subscription?.tier === selectedPaymentTier) {
        // pay for view
        switch (selectedPaymentTier) {
          case "basic": {
            paymentConfirmationModal["amount"] = paymentFor.price;
            break;
          }
          case "plus": {
            paymentConfirmationModal["amount"] = paymentFor.plusPrice;
            break;
          }
          case "premium": {
            paymentConfirmationModal["amount"] = paymentFor.premiumPrice;
            break;
          }
          default: {
          }
        }

        paymentConfirmationModal["isVisible"] = true;
        this.setState({
          paymentConfirmationModal,
        });
      } else {
        // upgrade tier
        if (subscription?.upgradeRestricted) {
          showToast("You can not upgrade your subscription", "error");
          return;
        }

        this.setState({ isBundleLoading: true });

        const bundlesRes = await getAllBundles({ _influencer: influencer._id });

        const filteredBundles = bundlesRes?.bundles?.length
          ? bundlesRes.bundles.filter(
              (bundle) => bundle.tier === selectedPaymentTier
            )
          : [];

        if (filteredBundles?.length) {
          let minimumSubscriptionPlan = filteredBundles.find(
            (bundle) => bundle.subscriptionPeriod === subscription?.billingCycle
          );

          if (!minimumSubscriptionPlan) {
            minimumSubscriptionPlan = filteredBundles[0];
            filteredBundles.forEach((bundle) => {
              if (
                bundle.subscriptionPeriod <
                minimumSubscriptionPlan.subscriptionPeriod
              ) {
                minimumSubscriptionPlan = bundle;
              }
            });
          }

          const { amountToBePaid } = await changeSubscriptionAmount({
            id: this.props.influencer?.id,
            payload: {
              tier: selectedPaymentTier,
              bundleId: minimumSubscriptionPlan?._id,
            },
          });

          paymentConfirmationModal["amount"] = amountToBePaid || 0;
          paymentConfirmationModal["isVisible"] = true;

          this.setState({
            selectedBundleId: minimumSubscriptionPlan._id,
            paymentConfirmationModal,
            isBundleLoading: false,
          });
        } else {
          const { amountToBePaid } = await changeSubscriptionAmount({
            id: this.props.influencer?.id,
            payload: {
              tier: selectedPaymentTier,
            },
          });

          paymentConfirmationModal["amount"] = amountToBePaid || 0;
          paymentConfirmationModal["isVisible"] = true;

          this.setState({
            paymentConfirmationModal,
            isBundleLoading: false,
          });
        }
      }
    } catch (error) {
      errorHandler(error);
      this.setState({ isBundleLoading: false });
    }
  };

  _dismissPaymentConfirmation = (_) => {
    let { paymentConfirmationModal } = deepClone(this.state);

    paymentConfirmationModal = {
      isVisible: false,
      amount: 0,
    };
    this.setState({ paymentConfirmationModal });
  };

  _ModalAndLoaderClose = () => {
    let { paymentConfirmationModal } = deepClone(this.state);

    paymentConfirmationModal = {
      isVisible: false,
      amount: 0,
    };
    this.setState(
      {
        paymentConfirmationModal,
      },
      () => {
        this.props.hideLoader();
      }
    );
  };

  _paymentDone = async (card = null) => {
    let { selectedPaymentTier, paymentConfirmationModal, selectedBundleId } =
      deepClone(this.state);
    const { subscription } = deepClone(this.props);

    let apiData = {};

    apiData["payload"] = {
      tier: selectedPaymentTier,
    };

    if (selectedBundleId) {
      apiData.payload["bundleId"] = selectedBundleId;
    }

    if (card) {
      apiData.payload["card"] = card;
    }

    if ((subscription && subscription?.tier) === selectedPaymentTier) {
      this.props.onBuy(paymentConfirmationModal.amount, card);
      paymentConfirmationModal = {
        isVisible: false,
        amount: 0,
      };
      this.setState({ paymentConfirmationModal });
    } else if (!subscription?.upgradeRestricted) {
      this.props.showLoader("Payment is processing");

      try {
        apiData.url3DSecure = `/payment/intent/subscription/upgrade/tier/${subscription?._influencer}`;
        apiData.url = `/subscription/upgrade/tier/${subscription?._influencer}`;

        apiData.influencer = this.props.influencer;

        await ProcessPayment.startPaymentProcess(
          apiData,
          paymentConfirmationModal.amount,
          "Put"
        );
        this.props.onPaymentSuccess();
        this._ModalAndLoaderClose();
        showToast("Your subscription tier updated successfully", "success");
      } catch (error) {
        if (error?.isRiskAssessment) {
          this.props.updateOnlyUserData({
            ...this.props.userData?.user,
            isRiskAssessment: error.isRiskAssessment,
          });
        }
        this.props.hideLoader();
        errorHandler(error);
      }
    } else {
      showToast("You can not upgrade your subscription", "error");
    }
  };

  _checkForJoinEventBtn = (selectedTier, paymentFor) => {
    switch (selectedTier) {
      case "basic":
        if (paymentFor.price === 0) {
          return true;
        }
        break;
      case "plus":
        if (paymentFor.plusPrice === 0) {
          return true;
        }
        break;
      case "premium":
        if (paymentFor.premiumPrice === 0) {
          return true;
        }
        break;
      default:
        return false;
    }
  };

  render() {
    const { paymentConfirmationModal, selectedPaymentTier, isBundleLoading } =
      deepClone(this.state);

    const { subscription, paymentFor, influencer } = this.props;

    return (
      <>
        <PaymentHoc
          // Apple pay relate
          content={
            selectedPaymentTier === subscription?.tier
              ? this.props.content
              : null
          }
          createPaymentIntent={() => this.props.createPaymentIntent()}
          onPaymentSuccess={() => this.props.onPaymentSuccess()}
          // End here
          apiData={this.props.apiData}
          isVisible={paymentConfirmationModal.isVisible}
          amount={paymentConfirmationModal.amount}
          influencer={this.props.influencer}
          onPaymentConfirmation={(card) => this._paymentDone(card)}
          onDismiss={() => this._dismissPaymentConfirmation()}
        />
        <div className="paymentLiveStream">
          <h4>Payment</h4>
          <div className="rsvpPaymentOptions">
            {/* basicPrice */}
            {paymentFor &&
            paymentFor.price >= 0 &&
            subscription &&
            subscription?.tier === "basic" ? (
              <div className="d-flex mb-3">
                <CustomInput
                  type="radio"
                  id="paymentFor_price_subscription_basic"
                  name={"basic"}
                  value={paymentFor.price}
                  onChange={(e) => this._onChangePaymentOption(e)}
                  checked={selectedPaymentTier === "basic"}
                />
                <Label for="paymentFor_price_subscription_basic">
                  <span>{formatCurrencyValue(paymentFor.price)}</span>
                  {paymentFor.price === 0 ? (
                    <span>This event is free</span>
                  ) : null}
                </Label>
              </div>
            ) : null}

            {influencer?.multiTierSubscription ? (
              <>
                {isSubscriptionPriceExist({
                  tier: "plus",
                  influencerId: influencer?._id,
                }) ? (
                  <>
                    {/* plusPrice */}
                    {paymentFor &&
                    paymentFor.plusPrice >= 0 &&
                    subscription &&
                    (subscription?.tier === "basic" ||
                      subscription?.tier === "plus") ? (
                      <div className="d-flex mb-3">
                        <CustomInput
                          type="radio"
                          id="paymentFor_plusPrice_subscription_plus"
                          name={"plus"}
                          value={paymentFor.plusPrice}
                          onChange={(e) => this._onChangePaymentOption(e)}
                          checked={selectedPaymentTier === "plus"}
                        />
                        <Label for="paymentFor_plusPrice_subscription_plus">
                          <span>
                            {formatCurrencyValue(paymentFor.plusPrice)}
                          </span>
                          &nbsp;
                          {paymentFor.plusPrice === 0 ? (
                            <span>(View this event for Free.</span>
                          ) : subscription?.tier !== "plus" ? (
                            "("
                          ) : null}
                          {subscription?.tier === "basic"
                            ? " Upgrade to Plus Membership)"
                            : paymentFor.plusPrice === 0
                            ? ")"
                            : null}
                        </Label>
                      </div>
                    ) : null}
                  </>
                ) : null}

                {isSubscriptionPriceExist({
                  tier: "premium",
                  influencerId: influencer?._id,
                }) ? (
                  <>
                    {/* premiumPrice */}
                    {paymentFor && paymentFor.premiumPrice >= 0 ? (
                      <div className="d-flex mb-1">
                        <CustomInput
                          type="radio"
                          id="paymentFor_premiumPrice_subscription_premium"
                          name={"premium"}
                          value={paymentFor.premiumPrice}
                          onChange={(e) => this._onChangePaymentOption(e)}
                          checked={selectedPaymentTier === "premium"}
                        />
                        <Label for="paymentFor_premiumPrice_subscription_premium">
                          <span>
                            {formatCurrencyValue(paymentFor.premiumPrice)}
                          </span>
                          &nbsp;
                          {paymentFor.premiumPrice === 0 ? (
                            <span>(View this event for Free.&nbsp;</span>
                          ) : subscription?.tier !== "premium" ? (
                            "("
                          ) : null}
                          {subscription?.tier !== "premium"
                            ? " Upgrade to Premium Membership)"
                            : paymentFor.premiumPrice === 0
                            ? ")"
                            : null}
                        </Label>
                      </div>
                    ) : null}
                  </>
                ) : null}
              </>
            ) : null}
          </div>

          {/* {paymentConfirmationModal.amount} */}
          {selectedPaymentTier === subscription?.tier &&
          this._checkForJoinEventBtn(selectedPaymentTier, paymentFor) ? (
            <Button className="paymentBtnTier">Join</Button>
          ) : (
            <Button
              className="themeBtn addBtn"
              disabled={isBundleLoading ? true : false}
              onClick={() => this._paymentProcess()}
            >
              {isBundleLoading ? (
                <i className="fa fa-spinner fa-spin mr-1" />
              ) : null}{" "}
              Make Payment
            </Button>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    updateOnlyUserData: (userData) => dispatch(updateOnlyUserData(userData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentTierComponent);
