import { createSlice } from "@reduxjs/toolkit";
import {
  storeFeeds,
  clearAllFeedData,
  updateAPost,
  updateList,
  getAndUpdateAllCreatorBundles,
  fetchPosts,
} from "../actions";

const initialState = {
  feeds: [],
  total: 0,
  bundles: [],
  subCount: 0, // subscription count
};

const feedDataSlice = createSlice({
  name: "feedData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(storeFeeds, (state, action) => {
        state.feeds = action.payload.feeds;
        state.total = action.payload.total;
        state.subCount = action.payload.subCount;
      })

      .addCase(updateAPost, (state, action) => {
        const { index, attr, value } = action.payload;
        state.feeds[index][attr] = value;
      })

      .addCase(updateList, (state, action) => {
        const { index, post } = action.payload;
        state.feeds[index] = post;
      })

      .addCase(clearAllFeedData, (state, action) => {
        state.feeds = [];
        state.total = 0;
        state.bundles = [];
        state.subCount = 0;
      })

      .addCase(fetchPosts.fulfilled, (state, action) => {
        const { posts, total, subCount, isConcat } = action.payload;

        if (isConcat) {
          state.feeds = state.feeds.concat(posts);
        } else {
          state.feeds = posts;
        }

        state.total = total;
        state.subCount = subCount;
      })

      .addCase(getAndUpdateAllCreatorBundles.fulfilled, (state, action) => {
        state.bundles = action.payload;
      });
  },
});

export const feedDataReducer = feedDataSlice.reducer;
