import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  CustomInput,
} from "reactstrap";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import {
  capitalizeEveryFirstLetter,
  getSocialLink,
  formatCurrencyValue,
  openSocialLink,
  getLowResolutionLink,
} from "../../helper-methods";

const ManageSubscriptionModal = ({
  isOpen,
  toggle,
  subscription,
  basicBundles,
  plusBundles,
  premiumBundles,
  selectedTier,
  changeSubscription,
  isRenewal,
  getBasicPriceDetails,
  loading = false,
}) => {
  const [selectedBillingTier, setSelectedBillingTier] = useState(""); // basic, plus, premium
  const [selectedSubscriptionPeriod, setSelectedSubscriptionPeriod] =
    useState(""); // 90, 180, 270, 360
  const [currentBundles, setCurrentBundles] = useState([]);

  const _closeModal = () => {
    toggle();
  };

  const _getBundles = (tier = "") => {
    switch (tier) {
      case "basic":
        return basicBundles;

      case "plus":
        return plusBundles;

      case "premium":
        return premiumBundles;

      default:
        return basicBundles;
    }
  };

  const _onChangeBillingTier = (tier) => {
    setSelectedBillingTier(tier);

    const newCurrentBundles = _getBundles(tier);

    setCurrentBundles(newCurrentBundles);

    if (!newCurrentBundles?.length) {
      setSelectedSubscriptionPeriod("");
      return;
    }

    if (subscription.tier !== tier) {
      const newSelectedBundleCycle = newCurrentBundles.find(
        (bundle) => bundle.subscriptionPeriod === subscription?.billingCycle
      );

      setSelectedSubscriptionPeriod(
        newSelectedBundleCycle?.subscriptionPeriod
          ? newSelectedBundleCycle?.subscriptionPeriod?.toString()
          : ""
      );
    } else {
      const newSelectedBundleCycle = newCurrentBundles.find(
        (bundle) => bundle.subscriptionPeriod > subscription?.billingCycle
      );

      setSelectedSubscriptionPeriod(
        newSelectedBundleCycle?.subscriptionPeriod
          ? newSelectedBundleCycle?.subscriptionPeriod?.toString()
          : ""
      );
    }
  };

  const _onChangeSubscriptionPeriod = (subscriptionPeriod) => {
    setSelectedSubscriptionPeriod(subscriptionPeriod?.toString());
  };

  const _changeSubscription = () => {
    changeSubscription(
      selectedBillingTier,
      selectedSubscriptionPeriod,
      subscription
    );
  };

  const isMultiTierSubscription =
    subscription?._influencer?.multiTierSubscription;

  const subscriptionFees =
    subscription?._influencer?.subscriptionFees?.amount || 0;
  const plusSubscriptionFees =
    subscription?._influencer?.plusSubscriptionFees?.amount || 0;
  const premiumSubscriptionFees =
    subscription?._influencer?.premiumSubscriptionFees?.amount || 0;

  const _setModalState = (subscription) => {
    let newCurrentBundles = [];

    switch (selectedTier) {
      case "basic":
        newCurrentBundles = basicBundles;
        break;
      case "plus":
        newCurrentBundles = plusBundles;
        break;
      case "premium":
        newCurrentBundles = premiumBundles;
        break;
      default:
        newCurrentBundles = basicBundles;
    }

    setSelectedBillingTier(selectedTier);
    setCurrentBundles(newCurrentBundles);

    if (!newCurrentBundles?.length) {
      setSelectedSubscriptionPeriod("");
      return;
    }

    if (subscription.tier !== selectedTier) {
      const newSelectedBundleCycle = newCurrentBundles.find(
        (bundle) => bundle.subscriptionPeriod === subscription?.billingCycle
      );

      setSelectedSubscriptionPeriod(
        newSelectedBundleCycle?.subscriptionPeriod
          ? newSelectedBundleCycle?.subscriptionPeriod?.toString()
          : ""
      );
    } else {
      const newSelectedBundleCycle = newCurrentBundles.find(
        (bundle) => bundle.subscriptionPeriod > subscription?.billingCycle
      );

      setSelectedSubscriptionPeriod(
        newSelectedBundleCycle?.subscriptionPeriod
          ? newSelectedBundleCycle?.subscriptionPeriod?.toString()
          : ""
      );
    }
  };

  useEffect(() => {
    if (isOpen && subscription) {
      _setModalState(subscription);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, subscription]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalHeader toggle={() => _closeModal()}>
        {isRenewal ? "Restart Subscription" : "Manage Your Subscription"}
      </ModalHeader>
      {subscription ? (
        <>
          <ModalBody className="modalScrollBar">
            <div className="d-flex align-items-center">
              <img
                src={
                  getLowResolutionLink(
                    subscription?._influencer?.profilePicture
                  ) || DEFAULT_PROFILE_PICTURE
                }
                onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                alt="Profile Pic"
                className="img60by60"
              />

              <div className="w-100 ml-2">
                <div className="influencerName mt-0">
                  {/* <h3>
                        {subscription &&
                          subscription._influencer &&
                          subscription._influencer.name.full}
                      </h3> */}
                  <Button
                    color="link"
                    className="otherPgsProfileID text-decoration-none"
                  >
                    @{subscription?._influencer?.username}
                  </Button>
                </div>

                {subscription?._influencer?.social?.length ? (
                  <div className="d-flex justify-content-start mt-1">
                    {getSocialLink(subscription._influencer.social, "facebook")
                      .length ? (
                      <Button
                        size="sm"
                        className="btn-facebook btn-brand mr-2"
                        title={getSocialLink(
                          subscription._influencer.social,
                          "facebook"
                        )}
                        onClick={() =>
                          openSocialLink(
                            getSocialLink(
                              subscription._influencer.social,
                              "facebook"
                            )
                          )
                        }
                      >
                        <i className="fa fa-facebook" />
                      </Button>
                    ) : null}
                    {getSocialLink(subscription._influencer.social, "twitter")
                      .length ? (
                      <Button
                        size="sm"
                        className="btn-twitter btn-brand mr-2"
                        title={getSocialLink(
                          subscription._influencer.social,
                          "twitter"
                        )}
                        onClick={() =>
                          openSocialLink(
                            getSocialLink(
                              subscription._influencer.social,
                              "twitter"
                            )
                          )
                        }
                      >
                        <i className="fa fa-twitter" />
                      </Button>
                    ) : null}
                    {getSocialLink(subscription._influencer.social, "instagram")
                      .length ? (
                      <Button
                        size="sm"
                        className="btn-instagram btn-brand mr-2"
                        title={getSocialLink(
                          subscription._influencer.social,
                          "instagram"
                        )}
                        onClick={() =>
                          openSocialLink(
                            getSocialLink(
                              subscription._influencer.social,
                              "instagram"
                            )
                          )
                        }
                      >
                        <i className="fa fa-instagram" />
                      </Button>
                    ) : null}

                    {getSocialLink(subscription._influencer.social, "youtube")
                      .length ? (
                      <Button
                        size="sm"
                        className="btn-youtube btn-brand mr-2"
                        title={getSocialLink(
                          subscription._influencer.social,
                          "youtube"
                        )}
                        onClick={() =>
                          openSocialLink(
                            getSocialLink(
                              subscription._influencer.social,
                              "youtube"
                            )
                          )
                        }
                      >
                        <i className="fa fa-youtube" />
                      </Button>
                    ) : null}
                    {getSocialLink(subscription._influencer.social, "tiktok")
                      .length ? (
                      <Button
                        size="sm"
                        className="btn-tiktok btn-brand"
                        title={getSocialLink(
                          subscription._influencer.social,
                          "tiktok"
                        )}
                        onClick={() =>
                          openSocialLink(
                            getSocialLink(
                              subscription._influencer.social,
                              "tiktok"
                            )
                          )
                        }
                      >
                        <img
                          src={"/assets/img/tiktok.png"}
                          alt="TikTok"
                          className="tiktok"
                          loading="lazy"
                        />
                      </Button>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
            {subscription && !isRenewal && !subscription?.isExpired ? (
              <div className="currentPlan">
                Current Plan:
                {isMultiTierSubscription ? (
                  <>
                    <img
                      src={`/assets/img/${subscription.tier}-tier.png`}
                      alt="Tier Icon"
                      loading="lazy"
                    />
                    <span className="themeColor mr-1">
                      {capitalizeEveryFirstLetter(subscription.tier)} Member
                    </span>
                  </>
                ) : null}
                <span>
                  ({formatCurrencyValue(subscription.amount)}/
                  {moment(subscription.end).diff(
                    moment(subscription.start),
                    "days"
                  )}{" "}
                  days)
                </span>
              </div>
            ) : null}

            <div className="editSubscription">
              {isMultiTierSubscription ? (
                <div>
                  <div className="sectionTitle">
                    <h4>Change Tier</h4>
                  </div>

                  <div>
                    <CustomInput
                      type="radio"
                      id="manageSubscriptionCustomRadioForBasic"
                      name="customRadio"
                      label={`Basic ${
                        subscriptionFees
                          ? `(${formatCurrencyValue(subscriptionFees)})`
                          : ""
                      }`}
                      onChange={() => _onChangeBillingTier("basic")}
                      checked={selectedBillingTier === "basic"}
                      disabled={!getBasicPriceDetails(subscription).isActive}
                    />

                    {subscription?._influencer && isMultiTierSubscription ? (
                      <>
                        {plusSubscriptionFees || plusBundles?.length ? (
                          <>
                            {/* // plusRadioBtn */}
                            <CustomInput
                              type="radio"
                              id="manageSubscriptionCustomRadioForPlus"
                              name="customRadio"
                              checked={selectedBillingTier === "plus"}
                              label={`Plus ${
                                plusSubscriptionFees
                                  ? `(${formatCurrencyValue(
                                      plusSubscriptionFees
                                    )})`
                                  : ""
                              }`}
                              onChange={() => _onChangeBillingTier("plus")}
                              disabled={
                                !getBasicPriceDetails(subscription).isActive
                              }
                            />
                          </>
                        ) : null}

                        {premiumSubscriptionFees || premiumBundles?.length ? (
                          <>
                            {/* // premiumRadioBtn */}
                            <CustomInput
                              type="radio"
                              id="manageSubscriptionCustomRadioForPremium"
                              name="customRadio"
                              checked={selectedBillingTier === "premium"}
                              label={`Premium ${
                                premiumSubscriptionFees
                                  ? `(${formatCurrencyValue(
                                      premiumSubscriptionFees
                                    )})`
                                  : ""
                              }`}
                              onChange={() => _onChangeBillingTier("premium")}
                            />
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                </div>
              ) : null}

              <div>
                <div className="sectionTitle mb-1">
                  <h4>Unlock Bundle Offer</h4>
                  {currentBundles?.length > 0 && (
                    <Button
                      color="link"
                      className="themeColor p-0"
                      onClick={() => setSelectedSubscriptionPeriod("")}
                    >
                      Reset
                    </Button>
                  )}
                </div>

                {currentBundles.length ? (
                  React.Children.toArray(
                    currentBundles.map((each, index) => (
                      <CustomInput
                        key={index}
                        type="radio"
                        id={`currentBundleCustomRadio${index}`}
                        name="customRadioBilling"
                        value={selectedSubscriptionPeriod}
                        label={`${Math.floor(
                          each.subscriptionPeriod / 30
                        )} Months (${formatCurrencyValue(each.price)})`}
                        onChange={() =>
                          _onChangeSubscriptionPeriod(each.subscriptionPeriod)
                        }
                        checked={
                          selectedSubscriptionPeriod ===
                          each.subscriptionPeriod.toString()
                        }
                      />
                    ))
                  )
                ) : (
                  <p>
                    No bundle available{" "}
                    {isMultiTierSubscription ? "for this tier" : null}
                  </p>
                )}
              </div>
            </div>
            {subscription && isRenewal ? (
              <h4 className="currentPlan mt-3">
                Last Plan:
                <span className="themeColor">
                  <img
                    src={`/assets/img/${subscription.tier}-tier.png`}
                    alt="Plus Tier"
                    loading="lazy"
                  />{" "}
                  {capitalizeEveryFirstLetter(subscription.tier)} Member
                </span>
                <span>
                  ({formatCurrencyValue(subscription.amount)}/
                  {subscription?.billingCycle?.toString()} days)
                </span>
              </h4>
            ) : null}
          </ModalBody>
        </>
      ) : null}

      <ModalFooter>
        <Button className="modalBtnCancel" onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button
          className="modalBtnSave"
          onClick={() => _changeSubscription()}
          disabled={loading}
        >
          {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ManageSubscriptionModal;
