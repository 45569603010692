import { createSlice } from "@reduxjs/toolkit";
import { toggleFloatingMode } from "../actions";

const initialState = {
  floatingFeedId: null,
};

const videoPlayerSlice = createSlice({
  name: "videoPlayer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(toggleFloatingMode, (state, action) => {
      if (state.floatingFeedId === action.payload) {
        state.floatingFeedId = null;
      } else {
        state.floatingFeedId = action.payload;
      }
    });
  },
});

export const videoPlayerReducer = videoPlayerSlice.reducer;
