import { createSlice } from "@reduxjs/toolkit";
import { getAndUpdateInfluencerList } from "../actions";

const initialState = {
  influencers: [],
};

const filterListDataSlice = createSlice({
  name: "filterListData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getAndUpdateInfluencerList.pending, (state) => {
        state.loading = true;
      })

      .addCase(getAndUpdateInfluencerList.fulfilled, (state, action) => {
        state.influencers = action.payload;
      });
  },
});

export const filterListDataReducer = filterListDataSlice.reducer;
