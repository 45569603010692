import React, { Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import { Card, CardBody, Col, Row } from "reactstrap";

const SkeletonVault = ({ count }) => {
  return (
    <>
      <div className="creatorInfoVault">
        <div className="d-flex justify-content-start align-items-center">
          <div className="position-relative">
            <Skeleton circle height={50} width={50} className="mr-1" />
          </div>
          <h5>
            <Skeleton height={15} width={Math.floor(Math.random() * 70 + 65)} />
          </h5>
        </div>
        {/* Below is the creator bio. It should not exceed 600 characters */}
        <p>
          <Skeleton
            height={13}
            width={Math.random() * 140 + 130}
            // className="float-left mb-5"
          />
        </p>
      </div>

      <Row className="noMargin">
        {[...Array(count)].map((_, index) => (
          <Col xs="6" sm="6" lg="4" className="vaultCustomCol" key={`item-${index}`}>
            <Card className="vaultFolderWrap">
              <CardBody>
                <Skeleton height={"100%"} width={"100%"} borderRadius={16} style={{verticalAlign: 3}} />
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default SkeletonVault;
