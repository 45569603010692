import React from "react";
import { Button } from "reactstrap";
import { TERMS_OF_SERVICE_URL } from "../config";
import { useHistory } from "react-router-dom";

const ScreenshotAlertPage = () => {
  const history = useHistory();

  const _goBack = () => {
    history.goBack();
  };

  const _redirectToTermPage = () => {
    window.open(TERMS_OF_SERVICE_URL, "_blank");
  };

  return (
    <div className="screenshotAlertWrap">
      <i className="fa fa-ban" />
      <h4>Screenshots NOT allowed!</h4>

      <p>
        Our system detected a screenshot and informed the owner. Warning: Users
        whom take content without expressed permission will be prosecuted.
      </p>

      <Button onClick={() => _goBack()} className="themeBtn">
        Return
      </Button>

      <Button
        onClick={() => _redirectToTermPage()}
        color="link"
        className="termsBtn"
      >
        Terms and Conditions
      </Button>
    </div>
  );
};

export default ScreenshotAlertPage;
