import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { errorHandler } from "../helper-methods";
import { getPublicShoutoutDetails } from "../http-calls/index";
import { showLoader, hideLoader } from "../redux/actions/loaderData";

const PublicShoutoutDetails = () => {
  const history = useHistory();

  const params = useParams();

  const dispatch = useDispatch();

  const [shoutoutData, setShoutoutData] = useState({
    contentType: null,
    url: null,
  });

  const _getPublicShoutoutDetails = async () => {
    try {
      dispatch(showLoader("Loading..."));

      const res = await getPublicShoutoutDetails(params?.id);

      dispatch(hideLoader());

      if (res?.contentType && res?.url) {
        setShoutoutData({
          contentType: res.contentType,
          url: res.url,
        });
      } else {
        history.replace("/login");
      }
    } catch (error) {
      dispatch(hideLoader());
      errorHandler({
        reason: "Data not found",
      });
      history.replace("/login");
    }
  };

  useEffect(() => {
    _getPublicShoutoutDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _publicShoutoutContent = () => {
    switch (shoutoutData?.contentType) {
      case "video": {
        return (
          <video
            width="100%"
            height="100%"
            src={shoutoutData.url}
            controls
            controlsList="nodownload"
            disablePictureInPicture
          />
        );
      }
      case "audio": {
        return (
          <audio
            ref="audioSource"
            controls="controls"
            src={shoutoutData.url}
            style={{ width: "100%" }}
          />
        );
      }
      default: {
        return "No Content Found";
      }
    }
  };

  return (
    <div className="publicShoutoutContent">{_publicShoutoutContent()}</div>
  );
};

export default PublicShoutoutDetails;
