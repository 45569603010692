import { createSlice } from "@reduxjs/toolkit";
import { clearSearchResult } from "../actions";
import { searchInluencersAsync } from "../actions/searchInfluencers";

const initialState = {
  searchResult: [],
  isLoading: false,
  error: null,
  hasMore: false,
};

const searchInfluencersSlice = createSlice({
  name: "searchInfluencer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(clearSearchResult, (state) => {
        state.searchResult = [];
      })

      .addCase(searchInluencersAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })

      .addCase(searchInluencersAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.searchResult = !action.payload?.metaData?.skip
          ? action.payload?.response?.influencers
          : [...state.searchResult, ...action.payload?.response?.influencers];
        state.hasMore =
          state?.searchResult?.length < action.payload?.response?.count;
      })

      .addCase(searchInluencersAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.searchError = action.error;
      });
  },
});

export const searchedInfluencersReducer = searchInfluencersSlice.reducer;
