import React, { useState, useEffect } from "react";
import {
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";
import { errorHandler, showToast } from "../helper-methods";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../redux/actions";
import { useRef } from "react";
import { CURRENCY_TYPE } from "../config";

const ApplePayment = ({
  content,
  createPaymentIntent,
  onPaymentSuccess,
  onDismiss,
}) => {
  const dispatch = useDispatch();

  const paymentRequestTimerRef = useRef({ current: null });

  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);

  const _createPaymentRequest = () => {
    try {
      if (paymentRequestTimerRef?.current) {
        clearTimeout(paymentRequestTimerRef.current);
      }

      setPaymentRequest(null);

      const amount = +content?.price;

      if (!stripe || !amount) {
        return;
      }

      paymentRequestTimerRef.current = setTimeout(() => {
        const pr = stripe.paymentRequest({
          country: "US",
          currency: CURRENCY_TYPE,
          total: {
            label: content?.name || content?.text || "",
            amount: amount.toFixed(2) * 100,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });

        // Check the availability of the Payment Request API.
        pr.canMakePayment()
          .then((result) => {
            console.log({ result });
            if (result) {
              setPaymentRequest(pr);
            }
          })
          .catch((error) => {
            console.log({ error });
          });

        pr.on("paymentmethod", async (ev) => {
          try {
            dispatch(showLoader("Payment is being processing"));

            // createPaymentIntent get from props
            const intentResponse = await createPaymentIntent();

            const clientSecret = intentResponse.secret;

            // Confirm the PaymentIntent without handling potential next actions (yet).
            const { paymentIntent, error: confirmError } =
              await stripe.confirmCardPayment(
                clientSecret,
                { payment_method: ev.paymentMethod.id },
                { handleActions: false }
              );

            if (confirmError) {
              // Report to the browser that the payment failed, prompting it to
              // re-show the payment interface, or show an error message and close
              // the payment interface.
              ev.complete("fail");
              showToast("Payment has been failed", "error");
            } else {
              // Report to the browser that the confirmation was successful, prompting
              // it to close the browser payment method collection interface.
              ev.complete("success");
              // Check if the PaymentIntent requires any actions and, if so, let Stripe.js
              // handle the flow. If using an API version older than "2019-02-11"
              // instead check for: `paymentIntent.status === "requires_source_action"`.
              if (paymentIntent.status === "requires_action") {
                // Let Stripe.js handle the rest of the payment flow.
                const { error } = await stripe.confirmCardPayment(clientSecret);
                if (error) {
                  // The payment failed -- ask your customer for a new payment method.
                  showToast("Payment has been failed", "error");
                } else {
                  // The payment has succeeded -- show a success message to your customer.
                  showToast("Payment has been succeeded", "success");
                  onPaymentSuccess();
                }
              } else {
                // The payment has succeeded -- show a success message to your customer.
                showToast("Payment has been succeeded", "success");
                onPaymentSuccess();
              }
            }

            dispatch(hideLoader());
            onDismiss();
          } catch (error) {
            ev.complete("fail");
            errorHandler(error);
            dispatch(hideLoader());
            onDismiss();
          }
        });
      }, 1000);
    } catch (error) {
      dispatch(hideLoader());
      errorHandler(error);
    }
  };

  useEffect(() => {
    _createPaymentRequest();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, content?.price]);

  if (paymentRequest) {
    return (
      <>
        <div className="m-2" style={{ width: "100px" }}>
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        </div>
      </>
    );
  }

  return <></>;
};

export default ApplePayment;
