import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { reactHtmlParser } from "../../helper-methods";

const PromptModal = ({
  isVisible,
  message,
  successButtonText,
  closeButtonText,
  isEmphasisNo,
  onSuccess,
  onDismiss,
}) => {
  const _onSuccess = () => {
    onSuccess();
  };

  const _onClose = () => {
    onDismiss();
  };

  return (
    <Modal
      isOpen={isVisible}
      toggle={() => _onClose()}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      {message && message.length ? (
        <ModalBody className="py-4 text-center">
          <h4 className="customModalHeader">{reactHtmlParser(message)}</h4>

          <div className="mt-5">
            <Button
              className={isEmphasisNo ? "modalBtnYes mr-2" : "modalBtnNo"}
              onClick={() => _onClose()}
            >
              {closeButtonText?.length ? closeButtonText : "No"}
            </Button>

            <Button
              className={isEmphasisNo ? "modalBtnNo mr-0" : "modalBtnYes"}
              onClick={() => _onSuccess()}
            >
              {successButtonText?.length ? successButtonText : "Yes"}
            </Button>
          </div>
        </ModalBody>
      ) : null}
    </Modal>
  );
};

export default PromptModal;
