import React, { Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import { ListGroup, ListGroupItem } from "reactstrap";

const SkeletonPaymentHistory = ({ count }) => {
  return (
    <Fragment>
      <p className="paymentDay">
        <Skeleton height={13} width={90} />
      </p>

      {[...Array(count)].map((_, index) => (
        <ListGroup className="paymentHistoryList" key={index}>
          <ListGroupItem>
            <div className="d-flex align-items-center">
              <Skeleton circle height={50} width={50} className="mr-1" />

              <div className="ml-2">
                {/* description */}
                <p>
                  <Skeleton
                    height={12}
                    width={Math.floor(Math.random() * 125 + 120)}
                    className="mt-3"
                  />
                </p>
                {/* date */}
                <div className="paymentDate">
                  <Skeleton height={12} width={100} />
                </div>
              </div>
            </div>
            {/* amount */}
            <div className="amtPaid">
              <Skeleton height={18} width={30} />
            </div>
          </ListGroupItem>
        </ListGroup>
      ))}
    </Fragment>
  );
};

export default SkeletonPaymentHistory;
