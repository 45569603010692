import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { getMessageParticipants } from "../../helper-methods";
import {
  fetchAllThreads,
  getAllSubscriptionDetails,
  getMessagesOfAThread,
  getUnreadMessageCount,
} from "../../http-calls";
import moment from "moment";

const clearAllMessageData = createAction("clearAllMessageData");
const unreadMessageCountUpdate = createAction("unreadMessageCountUpdate");
const updateSelectedThread = createAction("updateSelectedThread");
const updateMessageKey = createAction("updateMessageKey");
const addLocalMessage = createAction("addLocalMessage");

const getAndUpdateChatThreads = createAsyncThunk(
  "chatData/getAndUpdateChatThreads",
  async (payload, thunkAPI) => {
    const res = await fetchAllThreads(payload);

    const threads = res.threads?.map((thread) => {
      const { fan, influencer } = getMessageParticipants(thread);
      return {
        ...thread,
        fan,
        influencer,
      };
    });

    return {
      threads,
      threadsCount: res.count,
      isConcat: payload?.skip > 0 ? true : false,
    };
  }
);

const getAndUpdateMessages = createAsyncThunk(
  "chatData/getAndUpdateMessages",
  async (payload, thunkAPI) => {
    const res = await getMessagesOfAThread(payload);

    const { fan, influencer } = getMessageParticipants(res?.thread);

    const thread = {
      ...res?.thread,
      fan,
      influencer,
    };

    thread._messages = thread._messages.map((each) => ({
      ...each,
      time: moment(each.timestamp).format("h:mm A"),
      isMyMessage: each._from.userType === "Fan",
      hasMedia: each.content?.length ? true : false,
      isPublic: each?.content?.[0]?.amount > 0 ? false : true,
    }));

    thread._messages = thread._messages.reverse();

    return {
      thread,
      totalCount: res.totalCount,
      isConcat: payload?.payload?.skip > 0 ? true : false,
    };
  }
);

const getAndUpdateChatCreator = createAsyncThunk(
  "chatData/getAndUpdateChatCreator",
  async (payload, thunkAPI) => {
    const res = await getAllSubscriptionDetails();

    const validSubscriptions = res?.subscriptions?.filter((subscription) => {
      if (
        !subscription.isBlocked &&
        !subscription.isCountryBlocked &&
        !subscription.isExpired
      ) {
        return true;
      }
      return false;
    });

    const creators = validSubscriptions.map(
      (subscription) => subscription._influencer
    );

    return creators;
  }
);

const getUnreadMessageCountAction = createAsyncThunk(
  "chatData/getUnreadMessageCountAction",
  async (payload, thunkAPI) => {
    const res = await getUnreadMessageCount();
    return { unreadMessageCount: res?.count, unreadThreadIds: res?.threadIds };
  }
);

export {
  addLocalMessage,
  clearAllMessageData,
  updateSelectedThread,
  getAndUpdateMessages,
  updateMessageKey,
  unreadMessageCountUpdate,
  getAndUpdateChatCreator,
  getUnreadMessageCountAction,
  getAndUpdateChatThreads,
};
