import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isUserAuthenticated } from "../guards/auth-guard";
import { getFeatureStatus } from "../helper-methods";

const ProtectedFeatureRoute = ({
  component: Component,
  queryParam = "",
  ...rest
}) => {
  const isFeatureActive = getFeatureStatus(rest?.featureName);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isUserAuthenticated() && isFeatureActive) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: rest.redirectRoute,
                search: window.location.search || queryParam || "",
                extras: { ...rest.location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedFeatureRoute;
