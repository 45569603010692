import React from "react";
import { Button } from "reactstrap";
import {
  errorHandler,
  formatCurrencyValue,
  isSubscriptionPriceExist,
  showToast,
} from "../helper-methods";
import moment from "moment";
import { createPaymentIntent, getAllBundles } from "../http-calls";
import ProcessPayment from "../payment/index";
import PaymentHoc from "./PaymentHoc";
import { useEffect } from "react";
import { useState } from "react";
import { hideLoader, showLoader } from "../redux/actions";
import { useDispatch } from "react-redux";
import { tiersConfig } from "../config/helper-config";

const FeedPaymentTier = ({
  subscription,
  influencer,
  paymentFor,
  feedBundles,
  requestUpdate,
  unlockMethod,
  onPaymentComplete,
  feedIndex,
}) => {
  const dispatch = useDispatch();

  const [selectedPaymentTier, setSelectedPaymentTier] = useState("");
  const [isBundleLoading, setIsBundleLoading] = useState(false);
  const [selectedBundleId, setSelectedBundleId] = useState(null);
  const [paymentApiData, setPaymentApiData] = useState(null);
  const [paymentHoc, setPaymentHoc] = useState({
    isOpen: false,
    amount: 0,
  });

  useEffect(() => {
    if (subscription?.tier) {
      setSelectedPaymentTier(subscription.tier);
    }
  }, [subscription]);

  const _getRemainingPrice = (price) => {
    let usedUpRatio =
      moment().diff(subscription.end, "h") /
      moment(subscription.start).diff(subscription.end, "h");

    let remainungMoneyOfSubscription = subscription.amount * usedUpRatio;

    price = price - remainungMoneyOfSubscription;

    return Number(price).toFixed(2);
  };

  const _makeApiData = (tier) => {
    return new Promise(async (resolve) => {
      try {
        if (!subscription) {
          resolve(null);
        }

        if (subscription.tier === tier) {
          // belongs to same tier
          resolve(null);
        } else if (
          tiersConfig.indexOf(subscription.tier) < tiersConfig.indexOf(tier)
        ) {
          setIsBundleLoading(true);

          const bundlesRes = await getAllBundles({
            _influencer: influencer._id,
          });

          setIsBundleLoading(false);

          // checking for upper tier
          let currentBundle = bundlesRes?.bundles?.length
            ? bundlesRes.bundles.find(
                (bundle) =>
                  bundle._influencer === influencer._id &&
                  bundle.tier === tier &&
                  subscription.billingCycle === bundle.subscriptionPeriod
              )
            : null;

          if (currentBundle?.price) {
            resolve({
              bundleId: currentBundle._id,
              amount: _getRemainingPrice(currentBundle.price),
            });
          } else {
            // check monthly subscription
            let apiData = null;

            if (subscription.upgradeRestricted) {
              showToast("You can not upgrade your subscription", "error");
              resolve(null);
            }

            const filteredBundles = bundlesRes?.bundles?.length
              ? bundlesRes.bundles.filter((bundle) => bundle.tier === tier)
              : [];

            if (filteredBundles?.length) {
              let minimumSubscriptionPlan = filteredBundles.find(
                (bundle) =>
                  bundle.subscriptionPeriod === subscription.billingCycle
              );

              if (!minimumSubscriptionPlan) {
                minimumSubscriptionPlan = filteredBundles[0];
                filteredBundles.forEach((bundle) => {
                  if (
                    bundle.subscriptionPeriod <
                    minimumSubscriptionPlan?.subscriptionPeriod
                  ) {
                    minimumSubscriptionPlan = bundle;
                  }
                });
              }

              setSelectedBundleId(minimumSubscriptionPlan._id);

              apiData = {
                tier,
                amount: _getRemainingPrice(minimumSubscriptionPlan.price),
                bundleId: minimumSubscriptionPlan._id,
              };
            } else {
              const filteredBundleAggregation = bundlesRes?.bundleAggregation
                ?.length
                ? bundlesRes.bundleAggregation.find(
                    (bundle) => bundle.tier === tier
                  )
                : null;
              if (filteredBundleAggregation?.price) {
                apiData = {
                  tier,
                  amount: _getRemainingPrice(filteredBundleAggregation.price),
                };
              }
            }

            resolve(apiData);
          }
        }
        resolve(null);
      } catch (error) {
        console.log("error>>", error);
        resolve(null);
      }
    });
  };

  const _paymentProcess = async (selectedPaymentTier) => {
    const newPaymentHoc = { ...paymentHoc };
    if (
      (subscription && subscription.tier) === selectedPaymentTier ||
      ((subscription && subscription.tier) !== selectedPaymentTier &&
        paymentFor.plusPrice === undefined &&
        paymentFor.premiumPrice === undefined)
    ) {
      // pay for view
      switch (selectedPaymentTier) {
        case "basic": {
          newPaymentHoc.amount = paymentFor.price;
          break;
        }
        case "plus": {
          newPaymentHoc.amount = paymentFor.plusPrice;
          break;
        }
        case "premium": {
          newPaymentHoc.amount = paymentFor.premiumPrice;
          break;
        }
        default:
      }

      newPaymentHoc.isOpen = true;

      setPaymentHoc(newPaymentHoc);
      setSelectedPaymentTier(selectedPaymentTier);
    } else {
      // upgrade tier
      if (subscription?.upgradeRestricted) {
        showToast("You can not upgrade your subscription", "error");
        return;
      }
      let apiData = await _makeApiData(selectedPaymentTier);

      if (!apiData) {
        return;
      } else {
        newPaymentHoc.amount = apiData.amount;
        newPaymentHoc.isOpen = true;
      }

      setPaymentHoc(newPaymentHoc);
      setSelectedPaymentTier(selectedPaymentTier);
      setPaymentApiData(apiData);
    }
  };

  const _dismissPaymentConfirmation = (_) => {
    setPaymentHoc({
      isOpen: false,
      amount: 0,
    });
  };

  const _closePaymentModal = () => {
    _dismissPaymentConfirmation();
    requestUpdate();
    dispatch(hideLoader());
  };

  const _paymentDone = async (card = null) => {
    let paymentApiData = {
      payload: {},
    };

    paymentApiData.payload = {
      tier: selectedPaymentTier,
    };

    if (selectedBundleId) {
      paymentApiData.payload["bundleId"] = selectedBundleId;
    }

    if (card) {
      paymentApiData.payload["card"] = card;
    }

    if (
      (subscription && subscription.tier) === selectedPaymentTier ||
      ((subscription && subscription.tier) !== selectedPaymentTier &&
        paymentFor.plusPrice === undefined &&
        paymentFor.premiumPrice === undefined)
    ) {
      unlockMethod(paymentHoc.amount, card);
      _dismissPaymentConfirmation();
    } else if (!subscription.upgradeRestricted) {
      dispatch(showLoader("Payment is processing"));

      try {
        paymentApiData.url3DSecure = `/payment/intent/subscription/upgrade/tier/${subscription?._influencer}`;
        paymentApiData.url = `/subscription/upgrade/tier/${subscription?._influencer}`;

        paymentApiData.influencer = paymentFor?._influencer;

        await ProcessPayment.startPaymentProcess(
          paymentApiData,
          paymentHoc.amount,
          "Put"
        );
        _closePaymentModal();
        showToast("Your subscription tier updated successfully", "success");

        // const fanDefaultCardCountry = await getFanDefaultCardCountry();

        // if (
        //   (fanDefaultCardCountry &&
        //     fanDefaultCardCountry.country &&
        //     europeCountyList.includes(fanDefaultCardCountry.country)) ||
        //   minAmountFor3DSecureEnable <= paymentHoc.amount ||
        //   (userData && userData.user && userData.user.isThreeDSecure) ||
        //   (userData && userData.user && userData.user.isRiskAssessment)
        // ) {
        //   // 3D Payment
        //   const intentResponse = await upgradeTierIntent(
        //     subscription._influencer,
        //     payload
        //   );
        //   if (PAYMENT_GATEWAY === "stripe") {
        //     await cardPayment(
        //       intentResponse.secret,
        //       intentResponse.defaultCard
        //     );
        //     _closePaymentModal();
        //     showToast("Your subscription tier updated successfully", "success");
        //   } else {
        //     openUrlForHumboldtPayment(
        //       intentResponse.url,
        //       "width=730,height=345,left=100,top=100,resizable=yes,scrollbars=yes",
        //       "upgrade"
        //     );

        //     // Subscribe fan for payment response
        //     subscribeForPayment(
        //       userData && userData.user && userData.user._id,
        //       (response) => {
        //         if (response.payment) {
        //           _closePaymentModal();
        //           showToast(
        //             "Your subscription tier updated successfully",
        //             "success"
        //           );
        //           unsubscribeForPayment()
        //         } else {
        //           _closePaymentModal();
        //           showToast(
        //             "Server error. Try again after sometime.",
        //             "error"
        //           );
        //           unsubscribeForPayment()
        //         }
        //       }
        //     );
        //   }
        // } else {
        //   // Normal Payment
        //   await upgradeTier(subscription._influencer, payload);
        //   _closePaymentModal();
        //   showToast("Your subscription tier updated successfully", "success");
        // }
      } catch (error) {
        dispatch(hideLoader());
        errorHandler(error);
      }
    } else {
      showToast("You can not upgrade your subscription", "error");
    }
  };

  const _checkUpgradeButtonDisableCondition = (tier) => {
    if (!subscription) {
      return true;
    }

    if (subscription && subscription.tier === tier) {
      // belongs to same tier
      return false;
    } else if (
      tiersConfig.indexOf(subscription.tier) < tiersConfig.indexOf(tier)
    ) {
      // checking for upper tier
      let currentBundles = feedBundles.filter(
        (bundle) =>
          bundle._influencer === influencer._id &&
          bundle.tier === tier &&
          subscription.billingCycle === bundle.subscriptionPeriod
      );
      if (currentBundles.length) {
        return false;
      } else {
        // check monthly subscription
        let isButtonDisabled = true;
        if (
          isSubscriptionPriceExist({
            tier,
            influencerId: influencer._id,
          })
        ) {
          isButtonDisabled = false;
        }
        return isButtonDisabled;
      }
    }
    return false;
  };

  // Payment Intent For Apple Pay
  const _createPaymentIntent = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await createPaymentIntent("/payment/intent/post", {
          influencerId: paymentFor?._influencer?.id,
          postId: paymentFor?._id,
        });

        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  };

  // Payment Success For Apple Pay
  const _paymentSuccess = () => {
    onPaymentComplete(feedIndex);
  };

  return (
    <>
      {/* {PAYMENT_GATEWAY === "stripe" ? (
          <PaymentModal
            // Apple pay relate
            content={
              selectedPaymentTier === subscription.tier
                ? {
                    name: "Post",
                    price: paymentFor.price,
                  }
                : null
            }
            createPaymentIntent={() => _createPaymentIntent()}
            onPaymentSuccess={() => _paymentSuccess()}
            // End here
            apiData={state.apiData}
            isVisible={paymentHoc.isOpen}
            amount={paymentHoc.amount}
            onPaymentConfirmation={() => _paymentDone()}
            onDismiss={() => _dismissPaymentConfirmation()}
          />
        ) : (
          <HumboldtPayment
            // Apple pay relate
            content={
              selectedPaymentTier === subscription.tier
                ? {
                    name: "Post",
                    price: paymentFor.price,
                  }
                : null
            }
            createPaymentIntent={() => _createPaymentIntent()}
            onPaymentSuccess={() => _paymentSuccess()}
            // End here
            apiData={state.apiData}
            isVisible={paymentHoc.isOpen}
            amount={paymentHoc.amount}
            onPaymentConfirmation={(card) => _paymentDone(card)}
            onDismiss={() => _dismissPaymentConfirmation()}
          />
        )} */}
      <PaymentHoc
        // Apple pay relate
        content={
          selectedPaymentTier === subscription?.tier
            ? {
                name: "Post",
                price:
                  subscription.tier === "plus"
                    ? paymentFor.plusPrice
                    : subscription.tier === "premium"
                    ? paymentFor.premiumPrice
                    : paymentFor.price,
              }
            : null
        }
        createPaymentIntent={() => _createPaymentIntent()}
        onPaymentSuccess={() => _paymentSuccess()}
        // End here
        apiData={paymentApiData}
        influencer={paymentFor?._influencer}
        isVisible={paymentHoc.isOpen}
        amount={paymentHoc.amount}
        onPaymentConfirmation={(card) => _paymentDone(card)}
        onDismiss={() => _dismissPaymentConfirmation()}
      />
      <div className="text-center w-100">
        {/* Premium Button */}
        {paymentFor &&
        paymentFor._influencer &&
        paymentFor._influencer.hasOwnProperty("multiTierSubscription") && // This is for is premium tier has or not
        paymentFor._influencer.multiTierSubscription &&
        isSubscriptionPriceExist({
          tier: "premium",
          influencerId: paymentFor._influencer?._id,
        }) ? (
          paymentFor && paymentFor.premiumPrice >= 0 ? (
            <>
              <Button
                className="paymentBtnTier"
                onClick={() => _paymentProcess("premium")}
                disabled={
                  _checkUpgradeButtonDisableCondition("premium") ||
                  isBundleLoading
                }
              >
                <span>
                  {paymentFor.premiumPrice === 0
                    ? "FREE"
                    : formatCurrencyValue(paymentFor.premiumPrice)}
                </span>
                &nbsp;
                {subscription && subscription.tier !== "premium" && (
                  <span>For Premium</span>
                )}
                {/* {paymentFor.premiumPrice == 0 ? (
                    <span>(View this event for Free.&nbsp;</span>
                  ) : subscription && subscription.tier !== "premium" ? (
                    "("
                  ) : null}
                  {subscription && subscription.tier !== "premium"
                    ? "Upgrade to Premium Membership)"
                    : paymentFor.premiumPrice == 0
                    ? ")"
                    : null} */}
              </Button>
              {subscription && subscription.tier !== "premium" && (
                <span
                  className="d-block text-center"
                  style={{ marginBottom: -5, fontSize: 11 }}
                >
                  Upgrade to Premium
                </span>
              )}
            </>
          ) : null
        ) : null}

        {/* Plus Button */}
        {paymentFor &&
        paymentFor._influencer &&
        paymentFor._influencer.hasOwnProperty("multiTierSubscription") && // This is for is plus tier has or not
        paymentFor._influencer.multiTierSubscription &&
        isSubscriptionPriceExist({
          tier: "plus",
          influencerId: paymentFor._influencer?._id,
        }) ? (
          paymentFor &&
          paymentFor.plusPrice >= 0 &&
          subscription &&
          (subscription.tier === "basic" || subscription.tier === "plus") ? (
            <div>
              <Button
                className="paymentBtnTier"
                onClick={() => _paymentProcess("plus")}
                disabled={
                  _checkUpgradeButtonDisableCondition("plus") || isBundleLoading
                }
              >
                <span>
                  {paymentFor.plusPrice === 0
                    ? "FREE"
                    : formatCurrencyValue(paymentFor.plusPrice)}
                </span>
                &nbsp;
                {subscription && subscription.tier !== "plus" && (
                  <span>For Plus</span>
                )}
                {/* {paymentFor.plusPrice == 0 ? (
                    <span>(View this event for Free.</span>
                  ) : subscription && subscription.tier !== "plus" ? (
                    "("
                  ) : null}
                  {subscription && subscription.tier === "basic"
                    ? " Upgrade to Plus Membership)"
                    : paymentFor.plusPrice == 0
                    ? ")"
                    : null} */}
              </Button>
              {subscription && subscription.tier === "basic" && (
                <span
                  className="d-block text-center"
                  style={{ marginBottom: -5, fontSize: 11 }}
                >
                  Upgrade to Plus
                </span>
              )}
            </div>
          ) : null
        ) : null}
        {paymentFor &&
        paymentFor.price >= 0 &&
        subscription &&
        (subscription.tier === "basic" ||
          (subscription.tier !== "basic" &&
            paymentFor.plusPrice === undefined &&
            paymentFor.premiumPrice === undefined)) ? (
          <Button
            className="paymentBtnTier"
            onClick={() => _paymentProcess("basic")}
            disabled={
              _checkUpgradeButtonDisableCondition("basic") || isBundleLoading
            }
          >
            <span>{formatCurrencyValue(paymentFor.price)}</span>&nbsp;
            {subscription && subscription.tier !== "basic" ? (
              paymentFor.plusPrice === undefined &&
              paymentFor.premiumPrice === undefined ? (
                <span>Unlock</span>
              ) : (
                <span>For Basic</span>
              )
            ) : null}
            {/* ({paymentFor.price == 0 ? <span>This event is free </span> : null}
              for Basic Members) */}
          </Button>
        ) : null}
      </div>

      {/* {paymentHoc.amount} */}
      {/* <Button
            className="paymentBtnTier"
            onClick={() => _paymentProcess()}
          >
            Make Payment
          </Button> */}
      {/* {selectedPaymentTier === "free" ? (
          <Button className="paymentBtnTier">
            <ConfigureString keyString={"Join event"} />
          </Button>
        ) : (
          <Button
            className="paymentBtnTier"
            onClick={() => _paymentProcess()}
          >
            Make Payment
          </Button>
        )} */}
    </>
  );
};

export default FeedPaymentTier;
