import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button,
  Input,
} from "reactstrap";
import CustomLoader from "../components/custom/CustomLoader";
import { DEFAULT_PROFILE_PICTURE } from "../config";
import { errorHandler, getLowResolutionLink } from "../helper-methods";
import { getAndUpdateChatCreator } from "../redux/actions";

const MessageInitiatorPage = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { creators, loading } = useSelector((state) => state?.chatData || {});

  const [searchText, setSearchText] = useState("");

  const filteredCreators = useMemo(() => {
    if (!creators?.length) {
      return [];
    }
    if (!searchText?.trim()) {
      return creators;
    }

    return creators.filter((each) =>
      each.username.toLowerCase().includes(searchText.trim().toLowerCase())
    );
  }, [creators, searchText]);

  const _getAndUpdateChatCreator = async () => {
    try {
      await getAndUpdateChatCreator()(dispatch);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    _getAndUpdateChatCreator();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="noMargin">
          <Col xs="12" className="noPadding">
            <div className="pgTitle justify-content-start customMarginMob">
              <Button
                className="customBackBtn"
                onClick={() => history.goBack()}
              >
                <i className="fa fa-chevron-left" />
              </Button>

              <div className="d-flex">
                <h2>New Message</h2>
                {loading ? <CustomLoader className="mx-2" /> : null}
              </div>
            </div>

            <div className="newMessageWrap">
              <div className="searchChatListWrap mt-0">
                <i className="fa fa-search searchIcon" />
                <Input
                  type="text"
                  placeholder="Add People"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                {searchText ? (
                  <i
                    className="fa fa-times clearSearch"
                    onClick={() => setSearchText("")}
                  />
                ) : null}
              </div>

              {filteredCreators?.length ? (
                <div className="recentWrap">
                  <p>Select an Influencer</p>
                </div>
              ) : null}

              <ListGroup className="chatList">
                {filteredCreators?.length ? (
                  filteredCreators.map((each, index) => (
                    <ListGroupItem key={index}>
                      <div
                        className="userChatList"
                        onClick={() =>
                          history.push(`/messages?creatorId=${each._id}`)
                        }
                      >
                        <img
                          className="userImg"
                          src={
                            getLowResolutionLink(each?.profilePicture) ||
                            DEFAULT_PROFILE_PICTURE
                          }
                          onError={(e) =>
                            (e.target.src = DEFAULT_PROFILE_PICTURE)
                          }
                          alt="Profile Img"
                          loading="lazy"
                        />

                        <div className="ml-2">
                          {/* {each.name.full} */}
                          <h5>@{each.username}</h5>
                        </div>
                      </div>
                    </ListGroupItem>
                  ))
                ) : (
                  <div className="noContentFound">
                    {loading ? (
                      <CustomLoader />
                    ) : (
                      `No Influencer found ${
                        searchText?.trim() ? `with "${searchText?.trim()}"` : ""
                      }`
                    )}
                  </div>
                )}
              </ListGroup>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MessageInitiatorPage;
