import React, {Fragment} from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonViewChatMedia = ({
  count,
  borderRadius = 8,
  height = '100%',
  width = '100%',
}) => {
  return (
    <div className="mediaLibraryMsgPg">
      <div className="notificationDay">
        <Skeleton height={12} width={45} borderRadius={4} />
      </div>
      {[...Array(count)].map((_, index) => (
        <Fragment key={`SkeletonViewChatMedia_${index}`}>
          <div className={`mediaLibraryFilesMsg`}>
            <Skeleton
              {...(height ? { height } : {})}
              {...(width ? { width } : {})}
              borderRadius={borderRadius}
              style={{verticalAlign: 3}}
            />
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default SkeletonViewChatMedia;
