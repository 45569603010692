import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonThreadList = ({ count }) => {
  return (
    <div>
      {[...Array(count)].map((_, index) => (
        <div
          className="d-flex align-items-center py-2"
          key={`item-${index}`}
        >
          {/* user profile picture */}
          <Skeleton circle height={40} width={40} className="mr-1" />

          {/* username and last message */}
          <div>
            <Skeleton height={12} width={80} />
            <Skeleton
              height={12}
              width={Math.floor(Math.random() * 90 + 85)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default SkeletonThreadList;
